@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,300;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,300;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,300;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,300;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800;900&display=swap);
* {
    font-family: 'Montserrat', sans-serif;
}

@media only screen and (min-width:1550px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }

    .hide_secure_main_mobile {
        display: none;
    }

    .verifying_yes_mob {
        display: none;
    }

    .yes_details_more {
        display: none;
    }

    #special_text_white2_new {
        display: none;
    }

    #special_text_white3_new {
        display: none;
    }

    .lynksign_data_mobile {
        display: none;
    }

    .working_steps_mobile_1 {
        display: none;
    }

    .working_steps_mobile_2 {
        display: none;
    }

    .secure_main_mobile {
        display: none;
    }

    .securing_file_div {
        display: none;
    }

    .securing_file_daata {
        display: none;
    }

    .file_securing {
        display: none;
    }

    .verify_mobile {
        display: none;
    }

    .hide_verify_mobile {
        display: none;
    }

    .verfiy_mob_1 {
        display: none;
    }

    .hide_verfiy_mob_1 {
        display: none;
    }

    .hide_verifying_yes_mob {
        display: none;
    }

    #special_text_1_new {
        display: none;
    }

    #special_text_2_new {
        display: none;
    }

    #special_text_white_new {
        display: none;
    }

    #special_text_white1_new {
        display: none;
    }

    .verifying_no_mob {
        display: none;
    }

    .hide_verifying_no_mob {
        display: none;
    }



    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;

    }



    #scrolling_to_top:hover {
        background-color: #ff7200;

    }

    .home_div_outer {
        width: 100%;
        height: 650px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
        /* display: none; */
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 87%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 710px;
        height: 100%;
    }

    .newLynkRow {
        width: 76%;
        height: 40%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;

    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 70%;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .bigElem1 {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 14rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 13px;
        text-align: left;
        justify-content: space-around;
    }

    .headSpan1 {
        font-size: 3.5rem;
        color: #989898;
        position: relative;
        top: 7px;
    }

    .headSpan2 {
        font-size: 4.5rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 13px;
    }

    .newLynkHeadBtn {
        width: 420px;
        height: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .lockw {
        width: 100%;
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .myLock {
        width: 60px;
        border-radius: 50%;
        border: 1px solid silver;
        padding: 12px;
    }

    .nodebtn {
        width: 300px;
        height: 79px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .nodebtn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 9%;
        margin-right: 18px;
    }

    .btnSpan {
        font-size: 0.9rem;
        color: #fff;
        font-weight: 600;
    }

    .btnNewSpan {
        font-size: 1rem;
        color: #fff;
    }



    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 740px;
        position: relative;
        top: 0px;
        /* background-color: grey; */

    }

    .lynksign_work_inner {
        width: 100%;
        height: 80px;
        position: relative;
        text-align: center;
        position: relative;
        top: 30px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 55px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
    }

    .one {
        width: 20%;
        height: 100%;

    }

    .one_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .one_text p {
        font-size: 16px;
        position: relative;
        top: 10px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted {
        width: 78%;
        height: 32%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .one_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder {
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one {
        font-size: 53px;
        position: relative;
        top: -10px;
        left: 85%;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        color: #ff7200;
    }

    #arrow_two {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 95%;
        -webkit-transform: rotate(-40deg);
                transform: rotate(-40deg);
        color: #ff7200;
    }

    #arrow_three {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 89%;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        color: #ff7200;
    }


    #arrow_four {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: -6%;
        -webkit-transform: rotate(-40deg);
                transform: rotate(-40deg);
        color: #ff7200;
    }


    /*  */
    .two {
        width: 20%;
        height: 100%;
    }

    .two_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 288px;
        /* background-color: #ff7200; */
    }

    .two_text p {
        font-size: 16px;
        position: relative;
        top: 10px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .two_dotted {
        width: 78%;
        height: 32%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .two_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock {
        width: 90px;
        height: 90px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    /*  */

    .three {
        width: 20%;
        height: 100%;
    }

    .three_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .three_text p {
        font-size: 16px;
        position: relative;
        top: 10px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted {
        width: 78%;
        height: 32%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .three_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen {
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    /*  */


    .four {
        width: 20%;
        height: 100%;
    }

    .four_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 278px;
        /* background-color: #ff7200; */
    }

    .four_text p {
        font-size: 16px;
        position: relative;
        top: 10px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted {
        width: 78%;
        height: 32%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .four_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .three_folder {
        width: 90px;
        height: 90px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }


    .five {
        width: 20%;
        height: 100%;
    }

    .five_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .five_text p {
        font-size: 16px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted {
        width: 78%;
        height: 32%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .five_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal {
        width: 44px;
        height: 35px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 30px;
    }

    .not_equal {
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 30px;
    }



    /*  */
    .lynksign_for {
        width: 100%;
        height: 620px;
        background-color: #3d3d3d;
        position: relative;
        top: 119px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 55px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 77%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 120px;
        justify-content: space-between;
        /* background-color: pink; */

    }



    /*  */
    #circle1 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 13px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line1 {
        border-left: 6px solid white;
        height: 303px;

        -webkit-transform: rotate(34.5deg);

                transform: rotate(34.5deg);
        /* position:absolute; */
    }

    /*  */

    #circle3 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 10px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    .curved_div_1 {
        width: 27%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;  */
        align-items: center;
        text-align: center;
        position: relative;


        /* background-color: antiquewhite; */

    }

    #circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;

        top: 12px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line {
        border-left: 6px solid white;
        height: 342px;
        /* transform: rotate(42.2deg); */
        position: relative;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;

    }

    .verify {
        position: relative;
        top: 45px;
    }

    .curved_div_1 p {
        color: white;
        position: relative;
        top: 90px;
        font-size: 16px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_2 {
        width: 27%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        /* border-left-color: transparent;   */
        align-items: center;
        position: relative;
        text-align: center;
    }

    .projection {
        position: relative;
        top: 45px;
    }

    .curved_div_2 p {
        color: white;
        position: relative;
        top: 95px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_3 {
        width: 25%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        position: relative;
        align-items: center;
        text-align: center;
    }

    .concept {
        position: relative;
        top: 45px;
    }

    .curved_div_3 p {
        color: white;
        position: relative;
        top: 90px;
        font-size: 16px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }




    /*  */
    .secure_verify_div {
        width: 100%;
        height: 550px;
        position: relative;
        top: 52px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 50px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;

    }

    .secure_inner h4 {
        font-size: 20px;
        font-weight: 500;
        padding-top: 49px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 22px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 230px;
        height: 60px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        -webkit-transform: translateY(-6px);

                transform: translateY(-6px);
        border-radius: 32px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }


    #upload-photo[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 18px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }







    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* background-color: pink; */
    }

    .verify_inner h2 {
        font-size: 50px;
        padding-left: 20px;
        padding-top: 10px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_inner h4 {
        font-size: 20px;
        font-weight: 500;
        padding-top: 52px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 22px;
        padding-left: 20px;
        padding-top: 44px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;


    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 240px;
        height: 60px;
        border: none;
        background-color: #ff7200;
        font-size: 21px;
        color: black;
        cursor: pointer;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 32px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 550px;
        position: relative;
        top: 52px;

    }

    .securing_main_div {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: black;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 50px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 20px;
        font-weight: 500;
        padding-top: 70px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 20px;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 30%;
        height: 60px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 15px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);

    }

    .another_upload:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;

    }

    .securing_form {
        width: 75%;
        height: 90%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .securing_form form {
        width: 100%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 60px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 22px;
        font-weight: 600;
        position: relative;
        top: 82px;
        left: -5px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        left: 30px;
        top: 82px;
    }

    .sign {
        width: 150px;
        height: 42px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 100px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 19px;
        font-family: 'Montserrat', sans-serif;
    }

    #label_timee {
        position: relative;
        left: 29px;
        top: 70px;
    }




    .sign:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
      
       
    }
    /* .overlay{
        background-color: rgba(49,49,49,0.8);
    } */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 550px;
        position: relative;
        top: 50px;
    }

    .visibleEye {
        position: absolute;
        top: 40px;
        right: 55px;
        cursor: pointer;
        /* color: #ff7200; */
    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 45px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 166px;
        position: relative;
        top: 155px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify h2 {
        font-size: 50px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 20px;
        padding-top: 50px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        text-align: center;
    }

    #to_left {
        /* position: absolute; */
        left: 0%;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
    }

    #to_left1 {
        position: relative;
        font-size: 15px;
        bottom: 10px;
        left: 6%;
        font-weight: 600;
        width: 88%;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 14px;
        position: relative;
        top: 0px;
        left: 1%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 14px;
        position: relative;
        top: 0px;
        left: 10px;
        display: inline-block;


    }

    .submit_id_1 {
        width: 20%;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        bottom: 50px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #get_id {
        position: relative;
        top: 0px;
    }

    .form_verifying form {
        width: 100%;
        height: 80%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 22px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 170px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 60px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 13px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 30px;
        width: 230px;
        background-color: #212121;
        color: #C0C0C0;
    }

    .submit_id {
        width: 170px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 55px;
        left: 20px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }






    .show_verifying_div_1 {
        width: 100%;
        height: 550px;
        position: relative;
        top: 50px;
        background-color: white;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }

    #locatione {
        color: #ff7200;
        width: auto;
        font-size: 8px;
        position: absolute;
        top: 70%;
        left: 62%;
        font-weight: 700;
        text-decoration: underline !important;
        cursor: pointer;
        z-index: 1;
        /* border: 1px solid black; */
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }




    .verified_yes {
        width: 100%;
        height: 550px;
        position: relative;
        top: 52px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 80%;
        height: 20%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: green; */

    }

    .yes_1 h2 {
        font-size: 32px;
        position: relative;
        top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }

    .yes_2 {
        width: 70%;
        height: 75%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .boxes {
        width: 100%;
        height: 40px;
        display: flex;
        /* background-color: red; */
    }

    .box_0 {
        width: 20%;
        height: 100%;
    }

    .box_1 {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .box_1 p {
        font-size: 1.3rem;
        font-weight: 600;
        color: #fff;
    }

    .box_2 {
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 20px;
    }

    #special_green {
        font-size: 25px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        text-align: center;
        bottom: -25px;
        font-weight: 700;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 18px !important;
        padding-left: 6px !important;
        font-weight: 600;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 18px !important;
        font-weight: 600;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 18px !important;
        font-weight: 600;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 18px !important;
        font-weight: 600;
    }



    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 100%;
        height: 40%;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 50px;
        padding-top: 40px;
        position: relative;
        color: #C0C0C0;
        top: -5px;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 20px;
        padding-top: 50px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */

    }



    #trxnid {
        position: relative;

        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;
    }

    #choose_file {
        position: relative;

        top: 41px;
        font-weight: 600;
        color: white;
        font-size: 18px;
    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
    }

    .submit_id_green {
        width: 140px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 90px;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 550px;
        position: relative;
        top: 50px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    #fetched_location {
        width: 260px;
        height: auto;
        display: inline-block;
        text-align: start;
        font-size: 14px;
        position: absolute;
        top: -7px;
        left: 102px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #212121;
        word-wrap: break-word;
    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 360px;
        cursor: pointer;
        color: #fff;
    }

    #hide_remove {
        display: none;
    }

    .no_1 {
        width: 79%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 35px;
        position: relative;
        top: 2px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 138px;
        position: relative;
        top: 120px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 50px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 20px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    #trxnid_no {
        position: relative;
        /* left: 6%; */
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
    }

    #choose_file_no {
        position: relative;
        /* left: 6%; */
        top: 40px;
        font-weight: 600;
        color: white;
        font-size: 18px;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 20px;
        text-decoration: underline;
        top: 0px;

        font-size: 14px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 12px;
        text-decoration: underline;
        top: 0px;
        font-size: 15px;
    }

    .submit_id_red {
        width: 140px;
        height: 40px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 86px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #server_time::-webkit-input-placeholder {
        padding-left: 0px;
        color: #C0C0C0;
        font-size: 17px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    #server_time:-ms-input-placeholder {
        padding-left: 0px;
        color: #C0C0C0;
        font-size: 17px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    #server_time::placeholder {
        padding-left: 0px;
        color: #C0C0C0;
        font-size: 17px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 5;

    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 22%;
        height: 400px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        -webkit-transform: translateY(40%);
                transform: translateY(40%);
        transition: -webkit-transform 0.1s;
        transition: transform 0.1s;
        transition: transform 0.1s, -webkit-transform 0.1s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        -webkit-animation: slide 0.1s;
                animation: slide 0.1s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        border: 3.5px solid #ff7200;
        z-index: 100;

    }

    @-webkit-keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }
    }

    @keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }
    }

    .otp_image {
        width: 86%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 99px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 68px;
    }

    .closeMe {
        width: 40px;
    }

    .otp_image h3 {
        font-size: 17px;
        position: relative;
        top: 80px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {

        padding: 5px;
        border: 2px solid #ccc;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 31px;
        width: 72%;
        height: 35px;
        letter-spacing: 1px;
        font-size: 1rem;
        background-color: #FFF;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 45px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }


    .Load,
    .loader_overlay {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;


    }

    .loader_overlay {
        background-color: rgba(49, 49, 49, 0.8);
    }

    .loader_content {
        position: absolute;
        width: 3.2%;
        height: 50px;
        top: 40%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        /* background-color: #fff; */
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;

    }

    .loadingg {

        margin-left: auto;
        margin-right: auto;

    }

    .ClienstElem {
        width: 100%;
        height: auto;
    }





























}

@media only screen and (max-width:1550px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    #hide {
        display: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    .uploadLoader {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .displayFlexMe {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .displayFlexMe>span {
        font-size: 1rem !important;
        color: #ff7200;
    }

    .Lo {
        width: 100%;
        position: relative;
        top: 2700px !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }

    .hide_secure_main_mobile {
        display: none;
    }

    #special_text_white2_new {
        display: none;
    }

    #special_text_white3_new {
        display: none;
    }

    .lynksign_data_mobile {
        display: none;
    }

    .working_steps_mobile_1 {
        display: none;
    }

    .working_steps_mobile_2 {
        display: none;
    }

    .secure_main_mobile {
        display: none;
    }

    .securing_file_div {
        display: none;
    }

    .securing_file_daata {
        display: none;
    }

    .file_securing {
        display: none;
    }

    .verify_mobile {
        display: none;
    }

    .hide_verify_mobile {
        display: none;
    }

    .verfiy_mob_1 {
        display: none;
    }

    .hide_verfiy_mob_1 {
        display: none;
    }

    .hide_verifying_yes_mob {
        display: none;
    }

    #special_text_1_new {
        display: none;
    }

    #special_text_2_new {
        display: none;
    }

    #special_text_white_new {
        display: none;
    }

    #special_text_white1_new {
        display: none;
    }

    .verifying_no_mob {
        display: none;
    }

    .hide_verifying_no_mob {
        display: none;
    }

    .verifying_yes_mob {
        display: none;
    }

    .yes_details_more {
        display: none;
    }

    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;

    }

    #scrolling_to_top:hover {
        background-color: #ff7200;

    }

    .home_div_outer {
        width: 100%;
        height: 650px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
        /* display: none; */
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 87%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 95%;
        height: 100%;
    }

    .newLynkRow {
        width: 76%;
        height: 40%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;

    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 70%;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .bigElem1 {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 13rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 13px;
        text-align: left;
        justify-content: space-around;
    }

    .headSpan1 {
        font-size: 3rem;
        color: #989898;
        position: relative;
        top: 7px;
    }

    .headSpan2 {
        font-size: 4rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 13px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 50%;
        height: 77px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        box-shadow: 0px 3px #404040;
    }

    .nodebtn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .nodebtn:focus:not(:focus-visible) {
        outline: none;
    }

    .btnImg {
        width: 9%;
        margin-right: 18px;
    }

    .btnSpan {
        font-size: 0.9rem;
        color: #fff;
        font-weight: 600;
    }

    .btnNewSpan {
        font-size: 1rem;
        color: #fff;
    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .lockw {
        width: 100%;
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .myLock {
        width: 60px;
        border-radius: 50%;
        border: 1px solid silver;
        padding: 12px;
    }


    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 740px;
        position: relative;
        top: 0px;
        /* background-color: grey; */

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 30px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 35px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
    }

    .one {
        width: 20%;
        height: 100%;

    }

    .one_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .one_text p {
        font-size: 15px;
        position: relative;
        top: 20px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted {
        width: 72%;
        height: 30%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 40px;
    }

    .one_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder {
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one {
        font-size: 53px;
        position: relative;
        top: -10px;
        left: 85%;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        color: #ff7200;
    }

    #arrow_two {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 95%;
        -webkit-transform: rotate(-40deg);
                transform: rotate(-40deg);
        color: #ff7200;
    }

    #arrow_three {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 89%;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        color: #ff7200;
    }


    #arrow_four {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: -6%;
        -webkit-transform: rotate(-40deg);
                transform: rotate(-40deg);
        color: #ff7200;
    }


    /*  */
    .two {
        width: 20%;
        height: 100%;
    }

    .two_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 288px;
        /* background-color: #ff7200; */
    }

    .two_text p {
        font-size: 15px;
        position: relative;
        top: 20px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .two_dotted {
        width: 72%;
        height: 30%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 290px;
    }

    .two_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock {
        width: 90px;
        height: 90px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    /*  */

    .three {
        width: 20%;
        height: 100%;
    }

    .three_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .three_text p {
        font-size: 15px;
        position: relative;
        top: 20px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted {
        width: 72%;
        height: 30%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 40px;
    }

    .three_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen {
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    /*  */


    .four {
        width: 20%;
        height: 100%;
    }

    .four_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 278px;
        /* background-color: #ff7200; */
    }

    .four_text p {
        font-size: 15px;
        position: relative;
        top: 30px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted {
        width: 72%;
        height: 30%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 290px;
    }

    .four_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .three_folder {
        width: 90px;
        height: 90px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }


    .five {
        width: 20%;
        height: 100%;
    }

    .five_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;

    }

    .five_text p {
        font-size: 15px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted {
        width: 72%;
        height: 30%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 40px;
    }

    .five_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal {
        width: 44px;
        height: 35px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 30px;
    }

    .not_equal {
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 30px;
    }

    /*  */
    .lynksign_for {
        width: 100%;
        height: 570px;
        background-color: #3d3d3d;
        position: relative;
        top: 53px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 35px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 77%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        /* background-color: pink; */

    }



    /*  */
    #circle1 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 13px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line1 {
        border-left: 6px solid white;
        height: 303px;

        -webkit-transform: rotate(34.5deg);

                transform: rotate(34.5deg);
        /* position:absolute; */
    }

    /*  */

    #circle3 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 10px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    .curved_div_1 {
        width: 27%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;  */
        align-items: center;
        text-align: center;
        position: relative;


        /* background-color: antiquewhite; */

    }

    #circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;

        top: 12px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line {
        border-left: 6px solid white;
        height: 342px;
        /* transform: rotate(42.2deg); */
        position: relative;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;

    }

    .verify {
        position: relative;
        top: 45px;
    }

    .curved_div_1 p {
        color: white;
        position: relative;
        top: 70px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_2 {
        width: 27%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        /* border-left-color: transparent;   */
        align-items: center;
        position: relative;
        text-align: center;
    }

    .projection {
        position: relative;
        top: 45px;
    }

    .curved_div_2 p {
        color: white;
        position: relative;
        top: 70px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_3 {
        width: 25%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        position: relative;
        align-items: center;
        text-align: center;
    }

    .concept {
        position: relative;
        top: 45px;
    }

    .curved_div_3 p {
        color: white;
        position: relative;
        top: 70px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }




    /*  */
    .secure_verify_div {
        width: 100%;
        height: 440px;
        position: relative;
        top: 52px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 440px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;

    }

    .secure_inner h4 {
        font-size: 18px;
        font-weight: 500;
        padding-top: 54px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 34%;
        height: 60px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        -webkit-transform: translateY(-6px);

                transform: translateY(-6px);
        border-radius: 32px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;

    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 18px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .verify_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .verify_inner h4 {
        font-size: 18px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 44px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;


    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 69%;
        height: 60px;
        border: none;
        background-color: #ff7200;
        font-size: 21px;
        color: black;
        cursor: pointer;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 32px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 400px;
        position: relative;
        top: 52px;

    }

    .securing_main_div {
        width: 100%;
        height: 440px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 18px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 30%;
        height: 60px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 15px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);

    }

    .another_upload:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;

    }

    .securing_form {
        width: 75%;
        height: 90%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 45px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 19px;
        font-weight: 600;
        position: relative;
        top: 32px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_form form ::-webkit-input-placeholder {
        font-size: 12px;
        font-weight: 600;

        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_form form :-ms-input-placeholder {
        font-size: 12px;
        font-weight: 600;

        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_form form ::placeholder {
        font-size: 12px;
        font-weight: 600;

        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        left: 30px;
        top: 30px;
    }

    #fetched_location {
        width: 250px;
        height: auto;
        /* border: 2px solid red;  */
        display: inline-block;
        text-align: start;
        font-size: 13px;
        position: absolute;
        top: -12px;
        left: 93px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #212121;
        word-wrap: break-word;

    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 360px;
        cursor: pointer;
        color: #fff;
    }

    #hide_remove {
        display: none;
    }


    .sign {
        width: 150px;
        height: 42px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 50px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 19px;
        font-family: 'Montserrat', sans-serif;
    }

    #label_timee {
        position: relative;
        top: 23px;
        left: 30px;
    }




    .sign:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  
   
}
/* .overlay{
    background-color: rgba(49,49,49,0.8);
} */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 445px;
        position: relative;
        top: 50px;
    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 34px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 156px;
        position: relative;
        top: 125px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 20px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        text-align: center;
    }

    #to_left {
        /* position: absolute; */
        left: 0%;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
    }

    #to_left1 {
        position: relative;
        font-size: 15px;
        bottom: 10px;
        left: 6%;
        font-weight: 600;
        width: 88%;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 14px;
        position: relative;
        top: 0px;
        left: 1%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 14px;
        position: relative;
        top: 0px;
        left: 10px;
        display: inline-block;


    }

    .submit_id_1 {
        width: 20%;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        bottom: 50px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #get_id {
        position: relative;
        top: 0px;
    }

    .form_verifying form {
        width: 100%;
        height: 80%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 20px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 30%;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 60px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 13px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        width: 248px;
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 30px;
        background-color: #212121;
        color: #C0C0C0 ! important;
    }

    .submit_id {
        width: 30%;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 55px;
        left: 20px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }






    .show_verifying_div_1 {
        width: 100%;
        height: 443px;
        position: relative;
        top: 50px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }

    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }

    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }

    #locatione {
        color: #ff7200;
        width: auto;
        font-size: 8px;
        position: absolute;
        top: 68%;
        left: 63%;
        font-weight: 700;
        text-decoration: underline !important;
        cursor: pointer;
        z-index: 1;
        /* border: 2px solid black; */
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }

    .verified_yes {
        width: 100%;
        height: 440px;
        position: relative;
        top: 52px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 80%;
        height: 20%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: green; */

    }

    .yes_1 h2 {
        font-size: 29px;
        position: relative;
        top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }

    .yes_2 {
        width: 70%;
        height: 75%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;

    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .boxes {
        width: 100%;
        height: 40px;
        display: flex;
        /* background-color: red; */
    }

    .box_0 {
        width: 10%;
    }

    .box_1 {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        /* background-color: cyan; */
    }

    .box_1 p {
        font-size: 1.1rem;
        font-weight: 600;
        color: #fff;
    }

    .box_2 {
        width: 70%;
        height: 100%;
        display: flex;
        text-align: left;
        align-items: center;
        /* padding-left: 20px; */
    }

    #special_green {
        font-size: 22px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        text-align: center;
        bottom: -10px;
        font-weight: 700;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 15px !important;
        padding-left: 6px !important;
        font-weight: 600;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 15px !important;
        font-weight: 600;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 15px !important;
        font-weight: 600;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 15px !important;
        font-weight: 600;
    }


    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 100%;
        height: 40%;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: -5px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */

    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;
    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 105px;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 450px;
        position: relative;
        top: 50px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;

    }

    .no_1 {
        width: 79%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 28px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 118px;
        position: relative;
        top: 100px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
    }

    #trxnid_no {
        position: absolute;
        left: 6%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
    }

    #choose_file_no {
        position: absolute;
        left: 6%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 18px;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 20px;
        text-decoration: underline;
        top: 0px;

        font-size: 14px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 12px;
        text-decoration: underline;
        top: 0px;
        font-size: 15px;
    }

    .submit_id_red {
        width: 120px;
        height: 33px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 106px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #server_time::-webkit-input-placeholder {
        color: #C0C0C0 ! important;
        font-size: 14px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    #server_time:-ms-input-placeholder {
        color: #C0C0C0 ! important;
        font-size: 14px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    #server_time::placeholder {
        color: #C0C0C0 ! important;
        font-size: 14px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 5;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 22%;
        height: 400px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        -webkit-transform: translateY(40%);
                transform: translateY(40%);
        transition: -webkit-transform 0.1s;
        transition: transform 0.1s;
        transition: transform 0.1s, -webkit-transform 0.1s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        -webkit-animation: slide 0.1s;
                animation: slide 0.1s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        border: 3.5px solid #ff7200;
        z-index: 100;
    }

    @-webkit-keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }



    }

    @keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }



    }

    .otp_image {
        width: 81%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 99px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 68px;
    }

    .closeMe {
        width: 40px;
    }

    .otp_image h3 {
        font-size: 17px;
        position: relative;
        top: 80px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {

        padding: 5px;
        border: 2px solid #ccc;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 31px;
        width: 72%;
        height: 35px;
        letter-spacing: 1px;
        background-color: #fff;
        color: #000 !important;
        position: relative;
        font-size: 1rem;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 45px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .ClienstElem {
        width: 100%;
        height: auto;
    }

    .visibleEye {
        position: absolute;
        top: 40px;
        right: 55px;
        cursor: pointer;
        /* color: #ff7200; */
    }

}

@media only screen and (max-width:1301px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }

    .lynksign_data_mobile {
        display: none;
    }

    .working_steps_mobile_1 {
        display: none;
    }

    .working_steps_mobile_2 {
        display: none;
    }

    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;

    }

    #scrolling_to_top:hover {
        background-color: #ff7200;

    }

    .home_div_outer {
        width: 100%;
        height: 650px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 87%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 90%;
        height: 100%;
    }

    .newLynkRow {
        width: 76%;
        height: 30%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .bigElem1 {
        width: auto;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 10rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-around;
        position: relative;
        top: -15px;
    }

    .headSpan1 {
        font-size: 2.7rem;
        color: #989898;
        position: relative;
        top: 10px;
    }

    .headSpan2 {
        font-size: 3.3rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 8px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 65%;
        height: 70px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 9%;
        margin-right: 18px;
    }

    .btnSpan {
        font-size: 0.85rem;
        color: #fff;
        font-weight: 500;
    }

    .btnNewSpan {
        font-size: 0.85rem;
        color: #fff;
    }


    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 740px;
        position: relative;
        top: 0px;

        /* background-color: grey; */

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 30px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 35px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
    }

    .one {
        width: 20%;
        height: 100%;

    }

    .one_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .one_text p {
        font-size: 13px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted {
        width: 72%;
        height: 25%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 16px;
    }

    .one_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder {
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one {
        font-size: 53px;
        position: relative;
        top: -10px;
        left: 85%;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        color: #ff7200;
    }

    #arrow_two {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 95%;
        -webkit-transform: rotate(-40deg);
                transform: rotate(-40deg);
        color: #ff7200;
    }

    #arrow_three {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 89%;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        color: #ff7200;
    }


    #arrow_four {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: -6%;
        -webkit-transform: rotate(-40deg);
                transform: rotate(-40deg);
        color: #ff7200;
    }


    /*  */
    .two {
        width: 20%;
        height: 100%;
    }

    .two_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 288px;
        /* background-color: #ff7200; */
    }

    .two_text p {
        font-size: 13px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .two_dotted {
        width: 72%;
        height: 25%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .two_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock {
        width: 70px;
        height: 70px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    /*  */

    .three {
        width: 20%;
        height: 100%;
    }

    .three_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .three_text p {
        font-size: 13px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted {
        width: 72%;
        height: 25%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .three_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen {
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    /*  */


    .four {
        width: 20%;
        height: 100%;
    }

    .four_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 278px;
        /* background-color: #ff7200; */
    }

    .four_text p {
        font-size: 13px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted {
        width: 72%;
        height: 25%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .four_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .three_folder {
        width: 65px;
        height: 65px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }


    .five {
        width: 20%;
        height: 100%;
    }

    .five_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .five_text p {
        font-size: 13px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted {
        width: 72%;
        height: 25%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .five_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal {
        width: 34px;
        height: 25px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 30px;
    }

    .not_equal {
        width: 35px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 20px;
    }

    /*  */
    .lynksign_for {
        width: 100%;
        height: 520px;
        background-color: #3d3d3d;
        position: relative;
        top: 53px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 35px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 82%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        /* background-color: pink; */

    }



    /*  */
    #circle1 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 13px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line1 {
        border-left: 6px solid white;
        height: 303px;

        -webkit-transform: rotate(34.5deg);

                transform: rotate(34.5deg);
        /* position:absolute; */
    }

    /*  */

    #circle3 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 10px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    .curved_div_1 {
        width: 32%;
        height: 270px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;  */
        align-items: center;
        text-align: center;
        position: relative;


        /* background-color: antiquewhite; */

    }

    #circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;

        top: 12px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line {
        border-left: 6px solid white;
        height: 342px;
        /* transform: rotate(42.2deg); */
        position: relative;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;

    }

    .verify {
        position: relative;
        top: 45px;
    }

    .curved_div_1 p {
        color: white;
        position: relative;
        top: 55px;
        font-size: 14px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_2 {
        width: 32%;
        height: 270px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        /* border-left-color: transparent;   */
        align-items: center;
        position: relative;
        text-align: center;
    }

    .projection {
        position: relative;
        top: 35px;
    }

    .curved_div_2 p {
        color: white;
        position: relative;
        top: 60px;
        font-size: 14px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_3 {
        width: 32%;
        height: 270px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        position: relative;
        align-items: center;
        text-align: center;
    }

    .concept {
        position: relative;
        top: 27px;
    }

    .curved_div_3 p {
        color: white;
        position: relative;
        top: 50px;
        font-size: 14px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }




    /*  */
    .secure_verify_div {
        width: 100%;
        height: 440px;
        position: relative;
        top: 52px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 440px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;


    }

    .secure_inner h4 {
        font-size: 16px;
        font-weight: 500;
        padding-top: 34px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 19px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 34%;
        height: 60px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        -webkit-transform: translateY(-6px);

                transform: translateY(-6px);
        border-radius: 32px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;


    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 14px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        color: #C0C0C0;
    }

    .verify_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .verify_inner h4 {
        font-size: 16px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 19px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;



    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 69%;
        height: 60px;
        border: none;
        background-color: #ff7200;
        font-size: 16px;
        color: black;
        cursor: pointer;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 32px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 400px;
        position: relative;
        top: 52px;

    }

    .securing_main_div {
        width: 100%;
        height: 440px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 18px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 180px;
        height: 50px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 14px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);

    }

    .another_upload:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .securing_form {
        width: 85%;
        height: 90%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 50px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 16px;
        font-weight: 600;
        position: relative;
        top: 32px;
        text-align: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        left: 30px;
        top: 30px;
        background-color: #212121;
        color: #C0C0C0;
    }


    .sign {
        width: 130px;
        height: 42px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 45px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 19px;
        font-family: 'Montserrat', sans-serif;
    }




    .sign:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
      
       
    }
    /* .overlay{
        background-color: rgba(49,49,49,0.8);
    } */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 465px;
        position: relative;
        top: 50px;
    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 34px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 156px;
        position: relative;
        top: 125px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 17px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        /* background-color: antiquewhite; */
    }

    #to_left {
        position: absolute;
        left: 3%;
        font-size: 16px;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #to_left1 {
        position: absolute;
        font-size: 16px;
        bottom: 100px;
        left: 3%;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 13px;
        position: relative;
        top: 0px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        left: 0px;
        display: inline-block;

    }

    #special_text_1_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 12px;
        position: relative;
        top: -50px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 12px;
        position: relative;
        top: -52px;
        left: 0px;
        display: inline-block;

    }

    #get_id {
        position: relative;
        top: 0px;
    }

    .form_verifying form {
        width: 100%;
        height: 80%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 18px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 30%;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 70px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 13px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        width: 248px;
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 30px;
        background-color: #212121;
        color: #C0C0C0;
    }

    .submit_id {
        width: 30%;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 63px;
        left: 20px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .submit_id_1 {
        width: 30%;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: -6px;
        left: 20px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }





    .show_verifying_div_1 {
        width: 100%;
        height: 443px;
        position: relative;
        top: 50px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }


    #locatione {

        color: #ff7200;
        width: 190px;
        font-size: 8px;
        position: absolute;
        top: 67%;
        left: 63%;
        font-weight: 700;
        text-decoration: underline !important;
        cursor: pointer;
        z-index: 1;
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }


    #fetched_location {
        width: 250px;
        height: auto;
        display: inline-block;
        text-align: start;
        font-size: 13px;
        position: absolute;
        top: -15px;
        left: 83px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #212121;
        word-wrap: break-word;
    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 330px;

        cursor: pointer;
        color: #fff;
    }

    #hide_remove {
        display: none;
    }





    .verified_yes {
        width: 100%;
        height: 440px;
        position: relative;
        top: 52px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 80%;
        height: 20%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: green; */

    }

    .yes_1 h2 {
        font-size: 22px;
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }



    .yes_2 {
        width: 70%;
        height: 70%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 30px;
        text-align: center;
        /* background-color: green; */

    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;


    }

    .label_name {
        display: inline-block;


    }

    .label_mail {
        display: inline-block;
        position: relative;
        left: 13px;
    }

    .label_place {
        display: inline-block;

    }

    .label_time {
        position: absolute;
        left: 33px;
        display: inline-block;
    }


    .yes_2 label {
        font-size: 19px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        right: 20px;
        top: 40px;
    }


    #special_green {
        font-size: 18px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        bottom: -4px;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 8px;
        font-size: 14px !important;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 16px;
        font-size: 14px !important;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 12px;
        font-size: 14px !important;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 15px;
        font-size: 14px !important;
    }


    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 100%;
        height: 40%;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: 5px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 17px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 90%;
        height: 47%;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        /* background-color: red; */
        align-items: center;
    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
        display: none;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;

    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
        display: none;

    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
        display: none;
    }


    #special_text_white_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        /* left: 1%; */
        text-decoration: underline;
        top: 0px;
        font-size: 12px;
        display: block;

    }

    #special_text_white1_new {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        display: block;
        font-weight: 400;
        /* left: 3%; */
        top: 20px;
        font-size: 12px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 85px;

        align-items: center;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 450px;
        position: relative;
        top: 50px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .no_1 {
        width: 79%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 24px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 118px;
        position: relative;
        top: 100px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;

    }

    #trxnid_no {
        position: absolute;
        left: 3%;
        top: 14px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        display: none;
    }

    #choose_file_no {
        position: absolute;
        left: 3%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 15px;
        display: none;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 0px;
        text-decoration: underline;
        top: 0px;
        display: none;
        font-size: 11px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 7px;
        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: none;
    }

    #special_text_white2_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;

        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: block;
    }


    #special_text_white3_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        top: 23px;
        font-size: 11px;
    }

    .submit_id_red {
        width: 120px;
        height: 33px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 66px;

        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #label_timee {
        position: relative;
        left: 12px;
    }

    #server_time {
        position: relative;
        /* left: 0px; */
        color: #C0C0C0;
        font-size: 14px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 25%;
        height: 350px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        -webkit-transform: translateY(40%);
                transform: translateY(40%);
        transition: -webkit-transform 0.1s;
        transition: transform 0.1s;
        transition: transform 0.1s, -webkit-transform 0.1s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        -webkit-animation: slide 0.1s;
                animation: slide 0.1s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        z-index: 100;
        border: 3.5px solid #ff7200;

    }

    @-webkit-keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }



    }

    @keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }



    }









    .otp_image {
        width: 80%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 99px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 68px;
    }

    .closeMe {
        width: 40px;
    }

    .otp_image h3 {
        font-size: 15px;
        position: relative;
        top: 70px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {

        padding: 5px;
        border: 2px solid #ccc;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 31px;
        width: 72%;
        height: 35px;
        letter-spacing: 1px;
        font-size: 1rem;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 49px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        box-shadow: 0px 3px rgb(70, 68, 68);
    }

    .otp_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .lockw {
        width: 100%;
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .myLock {
        width: 60px;
        border-radius: 50%;
        border: 1px solid silver;
        padding: 8px;
    }

}

@media only screen and (max-width:1001px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    .lynksign_data_mobile {
        display: none;
    }

    .working_steps_mobile_1 {
        display: none;
    }

    .working_steps_mobile_2 {
        display: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }

    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;

    }

    #scrolling_to_top:hover {
        background-color: #ff7200;
    }

    .home_div_outer {
        width: 100%;
        height: 590px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 87%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 96%;
        height: 76%;
    }

    .newLynkRow {
        width: 76%;
        height: 30%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .bigElem1 {
        width: auto;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 7.7rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-around;
        position: relative;
        top: -15px;
    }

    .headSpan1 {
        font-size: 2.3rem;
        color: #989898;
        position: relative;
        top: 10px;
    }

    .headSpan2 {
        font-size: 3rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 8px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 75%;
        height: 60px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 8%;
        margin-right: 13px;
    }

    .btnSpan {
        font-size: 0.72rem;
        color: #fff;
        font-weight: 500;
    }

    .btnNewSpan {
        font-size: 0.7rem;
        color: #fff;
    }


    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 720px;
        position: relative;
        top: -7px;
        /* background-color: antiquewhite; */

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 20px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 32px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
        /* background-color: antiquewhite; */
    }

    .one {
        width: 20%;
        height: 100%;

    }

    .one_text {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .one_text p {
        font-size: 11.5px;
        position: relative;
        top: 0px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted {
        width: 72%;
        height: 20%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 16px;
    }

    .one_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one {
        font-size: 47px;
        position: relative;
        top: -10px;
        left: 85%;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        color: #ff7200;
    }

    #arrow_two {
        font-size: 47px;
        position: relative;
        top: -20px;
        left: 85%;
        -webkit-transform: rotate(-40deg);
                transform: rotate(-40deg);
        color: #ff7200;
    }

    #arrow_three {
        font-size: 47px;
        position: relative;
        top: -20px;
        left: 91%;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        color: #ff7200;
    }


    #arrow_four {
        font-size: 47px;
        position: relative;
        top: 10px;
        left: -11%;
        -webkit-transform: rotate(-40deg);
                transform: rotate(-40deg);
        color: #ff7200;
    }


    /*  */
    .two {
        width: 20%;
        height: 100%;
    }

    .two_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 288px;
        /* background-color: #ff7200; */
    }

    .two_text p {
        font-size: 11.5px;
        position: relative;
        top: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .two_dotted {
        width: 72%;
        height: 20%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .two_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    /*  */

    .three {
        width: 20%;
        height: 100%;
    }

    .three_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .three_text p {
        font-size: 11.5px;
        position: relative;
        top: 0px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted {
        width: 72%;
        height: 20%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .three_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen {
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    /*  */


    .four {
        width: 20%;
        height: 100%;
    }

    .four_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 278px;
        /* background-color: #ff7200; */
    }

    .four_text p {
        font-size: 11.5px;
        position: relative;
        top: 0px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted {
        width: 72%;
        height: 20%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .four_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .three_folder {
        width: 51px;
        height: 51px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }


    .five {
        width: 20%;
        height: 100%;
    }

    .five_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .five_text p {
        font-size: 11.5px;
        position: relative;
        top: 0px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted {
        width: 72%;
        height: 20%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .five_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal {
        width: 27px;
        height: 25px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 20px;
    }

    .not_equal {
        width: 27px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10px;
    }



















































    /*  */
    .lynksign_for {
        width: 100%;
        height: 480px;
        background-color: #3d3d3d;
        position: relative;
        top: -7px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 32px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 90%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        /* background-color: pink; */

    }



    /*  */
    #circle1 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 13px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line1 {
        border-left: 6px solid white;
        height: 303px;

        -webkit-transform: rotate(34.5deg);

                transform: rotate(34.5deg);
        /* position:absolute; */
    }

    /*  */

    #circle3 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 10px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    .curved_div_1 {
        width: 32%;
        height: 250px;
        border: 4px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;  */
        align-items: center;
        text-align: center;
        position: relative;


        /* background-color: antiquewhite; */

    }

    #circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;

        top: 12px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line {
        border-left: 6px solid white;
        height: 342px;
        /* transform: rotate(42.2deg); */
        position: relative;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;

    }

    .verify {
        position: relative;
        top: 45px;
    }

    .curved_div_1 p {
        color: white;
        position: relative;
        top: 55px;
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_2 {
        width: 32%;
        height: 250px;
        border: 4px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        /* border-left-color: transparent;   */
        align-items: center;
        position: relative;
        text-align: center;
    }

    .projection {
        position: relative;
        top: 35px;
    }

    .curved_div_2 p {
        color: white;
        position: relative;
        top: 60px;
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_3 {
        width: 32%;
        height: 250px;
        border: 4px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        position: relative;
        align-items: center;
        text-align: center;
    }

    .concept {
        position: relative;
        top: 27px;
    }

    .curved_div_3 p {
        color: white;
        position: relative;
        top: 50px;
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;
        font-family: 'Montserrat', sans-serif;
    }




    /*  */
    .secure_verify_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;


    }

    .secure_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 34px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 40%;
        height: 49px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        -webkit-transform: translateY(-6px);

                transform: translateY(-6px);
        border-radius: 25px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;


    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        /* background-color: pink; */
    }

    .verify_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;



    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 81%;
        height: 49px;
        border: none;
        background-color: #ff7200;
        font-size: 12px;
        color: black;
        cursor: pointer;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 22px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {

        -webkit-transform: translateY(-2px);

                transform: translateY(-2px);
    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -7px;


    }

    .securing_main_div {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 141px;
        height: 45px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 11px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);

    }

    .another_upload:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .securing_form {
        width: 100%;
        height: 100%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 85%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 40px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 12px;
        text-align: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #fetched_location {
        width: 181px;
        height: auto;
        display: inline-block;
        text-align: start;
        font-size: 9.5px;
        position: absolute;
        top: -6px;
        left: 60px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #212121;
        word-wrap: break-word;

    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 250px;
        top: 0px;
        cursor: pointer;
        color: #fff;
    }

    #hide_remove {
        display: none;
    }

    #label_timee {
        position: relative;
        left: 5px;
        top: 6px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        background-color: #212121;
        top: 10px;
        color: #C0C0C0;
    }

    .sign {
        width: 111px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 26px;
        left: 0px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 22px;
        font-family: 'Montserrat', sans-serif;
    }

    .sign:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -10px;
        /* background-color: red; */

    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 30px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 126px;
        position: relative;
        top: 100px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 90%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .heading_verify h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 14px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    #to_left {
        position: absolute;
        left: 3%;
        font-size: 16px;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #to_left1 {
        position: absolute;
        font-size: 16px;
        bottom: 100px;
        left: 3%;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 13px;
        position: relative;
        top: 0px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        left: 0px;
        display: inline-block;

    }

    #special_text_1_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 8px;
        position: relative;
        top: -50px;
        left: 0%;
        display: inline-block;


    }

    #special_text_2_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 8px;
        position: relative;
        top: -72px;
        left: 0px;
        display: inline-block;

    }

    #get_id {
        position: relative;
        top: 0px;
        width: 110px;
    }

    .form_verifying form {
        width: 100%;
        height: 70%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 16px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 120px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 44px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        width: 180px;
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 10px;
        background-color: #212121;
        color: #C0C0C0;
    }

    .submit_id {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 37px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .submit_id_1 {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: -44px;
        left: -6px !important;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }





    .show_verifying_div_1 {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }

    #locatione {
        color: #ff7200;
        width: auto;
        font-size: 8px;
        position: absolute;
        top: 65%;
        left: 53%;
        font-weight: 700;
        text-decoration: underline !important;
        cursor: pointer;
        z-index: 1;
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }




    .verified_yes {
        width: 100%;
        height: 400px;
        position: relative;
        top: -9px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 96%;
        height: 25%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;


    }

    .yes_1 h2 {
        font-size: 18px;
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }



    .yes_2 {
        width: 100%;
        height: 70%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
        text-align: center;
        /* background-color: green; */

    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .boxes {
        width: 100%;
        height: 40px;
        display: flex;
    }

    .box_0 {
        width: 10%;
        height: 100%;
    }

    .box_1 {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .box_1 p {
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
    }

    .box_2 {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 0px;
    }

    #special_green {
        font-size: 16px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        text-align: center;
        bottom: -20px;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 13px !important;
        padding-left: 6px !important;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 13px !important;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 13px !important;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 13px !important;
    }



    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 80%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 5px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 14px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 47%;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        /* background-color: antiquewhite; */
        align-items: center;
    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
        display: none;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;

    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
        display: none;

    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
        display: none;
    }


    #special_text_white_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        /* left: 1%; */
        text-decoration: underline;
        top: 0px;
        font-size: 9px;

    }

    #special_text_white1_new {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        display: block;
        font-weight: 400;
        /* left: 3%; */
        top: 20px;
        font-size: 9px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 65px;
        font-size: 11px;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .no_1 {
        width: 92%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 18px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 98px;
        position: relative;
        top: 80px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;

    }

    #trxnid_no {
        position: absolute;
        left: 3%;
        top: 14px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        display: none;
    }

    #choose_file_no {
        position: absolute;
        left: 3%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 15px;
        display: none;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 0px;
        text-decoration: underline;
        top: 0px;
        display: none;
        font-size: 11px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 7px;
        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: none;
    }

    #special_text_white2_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;

        text-decoration: underline;
        top: 10px;
        font-size: 8px;
        display: block;
    }


    #special_text_white3_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        top: 17px;
        font-size: 8px;
    }

    .submit_id_red {
        width: 120px;
        height: 35px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 51px;
        font-size: 11px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #server_time::-webkit-input-placeholder {
        color: #C0C0C0;
        font-size: 11px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    #server_time:-ms-input-placeholder {
        color: #C0C0C0;
        font-size: 11px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    #server_time::placeholder {
        color: #C0C0C0;
        font-size: 11px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 290px;
        height: 350px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        -webkit-transform: translateY(40%);
                transform: translateY(40%);
        transition: -webkit-transform 0.1s;
        transition: transform 0.1s;
        transition: transform 0.1s, -webkit-transform 0.1s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        -webkit-animation: slide 0.1s;
                animation: slide 0.1s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        border: 3.5px solid #ff7200;
        z-index: 100;
    }

    @-webkit-keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }



    }

    @keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }



    }









    .otp_image {
        width: 79%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 90px;
        position: relative;
        top: 35px;
        color: #ff7200;
    }

    .otp_image h3 {
        font-size: 15px;
        position: relative;
        top: 80px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {
        font-size: 0.9rem;
        padding: 5px;
        border: 2px solid #ccc;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 31px;
        width: 72%;
        height: 35px;
        font-size: 12px;
        letter-spacing: 1px;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 45px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .lockw {
        width: 100%;
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .myLock {
        width: 42px;
        border-radius: 50%;
        border: 1px solid silver;
        padding: 8px;
        position: relative;
        top: 5px;
    }

    .myLock img {
        width: 20px;
    }



}

@media only screen and (max-width:701px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;

    }

    .show_verifying_div {
        display: none;
    }

    .show_secure_div {
        display: none;
    }

    .show_verifying_div_1 {
        display: none;
    }

    .verified_yes {
        display: none;
    }

    .not_verified {
        display: none;
    }

    .hide_not_verified {
        display: none;
    }

    #root {
        height: 0px;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }


    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;

    }

    #scrolling_to_top:hover {
        background-color: #ff7200;
    }

    .home_div_outer {
        width: 100%;
        height: 450px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 76%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 100%;
        height: 79%;
    }

    .newLynkRow {
        width: 76%;
        height: 40%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .bigElem1 {
        width: auto;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 6.7rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-around;
        position: relative;
        top: -15px;
    }

    .headSpan1 {
        font-size: 1.8rem;
        color: #989898;
        position: relative;
        top: 9px;
    }

    .headSpan2 {
        font-size: 2.2rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 3px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 85%;
        height: 50px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 7%;
        margin-right: 7px;
    }

    .btnSpan {
        font-size: 0.6rem;
        color: #fff;
        font-weight: 500;
    }

    .btnNewSpan {
        font-size: 0.6rem;
        color: #fff;
    }

    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 899px;
        position: relative;
        top: -7px;
        background-color: rgb(187, 184, 184);

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 20px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 28px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
        display: none;
        /* background-color: antiquewhite; */
    }

    /*  */
    .working_steps_mobile_1 {
        width: 95%;
        height: 320px;
        position: relative;
        top: 50px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .one_mobile {
        width: 100%;
        height: 40%;
        /* background-color: aqua; */
        display: flex;
    }

    .one_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 10px;
        left: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .one_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .one_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one_mobile {
        font-size: 50px;
        position: relative;
        left: 93%;
        top: 77px;
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
        color: #ff7200;
    }

    .two_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;
        /* background-color: blue; */
    }

    .two_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .two_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .two_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .two_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    #arrow_two_mobile {
        font-size: 50px;
        position: relative;
        left: 37%;
        top: 30px;
        -webkit-transform: rotate(140deg);
                transform: rotate(140deg);
        color: #ff7200;
    }

    /*  */

    .working_steps_mobile_2 {
        width: 95%;
        height: 350px;
        position: relative;
        top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        /* background-color: blue; */
    }

    .three_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 14px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .three_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_three_mobile {
        font-size: 50px;
        position: relative;
        left: 100%;
        top: 80px;
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
        color: #ff7200;
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;
        /* background-color: blue; */
    }

    .three_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .four_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 10px;
        display: flex;
        /* background-color: blue; */
    }

    .four_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blueviolet; */

    }

    .four_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .four_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    #arrow_four_mobile {
        font-size: 50px;
        position: relative;
        left: -9%;
        top: 110px;
        -webkit-transform: rotate(140deg);
                transform: rotate(140deg);
        color: #ff7200;
    }


    .five_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        position: relative;
        top: 22px;
        /* background-color: blue; */
    }

    .five_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 14px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .five_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .five_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal_mobile {
        width: 39px;
        height: 30px;
        position: relative;
        top: 0px;
    }

    .not_equal_mobile {
        width: 39px;
        height: 44px;
        position: relative;
        bottom: 0px;
    }

    /*  */





    /*  */
    .lynksign_for {
        width: 100%;
        height: 880px;
        background-color: #3d3d3d;
        position: relative;
        top: -7px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 26px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 90%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        display: none;
        /* background-color: pink; */

    }

    .lynksign_data_mobile {
        width: 75%;
        height: 81%;
        /* background-color: blueviolet; */
        position: relative;
        top: 58px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .curved_1_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_1_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_1_mobile p {
        position: relative;
        top: 40px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 13.5px;
        color: white;
    }

    .curved_2_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_2_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_2_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        color: white;
    }

    .curved_3_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_3_mobile img {
        width: 50px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_3_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        color: white;
    }



    /*  */
    .secure_verify_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
        display: none;
    }

    .secure_mobile {
        width: 100%;
        height: 420px;
    }

    .secure_main_mobile {
        width: 100%;
        height: 420px;
        position: relative;
        top: -380px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_secure_main_mobile {
        display: none
    }

    .secure_mobile_inner {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 40px;
        background-color: #000;
    }

    .secure_mobile_head {
        width: 100%;
        height: 60%;
        text-align: center;
        /* background-color: burlywood; */
    }

    .secure_mobile_head h2 {
        font-size: 36px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #secure__mobile_color {
        color: #ff7200;
    }

    .secure_mobile_head h4 {
        font-size: 25px;
        position: relative;
        top: 70px;
        font-weight: 400;
        font-size: 15px;
        color: #fff;
    }

    #upload_pdf {
        font-size: 15px;
        position: relative;
        top: 109px;
    }

    .choosse_div_mobile {
        width: 100%;
        height: 35%;
        /* background-color: #ff7200; */
        text-align: center;
    }

    .choose_div_btn_mobile {
        width: 40%;
        height: 44px;
        position: relative;
        top: 56px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        box-shadow: 0px 3px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
    }

    .choose_div_btn_mobile:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #overlap_text_mobile {
        font-size: 14px;
        font-weight: 400;
        color: black;
    }

    .securing_file_div {
        width: 100%;
        height: 555px;
        position: relative;
        top: -369px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_securing_file_div {
        display: none;
    }

    .securing_file_inner {
        width: 80%;
        height: 459px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 53px;
        background-color: #000;
    }

    .file_securing {
        width: 100%;
        height: 15%;
        /* background-color: pink; */
        border: 2px solid silver;
        border-right-color: transparent;
        border-left-color: transparent;
        border-top-color: transparent;
        position: relative;
        display: flex;
        justify-content: space-around;
        /* display: block; */
        /* background-color: red; */
    }

    .file_securing p {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 25px;
        font-weight: 400;
        color: #fff;
    }

    .another_upload_mob {
        width: 90px;
        height: 31px;
        position: relative;
        top: 22px;
        background-color: #ff7200;
        border: none;
        color: white;
        font-size: 9px;
        border-radius: 11px;
        box-shadow: 0px 3px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
    }

    .another_upload_mob:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #file_name_mob {
        position: relative;
        left: 6px;
        color: #C0C0C0;
        font-size: 12px;
        font-weight: 700;
        border-bottom: 3px solid #C0C0C0;
        ;
    }


    .securing_file_daata {
        width: 100%;
        height: 82%;
        display: block;
    }

    .securing_file_daata form {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .securing_file_daata form label {
        font-size: 18px;
        font-weight: 600;
        position: relative;
        top: 70px;
        color: #fff;
    }

    #label_timee_mob {
        position: relative;
        left: -25px;
        top: 20px;

    }

    .securing_file_daata form input {
        position: relative;
        top: 70px;
    }

    .sign_mobile {
        width: 40%;
        height: 44px;
        position: relative;
        top: 48px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        box-shadow: 0px 3px rgb(70, 68, 68);
    }

    .sign_mobile:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .verify_mobile {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -382px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verify_mobile {
        display: none;
    }

    .verify_mob_inner {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;

    }

    .verify_mob_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_head h2 {
        font-size: 36px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #verify_mob_clr {
        color: #ff7200;
    }

    .verify_mob_head h4 {
        font-size: 15px;
        font-weight: 400;
        position: relative;
        top: 50px;
        color: #fff;
    }

    .verify_mob_upload {
        width: 90%;
        height: 150px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_upload h2 {
        font-size: 15px;
        position: relative;
        top: 40px;
        color: #C0C0C0;
    }

    .verify_mob_upload button {
        width: 40%;
        height: 40px;
        background-color: #ff7200;
        color: #000;
        border: none;
        border-radius: 10px;
        position: relative;
        top: 90px;
        box-shadow: 0px 3px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
    }

    .verify_mob_upload:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }

    .verfiy_mob_1 {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -380px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verfiy_mob_1 {
        display: none;
    }

    .verify_mob_inner_1 {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;
    }

    .verify_1_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_1_head h2 {
        font-size: 36px;
        position: relative;
        top: 27px;
        color: #C0C0C0;
    }

    .verify_1_head h4 {
        font-size: 15px;
        font-weight: 400;
        position: relative;
        top: 54px;
        color: #fff;
    }

    .verifying_1_form {
        width: 100%;
        height: 180px;
    }

    .verifying_1_form form {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .verifying_1_form form label {
        position: relative;
        top: 50px;
        font-weight: 500;
        color: #fff;
    }

    #get_id_mob {
        position: relative;
        top: 0px;
        width: 210px;
        background-color: #383838;
    }

    .choose_div_btn_1_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 60px;
        left: -30px;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
    }

    .choose_div_btn_1_mob:active {

        -webkit-transform: translateY(-2px);

                transform: translateY(-2px);
    }


    #overlap_text_1_mob {
        color: #000;
        font-size: 14px;
        font-weight: 400;
    }

    .submit_id_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 20px;
        color: #000;
        top: 60px;
        left: 30px;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
    }

    .submit_id_mob:active {

        -webkit-transform: translateY(-2px);

                transform: translateY(-2px);
    }



    /* YESSS MOBILE */

    .verifying_yes_mob {
        width: 100%;
        height: 480px;
        background-color: #E8E8E8;
        position: relative;
        top: -380px;
        /* background-color: rgb(247,245,245); */
        display: block;
    }

    .hide_verifying_yes_mob {
        display: none;
    }

    .verifying_yes_mob_in {
        width: 80%;
        height: 84%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #212121;
        /* box-shadow: 1px 1px 10px black; */
    }

    .yes_details {
        width: 100%;
        height: 70px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .yes_details h2 {
        font-size: 22px;
        position: relative;
        top: 17px;
        color: #909090;
    }

    .yes_details_more {
        width: 100%;
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        position: relative;
        top: 30px;
    }

    .newYes {
        width: 100%;
        height: 40px;
        display: flex;
    }

    .dataSpan {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .dataSpan span {
        font-size: 0.9rem;
        font-weight: 500;
        padding-right: 15px;
        color: #fff;
    }

    .dataField {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        /* background-color: red; */
    }

    .dataField span {
        font-size: 0.8rem;
        color: #C0C0C0;
    }

    #mob_name {
        position: absolute;
        left: 110px;
        top: 40px;
    }

    #mob_email {
        position: absolute;
        left: 110px;
        top: 80px;
    }

    #mob_place {
        position: absolute;
        left: 110px;
        top: 120px;
    }

    #mob_time {
        position: absolute;
        left: 110px;
        top: 160px;
    }

    .alignCentre {
        position: relative;
        top: 15px;
        text-align: center;
        justify-content: center;
    }

    #special_green_mob {
        font-size: 18px;
        color: green;
        position: relative;
        top: 0px;
        font-weight: 600;
    }

    #mob_yes_clrs {
        position: relative;
        left: 12px;
        /* padding-left: 12px; */
        color: red;
        font-size: 11px;
        font-weight: 400;
    }

    #mob_yes_time {
        padding-left: 15px;
        color: red;
        font-size: 11px;
        font-weight: 400;
    }

    .mob_another_btn {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        border-radius: 10px;
        position: relative;
        top: 20px;
        font-size: 0.8rem;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        box-shadow: 0px 1px rgb(78, 40, 40);
    }

    .mob_another_btn:active {

        -webkit-transform: translateY(-2px);

                transform: translateY(-2px);
    }



    /*  */

    .verifying_no_mob {
        width: 100%;
        height: 420px;
        background-color: #E8E8E8;
        display: block;
        position: relative;
        top: -380px;

    }

    .hide_verifying_no_mob {
        display: none;
    }

    .verifying_no_inn_mob {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 40px;
        text-align: center;
        border-radius: 10px;
        background-color: #212121;
        box-shadow: 1px 1px 10px black;
    }

    .question_mob {
        width: 90%;
        height: 90%;
        margin-left: auto;
        margin-right: auto;

    }

    .question_mob h2 {
        font-size: 21px;
        position: relative;
        top: 20px;
        color: #909090;
    }

    #cross_mob {
        font-size: 79px;
        color: red;
        position: relative;
        top: 66px;
    }

    .wrong_mob_btn {
        width: 120px;
        height: 39px;
        background-color: red;
        border: none;
        color: white;
        position: relative;
        top: -15px;
        border-radius: 10px;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        box-shadow: 0px 1px rgb(78, 40, 40);
    }

    .wrong_mob_btn:active {

        -webkit-transform: translateY(-2px);

                transform: translateY(-2px);
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;


    }

    .secure_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 34px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 40%;
        height: 49px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        -webkit-transform: translateY(-6px);

                transform: translateY(-6px);
        border-radius: 25px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;


    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        /* background-color: pink; */
    }

    .verify_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;



    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 81%;
        height: 49px;
        border: none;
        background-color: #ff7200;
        font-size: 12px;
        color: black;
        cursor: pointer;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 22px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -7px;


    }

    .securing_main_div {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 141px;
        height: 45px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 11px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);

    }

    .another_upload:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .securing_form {
        width: 100%;
        height: 100%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 85%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 40px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 32px;
        text-align: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #label_timee {
        position: relative;
        left: 16px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        background-color: #212121;
        top: 30px;
        color: #C0C0C0;
    }

    .sign {
        width: 137px;
        height: 43px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 53px;
        left: 0px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 22px;
        font-family: 'Montserrat', sans-serif;
    }




    .sign:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        
        
    }
    /* .overlay{
        background-color: rgba(49,49,49,0.8);
    } */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -10px;
        /* background-color: red; */

    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 30px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 126px;
        position: relative;
        top: 100px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 90%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .heading_verify h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 14px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        /* background-color: antiquewhite; */
    }

    #to_left {
        position: absolute;
        left: 3%;
        font-size: 16px;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #to_left1 {
        position: absolute;
        font-size: 16px;
        bottom: 100px;
        left: 3%;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 13px;
        position: relative;
        top: 0px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        left: 0px;
        display: inline-block;

    }

    #special_text_1_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -50px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -72px;
        left: 0px;
        display: inline-block;

    }

    #get_id {
        position: relative;
        top: 0px;
        width: 110px;
    }

    .form_verifying form {
        width: 100%;
        height: 70%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 16px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 120px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 44px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 30px;
        background-color: #000;
        color: #C0C0C0;

    }

    .submit_id {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 37px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .submit_id_1 {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: -36px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }





    .show_verifying_div_1 {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }

    #locatione {
        color: #ff7200;
        font-size: 7px;
        position: absolute;
        top: 63%;
        left: 58%;
        cursor: pointer;
        width: auto;
        z-index: 1;
        /* border: 1px solid black; */
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }

    #fetched_location_mob {

        font-size: 11px;
        position: relative;
        left: 22px;
        top: -35px;
        font-weight: 600;
        color: green;
        padding-left: 100px;
    }

    #fetched_location {
        width: 183px;
        height: auto;
        display: inline-block;
        text-align: start;
        font-size: 11px;
        position: absolute;
        top: -4px;
        left: 89px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #000;
        word-wrap: break-word;
    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 275px;
        cursor: pointer;
        color: #fff;
    }




    .verified_yes {
        width: 100%;
        height: 400px;
        position: relative;
        top: -9px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 96%;
        height: 25%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;


    }

    .yes_1 h2 {
        font-size: 18px;
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }



    .yes_2 {
        width: 100%;
        height: 70%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
        text-align: center;
        /* background-color: green; */

    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;


    }

    .label_name {
        position: absolute !important;
        left: -47px !important;



    }

    .label_mail {
        position: absolute;
        right: 0px !important;
    }

    .label_place {
        position: relative;
        right: 65px !important;

    }

    .label_time {

        position: relative !important;
        right: 1px !important;
    }


    .yes_2 label {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        right: 20px;
        top: 40px;
    }

    #special_green {
        font-size: 16px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        bottom: -14px;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 8px;
        font-size: 15px !important;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 14px;
        font-size: 15px !important;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 12px;
        font-size: 15px !important;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 50px;
        font-size: 15px !important;
    }


    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 80%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 5px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 14px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 47%;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        /* background-color: antiquewhite; */
        align-items: center;
    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
        display: none;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;

    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
        display: none;

    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
        display: none;
    }


    #special_text_white_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        /* left: 1%; */
        text-decoration: underline;
        top: 0px;
        font-size: 9px;

    }

    #special_text_white1_new {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        display: block;
        font-weight: 400;
        /* left: 3%; */
        top: 20px;
        font-size: 9px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 65px;
        font-size: 11px;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;

    }

    .no_1 {
        width: 92%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 18px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 98px;
        position: relative;
        top: 80px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;

    }

    #trxnid_no {
        position: absolute;
        left: 3%;
        top: 14px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        display: none;
    }

    #choose_file_no {
        position: absolute;
        left: 3%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 15px;
        display: none;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 0px;
        text-decoration: underline;
        top: 0px;
        display: none;
        font-size: 11px;

    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 7px;
        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: none;
    }

    #special_text_white2_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;

        text-decoration: underline;
        top: 10px;
        font-size: 8px;
        display: block;
    }


    #special_text_white3_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        top: 17px;
        font-size: 8px;
    }

    .submit_id_red {
        width: 120px;
        height: 35px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 51px;
        font-size: 11px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #server_time {
        position: relative;
        left: 50px;
        color: #C0C0C0;
        font-size: 12px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 250px;
        height: 300px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        -webkit-transform: translateY(40%);
                transform: translateY(40%);
        transition: -webkit-transform 0.1s;
        transition: transform 0.1s;
        transition: transform 0.1s, -webkit-transform 0.1s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        -webkit-animation: slide 0.1s;
                animation: slide 0.1s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        border: 3.5px solid #ff7200;
        z-index: 100;

    }

    @-webkit-keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }
    }

    @keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }
    }

    .otp_image {
        width: 76%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 70px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 60px;
    }

    .closeMe {
        width: 33px;
    }

    .otp_image h3 {
        font-size: 14px;
        position: relative;
        top: 55px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {
        font-size: 0.9rem;
        padding: 5px;
        border: 2px solid #ccc;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 15px;
        width: 72%;
        height: 35px;
        font-size: 12px;
        letter-spacing: 1px;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 25px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .ClienstElem {
        width: 100%;
        height: auto;
        position: relative;
        top: -385px;
    }

    .visibleEye {
        position: absolute;
        top: 25px;
        right: 45px;
        cursor: pointer;
        /* color: #ff7200; */
    }






}

@media only screen and (max-width:501px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;

    }

    .show_verifying_div {
        display: none;
    }

    .show_secure_div {
        display: none;
    }

    .show_verifying_div_1 {
        display: none;
    }

    .verified_yes {
        display: none;
    }

    .not_verified {
        display: none;
    }

    #root {
        height: 0px;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }


    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        right: 13px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;
    }

    #scrolling_to_top:hover {
        background-color: #ff7200;
    }

    .home_div_outer {
        width: 100%;
        height: 350px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 70%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 95%;
        height: 70%;
    }

    .newLynkRow {
        width: 76%;
        height: 40%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .bigElem1 {
        width: auto;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 4rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-around;
        position: relative;
        top: -15px;
    }

    .headSpan1 {
        font-size: 1.2rem;
        color: #989898;
        position: relative;
        top: 9px;
    }

    .headSpan2 {
        font-size: 1.8rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 2px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 93%;
        height: 40px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 7%;
        margin-right: 7px;
    }

    .btnSpan {
        font-size: 8.3px;
        color: #fff;
        font-weight: 500;
    }

    .btnNewSpan {
        font-size: 8.5px;
        color: #fff;
    }

    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 899px;
        position: relative;
        top: -7px;
        background-color: rgb(187, 184, 184);

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 20px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
        display: none;
        /* background-color: antiquewhite; */
    }

    /*  */
    .working_steps_mobile_1 {
        width: 95%;
        height: 320px;
        position: relative;
        top: 50px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .one_mobile {
        width: 100%;
        height: 40%;
        /* background-color: aqua; */
        display: flex;
    }

    .one_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 10px;
        left: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .one_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .one_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one_mobile {
        font-size: 50px;
        position: relative;
        left: 87%;
        top: 87px;
        -webkit-transform: rotate(35deg);
                transform: rotate(35deg);
        color: #ff7200;
    }

    .two_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;
        /* background-color: blue; */
    }

    .two_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .two_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .two_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .two_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    #arrow_two_mobile {
        font-size: 50px;
        position: relative;
        left: 47%;
        top: 30px;
        -webkit-transform: rotate(136deg);
                transform: rotate(136deg);
        color: #ff7200;
    }

    /*  */

    .working_steps_mobile_2 {
        width: 95%;
        height: 350px;
        position: relative;
        top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        /* background-color: blue; */
    }

    .three_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 14px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .three_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_three_mobile {
        font-size: 50px;
        position: relative;
        left: 90%;
        top: 90px;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        color: #ff7200;
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;
        /* background-color: blue; */
    }

    .three_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .four_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 10px;
        display: flex;
        /* background-color: blue; */
    }

    .four_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blueviolet; */

    }

    .four_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .four_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    #arrow_four_mobile {
        font-size: 50px;
        position: relative;
        left: -2%;
        top: 126px;
        -webkit-transform: rotate(140deg);
                transform: rotate(140deg);
        color: #ff7200;
    }


    .five_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        position: relative;
        top: 22px;
        /* background-color: blue; */
    }

    .five_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 14px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .five_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .five_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal_mobile {
        width: 39px;
        height: 30px;
        position: relative;
        top: 0px;
    }

    .not_equal_mobile {
        width: 39px;
        height: 44px;
        position: relative;
        bottom: 0px;
    }

    /*  */





    /*  */
    .lynksign_for {
        width: 100%;
        height: 880px;
        background-color: #3d3d3d;
        position: relative;
        top: -7px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 90%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        display: none;
        /* background-color: pink; */

    }

    .lynksign_data_mobile {
        width: 75%;
        height: 81%;
        /* background-color: blueviolet; */
        position: relative;
        top: 58px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .curved_1_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_1_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_1_mobile p {
        position: relative;
        top: 48px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }

    .curved_2_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_2_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_2_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }

    .curved_3_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_3_mobile img {
        width: 50px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_3_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }



    /*  */
    .secure_verify_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
        display: none;
    }

    .secure_mobile {
        width: 100%;
        height: 420px;
    }

    .secure_main_mobile {
        width: 100%;
        height: 420px;
        position: relative;
        top: -380px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_secure_main_mobile {
        display: none
    }

    .secure_mobile_inner {
        width: 88%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 40px;
        background-color: #000;
    }

    .secure_mobile_head {
        width: 100%;
        height: 60%;
        text-align: center;
        /* background-color: burlywood; */
    }

    .secure_mobile_head h2 {
        font-size: 30px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #secure__mobile_color {
        color: #ff7200;
    }

    .secure_mobile_head h4 {
        position: relative;
        top: 70px;
        font-weight: 400;
        font-size: 13px;
        color: #fff;
    }

    #upload_pdf {
        font-size: 15px;
        position: relative;
        top: 109px;
    }

    .choosse_div_mobile {
        width: 100%;
        height: 35%;
        /* background-color: #ff7200; */
        text-align: center;
    }

    .choose_div_btn_mobile {
        width: 40%;
        height: 44px;
        position: relative;
        top: 56px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
    }

    .choose_div_btn_mobile:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #overlap_text_mobile {
        font-size: 12px;
        font-weight: 400;
    }

    .securing_file_div {
        width: 100%;
        height: 555px;
        position: relative;
        top: -369px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_securing_file_div {
        display: none;
    }

    .securing_file_inner {
        width: 88%;
        height: 459px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 53px;
        background-color: #000;
    }

    .file_securing {
        width: 100%;
        height: 15%;
        /* background-color: pink; */
        border: 2px solid silver;
        border-right-color: transparent;
        border-left-color: transparent;
        border-top-color: transparent;
        position: relative;
        display: flex;
        justify-content: space-around;
        /* display: block; */
        /* background-color: red; */
    }

    .file_securing p {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 25px;
        font-weight: 400;
        color: #fff;
    }

    .another_upload_mob {
        width: 90px;
        height: 31px;
        position: relative;
        top: 22px;
        background-color: #ff7200;
        border: none;
        color: white;
        font-size: 9px;
        border-radius: 11px;

    }

    #file_name_mob {
        position: relative;
        left: 6px;
        color: #C0C0C0;
        ;
        font-size: 12px;
        font-weight: 700;
        border-bottom: 3px solid #C0C0C0;
        ;
    }


    .securing_file_daata {
        width: 100%;
        height: 82%;
        display: block;
    }

    .securing_file_daata form {
        width: 90%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .securing_file_daata form label {
        font-size: 13px;
        font-weight: 600;
        position: relative;
        top: 70px;
        right: 12px;
        color: #fff;
    }

    #fetched_location {
        width: 194px;
        height: auto;
        display: inline-block;
        text-align: start;
        font-size: 10px;
        position: absolute;
        top: -7px;
        left: 53px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #000;
        word-wrap: break-word;
    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 250px;
        cursor: pointer;
        color: #fff;
    }

    #label_timee_mob {
        position: relative;
        left: -40px;
    }

    .securing_file_daata form input {
        position: relative;
        top: 70px;
        width: 190px;
        background-color: #000;
    }

    .sign_mobile {
        width: 40%;
        height: 44px;
        position: relative;
        top: 49px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
    }

    .sign_mobile:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .verify_mobile {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -382px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verify_mobile {
        display: none;
    }

    .verify_mob_inner {
        width: 88%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;

    }

    .verify_mob_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_head h2 {
        font-size: 30px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #verify_mob_clr {
        color: #ff7200;
    }

    .verify_mob_head h4 {
        font-size: 13px;
        font-weight: 400;
        position: relative;
        top: 65px;
        color: #fff;
    }

    .verify_mob_upload {
        width: 90%;
        height: 150px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_upload h2 {
        font-size: 15px;
        position: relative;
        top: 40px;
        color: #C0C0C0;
    }

    .verify_mob_upload button {
        width: 42%;
        height: 40px;
        background-color: #ff7200;
        color: #000;
        border: none;
        border-radius: 10px;
        position: relative;
        top: 90px;
        font-size: 12px;
    }

    .verfiy_mob_1 {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -380px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verfiy_mob_1 {
        display: none;
    }

    .verify_mob_inner_1 {
        width: 88%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;
    }

    .verify_1_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_1_head h2 {
        font-size: 30px;
        position: relative;
        top: 27px;
        color: #C0C0C0;
    }

    .verify_1_head h4 {
        font-size: 13px;
        font-weight: 400;
        position: relative;
        top: 74px;
        color: #fff;
    }

    .verifying_1_form {
        width: 100%;
        height: 180px;
    }

    .verifying_1_form form {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .verifying_1_form form label {
        position: relative;
        top: 50px;
        font-weight: 500;
        color: #fff;
    }

    #get_id_mob {
        position: relative;
        top: 0px;
        left: 10px;
        width: 170px;
        background-color: #383838;
    }

    .choose_div_btn_1_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 60px;
        left: -30px;
    }

    #overlap_text_1_mob {
        color: #000;
        font-size: 12px;
        font-weight: 400;
    }

    .submit_id_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 20px;
        color: #000;
        top: 60px;
        left: 30px;
        font-size: 12px;
    }


    /* YESSS MOBILE */

    .verifying_yes_mob {
        width: 100%;
        height: 480px;
        background-color: #E8E8E8;
        position: relative;
        top: -380px;
        /* background-color: rgb(247,245,245); */
        display: block;
    }

    .hide_verifying_yes_mob {
        display: none;
    }

    .verifying_yes_mob_in {
        width: 90%;
        height: 84%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #212121;
        /* box-shadow: 1px 1px 10px black; */
    }

    .yes_details {
        width: 95%;
        height: 70px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .yes_details h2 {
        font-size: 20px;
        position: relative;
        top: 17px;
        color: #909090;
    }

    .yes_details_more {
        width: 95%;
        height: 270px;
        /* background-color: bisque; */
        position: relative;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    #mob_name {
        position: absolute;
        left: 80px;
        top: 40px;
        font-size: 14px;
    }

    #mob_email {
        position: absolute;
        left: 80px;
        top: 80px;
        font-size: 14px;
    }

    #mob_place {
        position: absolute;
        left: 80px;
        top: 120px;
        font-size: 14px;
    }

    #mob_time {
        position: absolute;
        left: 80px;
        top: 160px;
        font-size: 14px;
    }

    #special_green_mob {
        font-size: 16px;
        color: green;
        position: relative;
        top: 0px;
        font-weight: 600;
    }

    #mob_yes_clrs {
        padding-left: 12px;
        color: red;
        font-size: 14px;
        font-weight: 400;
    }

    .mob_another_btn {
        width: 120px;
        height: 30px;
        background-color: #ff7200;
        border: none;
        color: #000;
        border-radius: 10px;
        position: relative;
        top: 22px;
    }

    /*  */

    .verifying_no_mob {
        width: 100%;
        height: 420px;
        background-color: #E8E8E8;
        display: block;
        position: relative;
        top: -380px;

    }

    .hide_verifying_no_mob {
        display: none;
    }

    .verifying_no_inn_mob {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 40px;
        text-align: center;
        border-radius: 10px;
        background-color: #212121;
        box-shadow: 1px 1px 10px black;
    }

    .question_mob {
        width: 90%;
        height: 90%;
        margin-left: auto;
        margin-right: auto;

    }

    .question_mob h2 {
        font-size: 19px;
        position: relative;
        top: 20px;
        color: #909090;
    }

    #cross_mob {
        font-size: 79px;
        color: red;
        position: relative;
        top: 66px;
    }

    .wrong_mob_btn {
        width: 120px;
        height: 39px;
        background-color: red;
        border: none;
        color: white;
        position: relative;
        top: -15px;
        border-radius: 10px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;


    }

    .secure_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 34px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 40%;
        height: 49px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        -webkit-transform: translateY(-6px);

                transform: translateY(-6px);
        border-radius: 25px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;


    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        /* background-color: pink; */
    }

    .verify_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;



    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 81%;
        height: 49px;
        border: none;
        background-color: #ff7200;
        font-size: 12px;
        color: black;
        cursor: pointer;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 22px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -7px;


    }

    .securing_main_div {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 141px;
        height: 45px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 11px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);

    }

    .another_upload:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .securing_form {
        width: 100%;
        height: 100%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 85%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 40px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 32px;
        text-align: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #label_timee {
        position: relative;
        left: 16px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        background-color: #212121;
        top: 30px;
        color: #C0C0C0;
    }

    .sign {
        width: 137px;
        height: 43px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 53px;
        left: 0px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 22px;
        font-family: 'Montserrat', sans-serif;
    }




    .sign:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        
        
    }
    /* .overlay{
        background-color: rgba(49,49,49,0.8);
    } */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -10px;
        /* background-color: red; */

    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 30px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 126px;
        position: relative;
        top: 100px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 90%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .heading_verify h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 14px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        /* background-color: antiquewhite; */
    }

    #to_left {
        position: absolute;
        left: 3%;
        font-size: 16px;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #to_left1 {
        position: absolute;
        font-size: 16px;
        bottom: 100px;
        left: 3%;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 13px;
        position: relative;
        top: 0px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        left: 0px;
        display: inline-block;

    }

    #special_text_1_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -50px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -72px;
        left: 0px;
        display: inline-block;

    }

    #get_id {
        position: relative;
        top: 0px;
        width: 110px;
    }

    .form_verifying form {
        width: 100%;
        height: 70%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 16px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 120px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 44px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 0px;
        background-color: #212121;
        color: #C0C0C0;
    }

    .submit_id {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 37px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .submit_id_1 {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: -36px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }





    .show_verifying_div_1 {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }


    #locatione {
        color: #ff7200;
        font-size: 7px;
        position: absolute;
        top: 63%;
        left: 53%;
        cursor: pointer;
        width: auto;
        z-index: 1;
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }

    #fetched_location_mob {

        font-size: 10px;
        position: relative;
        left: 22px;
        top: -35px;
        font-weight: 600;
        color: green;
        padding-left: 80px;
    }

    .verified_yes {
        width: 100%;
        height: 400px;
        position: relative;
        top: -9px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 96%;
        height: 25%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .yes_details_more {
        width: 100%;
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        position: relative;
        top: 30px;
    }

    .newYes {
        width: 100%;
        height: 40px;
        display: flex;
    }

    .dataSpan {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .dataSpan span {
        font-size: 0.9rem;
        font-weight: 500;
        padding-right: 15px;
        color: #fff;
    }

    .dataField {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        text-align: left;
        /* background-color: red; */
    }

    .dataField span {
        font-size: 0.8rem;
        color: #C0C0C0;
    }

    .yes_1 h2 {
        font-size: 18px;
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }

    .yes_2 {
        width: 100%;
        height: 70%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
        text-align: center;
        /* background-color: green; */
    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .label_name {
        position: absolute !important;
        left: -47px !important;
    }

    .label_mail {
        position: absolute;
        right: 0px !important;
    }

    .label_place {
        position: relative;
        right: 65px !important;

    }

    .label_time {
        position: relative !important;
        right: 1px !important;
    }

    .yes_2 label {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        right: 20px;
        top: 40px;
    }

    #special_green {
        font-size: 16px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        bottom: -14px;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 8px;
        font-size: 15px !important;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 14px;
        font-size: 15px !important;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 12px;
        font-size: 15px !important;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 50px;
        font-size: 15px !important;
    }


    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 80%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        color: #C0C0C0;
        top: 5px;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 14px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 47%;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        /* background-color: antiquewhite; */
        align-items: center;
    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
        display: none;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;

    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
        display: none;

    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
        display: none;
    }


    #special_text_white_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        /* left: 1%; */
        text-decoration: underline;
        top: 0px;
        font-size: 9px;

    }

    #special_text_white1_new {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        display: block;
        font-weight: 400;
        /* left: 3%; */
        top: 20px;
        font-size: 9px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 65px;
        font-size: 11px;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .no_1 {
        width: 92%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 18px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 98px;
        position: relative;
        top: 80px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;

    }

    #trxnid_no {
        position: absolute;
        left: 3%;
        top: 14px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        display: none;
    }

    #choose_file_no {
        position: absolute;
        left: 3%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 15px;
        display: none;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 0px;
        text-decoration: underline;
        top: 0px;
        display: none;
        font-size: 11px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 7px;
        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: none;
    }

    #special_text_white2_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;

        text-decoration: underline;
        top: 10px;
        font-size: 8px;
        display: block;
    }


    #special_text_white3_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        top: 17px;
        font-size: 8px;
    }

    .submit_id_red {
        width: 120px;
        height: 35px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 51px;
        font-size: 11px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #server_time {
        position: relative;
        left: 18px !important;
        color: #C0C0C0;
        font-size: 12px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 250px;
        height: 300px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        -webkit-transform: translateY(40%);
                transform: translateY(40%);
        transition: -webkit-transform 0.1s;
        transition: transform 0.1s;
        transition: transform 0.1s, -webkit-transform 0.1s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        -webkit-animation: slide 0.1s;
                animation: slide 0.1s;
        z-index: 100;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        border: 2.7px solid #ff7200;


    }

    @-webkit-keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }



    }

    @keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }



    }









    .otp_image {
        width: 75%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 70px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 68px;
    }

    .closeMe {
        width: 33px;
    }

    .otp_image h3 {
        font-size: 15px;
        position: relative;
        top: 55px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;

        /* background-color: aqua; */

    }

    #otp_input {
        font-size: 0.9rem;
        padding: 5px;
        border: 2px solid #ccc;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 15px;
        width: 72%;
        height: 35px;
        font-size: 12px;
        letter-spacing: 1px;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 25px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }
}

@media only screen and (max-width:401px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;

    }

    .show_verifying_div {
        display: none;
    }

    .show_secure_div {
        display: none;
    }

    .show_verifying_div_1 {
        display: none;
    }

    .verified_yes {
        display: none;
    }

    .not_verified {
        display: none;
    }

    #root {
        height: 0px;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }

    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;
    }

    #scrolling_to_top:hover {
        background-color: #ff7200;
    }

    .home_div_outer {
        width: 100%;
        height: 350px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 70%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 90%;
        height: 64%;
    }

    .newLynkRow {
        width: 85%;
        height: 37%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .newLynkRow1 {
        width: 85%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .bigElem1 {
        width: auto;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 3.5rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-around;
        position: relative;
        top: -15px;
    }

    .headSpan1 {
        font-size: 1rem;
        color: #989898;
        position: relative;
        top: 8px;
    }

    .headSpan2 {
        font-size: 1.4rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 1px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 90%;
        height: 40px;
        border-radius: 35px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 6%;
        margin-right: 7px;
    }

    .btnSpan {
        font-size: 8px;
        color: #fff;
        font-weight: 500;
    }

    .btnNewSpan {
        font-size: 8px;
        color: #fff;
    }

    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 859px;
        position: relative;
        top: -7px;
        background-color: rgb(187, 184, 184);

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 20px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
        display: none;
        /* background-color: antiquewhite; */
    }

    /*  */
    .working_steps_mobile_1 {
        width: 95%;
        height: 320px;
        position: relative;
        top: 50px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .one_mobile {
        width: 100%;
        height: 40%;
        /* background-color: aqua; */
        display: flex;
    }

    .one_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 10px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .one_text_mobile p {
        font-size: 11px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 0px;
    }

    .one_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one_mobile {
        font-size: 50px;
        position: relative;
        left: 87%;
        top: 87px;
        -webkit-transform: rotate(35deg);
                transform: rotate(35deg);
        color: #ff7200;
    }

    .two_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;

    }

    .two_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .two_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .two_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .two_text_mobile p {
        font-size: 11px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    #arrow_two_mobile {
        font-size: 50px;
        position: relative;
        left: 47%;
        top: 30px;
        -webkit-transform: rotate(136deg);
                transform: rotate(136deg);
        color: #ff7200;
    }

    /*  */

    .working_steps_mobile_2 {
        width: 95%;
        height: 350px;
        position: relative;
        top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        /* background-color: blue; */
    }

    .three_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: pink; */
    }

    .three_text_mobile p {
        font-size: 11px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_three_mobile {
        font-size: 50px;
        position: relative;
        left: 90%;
        top: 90px;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        color: #ff7200;
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;
        /* background-color: blue; */
    }

    .three_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        left: 3px;
        top: 7px;

    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .four_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 10px;
        display: flex;
    }

    .four_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 10px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blueviolet; */

    }

    .four_text_mobile p {
        font-size: 11px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        right: 12px;
        top: 7px;

    }

    .four_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    #arrow_four_mobile {
        font-size: 50px;
        position: relative;
        left: -2%;
        top: 126px;
        -webkit-transform: rotate(140deg);
                transform: rotate(140deg);
        color: #ff7200;
    }


    .five_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        position: relative;
        top: 22px;
        /* background-color: blue; */
    }

    .five_text_mobile {
        width: 45%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 5px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .five_text_mobile p {
        font-size: 11px;
        position: relative;
        top: 6px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 0px;
    }

    .five_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal_mobile {
        width: 39px;
        height: 30px;
        position: relative;
        top: 0px;
    }

    .not_equal_mobile {
        width: 39px;
        height: 44px;
        position: relative;
        bottom: 0px;
    }

    /*  */





    /*  */
    .lynksign_for {
        width: 100%;
        height: 880px;
        background-color: #3d3d3d;
        position: relative;
        top: -7px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 90%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        display: none;
        /* background-color: pink; */

    }

    .lynksign_data_mobile {
        width: 75%;
        height: 81%;
        position: relative;
        top: 58px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .curved_1_mobile {
        width: 76%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_1_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_1_mobile p {
        position: relative;
        top: 48px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }

    .curved_2_mobile {
        width: 76%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_2_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_2_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }

    .curved_3_mobile {
        width: 76%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_3_mobile img {
        width: 50px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_3_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }



    /*  */
    .secure_verify_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
        display: none;
    }

    .secure_mobile {
        width: 100%;
        height: 420px;
    }

    .secure_main_mobile {
        width: 100%;
        height: 420px;
        position: relative;
        top: -380px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_secure_main_mobile {
        display: none
    }

    .secure_mobile_inner {
        width: 88%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 40px;
        background-color: #000;
    }

    .secure_mobile_head {
        width: 100%;
        height: 60%;
        text-align: center;
        /* background-color: burlywood; */
    }

    .secure_mobile_head h2 {
        font-size: 30px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #secure__mobile_color {
        color: #ff7200;
    }

    .secure_mobile_head h4 {
        position: relative;
        top: 70px;
        font-weight: 400;
        font-size: 13px;
        color: #fff;
    }

    #upload_pdf {
        font-size: 15px;
        position: relative;
        top: 109px;
    }

    .choosse_div_mobile {
        width: 100%;
        height: 35%;
        /* background-color: #ff7200; */
        text-align: center;
    }

    .choose_div_btn_mobile {
        width: 40%;
        height: 44px;
        position: relative;
        top: 56px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
    }

    .choose_div_btn_mobile:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #overlap_text_mobile {
        font-size: 12px;
        font-weight: 400;
    }

    .securing_file_div {
        width: 100%;
        height: 555px;
        position: relative;
        top: -369px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_securing_file_div {
        display: none;
    }

    .securing_file_inner {
        width: 88%;
        height: 459px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 53px;
        background-color: #000;

    }

    .file_securing {
        width: 100%;
        height: 19%;
        border: 2px solid silver;
        border-right-color: transparent;
        border-left-color: transparent;
        border-top-color: transparent;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* background-color: red; */
    }

    .file_securing p {
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 5px;
        left: 15px;
        font-weight: 400;
        color: #fff;
    }

    .another_upload_mob {
        width: 90px;
        height: 31px;
        position: relative;
        top: 4px;
        background-color: #ff7200;
        border: 0.2px solid #ff7200;
        color: #fff;
        font-size: 9px;
        font-weight: 700;
        border-radius: 11px;

    }

    #file_name_mob {
        position: relative;
        left: 6px;
        color: #C0C0C0;
        ;
        font-size: 12px;
        font-weight: 700;
        border-bottom: 3px solid #C0C0C0;
        ;
    }


    .securing_file_daata {
        width: 100%;
        height: 82%;
        display: block;
    }

    .securing_file_daata form {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .securing_file_daata form label {
        font-size: 11px;
        font-weight: 600;
        position: relative;
        top: 70px;
        color: #fff;
    }

    #fetched_location {
        width: 170px;
        height: auto;
        display: inline-block;
        /* border: 1px solid red; */
        text-align: start;
        font-size: 10px;
        position: absolute;
        top: -10px;
        left: 45px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #000;
        word-wrap: break-word;
    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 216px;

        cursor: pointer;
        color: #fff;
    }

    #label_timee_mob {
        position: relative;
        left: -40px;
    }

    .securing_file_daata form input {
        position: relative;
        top: 70px;
        position: relative;
        left: -2px;
        width: 165px;
    }

    .sign_mobile {
        width: 34%;
        height: 36px;
        position: relative;
        top: 45px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
    }

    .sign_mobile:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .verify_mobile {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -382px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verify_mobile {
        display: none;
    }

    .verify_mob_inner {
        width: 88%;
        height: 76%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;

    }

    .verify_mob_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_head h2 {
        font-size: 30px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #verify_mob_clr {
        color: #ff7200;
    }

    .verify_mob_head h4 {
        font-size: 13px;
        font-weight: 400;
        position: relative;
        top: 65px;
        color: #fff;
    }

    .verify_mob_upload {
        width: 90%;
        height: 150px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_upload h2 {
        font-size: 15px;
        position: relative;
        top: 40px;
        color: #C0C0C0;
    }

    .verify_mob_upload button {
        width: 35%;
        height: 38px;
        background-color: #ff7200;
        color: #000;
        border: none;
        border-radius: 10px;
        position: relative;
        top: 90px;
        font-size: 12px;
    }

    .verfiy_mob_1 {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -380px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verfiy_mob_1 {
        display: none;
    }

    .verify_mob_inner_1 {
        width: 88%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;
    }

    .verify_1_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_1_head h2 {
        font-size: 30px;
        position: relative;
        top: 27px;
        color: #C0C0C0;
    }

    .verify_1_head h4 {
        font-size: 13px;
        font-weight: 400;
        position: relative;
        top: 74px;
        color: #fff;
    }

    .verifying_1_form {
        width: 100%;
        height: 180px;
    }

    .verifying_1_form form {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .verifying_1_form form label {
        position: relative;
        top: 50px;
        font-weight: 500;
        color: #fff;
    }

    #get_id_mob {
        position: relative;
        top: 0px;
        left: 10px;
        width: 110px;
        background-color: #383838;
    }

    .choose_div_btn_1_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 60px;
        left: -10px;
    }

    #overlap_text_1_mob {
        color: #000;
        font-size: 10px;
        font-weight: 400;
    }

    .submit_id_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 20px;
        color: #000;
        top: 60px;
        left: 10px;
        font-size: 10px;
    }


    /* YESSS MOBILE */

    .verifying_yes_mob {
        width: 100%;
        height: 480px;
        background-color: #E8E8E8;
        position: relative;
        top: -380px;
        /* background-color: rgb(247,245,245); */
        display: block;
    }

    .hide_verifying_yes_mob {
        display: none;
    }

    .verifying_yes_mob_in {
        width: 90%;
        height: 84%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #212121;
        /* box-shadow: 1px 1px 10px black; */
    }

    .yes_details {
        width: 95%;
        height: 70px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .yes_details h2 {
        font-size: 18px;
        position: relative;
        top: 17px;
        color: #909090;
    }

    .yes_details_more {
        width: 100%;
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        position: relative;
        top: 30px;
    }

    .newYes {
        width: 100%;
        height: 40px;
        display: flex;
    }

    .dataSpan {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .dataSpan span {
        font-size: 0.8rem;
        font-weight: 500;
        padding-right: 15px;
        color: #fff;
    }

    .dataField {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        text-align: left;
        /* background-color: red; */
    }

    .dataField span {
        font-size: 0.8rem;
        color: #C0C0C0;
    }

    #mob_name {
        position: absolute;
        left: 70px;
        top: 30px;
        font-size: 12px;
    }

    #mob_email {
        position: absolute;
        left: 70px;
        top: 70px;
        font-size: 12px;
    }

    #mob_place {
        position: absolute;
        left: 70px;
        top: 110px;
        font-size: 12px;
    }

    #mob_time {
        position: absolute;
        left: 70px;
        top: 150px;
        font-size: 12px;
    }

    #special_green_mob {
        font-size: 15px;
        color: green;
        position: relative;
        top: 0px;
        font-weight: 600;
    }

    #mob_yes_clrs {
        padding-left: 12px;
        color: red;
        font-size: 11px;
        font-weight: 400;
    }

    .mob_another_btn {
        width: 99px;
        height: 28px;
        background-color: #ff7200;
        border: none;
        color: #000;
        font-size: 10px;
        border-radius: 10px;
        position: relative;
        top: 12px;
    }

    /*  */

    .verifying_no_mob {
        width: 100%;
        height: 420px;
        background-color: #E8E8E8;
        display: block;
        position: relative;
        top: -380px;

    }

    .hide_verifying_no_mob {
        display: none;
    }

    .verifying_no_inn_mob {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 40px;
        text-align: center;
        border-radius: 10px;
        background-color: #212121;
        box-shadow: 1px 1px 10px black;
    }

    .question_mob {
        width: 90%;
        height: 90%;
        margin-left: auto;
        margin-right: auto;

    }

    .question_mob h2 {
        font-size: 16px;
        position: relative;
        top: 20px;
        color: #909090;
    }

    #cross_mob {
        font-size: 79px;
        color: red;
        position: relative;
        top: 66px;
    }

    .wrong_mob_btn {
        width: 100px;
        height: 37px;
        font-size: 10px;
        background-color: red;
        border: none;
        color: white;
        position: relative;
        top: -15px;
        border-radius: 10px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;


    }

    .secure_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 34px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 40%;
        height: 49px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        -webkit-transform: translateY(-6px);

                transform: translateY(-6px);
        border-radius: 25px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;


    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        /* background-color: pink; */
    }

    .verify_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;



    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 81%;
        height: 49px;
        border: none;
        background-color: #ff7200;
        font-size: 12px;
        color: black;
        cursor: pointer;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 22px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -7px;


    }

    .securing_main_div {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 141px;
        height: 45px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 11px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);

    }

    .another_upload:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .securing_form {
        width: 100%;
        height: 100%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 85%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 40px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 32px;
        text-align: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #label_timee {
        position: relative;
        left: 16px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        background-color: #212121;
        top: 30px;
        color: #C0C0C0;
    }

    .sign {
        width: 137px;
        height: 43px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 53px;
        left: 0px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 22px;
        font-family: 'Montserrat', sans-serif;
    }




    .sign:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        
        
    }
    /* .overlay{
        background-color: rgba(49,49,49,0.8);
    } */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -10px;
        /* background-color: red; */

    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 30px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 126px;
        position: relative;
        top: 100px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 90%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .heading_verify h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 14px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        /* background-color: antiquewhite; */
    }

    #to_left {
        position: absolute;
        left: 3%;
        font-size: 16px;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #to_left1 {
        position: absolute;
        font-size: 16px;
        bottom: 100px;
        left: 3%;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 13px;
        position: relative;
        top: 0px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        left: 0px;
        display: inline-block;

    }

    #special_text_1_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -50px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -72px;
        left: 0px;
        display: inline-block;

    }

    #get_id {
        position: relative;
        top: 0px;
        width: 110px;
    }

    .form_verifying form {
        width: 100%;
        height: 70%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 16px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 120px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 44px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 30px;
        background-color: #212121;
        color: #C0C0C0;
    }

    .submit_id {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 37px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .submit_id_1 {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: -36px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }





    .show_verifying_div_1 {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }

    #locatione {
        color: #ff7200;
        font-size: 7px;
        position: absolute;
        top: 67%;
        left: 50%;
        cursor: pointer;
        width: auto;
        z-index: 1;
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }

    #fetched_location_mob {

        font-size: 10px;
        position: relative;
        left: 22px;
        top: -35px;
        font-weight: 600;
        color: green;
        padding-left: 80px;
    }



    .verified_yes {
        width: 100%;
        height: 400px;
        position: relative;
        top: -9px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 96%;
        height: 25%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;


    }

    .yes_1 h2 {
        font-size: 18px;
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }



    .yes_2 {
        width: 100%;
        height: 70%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
        text-align: center;
        /* background-color: green; */

    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;


    }

    .label_name {
        position: absolute !important;
        left: -47px !important;



    }

    .label_mail {
        position: absolute;
        right: 0px !important;
    }

    .label_place {
        position: relative;
        right: 65px !important;

    }

    .label_time {

        position: relative !important;
        right: 1px !important;
    }


    .yes_2 label {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        right: 20px;
        top: 40px;
    }

    #special_green {
        font-size: 16px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        bottom: -14px;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 8px;
        font-size: 15px !important;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 14px;
        font-size: 15px !important;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 12px;
        font-size: 15px !important;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 50px;
        font-size: 15px !important;
    }


    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 80%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        color: #C0C0C0;
        top: 5px;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 14px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 47%;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        /* background-color: antiquewhite; */
        align-items: center;
    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
        display: none;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;

    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
        display: none;

    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
        display: none;
    }


    #special_text_white_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        /* left: 1%; */
        text-decoration: underline;
        top: 0px;
        font-size: 9px;

    }

    #special_text_white1_new {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        display: block;
        font-weight: 400;
        /* left: 3%; */
        top: 20px;
        font-size: 9px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 65px;
        font-size: 11px;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .no_1 {
        width: 92%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 18px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 98px;
        position: relative;
        top: 80px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;

    }

    #trxnid_no {
        position: absolute;
        left: 3%;
        top: 14px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        display: none;
    }

    #choose_file_no {
        position: absolute;
        left: 3%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 15px;
        display: none;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 0px;
        text-decoration: underline;
        top: 0px;
        display: none;
        font-size: 11px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 7px;
        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: none;
    }

    #special_text_white2_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;

        text-decoration: underline;
        top: 10px;
        font-size: 8px;
        display: block;
    }


    #special_text_white3_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        top: 17px;
        font-size: 8px;
    }

    .submit_id_red {
        width: 120px;
        height: 35px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 51px;
        font-size: 11px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    #server_time {
        position: relative;
        left: -15px;
        color: #C0C0C0;
        font-size: 10px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 250px;
        height: 300px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        z-index: 100;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        -webkit-transform: translateY(40%);
                transform: translateY(40%);
        transition: -webkit-transform 0.1s;
        transition: transform 0.1s;
        transition: transform 0.1s, -webkit-transform 0.1s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        -webkit-animation: slide 0.1s;
                animation: slide 0.1s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        border: 2.7px solid #ff7200;


    }

    @-webkit-keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }



    }

    @keyframes slide {
        0% {
            -webkit-transform: translateY(10%);
                    transform: translateY(10%);
        }

        50% {
            -webkit-transform: translateY(20%);
                    transform: translateY(20%);
        }

        100% {
            -webkit-transform: translateY(35%);
                    transform: translateY(35%);
        }



    }









    .otp_image {
        width: 75%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 68px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 68px;
    }

    .closeMe {
        width: 33px;
    }

    .otp_image h3 {
        font-size: 15px;
        position: relative;
        top: 55px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {
        font-size: 0.85rem;
        padding: 5px;
        border: 2px solid #ccc;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 15px;
        width: 72%;
        height: 35px;
        font-size: 11px;
        letter-spacing: 1px;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 25px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }




























}
*{ 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.main_company{
   
    height: 650px;  
    width: 100%;
    position: relative;   
    top: 70px;
   
  
 }          

.sub_main_company{
    background-color: rgb(25,29,29);
    width: 100%;
    height: 485px;
    position: relative;
    top: 0px;
}

.sub_main_company h1{
      font-family: 'Montserrat', sans-serif;
    font-weight:900;
    color: #ff7200;;
    font-size: 59px;
    
    position: relative;  
   
    top: 24px;
   text-align: center;
      font-family: 'Montserrat', sans-serif;
   
}

.sub_main_company p{
   color: #fff;
   padding-left: -10%;
   padding-top: 48px;
   font-weight:300;
   padding-left: 5%;
   padding-right: 6%;
   font-size: 14px;
   letter-spacing: 1.5px;
   
   text-align: justify;
   text-align: center;
     font-family: 'Montserrat', sans-serif;
}

#one_company{
    padding-left: 533px;
    color: #fff;
}
#two_company{
    padding-left: 366px;
    color: #fff;
}
#three_company{
    padding-left: 347px;
    color: #fff; 
}

.sub_main_2{
    position: relative;
    top: -70px;
    width: 100%;
    height: 546px;
    background-color:white;
   
   
} 

/* #arrow1{
    position: relative;
    left: -366%;

} */
 

#Family{
    width: 68%;
    height: 522px;
   
    z-index: 10;
}

::-webkit-scrollbar{
    width: 6px;
}

::-webkit-scrollbar-thumb{
    background-color: #ff7200;
    border-radius: 7px;
    
}
#scrolling_to_top{
    background-color:transparent;
    z-index: 100;
    width: 35px;
    box-shadow: 1px 1px 4px black;
    height: 35px;
    background: rgb(194, 189, 189) no-repeat;

}
#scrolling_to_top:hover{
    background-color: #ff7200;
    }
    
    
    svg{
        vertical-align: middle;
        width: 22px;
        cursor: pointer;
    }
    
    svg:hover{ 
        width: 27px;
    }

    

@media only screen and (min-width:1901px){
   
    *{
        margin: 0px;
        padding: 0px;
    }
    
    html,body{
      margin: 0px;
      padding: 0px;
  }   
    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 18px;
     }
     .about_foooter{
        position: relative;
        top: 1005px;
    }  
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:700;
        color: #ff7200;;
        font-size: 68px;
        position: relative;
        top: 40px;
        font-weight: 900 ;
        letter-spacing: 0px ! important;  
      
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 90%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 18px;
       letter-spacing: 0.5px;
       line-height: 27px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 62%;
        margin-left: auto;
        margin-right: auto;
        height: 866px;
        border: 5px solid #ff7200;
       
       
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }  
    .customer_div1{
        display: none;
    }
    .customer_div{
        width: 100%;
        height: 700px;
        position: absolute;
        top: 2653px;
        display: flex;
        justify-content: center;
    }
    
    .customer_div h1{
         
        font-size: 80px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 39px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 149px;
        background-size:  0% 6px;
        background-position:  bottom;
        letter-spacing: 0px !important;
       
    
    }
    
    .customers_div_1{
        width: 94%;
        height: 198px;
      
        position: absolute;
        top: 230px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 13%;
        height: 198px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 94%;
        height: 198px;
        position: absolute;
        top: 456px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 15%;
        height: 198px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    /* 3 */
    #sch{
        width: 120px !important; 
    }
    
    .customers_div_3 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_3 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customer_images{
        width: 150px;
    }
    
    #cma{
        width: 148px;
        height: 110px;
    }
    
    #bharat{
        width: 130px;
        height: 139px;
    }
    
    #aditya{
        width: 140px;
        height: 130px;
    }
    
    #lg{
        width: 130px;
        height: 70px;
    }
    
    #dadri{
        width: 140px;
        height: 130px;
    }
    
    #hundred{
        position: relative;
        top: 14px;
    
    }
    
    #client_100{
        position: relative;
        top: 14px;
    
    }
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 645px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 50px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        
    }
    #more_3500 h1:hover{
        color: black;
    }
    .emptyDiv{
        width: 100%;
        height: 10px;
        position: relative;
        top: -150px;
    }

    
}
@media only screen and (max-width:1901px){
   
      
      *{
          margin: 0px;
          padding: 0px;
      }
      
      html,body{
        margin: 0px;
        padding: 0px;
       
    }
    .about_foooter{
        position: relative;
        top: 925px;
    }   
    .main_company{
       
        height: 530px;
        width: 100%;
        position: relative;
        top: 18px;
       
        
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100% !important;
        height: 780px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px ! important;
        color: #ff7200;;
        font-size: 63px;
        position: relative;
        top: 40px;
        font-weight: 900 !important;
        
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 90%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 17px;
       letter-spacing: 0.5px;
       line-height: 25px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 65%;
        margin-left: auto;
        margin-right: auto;
        height: 796px;
        border: 5px solid #ff7200;
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    
.customer_div1{
    display: none;
}
.customer_div{
    width: 100%;
    height: 700px;
    position: absolute;
    top: 2653px;
    display: flex;
    justify-content: center;
}

.customer_div h1{
     
    font-size: 70px;
    color: black;
    text-shadow: none;
    position: absolute;
    top: 30px;
    font-weight: 900;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 0px;
    background-image:linear-gradient(#ff7200, #ff7200);
    background-repeat: no-repeat;
    line-height: 140px;
    background-size:  0% 6px;
    background-position:  bottom;
    letter-spacing: 0px !important;
   

}

.customers_div_1{
    width: 97%;
    height: 198px;
    /* border: 1px solid red; */
    position: absolute;
    top: 222px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_1 div{
    width: 11%;
    height: 198px;
  
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_1 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}

.customers_div_1 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}


.customers_div_2{
    width: 97%;
    height: 198px;
    
    position: absolute;
    top: 450px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_2 div{
    width: 11%;
    height: 198px;
 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_2 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}

.customers_div_2 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}
#sch{
    width: 120px;
}

.customer_images{
    width: 150px;
}

#cma{
    width: 130px;
    height: 100px;
}

#bharat{
    width: 120px;
    height: 134px;
}

#aditya{
    width: 130px;
    height: 120px;
}

#lg{
    width: 150px;
    height: 80px;
}

#dadri{
    width: 120px;
    height: 110px;
}
#teas{
    width: 68%;
}
#hundred{
    position: relative;
    top: 14px;

}

#client_100{
    position: relative;
    top: 14px;

}
#sch{
    width: 55%;
}
#more_3500{
    width: 100%;
    height: 66px;
    position: relative;
    top: 652px;
  
    display: flex;
    align-items: center;
    justify-content: center;
}

#more_3500 h1{
    position: relative;
    font-size: 46px;
    color: #424141;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background-image:linear-gradient(white,white);
    background-repeat: none;
    background-size:  0% 0px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    
}
#more_3500 h1:hover{
    color: black;
}
.emptyDiv{
    width: 100%;
    height: 20px;
    position: relative;
    top: -150px;
}

}
@media only screen and (max-width:1550px){
    body{
        margin: 0px;
        padding: 0px;
        box-sizing:border-box ;
    }
    
    .main_company{
        height: 530px;
        width: 100% !important;
        position: absolute;
        top: 70px;        
     }
    .about_foooter{
        position: relative;
        top: 1330px;
    }
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       
        /* border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900 !important;
        color: #ff7200;;
        font-size: 59px;
        position: relative;
        top: 40px;
        letter-spacing: 0px ! important;
      
       
    }
    
    .sub_main_company p{
        /* border: 1px solid green;   */
       color: #fff;
       width: 90%;
       height: 84%;
       padding-left: 0%;
       padding-right: 0%;
       margin-left: auto;
       margin-right: auto;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 15px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        height: 626px;
        border: 4px solid #ff7200;
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    


.customer_div1{
    display: none;
}

.customer_div{
    width: 100%;
    height: 700px;
    position: absolute;
    top: 2445px;
    display: flex;
    justify-content: center;
}
.customer_div h1{    
    font-size: 54px;
    color: black;
    text-shadow: none;
    position: absolute;
    top: 10px;
    font-weight: 900;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 0px;
    background-image:linear-gradient(#ff7200, #ff7200);
    background-repeat: no-repeat;
    line-height: 110px;
    background-size:  0% 4px;
    background-position:  bottom;
    letter-spacing: 0px !important;
}

.customers_div_1{
    width: 95%;
    position: absolute;
    top: 210px;
    display: flex;
    justify-content: space-around;
}

.customers_div_1 div{
    width: 12%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_1 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}

.customers_div_1 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}
.customers_div_2{
    width: 95%;
    height: 160px;
    /* border: 2px solid green;  */
    position: absolute;
    top: 408px;
    display: flex;
    justify-content: space-around; 
}
.customers_div_2 div{
    width: 12% !important;
    height: 160px;
 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}
.customers_div_3{
    width: 24%;
    height: 182px;
    position: absolute;
    top: 638px;
    display: flex;
    justify-content: space-between; 
}
.customers_div_3 div{
    width: 50% !important;
    height: 170px;
 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}
#sch{
    width: 60%;
}

.customers_div_2 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.customers_div_2 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
}


.customer_images{
    width: 120px;
}

#cma{
    width: 85%;
    height: 100px;
}

#bharat{
    width: 100px;
    height: 130px;
}

#aditya{
    width: 110px;
    height: 100px;
}

#lg{
    width: 100px;
    height: 60px;
}

#dadri{
    width: 110px;
    height: 95px;
}

#hundred{
    position: relative;
    top: 14px;

}

#client_100{
    position: relative;
    top: 14px;

}

#more_3500{
    width: 100%;
    height: 66px;
    position: relative;
    top: 589px;
  
    display: flex;
    align-items: center;
    justify-content: center;
}

#more_3500 h1{
    position: relative;
    font-size: 46px;
    color: #424141;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background-image:linear-gradient(white,white);
    background-repeat: none;
    background-size:  0% 0px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    
}

#more_3500 h1:hover{
    color: black;
}

}
@media only screen and (max-width:1301px){
    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;        
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    .about_foooter{
        position: relative;
        top: 777px;
    }
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif !important;
        font-weight:900;
        color: #ff7200;
        letter-spacing: 0px ! important;
        font-size: 49px;
        position: relative;
        top: 40px;
        font-weight: 900;
      
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        height: 600px; 
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }    
    .customer_div1{
        display: none;
    }
    .customer_div{
        width: 100%;
        height: 700px;
        position: absolute;
        top: 2405px;
        display: flex;
        justify-content: center;
       
    }
    
    .customer_div h1{
         
        font-size: 54px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size:  0% 4px;
        background-position:  bottom;
       
    
    }
    
    .customers_div_1{
        width: 98%;
        height: 160px;
        /* border: 2px solid black; */
        position: absolute;
        top: 197px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 14%;
        height: 160px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 98%;
        height: 160px;
        
        position: absolute;
        top: 396px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 14%;
        height: 160px;
     
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customer_images{
        width: 106px;
    }
    
    #cma{
        width: 110px;
        height: 120px;
    }
    
    #bharat{
        width: 105px;
        height: 115px;
    }
    
    #aditya{
        width: 110px;
        height: 100px;
    }
    
    #lg{
        width: 100px;
        height: 55px;
    }
    
    #dadri{
        width: 110px;
        height: 90px;
    }
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 563px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 42px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        
    }
    #more_3500 h1:hover{
        color: black;
    }    

}
@media only screen and (max-width:1201px){
    .main_company{
       
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    .about_foooter{
        position: relative;
        top: 757px;
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif !important;
        font-weight:900;
        color: #ff7200;;
        font-size: 49px;
        position: relative;
        top: 40px;
        font-weight: 900;
        letter-spacing: 0px ! important;
        
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
       margin-left: auto !important;
       margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
       line-height: 22px;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        height: 580px;  
    }     
    #Family{
        width: 100%;
        height: 100%;     
    }
    .customer_div{
        width: 100%;
        height: 640px;
        position: absolute;
        top: 2400px;
        display: flex;
        justify-content: center;
    }
    
    .customer_div h1{
         
        font-size: 48px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size:  0% 4px;
        background-position:  bottom;
        letter-spacing: 0px !important;
       
    
    }
    
    .customers_div_1{
        width: 98%;
        height: 150px;
        /* border: 2px solid red; */
        position: absolute;
        top: 170px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 16%;
        height: 150px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    .customers_div_2{
        width: 98%;
        height: 150px;
        position: absolute;
        top: 352px;
        display: flex;
        justify-content: space-around; 
    }
    
    .customers_div_2 div{
        width: 16%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    .customers_div_3{
        width: 24%;
        height: 178px;
        position: absolute;
        top: 577px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_3 div{
        width: 16%;
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    #sch{
        width: 60%;
    }
    
    .customers_div_2 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customer_images{
        width: 89px;
    }
    
    #cma{
        width: 90px;
        height: 100px;
    }
    
    #bharat{
        width: 85px;
        height: 100px;
    }
    
    #aditya{
        width: 90px;
        height: 90px;
    }
    
    #lg{
        width: 90px;
        height: 55px;
    }
    
    #dadri{
        width: 85px;
        height: 76px;
    }
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 35px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        
    }
    
    #more_3500 h1:hover{
        color: black;
    } 
 
 
}
@media only screen and (max-width:1051px){
    .main_company{
       
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;  
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    .about_foooter{
        position: relative;
        top: 715px;
    }
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 49px;
        position: relative;
        top: 40px;
        letter-spacing: 0px ! important;
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
       margin-left: auto !important;
       margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: 540px;   
    } 
    
    #Family{
        width: 100%;
        height: 100%;     
    }    
    .customer_div{
        width: 100%;
        height: 584px;
        position: absolute;
        top: 2400px;
        display: flex;
        justify-content: center;
    }
    
    .customer_div h1{
         
        font-size: 48px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size:  0% 4px;
        background-position:  bottom;
       
    
    }
    
    .customers_div_1{
        width: 98%;
        height: 150px;
      
        position: absolute;
        top: 162px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 16%;
        height: 150px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 98%;
        height: 150px;
        position: absolute;
        top: 340px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 16%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    .customers_div_3{
        width: 24%;
        height: 168px;
        position: absolute;
        top: 528px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_3 div{
        width: 49%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customer_images{
        width: 90px;
    }
    
    #cma{
        width: 80px;
        height: 85px;
    }
    
    #bharat{
        width: 80px;
        height: 95px;
    }
    
    #aditya{
        width: 80px;
        height: 80px;
    }
    
    #lg{
        width: 80px;
        height: 55px;
    }
    
    #dadri{
        width: 76px;
        height: 76px;
    }
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 485px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 35px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        
    }
    
    #more_3500 h1:hover{
        color: black;
    }    
}
@media only screen and (max-width:1001px){
    .main_company{
       
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;  
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 49px;
        position: relative;
        top: 40px;
        letter-spacing: 0px ! important;
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
       margin-left: auto !important;
       margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: 540px;   
    } 
    
    #Family{
        width: 100%;
        height: 100%;     
    }    
    .customer_div{
        width: 100%;
        height: 584px;
        position: absolute;
        top: 2143px;
        display: flex;
        justify-content: center;
    }
    
    .customer_div h1{
         
        font-size: 48px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size:  0% 4px;
        background-position:  bottom;
       
    
    }
    
    .customers_div_1{
        width: 98%;
        height: 145px;
      
        position: absolute;
        top: 162px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 16%;
        height: 145px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 98%;
        height: 145px;
        
        position: absolute;
        top: 335px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 16%;
        height: 145px;
     
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customer_images{
        width: 82px;
    }
    
    #cma{
        width: 80px;
        height: 85px;
    }
    
    #bharat{
        width: 70px;
        height: 80px;
    }
    
    #aditya{
        width: 80px;
        height: 79px;
    }
    
    #lg{
        width: 70px;
        height: 50px;
    }
    
    #dadri{
        width: 80px;
        height: 70px;
    }
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 475px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 35px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        
    }
    
    #more_3500 h1:hover{
        color: black;
    }    
  
}
@media only screen and (max-width:951px){
    
    html,body{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden !important;
    }

    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;
        z-index: 2;
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 820px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 42px;
        position: relative;
        top: 40px;
        font-weight: 700;
        letter-spacing: 0px ! important;
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
      
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 84%;
        margin-left: auto;
        margin-right: auto;
        height: 470px;  
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    .customer_div{
       display: none;
    }
.customer_div1{
    width: 100%;
    height: 900px;
    position: relative;
    top: -10px;
    display: flex;
    justify-content: center;  
}



.customer_div1 h1{
     
    font-size: 44px;
    color: black;
    text-shadow: none;
    position: absolute;
    top: 10px;
    font-weight: 900;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 0px !important;
    background-image:linear-gradient(#ff7200, #ff7200);
    background-repeat: no-repeat;
    line-height: 110px;
    background-size:  0% 4px;
    background-position:  bottom;
   

}

.customers_div_1{
    width: 90%;
    height: 145px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 155px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_1 div{
    width: 24%;
    height: 145px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_1 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}

.customers_div_1 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}


.customers_div_2{
    width: 68% !important;
    height: 145px;
    
    position: absolute;
    top: 325px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_2 div{
    width: 35% !important;
    height: 145px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_2 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}

.customers_div_2 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}

.customers_div_3{
    width: 90%;
    height: 145px;
    
    position: absolute;
    top: 497px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_3 div{
    width: 24%;
    height: 145px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_3 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}
.customers_div_3 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}

.customers_div_4{
    width: 68% !important;
    height: 145px;
     /* border: 1px solid green;  */
    position: absolute;
    top: 669px;
    display: flex;
    justify-content: space-evenly;
    
}

.customers_div_4 div{
    width: 35% !important;
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_4 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}
.customers_div_4 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}
/* 500 */
.customers_div_5{
    width: 90% !important;
    height: 158px;
    position: absolute;
    top: 841px;
    display: flex;
    justify-content: space-evenly;
    
}

.customers_div_5 div{
    width: 24% !important;
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_5 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}
.customers_div_5 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}
#sch{
    width: 46%;
}

.customer_images{
    width: 140px;
}

#cma{
    width: 150px;
    height: 120px;
}

#bharat{
    width: 100px;
    height: 118px;
}

#aditya{
    width: 130px;
    height: 105px;
}

#lg{
    width: 100px;
    height: 60px;
}

#dadri{
    width: 120px;
    height: 90px;
}
#teas{
    width: 60%;
}
#more_3500{
    width: 100%;
    height: 66px;
    position: relative;
    top: 985px;
  
    display: flex;
    align-items: center;
    justify-content: center;
}

#more_3500 h1{
    position: relative;
    font-size: 30px;
    color: black;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background-image:linear-gradient(white,white);
    background-repeat: none;
    background-size:  0% 0px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    
}

}
@media only screen and (max-width:751px){
    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;  
     }
     .about_foooter{
        position: relative;
        top: 737px;
    }
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 910px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:700;
        color: #ff7200;;
        font-size: 42px;
        position: relative;
        top: 40px;
        font-weight: 900;
        letter-spacing: 0px ! important;
      
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: 400px; 
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    
.customer_div1{
    width: 100%;
    height: 850px;
    position: relative;
    top: 0px;
    display: flex;
    justify-content: center;
     
}



.customer_div1 h1{
     
    font-size: 38px;
    color: black;
    text-shadow: none;
    position: absolute;
    top: 0px;
    font-weight: 900;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 0px;
    background-image:linear-gradient(#ff7200, #ff7200);
    background-repeat: no-repeat;
    line-height: 110px;
    background-size:  0% 4px;
    background-position:  bottom;
    letter-spacing: 0px !important;
   

}

.customers_div_1{
    width: 94%;
    height: 140px;
   
    position: absolute;
    top: 115px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_1 div{
    width: 24%;
    height: 140px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_1 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}

.customers_div_1 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}


.customers_div_2{
    width: 68% !important;
    height: 140px;
    
    position: absolute;
    top: 290px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_2 div{
    width: 35% !important;
    height: 140px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_2 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}

.customers_div_2 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}

.customers_div_3{
    width: 94%;
    height: 170px;
    
    position: absolute;
    top: 463px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_3 div{
    width: 24%;
    height: 140px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_3 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}
.customers_div_3 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}
.customers_div_4{
    width: 68% ! important;
    height: 168px;
    position: absolute;
    top: 634px;
    display: flex;
    justify-content: space-around;
    
}
.customers_div_4 div{
    width: 35% !important;
    height: 140px; 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}
.customers_div_5{
    width: 94% ! important;
    height: 168px;
    position: absolute;
    top: 805px;
    display: flex;
    justify-content: space-around;
    
}
.customers_div_5 div{
    width: 24% !important;
    height: 140px; 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customer_images{
    width: 110px;
}

#cma{
    width: 120px;
    height: 100px;
}

#bharat{
    width: 90px;
    height: 108px;
}

#aditya{
    width: 90px;
    height: 83px;
}

#lg{
    width: 90px;
    height: 50px;
}

#dadri{
    width: 94px;
    height: 80px;
}

#more_3500{
    width: 100%;
    height: 66px;
    position: relative;
    top: 955px;
  
    display: flex;
    align-items: center;
    justify-content: center;
}

#more_3500 h1{
    position: relative;
    font-size: 28px;
    color: black;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background-image:linear-gradient(white,white);
    background-repeat: none;
    background-size:  0% 0px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    
}


}
@media only screen and (max-width:701px){
  
}
@media only screen and (max-width:621px){
    .main_company{
       
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;  
     }
     .about_foooter{
        position: relative;
        top: 645px;
    }
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 870px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:700;
        color: #ff7200;;
        font-size: 42px;
        position: relative;
        top: 40px;
        font-weight: 900;
        letter-spacing: 0px ! important;
      
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 100%;
       height: 84%;
       padding-left: 2px;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: 350px;
    } 
    
    #Family{
        width: 100%;
        height: 100%;     
    }
    


.customer_div1{
    width: 100%;
    height: 750px;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
     
}



.customer_div1 h1{
     
    font-size: 35px;
    color: black;
    text-shadow: none;
    position: absolute;
    top: 0px;
    font-weight: 900;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 0px;
    background-image:linear-gradient(#ff7200, #ff7200);
    background-repeat: no-repeat;
    line-height: 110px;
    background-size:  0% 4px;
    background-position:  bottom;
    letter-spacing: 0px !important;
   

}

.customers_div_1{
    width: 97%;
    height: 170px;
    position: absolute;
    top: 120px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_1 div{
    width: 26% !important;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_1 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}

.customers_div_1 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}


.customers_div_2{
    width: 73% !important;
    height: 170px;
    position: absolute;
    top: 263px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_2 div{
    width: 37% !important;
    height: 120px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_2 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}

.customers_div_2 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}

.customers_div_3{
    width: 97%;
    height: 120px;
    
    position: absolute;
    top: 407px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_3 div{
    width: 26%;
    height: 120px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_3 img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);;
}
.customers_div_3 img:hover{
    cursor: pointer;
    -webkit-filter: none;
            filter: none;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);;
}

.customers_div_4{
    width: 73% !important;
    height: 168px;
    position: absolute;
    top: 551px;
    display: flex;
    justify-content: space-around;
    
}
.customers_div_4 div{
    width: 37%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}
.customers_div_5{
    width: 97% !important;
    height: 168px;
    position: absolute;
    top: 697px;
    display: flex;
    justify-content: space-around;
    
}
.customers_div_5 div{
    width: 26%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}
#sch{
    width: 55% !important;
}
.customer_images{
    width: 95px;
}

#cma{
    width: 100px;
    height: 90px;
}

#bharat{
    width: 85px;
    height: 105px;
}

#aditya{
    width: 95px;
    height: 78px;
}

#lg{
    width: 80px;
    height: 40px;
}

#dadri{
    width: 90px;
    height: 80px;
}


#more_3500{
    width: 100%;
    height: 66px;
    position: relative;
    top: 820px;
  
    display: flex;
    align-items: center;
    justify-content: center;
}

#more_3500 h1{
    position: relative;
    font-size: 26px;
    color: black;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background-image:linear-gradient(white,white);
    background-repeat: none;
    background-size:  0% 0px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

}
@media only screen and (max-width:521px){

    .main_company{  
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;  
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 850px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    .about_foooter{
        position: relative;
        top: 587px;
    }
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 38px;
        position: relative;
        top: 40px;
        font-weight: 700;
        letter-spacing: 0px ! important;
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 100%;
       height: 84%;
       padding-left: 2px;
       padding-right: 3px;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 13px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 88%;
        margin-left: auto;
        margin-right: auto;
        height:310px;  
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    .customer_div1{
        width: 100%;
        height: 690px;
        position: absolute;
        top: 0px;
        display: flex;
        justify-content: center;  
    }
    
    .customer_div1 h1{   
        font-size: 30px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 80px;
        background-size:  0% 4px;
        background-position:  bottom;
        letter-spacing: 0px !important;
       
    
    }
    
    .customers_div_1{
        width: 98%;
        height: 150px;
       
        position: absolute;
        top: 100px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 23%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 74% !important;
        height: 140px;
        
        position: absolute;
        top: 235px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 38% !important;
        height: 110px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    .customers_div_3{
        width: 98%;
        height: 130px;
        
        position: absolute;
        top: 372px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_3 div{
        width: 23%;
        height: 110px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_3 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    .customers_div_3 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    .customers_div_4{
        width: 74% !important;
        height: 168px;
        position: absolute;
        top: 507px;
        display: flex;
        justify-content: space-around;
        
    }
    
    
    .customers_div_4 div{
        width: 38%;
        height: 110px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    .customers_div_5{
        width: 98% !important;
        height: 168px;
        position: absolute;
        top: 644px;
        display: flex;
        justify-content: space-around;
        
    }
    
    
    .customers_div_5 div{
        width: 23%;
        height: 110px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customer_images{
        width: 85px;
    }
    
    #cma{
        width: 80px;
        height: 75px;
    }
    
    #bharat{
        width: 70px;
        height: 80px;
    }
    
    #aditya{
        width: 80px;
        height: 65px;
    }
    
    #lg{
        width: 70px;
        height: 40px;
    }
    
    #dadri{
        width: 72px;
        height: 66px;
    }
    
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 757px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 23px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
    }

}
@media only screen and (max-width:425px){
    
    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px; 
     }
     .about_foooter{
        position: relative;
        top: 394px;
    }
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 710px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 36px;
        position: relative;
        top: 40px;
        letter-spacing: 0px ! important;
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 95%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 59px;
       position: relative;
       bottom: 0px;
       font-size: 11px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
       letter-spacing: 0px;
       line-height: 17px;
      
    }
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 260px;  
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    .customer_div1{
        width: 100%;
        height: 630px;
        position: absolute;
        top: 0px;
        display: flex;
        justify-content: center;
         
    }
    
    
    
    .customer_div1 h1{
         
        font-size: 26px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 70px;
        background-size:  0% 4px;
        background-position:  bottom;
        letter-spacing: 0px !important;
       
    
    }
    
    .customers_div_1{
        width: 99%;
        height: 120px;
        left: 1%px;
        position: absolute;
        top: 90px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 23%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 74% !important;
        height: 120px;
        /* left: 1%; */
        position: absolute;
        top: 212px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 36% !important;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    .customers_div_3{
        width: 99%;
        height: 130px;
        /* left: 1%; */
        position: absolute;
        top: 336px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_3 div{
        width: 23%;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_3 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    .customers_div_3 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    .customers_div_4{
        width: 74% !important;
        height: 128px;
        /* left: 1%; */
        position: absolute;
        top: 459px;
        display: flex;
        justify-content: space-around;
        
    }
    .customers_div_4 div{
        width: 36% !important;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    .customers_div_5{
        width: 99% !important;
        height: 128px;
        /* left: 1%; */
        position: absolute;
        top: 579px;
        display: flex;
        justify-content: space-around;
        
    }
    .customers_div_5 div{
        width: 23% !important;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    .customer_images{
        width: 72px;
    }
    
    #cma{
        width: 70px;
        height: 60px;
    }
    
    #bharat{
        width: 60px;
        height: 79px;
    }
    
    #aditya{
        width: 70px;
        height: 64px;
    }
    
    #lg{
        width: 68px;
        height: 40px;
    }
    
    #dadri{
        width: 60px;
        height: 60px;
    }
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 675px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 22px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
       
    }
  

}
@media only screen and (max-width:375px){
    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;
     }
     .about_foooter{
        position: relative;
        top: 417px;
    }
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 730px;
        position: relative; 
        top: -20px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
                clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 32px;
        position: relative;
        top: 40px;
        letter-spacing: 0px ! important;
      
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 95%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 57px;
       position: relative;
       bottom: 0px;
       font-size: 11px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
       letter-spacing: 0px;
      
    } 
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 265px;  
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    .customer_div1{
        width: 100%;
        height: 620px;
        
        position: absolute;
        top: 0px;
        display: flex;
        justify-content: center;
    }
    .customer_div1 h1{
         
        font-size: 26px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 70px;
        background-size:  0% 4px;
        background-position:  bottom;
        letter-spacing: 0px !important;
       
    
    }
    
    .customers_div_1{
        width: 99%;
        height: 120px;
        left: 2px;
        position: absolute;
        top: 92px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 23%;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 74% !important;
        height: 120px;
        position: absolute;
        margin-left:auto ;
        margin-right: auto;
        top: 215px;
        /* left: 0px; */
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 35% !important;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    .customers_div_3{
        width: 99%;
        height: 120px;
        left: 2px !important;
        position: absolute;
        top: 338px;
        left: 0px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_3 div{
        width: 23%;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_3 img{
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);;
    }
    .customers_div_3 img:hover{
        cursor: pointer;
        -webkit-filter: none;
                filter: none;
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);;
    }
    
    .customers_div_4{
        width: 74% !important;
        height: 168px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 458px;
        display: flex;
        justify-content: space-around;
        
    }
    
    
    .customers_div_4 div{
        width: 35% !important;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customer_images{
        width: 63px;
    }
    
    #cma{
        width: 70px;
        height: 60px;
    }
    
    #bharat{
        width: 60px;
        height: 80px;
    }
    
    #aditya{
        width: 67px;
        height: 57px;
    }
    
    #lg{
        width: 59px;
        height: 38px;
    }
    
    #dadri{
        width: 65px;
        height: 55px;
    }
    
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 659px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 18px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
        top: 13px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;  
    }
  

}


*{
    padding:0;
    margin: 0;
    box-sizing: border-box;
   
}
html {
    padding: 0px;
    margin: 0px;
    width: 100vw;
}

body {
    width: 100%;
}

.footer_main{
    position: relative;
    width: 100%;
    height: 400px;
   
   
}

.footer_part1{
    position: relative;
    bottom: 10px;
    width: 100%;
    height: 250px;
    background: #ff7200;
    -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
            clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
}


.footer_heading1 {
   
    position: relative;
    top: 70px;
    width: 28%;
    height: 120px;
    left: 12%;
  
}  

.footer_heading1 h1{ 
    color: #fff;
    font-size: 28px;
    float: left;
     font-family: 'Montserrat', sans-serif;
   
   font-weight: 700;
    
}

.footer_heading1 p{
    float: left;
    margin-top: 12px;
    color: #fff;
    text-shadow: 0px 0px 1px black;
    font-size: 14px;
   font-weight: 300;
     font-family: 'Montserrat', sans-serif;
}

.email_1{
    position: relative;
    display: flex;
   
    left: 59%;
    bottom: 20px;
    width: 400px;
    color: transparent;
    height: 45px;
    border: 1.5px solid #ff7200;
    border-radius: 9px;

}
.email_1 #text_field1{
    width: 303px;
    background: transparent;
    border: 0px solid;
    border-style: none;
    outline: none;
    

}
::-webkit-input-placeholder{
  
    font-size: 10px;
    padding-left: 20px;
     font-family: 'Montserrat', sans-serif;
}
:-ms-input-placeholder{
  
    font-size: 10px;
    padding-left: 20px;
     font-family: 'Montserrat', sans-serif;
}
::placeholder{
  
    font-size: 10px;
    padding-left: 20px;
     font-family: 'Montserrat', sans-serif;
}



.email_1 button{
    position: relative;
    left: 14%;
    margin-top: 1.5px;
    width: 35px;
    height: 37px;
    border-radius: 7px;
    background: #ff7200;
    border: none;
    cursor: pointer;
}

#arrow2{
    position: relative;
    left: 0%;
}

.footer_new_part{
    width: 100%;
    height: 324px;
  /*   background-color:#1c1c1c;  */
  background-color: black;
 position: relative;
 bottom: 10px;
  display: flex;
  
  justify-content: space-evenly;
  
  
    
    
}

.footer_new_part .footer_logo{
    width: 200px;  
    height: 40px;
    
    position: absolute;
    left: 5%;
    
    
}

    #logo_footer{
    width: 133px;
    
    height: 52px;
    position:absolute;
    left: 52%;
    top: 13px;
    
    
}

.data_footer1{
    /*  border: 1px solid yellow;  */
    width: 5%;
    height: 190px;
    position: absolute;
    top: 110px; 
    right: 83%;
   
  
}
               



.data_footer_1 p{
    font-size: 13px;
   
   font-weight: 300;
    color:white;
     font-family: 'Montserrat', sans-serif;
}

.data_footer_1 #address{
   
    font-size: 12px;
     font-family: 'Montserrat', sans-serif;
    
}

.location_all{
    width: 32px;
    height: 143px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    position: relative;
    
    
   
      
}

#location{
    width: 27px;
   margin-top: 2px;

  
}
.addressdiv{
    
   
    width: 49%;
    height: 180px;
    position: absolute;
    top: 115px;
    right: 35%;
    display: flex;
    flex-direction: column;
   
   
   
}


#addresss{
    color: #fff;
   
    text-decoration: none;
    font-size: 14px;
   font-weight: 300;
     font-family: 'Montserrat', sans-serif;

}

#addresss:hover{
    color: #ff7200;
    text-decoration: underline;
}
#addresss_1{
    color: #fff;
    position: absolute;
    top: 69px;
   font-weight: 300;
    font-size: 14px;
    text-decoration: none;
     font-family: 'Montserrat', sans-serif;
}

#addresss_1:hover{
    color: #ff7200;
    text-decoration: underline;
}

#addresss_2{
    color: #fff;
    position: absolute;
    top: 120px;
   font-weight: 300; 
    text-decoration: none;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;
}
#addresss_2:hover{
    color: #ff7200;
    text-decoration: underline;
}

   

.data_footer2{
   /*  border: 1px solid orange;  */
    width:29%;
    height: 190px;
    position: absolute;
    top: 59px;
    right: 32%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
   
   
   
}

.data_footer2 p{
   
    color:white;
   
    font-size: 13px;
    
   
    font-weight: bold;
     font-family: 'Montserrat', sans-serif;
}
.data_footer2 .data_footer_1{

    color: white;
    text-decoration: none;
    
  
    font-size: 14px;
   font-weight: 300;
     font-family: 'Montserrat', sans-serif;
}

.data_footer2 .data_footer_1:hover{
    color: #ff7200;
    text-decoration: underline;

}
.data_footer2 .data_footer_2{

    color: white;
    text-decoration: none;
   
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;

    
}

.data_footer2 .data_footer_2:hover{
    color: #ff7200;
    text-decoration: underline;

}
.data_footer2 .data_footer_3{

    color: white;
    text-decoration: none;
   
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;
}

.data_footer2 .data_footer_3:hover{
    color: #ff7200;
    text-decoration: underline;

}




.data_footer3{
    width: 20%;
    height: 230px;
   /*  border: 1px solid greenyellow; */
    position: absolute;
    top: 59px;
    right: 28%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
   
}

.data_footer3 p{ 
   
    color:white;
    font-size: 13px;
    font-weight: bold;
     font-family: 'Montserrat', sans-serif;
}

.data_footer3 .data_footer3_1{
    color: white;
    text-decoration: none;
  
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;
}

.data_footer3 .data_footer3_1:hover{
    color: #ff7200;
    text-decoration: underline;
}

.data_footer3 .data_footer3_2{
    color: white;
    text-decoration: none;
   
   font-weight: 300;
    left: -57px;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;
}
.data_footer3 .data_footer3_2:hover{
    color: #ff7200;
    text-decoration: underline;
}

.data_footer3 .data_footer3_3{
    color: white;
    text-decoration: none;
   
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;
}
.data_footer3 .data_footer3_3:hover{
    color: #ff7200;
    text-decoration: underline;
}

.data_footer3 .data_footer3_4{
    color: white;
    
   font-weight: 300;
    right: 56px;
    font-size: 14px;
    text-decoration: none;
     font-family: 'Montserrat', sans-serif;
}

.data_footer3 .data_footer3_4:hover{
    color: #ff7200;
    text-decoration: underline;
}

.data_footer4{
    width: 22%;
    height: 230px;
   /*  border: 1px solid cyan;  */

    position: absolute;
    top: 59px;
    right: 13%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  
   
}

.data_footer4 p{
  
    color:white;
    font-size: 13px;
    font-weight: bold;
     font-family: 'Montserrat', sans-serif;
    


}

.data_footer4 .data_footer4_one{
    color: white;
    text-decoration: none;
    
    font-size: 14px;
   font-weight: 300;
     font-family: 'Montserrat', sans-serif;
}


.data_footer4 .data_footer4_one:hover{
    color: #ff7200;
    text-decoration: underline;
}
.data_footer4 .data_footer4_two{
    color: white;
    text-decoration: none;
    
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;

}

.data_footer4 .data_footer4_two:hover{
    color: #ff7200;  
    text-decoration: underline;
}

.data_footer4 .data_footer4_three{
    color: white;
    text-decoration: none;
    
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;

}

.data_footer4 .data_footer4_three:hover{
    color: #ff7200;
    text-decoration: underline;
}



.data_footer5{
    width: 12%;
    height: 220px;
    /*  border: 1px solid cyan;  */
     position: absolute;
     top: 50px;
     right: 10%;
     display: flex;
     flex-direction: column;
     justify-content: space-evenly;
   
}
.social{
    width: 120px;
  
    
    display: flex;
    justify-content: space-around;
    position: relative;
  
    
}
.data_footer5 p{
    
    color:white;
    font-size: 13px;
    font-weight: bold;
     font-family: 'Montserrat', sans-serif;
}



.last_foot{
    background-color:#1a1a1a;
   /*  border: 1px solid black; */
    position: relative;
    top: -10px;
    
    width: 100%;
    height: 43px;    
    text-align: center;
}
.last_foot p{
    color: white;
 
    font-size: 12px;
    letter-spacing: 2px;
    position: relative;
    top: 13px;
     font-family: 'Montserrat', sans-serif;   
    
}

#root{
    height: 0px;
}
input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
margin: 0;
    }





@media screen and  (min-width: 1550px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
        
    }

    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
        
    }
    
    body {
        width: 100%;
    }
    .homeFooter{
        position: relative;
        top: 876px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 400px;            
    }

    #text_field1{
        padding-left: 10px;
    }

    
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 250px;
        background: #ff7200;
        -webkit-clip-path: polygon(46% 0%, 100% 25%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(46% 0%, 100% 25%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 26%;
        height: 120px;
        left: 6%;  
    } 
    
    .footer_heading1 a{
        cursor: pointer;
    }
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 32px;
        overflow: hidden;
            font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 14px;
        font-weight: 300;
            font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 410px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 393px; 
        height: 38px;
        background-color: transparent;
        position: absolute;
        top: 0px;
        border: 0px solid;
        border-style: none;
        outline: none;
        left: 0px;
    
    }
    #text_field1::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: 0px;
        padding-left: 0px !important;
        
        
    }
    #text_field1:-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: 0px;
        padding-left: 0px !important;
        
        
    }
    #text_field1::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: 0px;
        padding-left: 0px !important;
        
        
    }
    

    
    
    
    .email_1 button{
        position: absolute;
        left: 367px !important;
        top: 0px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
        color: black;
    }
    
    .footer_new_part{
        width: 100%;
        height: 320px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 12px;
        display: flex;       
        justify-content: space-evenly;
    }
    
    .footer_new_part .footer_logo{
        width: 20%;  
        height: 40px;
        position: absolute;
        left: 10%;
        
        
        
    }
    
        #logo_footer{
        width: 133px;
        
        height: 52px;
        position:absolute;
        left: 6%;
        top: 16px;
        
        
    }
    
    
    .data_footer1{
            /* border: 1px solid yellow;   */
        width: 5%;
        height: 190px;
        position: absolute;
        top: 95px; 
        right: 84%;
        
        
        
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 14px;
        
        font-weight: 300;
        color:white;
            font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
            font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 21%;
        height: 180px;
        /* border: 1px solid red; */
        position: absolute;
        top: 96px;
        right: 64.5%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 14px;
        font-weight: 300;
            font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
        font-weight: 300;
        font-size: 14px;
        text-decoration: none;
            font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
        font-weight: 300; 
        text-decoration: none;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        

    
    .data_footer3{
        width: 12%;
        height: 277px;
        /* border: 1px solid red;  */
        position: absolute;
        top: 27px;
        right: 48%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 15px;
        font-weight: bold;
            font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
        font-weight: 300;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
        font-weight: 300;
        left: -57px;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
        font-weight: 300;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
        font-weight: 300;
        right: 56px;
        font-size: 14px;
        text-decoration: none;
            font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 11%;
        height: 230px;
            /* border: 1px solid cyan;  */
        
        position: absolute;
        top: 31px;
        right: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
            font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 14px;
        font-weight: 300;
            font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
        font-weight: 300;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
        font-weight: 300;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 12%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 20px;
            right: 14%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
            font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -20px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        font-weight: 300;
        font-size: 11px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
            font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
/*  */

        .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 40%;
        height: 550px;
        z-index: 10 !important;
        
        
        
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 42px;
        font-weight: 600;
        padding-top: 2px;

    }
    

    /*  */

    .form_div form{
        width: 53%;
        /* border: 1px solid red; */
        position: absolute;
        top: 55%;
        left: 51%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        
    }
    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
    }
    
    .form_div input{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        line-height: 27px;
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 40%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        
        font-weight: bold;
        
    }
    .close_pop_up{
        width: 40%;
        margin-top: 13px;
        position: absolute;
        left: 188px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
        
        
        
        

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
    }

    ::placeholder{
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }

    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        height: 31px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;

    }

    
    
}
@media screen and  (max-width: 1550px){
    .homeFooter{
        position: relative;
        top: 724px;
    }
    
    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
        
    }

    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
        
    }
    
    body {
        width: 100%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 400px;
        /* top: 777px; */
          
    }

    #text_field1{
        padding-left: 10px;
    }

    
    
    .footer_part1{
        position: relative;
        bottom: -11px;
        width: 100%;
        height: 250px;
        background: #ff7200;
        -webkit-clip-path: polygon(46% 0%, 100% 25%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(46% 0%, 100% 25%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 26%;
        height: 120px;
        left: 6%;  
    } 
    
    .footer_heading1 a{
        cursor: pointer;
    }
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 32px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 410px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 393px; 
        height: 38px;
        background-color: transparent;
        position: absolute;
        top: 0px;
        left: 0px;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    #text_field1::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;       
    }
    #text_field1:-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;       
    }
    #text_field1::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;       
    }
    

    
    
    
    .email_1 button{
        position: absolute;
        left: 367px !important;
        top: 0px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
        color: black;
    }
    
    .footer_new_part{
        width: 100%;
        height: 320px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 12px;
        display: flex;       
        justify-content: space-evenly;
    }
    
    .footer_new_part .footer_logo{
        width: 20%;  
        height: 40px;
        position: absolute;
        left: 10%;
       
        
        
    }
    
        #logo_footer{
        width: 133px;
        
        height: 52px;
        position:absolute;
        left: 6%;
        top: 16px;
        
        
    }
    
    
    .data_footer1{
          /* border: 1px solid yellow;   */
        width: 5%;
        height: 190px;
        position: absolute;
        top: 95px; 
        right: 84%;
        
       
       
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 14px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 21%;
        height: 180px;
        /* border: 1px solid red; */
        position: absolute;
        top: 96px;
        right: 64.5%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 14px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        

    
    .data_footer3{
        width: 12%;
        height: 277px;
        /* border: 1px solid red;  */
        position: absolute;
        top: 27px;
        right: 48%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 14px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 11%;
        height: 230px;
         /* border: 1px solid cyan;  */
      
        position: absolute;
        top: 31px;
        right: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 12%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 20px;
            right: 14%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -20px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
       font-weight: 300;
        font-size: 11px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
/*  */

     .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 550px;
       z-index: 10 !important;
     
       
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 42px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 53%;
        /* border: 1px solid red; */
        position: absolute;
        top: 55%;
        left: 51%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
       
    }
    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
    }
    
    .form_div input{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        line-height: 27px;
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 40%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 40%;
        margin-top: 13px;
        position: absolute;
        left: 188px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
    }

    ::placeholder{
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }

    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        height: 31px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;

    }

   
    
}
@media screen and  (max-width: 1415px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
      
    }
    
    body {
        width: 100%;
      
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    #text_field1{
        padding-left: 10px;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;

    }

    :-ms-input-placeholder{
        padding-left: 0px;

    }

    ::placeholder{
        padding-left: 0px;

    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 410px;
    }
    
    .footer_part1{
        position: relative;
        bottom: -10px;
        width: 100%;
        height: 250px;
        background: #ff7200;
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 32%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 32px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: white !important;
        
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 350px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
        border: none !important;
    
    }
    .email_1 #text_field1{
        width: 343px;
        height: 37px;
       
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 0px !important;
        position: relative;
        right: 0px;
        top: 1px;
        font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 0px !important;
        position: relative;
        right: 0px;
        top: 1px;
        font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 0px !important;
        position: relative;
        right: 0px;
        top: 1px;
        font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        
        top: 1.5px;
        left: 310px !important;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
        color: black !important;
    }
    
    
    .footer_new_part{
        width: 100%;
        height: 310px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 11%;  
        height: 40px;
        position: absolute;
        left: 7.4%;
        top: 19px;
        /* border: 1px solid red; */
       
        
        
    }
    
        #logo_footer{
        width: 133px;
        
        height: 52px;
        position:absolute;
        left: 6%;
        top: 0px;
        
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 190px;
        position: absolute;
        top: 95px; 
        right: 87%;
     
       
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 22%;
        height: 180px;
        position: absolute;
        /* border: 2px solid red; */
        top: 96px;
        right: 66%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 14px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
     
    
    
    .data_footer3{
        width: 12%;
        height: 272px;
       /* border: 1px solid red; */
        position: absolute;
        top: 29px;
        right: 47%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 14px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 11%;
        height: 230px;
        /*  border: 1px solid cyan;  */
       
        position: absolute;
        top: 31px;
        right: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 12%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 20px;
            right: 13%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -12px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 11px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 550px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 42px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 62%;
        height: 470px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
       
        
        
        /* border: 1px solid red;  */
      
       
    }
    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
    }
    
    .form_div input{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        line-height: 27px;
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 40%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 40%;
        margin-top: 13px;
        position: absolute;
        left: 188px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
    }

    ::placeholder{
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        height: 31px;
        line-height: 22px;
        padding-left: 6px;
        color: grey;

    }
    
    
}
@media screen and  (max-width: 1201px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 410px;
    }
    .homeFooter{
        position: relative;
        top: 655px;
    }
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 250px;
        background: #ff7200;
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 38%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 30px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 13px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 280px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 273px;
        height: 39px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 239px !important;
        top: 1.5px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
    }
    
    
    .footer_new_part{
        width: 100%;
        height: 310px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 40px;
        
        position: absolute;
        left: 10%;
        
        
    }
    
        #logo_footer{
        width: 133px;
        
        height: 52px;
        position:absolute;
        left: 6%;
        top: 1px;
        
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 190px;
        position: absolute;
        top: 95px; 
        right: 84%;
        
       
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: -1px;
    
        
    }
    .addressdiv{
        
        
        width: 22%;
        height: 180px;
        position: absolute;
        /* border: 3px solid red; */
        top: 96px;
        right: 62%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 123px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    .data_footer3{
        width: 26%;
        height: 272px;
        
        position: absolute;
        top: 27px;
        right: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 13%;
        height: 230px;
        /*  border: 1px solid cyan;  */
        
        position: absolute;
        top: 31px;
        right: 26%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 13%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 19px;
            right: 11%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 11px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 50%;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;   */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 130%;
        line-height: 22px;
       
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 148px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
    }

    ::placeholder{
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 130%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;

    }
    
    
    
    
}
@media screen and  (max-width: 1051px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 400px;
    }
    .homeFooter{
        position: relative;
        top: 622px;
    }
    .footer_part1{
        position: relative;
        bottom: -8px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 38%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 25px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 13px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 250px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 243px;
        height: 37px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none; 
        font-size: 10px;
        
    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        position: relative;
        top: 4px !important;
         font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        position: relative;
        top: 4px !important;
         font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        position: relative;
        top: 4px !important;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 212px !important;
        top: 2px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 313px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 40px;
        
        position: absolute;
        left: 8%;
        
        
    }
    
        #logo_footer{
        width: 128px;
        
        height: 48px;
        position:absolute;
        left: 4%;
        top: 0px;
        
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 180px;
        position: absolute;
        top: 89px; 
        right: 86%;
      
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 24%;
        height: 180px;
        position: absolute;
        /* border: 2px solid red; */
        top: 94px;
        right: 62%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    .data_footer3{
        width: 26%;
        height: 2669x;
        
        position: absolute;
        top: 27px;
        right: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 13%;
        height: 230px;
        /*  border: 1px solid cyan;  */
        position: absolute;
        top: 31px;
        right: 27%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 16%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 19px;
            right: 8%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 10px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 143%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;
        font-size: 10px;

    }
    
    
    
}
@media screen and  (max-width: 1001px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 400px;
    }
    .homeFooter{
        position: relative;
        top: 570px;
    }
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 38%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 25px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 13px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 250px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 243px;
        height: 37px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none; 
        font-size: 10px;
        
    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 212px !important;
        top: 2px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 313px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 40px;
        
        position: absolute;
        left: 8%;
        
        
    }
    
        #logo_footer{
        width: 128px;
        
        height: 48px;
        position:absolute;
        left: 4%;
        top: 0px;
        
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 180px;
        position: absolute;
        top: 89px; 
        right: 86%;
      
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 24%;
        height: 180px;
        position: absolute;
        /* border: 2px solid red; */
        top: 94px;
        right: 62%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    .data_footer3{
        width: 26%;
        height: 2669x;
        
        position: absolute;
        top: 27px;
        right: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 13%;
        height: 230px;
        /*  border: 1px solid cyan;  */
        position: absolute;
        top: 31px;
        right: 27%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 16%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 19px;
            right: 8%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 10px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 143%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;
        font-size: 10px;

    }
    
    
    
}
@media screen and  (max-width: 951px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 400px;
    }
    .homeFooter{
        position: relative;
        top: 190px;
    }
      
    
    
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 38%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 25px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 13px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 250px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 243px;
        height: 37px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none; 
        font-size: 10px;
        
    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 212px !important;
        top: 2px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 313px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 40px;
        
        position: absolute;
        left: 8%;
        
        
    }
    
        #logo_footer{
        width: 128px;
        
        height: 48px;
        position:absolute;
        left: 4%;
        top: 0px;
        
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 180px;
        position: absolute;
        top: 89px; 
        right: 86%;
      
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 24%;
        height: 180px;
        position: absolute;
        /* border: 2px solid red; */
        top: 94px;
        right: 62%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    .data_footer3{
        width: 26%;
        height: 2669x;
        
        position: absolute;
        top: 27px;
        right: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 13%;
        height: 230px;
        /*  border: 1px solid cyan;  */
        position: absolute;
        top: 31px;
        right: 27%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 16%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 19px;
            right: 8%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 10px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 143%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;
        font-size: 10px;

    }
    
    
    
}
@media screen and  (max-width: 801px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 440px;
       
    }
    
    
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 42%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 20px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 55%;
        bottom: 0px;
        width: 220px;
        background-color: #fff;
        height: 38px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 213px;
        height: 36px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 182px !important;
        top: 1px;
        
        width: 35px;
        height: 32px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: -0.4px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 520px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
        
        position: absolute;
        left: 9%;
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 7%;
        top: 0px;
       
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 170px;
        position: absolute;
        top: 89px; 
        right: 84%;
      
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 34%;
        height: 180px;
        position: absolute;
        
        top: 95px;
        right: 49%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    
    
    .data_footer3{
        width: 26%;
        height: 265px;
        /*  border: 1px solid greenyellow; */
        position: absolute;
        top: 20px;
        right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 55%;
        height: 230px;
        /*  border: 1px solid cyan;  */
      
        position: absolute;
        top: 299px;
        right: 33%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 25%;
        height: 220px;
         
            position: absolute;
            top: 288px;
            right: 6%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 9px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 55%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }

    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 143%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;
        font-size: 10px;

    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    
    
    
}
@media screen and  (max-width: 701px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 440px;
    }
    .homeFooter{
        position: relative;
        top: -200px;
    }
    
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 42%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 20px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 55%;
        bottom: 0px;
        width: 220px;
        background-color: #fff;
        height: 38px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 213px;
        height: 36px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        position: absolute;
        left: 2px;    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        position: relative;
        left: 5px !important;
         font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        position: relative;
        left: 5px !important;
         font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        position: relative;
        left: 5px !important;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 182px !important;
        top: 1px;
        
        width: 35px;
        height: 32px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: -0.4px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 520px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
        
        position: absolute;
        left: 9%;
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 7%;
        top: 0px;
       
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 170px;
        position: absolute;
        top: 89px; 
        right: 84%;
      
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 34%;
        height: 180px;
        position: absolute;
        
        top: 95px;
        right: 49%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    
    
    .data_footer3{
        width: 26%;
        height: 265px;
        /*  border: 1px solid greenyellow; */
        position: absolute;
        top: 20px;
        right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 55%;
        height: 230px;
        /*  border: 1px solid cyan;  */
      
        position: absolute;
        top: 299px;
        right: 33%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 25%;
        height: 220px;
         
            position: absolute;
            top: 288px;
            right: 6%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 9px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 55%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }

    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 143%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;
        font-size: 10px;

    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    
    
    
}
@media screen and  (max-width: 622px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 410px;
    }
    .homeFooter{
        position: relative;
        top: 510px;
    }
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
        
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 41%;
        height: 120px;
        left: 6%;
        z-index: 10;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 17px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 11px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 52%;
        bottom: 0px;
        width: 200px;
        background-color: #fff;
        height: 36px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
        border: none;
    
    }
    .email_1 #text_field1{
        width: 193px;
        height: 33px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 162px !important;
        top: 2px;
        
        width: 35px;
        height: 30px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 1px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 540px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
        position: absolute;
        left: 5%;
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 7%;
        top: 0px;
       
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 170px;
        position: absolute;
        top: 89px; 
        right: 88%;
        
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 42%;
        height: 180px;
        position: absolute;
        
        top: 95px;
        right: 43%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 11px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 11px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    .data_footer3{
        width: 26%;
        height: 260px;
        /*  border: 1px solid greenyellow; */
        position: absolute;
        top: 30px;
        right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 11px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 55%;
        height: 230px;
        /*  border: 1px solid cyan;  */
    
        position: absolute;
        top: 310px;
        right: 37%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 11px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 23%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 297px;
            right: 7.9%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 9px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
    /*  */

    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 55%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    
    
    
    
}
@media screen and  (max-width: 521px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
        
       
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 610px;
    }
    .homeFooter{
        position: relative;
        top: 446px;
    }
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 196px;
        background: #ff7200;
        overflow: hidden;
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 70px;
        width: 43%;
        height: 110px;
        left: 6%;
        
       
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 19px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 11px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 54%;
        bottom: 0px;
        width: 170px;
        background-color: #fff;
        height: 31px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 163px;
        height: 26px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 138px !important;
        top: 1px;
        
        width: 29px;
        height: 25px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: -0.2px;
        
    }
    
    .footer_new_part{
        width: 100%;
        height: 540px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
      
        position: absolute;
        left: 4%;
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 6%;
        top: 0px;
       
        
    }
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 260px;
        position: absolute;
        top: 89px; 
        right: 88%;
        
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 22px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 42%;
        height: 180px;
        position: absolute;
        
        top: 95px;
        right: 42%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
           
    
    
    
    .data_footer3{
        width: 29%;
        height: 254px;
         /* border: 1px solid greenyellow;  */
        position: absolute;
        top: 32px;
        right: 4%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
       
       font-weight: 300;
        right: 56px;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 25%;
        height: 250px;
          /* border: 1px solid cyan;   */
    
        position: absolute;
        top: 304px;
        right: 67%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 29%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 292px;
            right: 4%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 8px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 65%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::-webkit-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    :-ms-input-placeholder{
        padding-left: 0px;
        font-size: 9px;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    
    
    
}
@media screen and  (max-width: 425px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 410px;
    }
    .homeFooter{
        position: relative;
        top: 359px;
    }
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 186px;
        background: #ff7200;
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 70px;
        width: 43%;
        height: 106px;
        left: 6%;
       
       
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 17px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 54%;
        bottom: 0px;
        width: 150px;
        background-color: #fff;
        height: 30px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
      
    
    }
    .email_1 #text_field1{
        width: 143px;
        height: 26px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 119px !important;
        top: 1.5px;
        
        width: 27px;
        height: 24px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 1px;
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
        
        position: absolute;
        
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 4%;
        top:  0px;
       
        
    }
   
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 170px;
        position: absolute;
        top: 80px; 
        right: 88%;
        
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 139px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 19px;
        margin-top: 1px;
    
        
    }
    .addressdiv{
        
        
        width: 47%;
        height: 180px;
        position: absolute;
        
        top: 85px;
        right: 38%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 66px;
       font-weight: 300;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    
    .data_footer3{
        width: 28%;
        height: 254px;
         /* border: 1px solid greenyellow;  */
        position: absolute;
        top: 26px;
        right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 25%;
        height: 240px;
        /*  border: 1px solid cyan;  */
       
        position: absolute;
        top: 309px;
        right:67%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 29%;
        height: 220px;
      /* border: 1px solid cyan;   */
        position: absolute;
        top: 298px;
        right: 6%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
       
        
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 9px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 70%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
    }

    ::-webkit-input-placeholder{
        font-size: 9px;
        padding-left: 0px;
    }

    :-ms-input-placeholder{
        font-size: 9px;
        padding-left: 0px;
    }

    ::placeholder{
        font-size: 9px;
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    
    
}
@media screen and  (max-width: 375px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 410px;
    }
    .homeFooter{
        position: relative;
        top: 348px;
    }
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 186px;
        background: #ff7200;
        -webkit-clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
                clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 70px;
        width: 43%;
        height: 100px;
       
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 17px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 54%;
        bottom: 0px;
        width: 150px;
        background-color: #fff;
        height: 30px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 143px;
        height: 25px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::-webkit-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    :-ms-input-placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 120px !important;
        top: 1.2px;
        
        width: 27px;
        height: 24px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 1px;
    }

    .footer_new_part{
        width: 100%;
        height: 546px;
      /*background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
      
      justify-content: space-evenly;
      
      
        
        
    }
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
      
        position: absolute;
        left: 8%;
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 4%;
        top: -3px;
       
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 170px;
        position: absolute;
        top: 80px; 
        right: 85%;
       
      
    }
                   
    
    
    
    .data_footer_1 p{
        font-size: 13px;
       
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
       
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      
        position: relative;
        
        
       
          
    }
    
    #location{
        width: 22px;
       margin-top: 2px;
    
      
    }
    .addressdiv{
        
       
        width: 42%;
        height: 180px;
        position: absolute;
        /* border: 1px solid red; */
        top: 84px;
        right: 38%;
        display: flex;
        flex-direction: column;
       
       
       
    }
    
    
    #addresss{
        color: #fff;
       
        text-decoration: none;
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 124px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
       
    
    
    
    
    .data_footer3{
        width: 26%;
        height: 254px;
       /*  border: 1px solid greenyellow; */
       position: absolute;
       top: 26px;
       right: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
       
    }
    
    .data_footer3 p{ 
       
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
      
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
       
       font-weight: 300;
        left: -57px;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
       
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 55%;
        height: 247px;
       /*  border: 1px solid cyan;  */
    
        position: absolute;
        top: 309px;
        right: 34%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      
       
    }
    
    .data_footer4 p{
      
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 29%;
        height: 220px;
        /*  border: 1px solid cyan;  */
         position: absolute;
         top: 298px;
         right:8%;
         display: flex;
         flex-direction: column;
         justify-content: space-evenly;
       
    }
    .social{
        width: 120px;
      
        
        display: flex;
        justify-content: space-around;
        position: relative;
      
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
       /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
     
        font-size: 9px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
      
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    
    
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: white;
        width: 80%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::-webkit-input-placeholder{
        color: silver;
    }
    :-ms-input-placeholder{
        color: silver;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
    }

    ::-webkit-input-placeholder{
        font-size: 9px;
        padding-left: 0px;
    }

    :-ms-input-placeholder{
        font-size: 9px;
        padding-left: 0px;
    }

    ::placeholder{
        font-size: 9px;
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    
    
}
    
    
    
    
    
    

    
    
    
    
        
  
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    







    


    
     



@media only screen and (min-width:1551px){
    .navbar_div_outer{
        width: 100%;
        height: 70px;
        display: flex; 
        position: fixed;
        z-index: 100;
       
       
    }
    .crosss{display: none;}
    .hide_crosss{display: none;}
    #burger{display: none;}
    #no_burger{display: none;}
    .slide_navbar a{display: none;}
    .hide_slide_nav{display: none;}
    
    .navbar_inner_1{
        width: 52%;
        height: 70px;
        position: absolute; 
        background-color: #000;
        opacity: 1;
    }
    
    .navbar_inner_1 img{
        opacity: 0.16;
    }
    
    #logo{
        position: absolute;
        top: 8px;
        left: 11%;
        width: 150px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    
    .navbar_inner_1 h2{
       position: absolute;
       top: 15px;
       left: 100px;
       font-size: 32px;
       color: #ff7200;
       cursor: pointer;
       text-shadow: 1px 1px 0px black;
    
    }
    
    #black_sign{
        color: black;
        cursor: pointer;
    }
    
    .navbar_inner_2{
        width: 50%;
        height: 100%;
       position: relative;
       background-color: #000;
       left: 50%;
       display: flex;
       flex-direction: row;      
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 40%;
        height: auto;
        position: absolute;
        left: 120px;
        justify-content: space-around;
        display: flex;
    
       
    
    }
    
    .inner_2_1 a{
        position: relative;
        top: 24px;
        font-weight: 700;
        text-decoration: none;
        font-size: 16px;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    .inner_2_1 a:hover{
        color: #ff7200;
    }
}
@media only screen and (max-width:1551px){
.navbar_div_outer{
    width: 100%;
    height: 70px;
    display: flex; 
    position: fixed;
    z-index: 100;
   
}
.crosss{display: none;}
.hide_crosss{display: none;}
#burger{display: none;}
#no_burger{display: none;}
.slide_navbar a{display: none;}
.hide_slide_nav{display: none;}

.navbar_inner_1{
    width: 52%;
    height: 70px;
    position: absolute; 
    background-color: #000;
    opacity: 1;
}

.navbar_inner_1 img{
    opacity: 0.16;
}

#logo{
    position: absolute;
    top: 8px;
    left: 11%;
    width: 150px;
    opacity: 1;
    z-index: 2;
    cursor: pointer;
}

.navbar_inner_1 h2{
   position: absolute;
   top: 15px;
   left: 100px;
   font-size: 32px;
   color: #ff7200;
   cursor: pointer;
   text-shadow: 1px 1px 0px black;

}

#black_sign{
    color: black;
    cursor: pointer;
}

.navbar_inner_2{
    width: 50%;
    height: 100%;
   position: relative;
   background-color: #000;
   left: 50%;
   display: flex;
   flex-direction: row; 
}

.navbar_inner_2 img{
    width: 100%;
}


.inner_2_1{
    width: 50%;
    height: auto;
    position: absolute;
    left: 120px;
    justify-content: space-around;
    display: flex;
}

.inner_2_1 a{
    position: relative;
    top: 24px;
    font-weight: 700;
    text-decoration: none;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.inner_2_1 a:hover{
    color: #ff7200;
}
}
@media only screen and (max-width:1101px){
    .navbar_div_outer{
        width: 100%;
        height: 70px;
        display: flex; 
        position: fixed;
        z-index: 100;  
    }
    .crosss{display: none;}
    .hide_crosss{display: none;}
    #burger{display: none;}
    #no_burger{display: none;}
    .slide_navbar a{display: none;}
    .hide_slide_nav{display: none;}
    
    .navbar_inner_1{
        width: 51%;
        height: 70px;
        position: absolute; 
        background-color:#000;
        /* background-color: red; */
    }
    
    .navbar_inner_1 img{
        opacity: 0.16;
    }
    
    #logo{
        position: absolute;
        top: 8px;
        left: 10%;
        width: 150px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    
    .navbar_inner_1 h2{
       position: absolute;
       top: 15px;
       left: 100px;
       font-size: 32px;
       color: #ff7200;
       cursor: pointer;
       text-shadow: 1px 1px 0px black;
    
    }
    
    #black_sign{
        color: black;
        cursor: pointer;
    }
    
    .navbar_inner_2{
        width: 50%;
        height: 100%;
       position: relative;
       background-color:#000;
       left: 50%;
       display: flex;
       flex-direction: row;
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 54%;
        height: auto;
        position: absolute;
        left: 120px;
        justify-content: space-around;
        display: flex;
       /* background-color: pink; */
       
    
    }
    
    .inner_2_1 a{
        position: relative;
        top: 24px;
        font-weight: 700;
        font-size: 13px;
        text-decoration: none;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    .inner_2_1 a:hover{
        color: #ff7200;
    }
}
@media only screen and (max-width:801px){
    .navbar_div_outer{
        width: 100%;
        height: 70px;
        display: flex; 
        position: fixed;
        z-index: 100;  
    }
    .crosss{display: none;}
    .hide_crosss{display: none;}
    #burger{display: none;}
    #no_burger{display: none;}
    .slide_navbar a{display: none;}
    .hide_slide_nav{display: none;}
    
    .navbar_inner_1{
        width: 52%;
        height: 70px;
        position: absolute; 
        background-color: #000;
        opacity: 1;
    }
    
    .navbar_inner_1 img{
        opacity: 0.16;
    }
    
    #logo{
        position: absolute;
        top: 8px;
        left: 10%;
        width: 150px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    
    .navbar_inner_1 h2{
        position: absolute;
        top: 15px;
        left: 100px;
        font-size: 32px;
        color: #ff7200;
        cursor: pointer;
        text-shadow: 1px 1px 0px black;
    
    }
    
    #black_sign{
        color: black;
        cursor: pointer;
    }
    
    .navbar_inner_2{
        width: 50%;
        height: 100%;
        position: relative;
        background-color: #000;
        left: 50%;
        display: flex;
        flex-direction: row;        
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 54%;
        height: auto;
        position: absolute;
        left: 120px;
        justify-content: space-around;
        display: flex;
        /* background-color: pink; */
        
    
    }
    
    .inner_2_1 a{
        position: relative;
        top: 24px;
        font-weight: 700;
        font-size: 12px;
        text-decoration: none;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    .inner_2_1 a:hover{
        color: #ff7200;
    }
}
@media only screen and (max-width:701px){
    #logo{
        position: absolute;
        top: 8px;
        left: 12%;
        width: 150px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    .navbar_inner_2{
        width: 50%;
        height: 100%;
       position: relative;
       background-color:#000;
       left: 50%;
       display: flex;
       flex-direction: row;
       -webkit-clip-path: polygon(2% 0, 100% 0, 100% 100%, 0% 100%);
               clip-path: polygon(2% 0, 100% 0, 100% 100%, 0% 100%);
       /* background-color: antiquewhite; */
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 20%;
        height: auto;
        position: absolute;
        left: 72%;
        justify-content: space-around;
        display: flex;
       
        /* background-color: aqua; */
    
       
    
    }
    .inner_2_1 a{
        display: none;
    }

    #burger{
        color: #ff7200;
        display: block;
        font-size: 23px;
        position: absolute;
        top: 20px;
        right: 0px;
    }

    #no_burger{display: none;}

    .crosss{
        color: #ff7200;
        display: block;
        font-size: 19px;
        position: absolute;
        top: 25px;
        right: 0px;
    }
    .hide_crosss{display: none;}
    .hide_slide_nav{display: none;}

    .slide_navbar{
        width: 20%;
        height: 87px;
        position: absolute;
        top:47px;
        left: 76.6%;
        display: flex;
        flex-direction: column;
        background-color: #000;
        text-align: center;
        justify-content: space-evenly;
        z-index: 10;
  
        
    }
    .slide_navbar a{
        color: white;
        letter-spacing: 0.3px;
        text-decoration: none;
        font-size: 11px;
        display: block;
    }
    .slide_navbar a:hover{
        color: #ff7200;
    }
}
@media only screen and (max-width:501px){
    #logo{
        position: absolute;
        top: 9px;
        left: 12%;
        width: 140px;
        height: 57px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    .navbar_inner_2{
        width: 50%;
        height: 100%;
       position: relative;
       background-color: #000;
       left: 50%;
       display: flex;
       flex-direction: row;
       -webkit-clip-path: polygon(2% 0, 100% 0, 100% 100%, 0% 100%);
               clip-path: polygon(2% 0, 100% 0, 100% 100%, 0% 100%);
       /* background-color: antiquewhite; */
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 20%;
        height: auto;
        position: absolute;
        left: 66%;
        justify-content: space-around;
        display: flex;      
    
    }
    .inner_2_1 a{
        display: none;
    }

    #burger{
        color: #ff7200;
        display: block;
        font-size: 23px;
        position: absolute;
        top: 20px;
        right: 0px;
    }

    #no_burger{display: none;}

    .crosss{
        color: #ff7200;
        display: block;
        font-size: 19px;
        position: absolute;
        top: 25px;
        right: 0px;
    }
    .hide_crosss{display: none;}
    .hide_slide_nav{display: none;}

    .slide_navbar{
        width: 23%;
        height: 87px;
        position: absolute;
        top:47px;
        left: 71%;
        display: flex;
        flex-direction: column;
        background-color: #000;
        text-align: center;
        justify-content: space-evenly;
        z-index: 10;
  
        
    }
  

    .slide_navbar a{
        color: white;
        letter-spacing: 0px;
        text-decoration: none;
        font-size: 11px;
        display: block;

    }
    .slide_navbar a:hover{
        color: #ff7200;
    }
}
@media only screen and (max-width:401px){
    #logo{
        position: absolute;
        top: 9px;
        left: 12%;
        width: 140px;
        height: 57px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    .navbar_inner_2{
        width: 50%;
        height: 100%;
       position: relative;
       background-color: #000;
       left: 50%;
       display: flex;
       flex-direction: row;
       -webkit-clip-path: polygon(2% 0, 100% 0, 100% 100%, 0% 100%);
               clip-path: polygon(2% 0, 100% 0, 100% 100%, 0% 100%);
       /* background-color: antiquewhite; */
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 20%;
        height: auto;
        position: absolute;
        left: 66%;
        justify-content: space-around;
        display: flex;
       
        /* background-color: aqua; */
    
       
    
    }
    .inner_2_1 a{
        display: none;
    }

    #burger{
        color: #ff7200;
        display: block;
        font-size: 23px;
        position: absolute;
        top: 20px;
        right: 0px;
    }

    #no_burger{display: none;}

    .crosss{
        color: #ff7200;
        display: block;
        font-size: 19px;
        position: absolute;
        top: 25px;
        right: 0px;
    }
    .hide_crosss{display: none;}
    .hide_slide_nav{display: none;}

    .slide_navbar{
        width: 27%;
        height: 87px;
        position: absolute;
        top:47px;
        left: 71%;
        display: flex;
        flex-direction: column;
        background-color: #000;
        text-align: center;
        justify-content: space-evenly;
        z-index: 10;
  
        
    }
  

    .slide_navbar a{
        color: white;
        letter-spacing: 0px;
        text-decoration: none;
        font-size: 11px;
        display: block;

    }
    .slide_navbar a:hover{
        color: #ff7200;
    }
}



#nprogress .bar {
    background: #ff7200 !important;
    position: relative;
    top: 70px !important;
    height: 7px !important;
}
a{
    /* text-decoration: none; */
    color: #ff7200;
}
#nprogress .peg {
    box-shadow: 0 0 0px #000, 0 0 0px #000 !important;
}

#nprogress .spinner-icon {
    position: relative;
    top: 70px !important;
    border-top-color: #ff7200 !important;
    border-left-color: #ff7200 !important;
}
.xxxl{
    color: #ff7200 !important;
        font-weight: 700 ! important;
}
.progress-bar{
    width: 100% !important;
    background-color: #ff7200;    
}

@media only screen and (min-width:1551px){
    .div70{
        width: 100%;
        height: 90px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        background-color: #E8E8E8;
    
    }
    .hideAndSeek{
        position: absolute;
        width: 100%;
        height: 10px;
        top: 71px;
    }
    .progressBar{
        position: absolute;
        width: 100%;
        left: 0px;
        max-height: 10px !important;
    
    }
    progress {
        border: none;
      }
      progress::-webkit-progress-value {
        background: #ff7200;
      }
      
      progress::-moz-progress-bar {
        background: #ff7200;
      }
      progress::-webkit-progress-bar {
        background: #D0D0D0;
      }
    .progressValueSpan{
        position: absolute;
        font-size: 0.65rem;
        font-weight: 700;
        left: 50%;
        right: 50%;
        top: -2px;
        z-index: 11;
    }
    .hide_plz{
        display: none;
    }
    .show_plz{
        display: block;
    }
    .boxElem{
        position: absolute;
        bottom: 135px;
        left: 5px;
    }
    .rnd{
        height: auto ! important ;
        display: flex;
        flex-direction: column;
        border:  2px dotted #ddd;
        text-align: start;
        word-wrap: break-word;
        background-color: transparent;
        padding-left: 4px;
    }
    
    .draggableText{
        font-size: 0.8rem;
        font-weight: 600;
        position: relative;
    padding: 0px;
    }
    .adjust{
        display: flex;
        align-items: center;
        position: relative;
        top: 50% !important;
    }
    #specified_text{
        font-size: 9px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .draggableTextNew{
        color: #ff7200;
        font-size: 0.65rem;
        display: block;
    }
    .signElem{
        height: 16px;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        /* justify-content: space-between !important; */
    }
    .draggableTextSign{
        width: auto !important;
        display: block !important;
        font-size: 0.7rem;
        font-weight: 600;
        cursor: pointer;
    }
    .resizeableImg{
        height: 20px;
    }
    
      
    #fetched_ltn{
        color: black;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -4px;
        padding-left: 2px;
    }
    
    
    #hide_specified{display: none;}

    .sign_div{
        width: 100%;
        height: auto;
        background-color: #E8E8E8;
    }
    .conditional_text{
        padding-left: 15px;
        color: #ff7200;
        position: relative;
        bottom: 2px;
        font-size: 14px;
    }
      
    .pdf_sign{
        width: 45%;
        height: auto !important;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(240, 239, 239);
        overflow: hidden;
        padding-bottom: 0px;    
    }
    .choosen_image{
        position: relative;
        width: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
        border-bottom: 1px solid #888888;
    }
    
    .choosen_image_1{
        position: relative;
        width: 100%;
        border-bottom: 1px solid grey;
        display: flex;
        margin-left: auto;
        margin-right: auto;
      
        /* transform: rotateX(180deg); */
    }
    
    .draggable{
        width: 100%;
        height: 100px;
       
    }
   
.hide_plz{
    display: none;
}
.show_plz{
    display: block;
}

#hide_specified{display: none;}


#notspecified{display: none;}

#last_sign{
    width: auto;
    height: 45px;
    /* border: 1px dotted silver; */
    position: relative;
    left: 23px;
    bottom: 4px;
   
}
.text{
    color: #ff7200;
    padding-left: 15px;
    position: relative;
    bottom: 0px;
}

.form_data h4{
    font-size: 22px;
    padding-top: 11px;
    padding-left: 12px;
    font-family: 'Encode Sans Semi Condensed', sans-serif;
}

    /*  */
  
    .Download{
        width: 100%;
        height: 100px;
        position: relative;
        display: flex;
        top: 0px;
        text-align: center;
        align-items:center ;
        justify-content: center;
        background-color: #E8E8E8;
    }
    .download_btn{
        width: 9%;
        height: 30px;
        cursor: pointer;
        border-radius: 6px;
        border: none;
        position: relative;
        justify-content: center;
        text-align: center;
        align-items: center;
        display: flex;
        background-color: #ff7200;
        color: #fff;
        margin-right: 15px;
        border: none;
    }
    
    .emailButton{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        display: flex;
        /* border: 1px solid #fff; */
        background-color: #ff7200;
        color: #fff;
    }
    .button_text{
        height:100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
    }
    .button_icon{
        width: auto  !important;
        padding-left: 7px;
        padding-right: 7px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .infoIcon{
        background-color: #ff7200;
        color: #fff;
    }
    .Download .titleBox{
        width:220px;
        height: auto;
        padding: 5px;
        background-color: #727376;
        position: absolute;
        top: 40px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: none;
    }
    .titleBox::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -7px;
        left: 8%;
        -webkit-transform: translate(-50%, 50%) rotate(45deg);
                transform: translate(-50%, 50%) rotate(45deg);
        background-color: #727376;
        overflow: hidden;
    }
    .Download>.emailButton .button_icon:hover>.titleBox{
        min-width:-webkit-fit-content;
        min-width:-moz-fit-content;
        min-width:fit-content;
        height: auto;
        padding: 5px;
        background-color: #727376;
        color: #666666;
        position: absolute;
        top: 35px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: block !important;
    }
    
    .titleBox span{
        color: #fff;
        font-size: 0.75rem;
    }
    
    
    
    .group_btns{
        width: 45%;
        height: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 11%;
        position: relative;
        display: flex;
        justify-content: space-between;
      
        text-align: center;
        align-items: center;
    }
    
    .save{
        width: 80px;
        height: 24px;
        background-color: green;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
       
    }
      
    .save:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
    #save_icon{
      font-size: 17px;  
      padding-top: 2px;
      color: #f1f1f1;
    }
    
    
    
    
    .show{
        width: 80px;
        height: 24px;
        background-color: rgb(78, 78, 233);
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
       
    }
      
    .show:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
        
    
    #eye{
        font-size: 17px;
        padding-top: 2px;
    }
    .clear{
        width: 80px;
        height: 24px;
       background-color: red;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
       
    }
      
    .clear:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
    
    #delete{
        font-size: 16px;
        padding-top: 3px;
        color: white;
    }
    .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 2;
       
    }
    .overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .model_content{
        position: absolute;
        width: 60%;
        height: 300px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        border: 4.5px solid #ff7200;
        z-index: 100;
        overflow: hidden;
    }
    .model_content.open{
        height: 550px !important;
        -webkit-animation: mymove;
                animation: mymove;
        -webkit-animation-duration: 0.5s !important;
                animation-duration: 0.5s !important;
        overflow: auto;
    }
    @-webkit-keyframes mymove {
        0%{
            height: 300px;
        }
        100%{
            height: 550px !important;
        }
    }
    @keyframes mymove {
        0%{
            height: 300px;
        }
        100%{
            height: 550px !important;
        }
    }
    .model_content.close{
        height: 300px;
        -webkit-animation: mymoveBack;
                animation: mymoveBack;
        -webkit-animation-duration: 0.5s !important;
                animation-duration: 0.5s !important;
    }
    @-webkit-keyframes mymoveBack {
        0%{
            height: 550px !important;
        }
        100%{
            height: 300px !important;
        }
    }
    @keyframes mymoveBack {
        0%{
            height: 550px !important;
        }
        100%{
            height: 300px !important;
        }
    }
    .sigCloseBtn{
        width: 30px;
        height: 29px;
       position: absolute;
       top: 0px;
       border-radius: 5px;
       right: 0px;
       background-color: #ff7200;
       border: 1px solid #000;
    }
    .colorPallateElem{
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        top: 80px;
        align-items: center;
        justify-content: center;
    }
    .pallate{
        background-color: #ff7200;
        width: 80px;
        height: 24px;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
    }
    .pallateIcon{
        color: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet);
    }
    .sigCloseBtn:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    }
    
    #sigClose{
    font-size: 1.2rem;
    }
    
    #sign_area{
        width: 62%;
        height: 200px;
        position: relative;
        top: 10%;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    #sign_area h3{
        position: relative;
        bottom: 3px;
    }
    .singnatureCanvas{
        border: 2px dashed silver;
        width: 100%;
        min-height: 150px;
    }
   
    /*  */
    .hide_text{
        display: none;
    }
    
    .show_hide_text{
        color: #ff7200;
        padding-left: 15px;
        position: relative;
        bottom: 13px;
    }
    
    .hide_textt{
        display: none;
    }
    .Load, .loader_overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
      
       
    }
    .loader_overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .loader_content{
        position: absolute;
        width: auto;
        height: 50px;
        top: 40%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        /* background-color: #fff; */
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;      
        text-align:center ;
    }
    .displayFlexMe {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .displayFlexMe>span{
        color: #ff7200 !important;
        font-weight: 700;
    }
    
    
    .loadingg{
        margin-left: auto;
        margin-right: auto;
    }
    .signFooter{
        position: relative;
        top: 0px;
        background-color: #E8E8E8;
    }
    
    
    } 
@media only screen and (max-width:1550px){
.div70{
    width: 100%;
    height: 90px;
    background-color: #E8E8E8;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.hideAndSeek{
    position: absolute;
    width: 100%;
    height: 10px;
    top: 71px;
    background-color: cyan;
}
.progressBar{
    position: absolute;
    width: 100%;
    left: 0px;
    max-height: 10px !important;

}
progress {
    border: none;
  }
  progress::-webkit-progress-value {
    background: #ff7200;
  }
  
  progress::-moz-progress-bar {
    background: #ff7200;
  }
  progress::-webkit-progress-bar {
    background: #D0D0D0;
  }
.progressValueSpan{
    position: absolute;
    font-size: 0.65rem;
    font-weight: 700;
    top: -2px;
    z-index: 11;
}

#tableToPrint{
    position: relative;
}
.myColor{
    color: #000;
}
.sign_div{
    width: 100%;
    height: auto;
    text-align: center;
    position: relative;
    top: 0px;
    background-color: #E8E8E8;
}
.blockMe{
    display: block !important;
}
#num{
    position: relative;
    top: 22px;
    color: black;
    font-weight: 600;
}

#slash{
    color: #ff7200;
    font-size: 23px;
    position: relative;
    top: 6px;
    
}

.pre{
    position: relative;
    top: 7px;
    cursor: pointer;   
    right: 7px;
    font-size: 23px;
}

.next{
    position: relative;
    top: 7px;
   left: 7px;
   font-size: 23px;
    cursor: pointer;
    color: red ;
   
}
.pdf_sign{
    width: 45%;
    height: auto !important;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: rgb(240, 239, 239);
    overflow: hidden;
    padding-bottom: 0px;
    /* background-color: red; */

}
.tableToPrint{
    /* overflow: hidden !important; */
}
.choosen_image{
    position: relative;
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    border-bottom: 1px solid #888888;
}

.choosen_image_1{
    position: relative;
    width: 100%;
    border-bottom: 1px solid grey;
    /* height: 1002px; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
  
    /* transform: rotateX(180deg); */
}
.hide_plz{
    display: none;
}
.show_plz{
    display: block;
}
.resize{
    min-width: 50px !important;
    height: 50px;
    /* background-color: red; */
    display: flex;
}
.boxElem{
    position: absolute;
    bottom: 135px;
    left: 5px;
}
.rnd{
    height: auto !important ;
    display: flex;
    flex-direction: column;
    border:  2px dotted #ddd;
    text-align: start;
    /* word-wrap: break-word; */
    background-color: transparent;
    padding-left: 3px;
    display: inline-block;
}
.adjust{
    display: flex;
    position: relative;
    top: 50% !important;
    align-items: center !important;
}
.draggableText{
    font-size: 0.8rem;
    font-weight: 600;
    position: relative;
    padding: 0px;
    justify-content: space-between ;
}
.inlineblock{
    display: inline-block;
}
#specified_text{
    font-size: 9px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding-left: 5px; 
}
.draggableTextNew{
    color: #ff7200;
    font-size: 0.65rem;
    display: block;
}
.signElem{
    height: 16px;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    /* justify-content: space-between !important; */
}
.draggableTextSign{
    width: auto !important;
    display: block !important;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
}
.resizeableImg{
    height: 20px;
}
  
#fetched_ltn{
    color: black;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: -4px;
    padding-left: 2px;
}
.hide_plz{
    display: none;
}
.show_plz{
    display: block;
}

#hide_specified{display: none;}
#specified{
    color: black;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: 0px;
    padding-left: 1px;
}

#notspecified{display: none;}
.text{
    color: #ff7200;
    padding-left: 15px;
    position: relative;
    bottom: 0px;
}

.form_data h4{
    font-size: 22px;
    padding-top: 11px;
    padding-left: 12px;
    font-family: 'Encode Sans Semi Condensed', sans-serif;
}
/*  */
.Download{
    width: 100%;
    height: 100px;
    position: relative;
    display: flex;
    top: 0px;
    text-align: center;
    align-items:center ;
    justify-content: center;
    background-color: #E8E8E8;
}
.download_btn{
    width: 10%;
    height: 30px;
    cursor: pointer;
    border-radius: 6px;
    border: none;
    position: relative;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    background-color: #ff7200;
    color: #fff;
  margin-right: 15px;
   
}

.emailButton{
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    display: flex;
   
    /* border: 1px solid #fff; */
    background-color: #ff7200;
    color: #fff;
}
.button_text{
   
    height:100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #fff;
}
.button_icon{
    width: auto  !important;
    padding-left: 5px;
    padding-right: 5px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.infoIcon{
    background-color: #ff7200;
    color: #fff;
}
.Download .titleBox{
    width:200px;
    height: auto;
    padding: 5px;
    background-color: #727376;
    position: absolute;
    top: 40px;
    left: 5px !important;
    border-radius: 4px;
    z-index: 9999999999;
    display: none;
}
.titleBox::after{
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: -7px;
    left: 8%;
    -webkit-transform: translate(-50%, 50%) rotate(45deg);
            transform: translate(-50%, 50%) rotate(45deg);
    background-color: #727376;
    overflow: hidden;
}
.Download>.emailButton .button_icon:hover>.titleBox{
    min-width:-webkit-fit-content;
    min-width:-moz-fit-content;
    min-width:fit-content;
    height: auto;
    padding: 5px;
    background-color: #727376;
    color: #666666;
    position: absolute;
    top: 35px;
    left: 0px;
    border-radius: 4px;
    z-index: 9999999999;
    display: block !important;
}

.titleBox span{
    color: #fff;
    font-size: 0.7rem;
}



.group_btns{
    width: 51%;
    height: 40px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 14%;
    position: relative;
    display: flex;
    justify-content: space-between;
  
    text-align: center;
    align-items: center;
}

.save{
    width: 80px;
    height: 24px;
    background-color: green;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.save:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   

}
#save_icon{
  font-size: 17px;  
  padding-top: 2px;
  color: #f1f1f1;
}




.show{
    width: 80px;
    height: 24px;
    background-color: rgb(78, 78, 233);
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.show:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   

}
    

#eye{
    font-size: 17px;
    padding-top: 2px;
    color: black;
}
.clear{
    width: 80px;
    height: 24px;
   background-color: red;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.clear:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   

}

#delete{
    font-size: 16px;
    padding-top: 3px;
    color: white;
}

.modal, .overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed; 
    z-index: 2; 
}
.overlay{
    background-color: rgba(49,49,49,0.8); 
    
}

.model_content{
    position: absolute;
    width: 45%;
    height: 300px;
    top: 52%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
    border: 3.5px solid #ff7200;
    z-index: 100;
    overflow: hidden;
}
.model_content.open{
    height: 550px;
    -webkit-animation: mymove;
            animation: mymove;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}
@-webkit-keyframes mymove {
    0%{
        height: 300px;
    }
    100%{
        height: 550px;
    }
}
@keyframes mymove {
    0%{
        height: 300px;
    }
    100%{
        height: 550px;
    }
}
.model_content.close{
    height: 300px;
    -webkit-animation: mymoveBack;
            animation: mymoveBack;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}
@-webkit-keyframes mymoveBack {
    0%{
        height: 550px;
    }
    100%{
        height: 300px;
    }
}
@keyframes mymoveBack {
    0%{
        height: 550px;
    }
    100%{
        height: 300px;
    }
}
.sigCloseBtn:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   
}

#sigClose{
font-size: 1.2rem;
}
.newbtn{
    position: absolute;
    cursor: pointer;
    top: -8px;
    width: 30px;
    border-radius: 8px !important;
    height: 28px;
    right: -16px;
   background-color: #ff7200 !important;
    color: white;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border: none;
}
  
.sigCloseBtn{
    width: 30px;
    height: 29px;
   position: absolute;
   top: 0px;
   border-radius: 5px;
   right: 0px;
   background-color: #ff7200;
   border: 1px solid #000 !important;
   border: none;
   overflow: visible;
}
  
.sigCloseBtn:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   
}

#sigClose{
font-size: 1.2rem;
}

#sign_area{
    width: 62%;
    height: 200px;
    position: relative;
    top: 10%;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
#sign_area h3{
    font-size: 20px;
    position: relative;
    bottom: 3px;
}

.singnatureCanvas{
    border: 2px dashed silver;
    width: 100%;
    min-height: 150px;
}
.hide_text{
    display: none;
}

.show_hide_text{
    color: #ff7200;
    padding-left: 15px;
    position: relative;
    bottom: 13px;
}

.hide_textt{
    display: none;
}



.Load, .loader_overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  
   
}
.loader_overlay{
    background-color: rgba(49,49,49,0.8);
}

.loader_content{
    position: absolute;
    width: auto;
    height: 50px;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* background-color: #fff; */
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
   text-align:center ;
}

.loadingg{
    margin-left: auto;
    margin-right: auto;
}
.pallate{
    background-color: #ff7200;
    width: 80px;
    height: 24px;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
}
.pallateIcon{
    color: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet);
}
.colorPallateElem{
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    top: 80px;
    align-items: center;
    justify-content: center;
}
.signFooter{
    position: relative;
    top: 0px;
    background-color: #E8E8E8;
}
}
@media only screen and (max-width:1450px){
    
.group_btns{
    width: 58%;
    height: 40px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 14%;
    position: relative;
    display: flex;
    justify-content: space-between;
  
    text-align: center;
    align-items: center;
}

.save{
    width: 75px;
    height: 24px;
    background-color: green;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.save:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   

}
#save_icon{
  font-size: 17px;  
  padding-top: 2px;
  color: #f1f1f1;
}




.show{
    width: 75px;
    height: 24px;
    background-color: rgb(78, 78, 233);
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.show:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   

}
    

#eye{
    font-size: 17px;
    padding-top: 2px;
    color: black;
}
.clear{
    width: 75px;
    height: 24px;
   background-color: red;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.clear:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   

}

#delete{
    font-size: 16px;
    padding-top: 3px;
    color: white;
}
.pallate{
    background-color: #ff7200;
    width: 75px;
    height: 24px;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
}

}
@media only screen and (max-width:1351px){
    
.rnd{
    /* min-height: 80px !important; */
    display: flex;
    flex-direction: column;
    border:  2px dotted #ddd;
    text-align: start;
    word-wrap: break-word;
    background-color: transparent;
    padding-left: 3px;
}

.draggableText{
    font-size: 0.75rem;
    font-weight: 600;
    position: relative;
    padding: 0px;
}
#specified_text{
    font-size: 9px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: -1px;
    padding-left: 5px; 
}
.draggableTextNew{
    color: #ff7200;
    font-size: 0.6rem;
    display: block;
}
.signElem{
    height: 16px;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    /* justify-content: space-between !important; */
}
.draggableTextSign{
    width: auto !important;
    display: block !important;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
}
.resizeableImg{
    height: 20px;
}
  
#fetched_ltn{
    color: black;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: -4px;
    padding-left: 2px;
}


.hide_plz{
    display: none;
}
.show_plz{
    display: block;
}

#hide_specified{display: none;}
#notspecified{display: none;}

.not_movable{
    width: 68%;
    height: 37px;
    cursor: pointer;
    display: flex;
    position: relative;
    top: -20px;
}

.group_btns{
    width: 52%;
    height: 40px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 14%;
    position: relative;
    display: flex;
    justify-content: space-between;
  
    text-align: center;
    align-items: center;
}

.save{
    width: 60px;
    height: 24px;
    background-color: green;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.save:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   

}
#save_icon{
  font-size: 17px;  
  padding-top: 2px;
  color: #f1f1f1;
}




.show{
    width: 60px;
    height: 24px;
    background-color: rgb(78, 78, 233);
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.show:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   

}
    

#eye{
    font-size: 17px;
    padding-top: 2px;
    color: black;
}
.clear{
    width: 60px;
    height: 24px;
   background-color: red;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.clear:active{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);   

}

#delete{
    font-size: 16px;
    padding-top: 3px;
    color: white;
}
.pallate{
    background-color: #ff7200;
    width: 60px;
    height: 24px;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
}

.model_content{
    position: absolute;
    width: 55%;
    height: 300px;
    top: 55%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
    border: 3.5px solid #ff7200;
    z-index: 100;
    overflow: hidden;
    -webkit-animation-duration: 0.5s !important;
            animation-duration: 0.5s !important;
}
.model_content.open{
    height: 540px !important;
    -webkit-animation: mymove;
            animation: mymove;
    overflow: auto;
    -webkit-animation-duration: 0.5s !important;
            animation-duration: 0.5s !important;
}
@-webkit-keyframes mymove {
    0%{
        height: 300px;
    }
    100%{
        height: 540px !important;
    }
}
@keyframes mymove {
    0%{
        height: 300px;
    }
    100%{
        height: 540px !important;
    }
}
.model_content.close{
    height: 300px;
    -webkit-animation: mymoveBack;
            animation: mymoveBack;
    -webkit-animation-duration: 0.5s !important;
            animation-duration: 0.5s !important;
}
@-webkit-keyframes mymoveBack {
    0%{
        height: 540px;
    }
    100%{
        height: 300px;
    }
}
@keyframes mymoveBack {
    0%{
        height: 540px;
    }
    100%{
        height: 300px;
    }
}
#sign_area{
    width: 78%;
    height: 200px;
    position: relative;
    top: 10%;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.colorPallateElem{
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    top: 75px;
    align-items: center;
    justify-content: center;
}


}
@media only screen and (max-width:1100px){
    .boxElem{
        position: absolute;
        bottom: 135px;
        left: 5px;
    }
    .rnd{
        /* min-height: 80px !important; */
        display: flex;
        flex-direction: column;
        border:  2px dotted #ddd;
        text-align: start;
        word-wrap: break-word;
        background-color: transparent;
        padding-left: 3px;
    }
    
    .draggableText{
        font-size: 0.65rem;
        font-weight: 600;
        position: relative;
        padding: 0px;
    }
    #specified_text{
        font-size: 9px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .draggableTextNew{
        color: #ff7200;
        font-size: 0.6rem;
        display: block;
    }
    .signElem{
        height: 15px;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        /* justify-content: space-between !important; */
    }
    .draggableTextSign{
        width: auto !important;
        display: block !important;
        padding: 0px;
        font-size: 0.62rem;
        font-weight: 600;
        cursor: pointer;
    }
    .resizeableImg{
        height: 20px;
    }
    #fetched_ltn{
        color: black;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -4px;
        padding-left: 2px;
    }
    

    .sign_div{
        width: 100%;
        height: auto;
        background-color: #E8E8E8;
         
    }
      
    .pdf_sign{
        width: 70%;
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(240, 239, 239);
        overflow: hidden;
        padding-bottom: 0px;
    
    }
    
    .choosen_image{
        position: relative;
        border-bottom: 1px solid #888888;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
    }
    
    .choosen_image_1{
        position: relative;
      border-bottom: 1px solid grey;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* transform: rotateX(180deg); */
    }
    
        
.hide_plz{
    display: none;
}
.show_plz{
    display: block;
}

#hide_specified{display: none;}

#notspecified{display: none;}

    /*  */
    .Download{
        width: 100%;
        height: 100px;
        position: relative;
        display: flex;
        top: 0px;
        text-align: center;
        align-items:center ;
        justify-content: center;
        background-color: #E8E8E8;
    }
    .download_btn{
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        height: 25px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        position: relative;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 0.7rem;
        display: flex;
        background-color: #ff7200;
        color: #fff;
      margin-right: 15px;
       
    }
    
    .emailButton{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        height: 25px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        display: flex;
       
        /* border: 1px solid #fff; */
        background-color: #ff7200;
        color: #fff;
    }
    .button_text{
        height:100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
        font-size: 0.7rem;
    }
    .button_icon{
        width: auto  !important;
        padding-left: 4px;
        padding-right: 4px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .infoIcon{
        background-color: #ff7200;
        color: #fff;
    }
    .Download .titleBox{
        width:200px;
        height: auto;
        padding: 5px;
        background-color: #727376;
        position: absolute;
        top: 40px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: none;
    }
    .titleBox::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -7px;
        left: 8%;
        -webkit-transform: translate(-50%, 50%) rotate(45deg);
                transform: translate(-50%, 50%) rotate(45deg);
        background-color: #727376;
        overflow: hidden;
    }
    .Download>.emailButton .button_icon:hover>.titleBox{
        min-width:-webkit-fit-content;
        min-width:-moz-fit-content;
        min-width:fit-content;
        height: auto;
        padding: 5px;
        background-color: #727376;
        color: #666666;
        position: absolute;
        top: 30px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: block !important;
    }
    
    .titleBox span{
        color: #fff;
        font-size: 0.65rem;
    }
    
    .group_btns{
        width: 55%;
        height: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 13%;
        position: relative;
        display: flex;
        justify-content: space-between;
       /* background-color: #ff7200; */
        text-align: center;
        align-items: center;
    }
    
    .save{
        width: 62px;
        height: 24px;
        background-color: green;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
       
    }
      
    .save:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
    #save_icon{
      font-size: 17px;  
      padding-top: 2px;
      color: #f1f1f1;
    }
    .show{
        width: 62px;
        height: 24px;
        background-color: rgb(78, 78, 233);
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40); 
    }
      
    .show:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    }
        
    #eye{
        font-size: 17px;
        padding-top: 2px;
    }
    .clear{
        width: 62px;
        height: 24px;
       background-color: red;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
       
    }
      
    .clear:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
    
    #delete{
        font-size: 16px;
        padding-top: 3px;
        color: white;
    }
    .pallate{
        background-color: #ff7200;
        width: 62px;
        height: 24px;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
    }
    .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 2;
    }
    .overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .model_content{
        position: absolute;
        width: 68%;
        height: 300px;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        border: 3.5px solid #ff7200;
        z-index: 100;
    }
    .sigCloseBtn{
        width: 30px;
        height: 29px;
       position: absolute;
       top: 0px;
       border-radius: 5px;
       right: 0px;
       background-color: #ff7200;
       border: none;
    }
    .model_content.open{
        height: 490px;
        -webkit-animation: mymove;
                animation: mymove;
        -webkit-animation-duration: 0.5s !important;
                animation-duration: 0.5s !important;
        overflow: auto;
    }
    @-webkit-keyframes mymove {
        0%{
            height: 300px;
        }
        100%{
            height: 490px;
        }
    }
    @keyframes mymove {
        0%{
            height: 300px;
        }
        100%{
            height: 490px;
        }
    }
    .model_content.close{
        height: 300px;
        -webkit-animation: mymoveBack;
                animation: mymoveBack;
        -webkit-animation-duration: 0.5s !important;
                animation-duration: 0.5s !important;
    }
    @-webkit-keyframes mymoveBack {
        0%{
            height: 490px;
        }
        100%{
            height: 300px;
        }
    }
    @keyframes mymoveBack {
        0%{
            height: 490px;
        }
        100%{
            height: 300px;
        }
    }
      
    .sigCloseBtn:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    }
    
    #sigClose{
    font-size: 1.2rem;
    }
    
    #sign_area{
        width: 78% !important;
        height: 210px;
        position: relative;
        top: 8%;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
    }
    .singnatureCanvas{
        border: 2px dashed silver;
        width: 100%;
        min-height: 150px;
    }
    /*  */
    .hide_text{
        display: none;
    }
    
    .show_hide_text{
        color: #ff7200;
        padding-left: 15px;
        position: relative;
        bottom: 13px;
    }
    
    .hide_textt{
        display: none;
    }
    .colorPallateElem{
        width: 100%;
        height: 150px;
        display: flex;
        position: relative;
        top: 98px;
        align-items: center;
        justify-content: center;
    }
    .tray{
        height: 180px !important;
    }
    }
@media only screen and (max-width:800px){
    .boxElem{
        position: absolute;
        bottom: 135px;
        left: 5px;
    }
    .rnd{
        /* min-height: 80px !important; */
        display: flex;
        flex-direction: column;
        border:  2px dotted #ddd;
        text-align: start;
        word-wrap: break-word;
        background-color: transparent;
        padding-left: 1px;
    }
    
    .draggableText{
        font-size: 0.62rem;
        font-weight: 600;
        position: relative;
        padding: 0px;
    }
    #specified_text{
        font-size: 9px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .draggableTextNew{
        color: #ff7200;
        font-size: 0.6rem;
        display: block;
    }
    .signElem{
        height: 15px;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        /* justify-content: space-between !important; */
    }
    .draggableTextSign{
        width: auto !important;
        display: block !important;
        font-size: 0.64rem;
        font-weight: 600;
        cursor: pointer;
    }
    .resizeableImg{
        height: 20px;
    }
    #fetched_ltn{
        color: black;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -4px;
        padding-left: 2px;
    }

    .sign_div{
        width: 100%;
        height: auto;
        background-color: #E8E8E8;
    }
        
    .pdf_sign{
        width: 90%;
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(240, 239, 239);
        overflow: hidden;
    
    }
    
    .choosen_image{
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
        border-bottom: 1px solid #888888;
    }
    
    .choosen_image_1{
        position: relative;
        border-bottom: 1px solid grey;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* transform: rotateX(180deg); */
    }
    
    .form_data{
        width:auto;
        height: 120px;
        border: 2px dashed silver;
        cursor: grab;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        bottom: 7px;
        left: 23px;
        text-align: left;
    }
    
    #namee{
        color: black;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 14px;
        padding-left: 8px;
        font-family: 'Montserrat', sans-serif;
    }
    
    
    #emaill{
        color: black;
        font-weight: 600;
        font-size: 12px;
        padding-left: 8px;
        position: relative;
        top: 8px;
        font-family: 'Montserrat', sans-serif;
    }
    
    #placee{
        color: black;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        padding-left: 0px;
        top: -6px;
        left: -3px;
    }
    
    
    #fetched_ltn{
        color: black;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -6px;
        left: -6px;
    }
    
    #specified_text{
        font-size: 9px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .hide_plz{
        display: none;
    }
    .show_plz{
        display: block;
    }
    
    #hide_specified{display: none;}
    
    #placee_colored{
        color: black;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: 6px;
        padding-left: 1px;
    }
    #specified{
        color: black;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: 0px;
        padding-left: 1px;
    }
    
    #notspecified{display: none;}
    
    #timee{
        color: black;
        font-size: 11px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -22px;
        left: -3px;
        padding-left: 0px;
    }
    
    .not_movable{
        width: 60%;
        height: 27px;
        cursor: pointer;
        display: flex;
        position: relative;
        top: -27px;
        align-items: center;
        text-align: center;
    }
    #sign_{
        color: #000;
        font-size: 11px;
        padding-left: 10px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
      }
    
      #sign_done{
        color: red;
        font-size: 15px;
        text-decoration: underline;
        padding-left: 15px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        -webkit-animation: blinker 3s linear infinite;
                animation: blinker 3s linear infinite;
      }
      .conditional_text{
        padding-left: 10px;
        color: #ff7200;
        position: relative;
        bottom: 23px;
        font-size: 8.5px;
    }
    #last_sign {
        width: 40%;
        height: 27px;
        position: relative;
        left: 23px;
        bottom: -5px;
    }
    
    /*  */
    .Download{
        width: 100%;
        height: 100px;
        position: relative;
        display: flex;
        top: 0px;
        text-align: center;
        align-items:center ;
        justify-content: center;
        background-color: #E8E8E8;
    }
    .download_btn{
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        height: 25px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        position: relative;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 0.7rem;
        display: flex;
        background-color: #ff7200;
        color: #fff;
      margin-right: 15px;
       
    }
    
    .emailButton{
        width: auto !important;
        height: 25px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        display: flex;
        background-color: #ff7200;
        color: #fff;
    }
    .button_text{
        height:100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
        font-size: 0.7rem;
    }
    .button_icon{
        width: auto  !important;
        padding-left: 2px;
        padding-right: 2px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .infoIcon{
        background-color: #ff7200;
        color: #fff;
    }
    .Download .titleBox{
        width:140px;
        height: auto;
        padding: 5px;
        background-color: #727376;
        position: absolute;
        top: 30px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: none;
    }
    .titleBox::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -7px;
        left: 12%;
        -webkit-transform: translate(-50%, 50%) rotate(45deg);
                transform: translate(-50%, 50%) rotate(45deg);
        background-color: #727376;
        overflow: hidden;
    }
    .Download>.emailButton .button_icon:hover>.titleBox{
        min-width:-webkit-fit-content;
        min-width:-moz-fit-content;
        min-width:fit-content;
        height: auto;
        padding: 5px;
        background-color: #727376;
        color: #666666;
        position: absolute;
        top: 30px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: block !important;
    }
    
    .titleBox span{
        color: #fff;
        font-size: 0.65rem;
    }
    .group_btns{
        width: 68%;
        height: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 13%;
        position: relative;
        display: flex;
        justify-content: space-between;
        /* background-color: #ff7200; */
        text-align: center;
        align-items: center;
    }
    
    .save{
        width: 70px;
        height: 24px;
        background-color: green;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .save:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
    #save_icon{
        font-size: 17px;  
        padding-top: 2px;
        color: #f1f1f1;
    }
    
    
    
    
    .show{
        width: 70px;
        height: 24px;
        background-color: rgb(78, 78, 233);
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .show:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
        
    
    #eye{
        font-size: 17px;
        padding-top: 2px;
    }
    .clear{
        width: 70px;
        height: 24px;
        background-color: red;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .clear:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
    
    #delete{
        font-size: 16px;
        padding-top: 3px;
        color: white;
    }
    .pallate{
        background-color: #ff7200;
        width: 70px;
        height: 24px;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
    }
    .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 2;
       
    }
    .overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .model_content{
        position: absolute;
        width: 80%;
        height: 300px;
        top: 52%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        border: 3.5px solid #ff7200;
        z-index: 100;
    }
    .sigCloseBtn{
        width: 30px;
        height: 29px;
       position: absolute;
       top: 0px;
       border-radius: 5px;
       right: 0px;
       background-color: #ff7200;
       border: none;
    }
    .model_content.open{
        height: 495px !important;
        -webkit-animation: mymove;
                animation: mymove;
        -webkit-animation-duration: 0.5s  !important;
                animation-duration: 0.5s  !important;
    }
    @-webkit-keyframes mymove {
        0%{
            height: 300px;
        }
        100%{
            height: 475px !important;
        }
    }
    @keyframes mymove {
        0%{
            height: 300px;
        }
        100%{
            height: 475px !important;
        }
    }
    .model_content.close{
        height: 300px;
        -webkit-animation: mymoveBack ease-in;
                animation: mymoveBack ease-in;
        -webkit-animation-duration: 0.5s !important;
                animation-duration: 0.5s !important;
    }
    @-webkit-keyframes mymoveBack {
        0%{
            height: 475px;
        }
        100%{
            height: 300px;
        }
    }
    @keyframes mymoveBack {
        0%{
            height: 475px;
        }
        100%{
            height: 300px;
        }
    }
      
    .sigCloseBtn:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    }
    
    #sigClose{
    font-size: 1.2rem;
    }
    
    #sign_area{
        width: 78% !important;
        height: 210px;
        position: relative;
        top: 10%;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
    }
    .singnatureCanvas{
        border: 2px dashed silver;
        width: 100%;
        min-height: 150px;
    }
    /*  */
    .hide_text{
        display: none;
    }
    
    .show_hide_text{
        color: #ff7200;
        padding-left: 15px;
        position: relative;
        bottom: 13px;
    }
    
    .hide_textt{
        display: none;
    }
    .colorPallateElem{
        width: 100%;
        height: 150px;
        display: flex;
        position: relative;
        top: 75px;
        align-items: center;
        justify-content: center;
    }
    .tray{
        height: 150px !important;
    }
 
    }
@media only screen and (max-width:600px){
    .boxElem{
        position: absolute;
        bottom: 135px;
        left: 5px;
    }
    .rnd{
        /* min-height: 80px !important; */
        display: flex;
        flex-direction: column;
        border:  2px dotted #ddd;
        text-align: start;
        word-wrap: break-word;
        background-color: transparent;
        padding-left: 1px;
    }
    
    .draggableText{
        font-size: 0.62rem;
        font-weight: 600;
        position: relative;
        padding: 0px;
    }
    #specified_text{
        font-size: 8px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .draggableTextNew{
        color: #ff7200;
        font-size: 0.6rem;
        display: block;
    }
    .signElem{
        height: 14px;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        /* justify-content: space-between !important; */
    }
    .draggableTextSign{
        width: auto !important;
        display: block !important;
        font-size: 0.6rem;
        font-weight: 600;
        cursor: pointer;
    }
    .resizeableImg{
        height: 20px;
    }
      
    #fetched_ltn{
        color: black;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -4px;
        padding-left: 2px;
    }


    .sign_div{
        width: 100%;
        height: auto;
        background-color: #E8E8E8; 
    }
        
    .pdf_sign{
        width: 90%;
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(240, 239, 239);
        overflow: hidden;
    }
    
    .choosen_image{
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
        border-bottom: 1px solid #888888;
    }
    
    .choosen_image_1{
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-bottom: 1px solid grey;
    }
    
    
       .hide_plz{
        display: none;
    }
    .show_plz{
        display: block;
    }
    
    #hide_specified{display: none;}
    
    #notspecified{display: none;}
    #last_sign {
        width: 40%;
        height: 27px;
        position: relative;
        left: 23px;
        bottom: -5px;
    }
    /*  */
    .Download{
        width: 100%;
        height: 100px;
        position: relative;
        display: flex;
        top: 0px;
        text-align: center;
        align-items:center ;
        justify-content: center;
        background-color: #E8E8E8;
    }
    .download_btn{
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        position: relative;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 0.65rem;
        display: flex;
        background-color: #ff7200;
        color: #fff;
      margin-right: 10px;
       
    }
    
    .emailButton{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        display: flex;
        /* border: 1px solid #fff; */
        background-color: #ff7200;
        color: #fff;
    }
    .button_text{
        height:100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
        font-size: 0.65rem;
    }
    .button_icon{
        padding-left: 2px;
        padding-right: 2px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .infoIcon{
        background-color: #ff7200;
        color: #fff;
    }
    .Download .titleBox{
        width:140px;
        height: auto;
        padding: 5px;
        background-color: #727376;
        position: absolute;
        top: 30px;
        left: -50px !important;
        border-radius: 4px;
        z-index: 9999999999;
        display: none;
    }
    .titleBox::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -7px;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        -webkit-transform: translate(-50%, 50%) rotate(45deg);
                transform: translate(-50%, 50%) rotate(45deg);
        background-color: #727376;
        overflow: hidden;
    }
    .Download>.emailButton .button_icon:hover>.titleBox{
        min-width:-webkit-fit-content;
        min-width:-moz-fit-content;
        min-width:fit-content;
        height: auto;
        padding: 5px;
        background-color: #727376;
        color: #666666;
        position: absolute;
        top: 30px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: block !important;
    }
    
    .titleBox span{
        color: #fff;
        font-size: 0.65rem;
    }
    .group_btns{
        width: 75%;
        height: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 15%;
        position: relative;
        display: flex;
        justify-content: space-between;
        /* background-color: #ff7200; */
        text-align: center;
        align-items: center;
    }
    
    .save{
        width: 52px;
        height: 20px;
        background-color: green;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .save:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
    #save_icon{
        font-size: 15px;  
        padding-top: 0px;
        color: #f1f1f1;
    }
    
    
    
    
    .show{
        width: 52px;
        height: 20px;
        background-color: rgb(78, 78, 233);
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
 
    .show:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
        
    
    #eye{
        font-size: 15px;
        padding-top: 0px;
    }
    .clear{
        width: 52px;
        height: 20px;
        background-color: red;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .clear:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
    .pallate{
        background-color: #ff7200;
        width: 52px;
        height: 20px;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
    }
    
    #delete{
        font-size: 13px;
        position: relative;
        bottom: 2px;
        padding-top: px !important;
        color: white;
    }
    .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 2;
        
    }
    .overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .model_content{
        position: absolute;
        width: 87%;
        height: 275px !important ;
        top: 54%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        border: 3px solid #ff7200;
        z-index: 100;
    }
    .model_content.open{
        height: 475px !important;
        -webkit-animation: mymove;
                animation: mymove;
        -webkit-animation-duration: 0.5s !important;
                animation-duration: 0.5s !important;
        
    }
    @-webkit-keyframes mymove {
        0%{
            height: 275px !important;
        }
        100%{
            height: 475px !important;
        }
    }
    @keyframes mymove {
        0%{
            height: 275px !important;
        }
        100%{
            height: 475px !important;
        }
    }
    .model_content.close{
        height: 300px;
        -webkit-animation: mymoveBack ease-in;
                animation: mymoveBack ease-in;
        -webkit-animation-duration: 0.5s !important;
                animation-duration: 0.5s !important;
    }
    @-webkit-keyframes mymoveBack {
        0%{
            height: 475px !important;
        }
        100%{
            height: 275px !important;
        }
    }
    @keyframes mymoveBack {
        0%{
            height: 475px !important;
        }
        100%{
            height: 275px !important;
        }
    }
    .sigCloseBtn{
        width: 30px;
        height: 29px;
       position: absolute;
       top: 0px;
       border-radius: 5px;
       right: 0px;
       background-color: #ff7200;
       border: none;
    }
      
    .sigCloseBtn:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    }
    
    #sigClose{
    font-size: 1.2rem;
    }
    
    #sign_area{
        width: 78%;
        height: 189px;
        position: relative;
        top: 10%;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
    }
    .singnatureCanvas{
        border: 2px dashed silver;
        width: 100%;
        min-height: 120px;
    }
    /*  */
    .hide_text{
        display: none;
    }
    
    .show_hide_text{
        color: #ff7200;
        padding-left: 15px;
        position: relative;
        bottom: 13px;
    }
    
    .hide_textt{
        display: none;
    }
    .colorPallateElem{
        width: 100%;
        height: 150px;
        display: flex;
        position: relative;
        top: 79px;
        align-items: center;
        justify-content: center;
    }
    .tray{
        height: 150px !important;
    }
   
    }  
@media only screen and (max-width:400px){
    .boxElem{
        position: absolute;
        bottom: 135px;
        left: 5px;
    }
    .rnd{
        /* min-height: 89px !important; */
        display: flex;
        flex-direction: column;
        border:  2px dotted #ddd;
        text-align: start;
        word-wrap: break-word;
        background-color: transparent;
        padding-left: 1px;
    }
    
    .draggableText{
        font-size: 0.6rem;
        font-weight: 600;
        position: relative;
        padding: 0px;
    }
    #specified_text{
        font-size: 7px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .draggableTextNew{
        color: #ff7200;
        font-size: 0.55rem;
        display: block;
    }
    .signElem{
        height: 14px;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        /* justify-content: space-between !important; */
    }
    .draggableTextSign{
        width: auto !important;
        display: block !important;
        font-size: 0.58rem;
        font-weight: 600;
        cursor: pointer;
    }
    .resizeableImg{
        height: 20px;
    }
      
    #fetched_ltn{
        color: black;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -4px;
        padding-left: 2px;
    }

    .sign_div{
        width: 100%;
        height: auto;
        background-color: #E8E8E8; 
    }
        
    .pdf_sign{
        width: 94%;
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(240, 239, 239);
        overflow: hidden;
        padding-bottom: 0px;
    
    }
    
    .choosen_image{
        position: relative;
        border-bottom: 1px solid #888888;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
    }
    
    .choosen_image_1{
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-bottom: 1px solid grey;
    }
    

    .hide_plz{
        display: none;
    }
    .show_plz{
        display: block;
    }
    
    #hide_specified{display: none;}
    
    #notspecified{display: none;}
    #last_sign {
        width: 40%;
        height: 27px;
        position: relative;
        left: 23px;
        bottom: -5px;
    }
    /*  */
    .Download{
        width: 100%;
        height: 100px;
        position: relative;
        display: flex;
        top: 0px;
        text-align: center;
        align-items:center ;
        justify-content: center;
        background-color: #E8E8E8;
    }
    .download_btn{
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        position: relative;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 0.60rem;
        display: flex;
        background-color: #ff7200;
        color: #fff;
      margin-right: 10px;
       
    }
    
    .emailButton{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        display: flex;
        /* border: 1px solid #fff; */
        background-color: #ff7200;
        color: #fff;
    }
    .button_text{
        height:100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
        font-size: 0.60rem;
    }
    .button_icon{
        padding-left: 2px;
        padding-right: 2px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .infoIcon{
        background-color: #ff7200;
        color: #fff;
    }
    .Download .titleBox{
        width:120px;
        height: auto;
        padding: 5px;
        background-color: #727376;
        position: absolute;
        top: 30px;
        left: -85px !important;
        border-radius: 4px;
        z-index: 9999999999;
        display: none;
    }
    .titleBox::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -7px;
        right:-90px !important;
        -webkit-transform: translate(-50%, 50%) rotate(45deg);
                transform: translate(-50%, 50%) rotate(45deg);
        background-color: #727376;
        overflow: hidden;
    }
    .Download>.emailButton .button_icon:hover>.titleBox{
        min-width:-webkit-fit-content;
        min-width:-moz-fit-content;
        min-width:fit-content;
        height: auto;
        padding: 5px;
        background-color: #727376;
        color: #666666;
        position: absolute;
        top: 30px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: block !important;
    }
    
    .titleBox span{
        color: #fff;
        font-size: 0.6rem;
    }
    .group_btns{
        width: 68%;
        height: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 16%;
        position: relative;
        display: flex;
        justify-content: space-between;
        /* background-color: #ff7200; */
        text-align: center;
        align-items: center;
    }
    
    .save{
        width: 52px;
        height: 20px;
        background-color: green;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .save:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
    #save_icon{
        font-size: 14px;  
        padding-top: 0px;
        color: #f1f1f1;
    }
    
    
    
    
    .show{
        width: 52px;
        height: 20px;
        background-color: rgb(78, 78, 233);
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .show:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
        
    
    #eye{
        font-size: 14px;
        padding-top: 0px;
    }
    .clear{
        width: 52px;
        height: 20px;
        background-color: red;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .clear:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    
    }
    
    #delete{
        font-size: 13px;
        padding-top: 3px;
        color: white;
    }
    .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 2;
        
    }
    .overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .model_content{
        position: absolute;
        width: 90%;
        height: 250px !important;
        top: 53%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        border: 3px solid #ff7200;
        z-index: 100;
    }
    .model_content.open{
        height: 450px !important;
        -webkit-animation: mymove;
                animation: mymove;
        -webkit-animation-duration: 0.5s  !important;
                animation-duration: 0.5s  !important;
    }
    @-webkit-keyframes mymove {
        0%{
            height: 250px !important;
        }
        100%{
            height: 450px !important;
        }
    }
    @keyframes mymove {
        0%{
            height: 250px !important;
        }
        100%{
            height: 450px !important;
        }
    }
    .model_content.close{
        height: 250px !important;
        -webkit-animation: mymoveBack ease-in;
                animation: mymoveBack ease-in;
        -webkit-animation-duration: 0.5s !important;
                animation-duration: 0.5s !important;
    }
    @-webkit-keyframes mymoveBack {
        0%{
            height: 450px !important;
        }
        100%{
            height: 250px !important;
        }
    }
    @keyframes mymoveBack {
        0%{
            height: 450px !important;
        }
        100%{
            height: 250px !important;
        }
    }
    
    .sigCloseBtn{
        width: 30px;
        height: 29px;
       position: absolute;
       top: 0px;
       border-radius: 5px;
       right: 0px;
       background-color: #ff7200;
       border: none;
    }
      
    .sigCloseBtn:active{
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);   
    }
    
    #sigClose{
    font-size: 1.2rem;
    }
    
    #sign_area{
        width: 78%;
        height: 165px;
        position: relative;
        top: 13%;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
    }
    .singnatureCanvas{
        border: 2px dashed silver;
        width: 100%;
        min-height: 120px;
    }
    /*  */
    .hide_text{
        display: none;
    }
    
    .show_hide_text{
        color: #ff7200;
        padding-left: 15px;
        position: relative;
        bottom: 13px;
    }
    
    .hide_textt{
        display: none;
    }
    }          











@media only screen and (min-width: 2001px){

    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    
    html,body{
        width: 100%;
        font-family: 'Montserrat', sans-serif;
    
    }
    .hide{display: none;}
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
        z-index: 4;
    }
    .advisoryFooter{
        width: 100%;
        position: absolute;
        top: 1022px;
        z-index: 11;
    }
    .Advisory_main .Advisory_heading{
        
        
        width: 100%;
        height: 70px;
        position: relative;
        top: 19px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
        
        font-size: 69px;
        font-weight: 900;
        letter-spacing: 0px ! important;
      
        
        
        height: 180px;
    }
  
.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    -webkit-clip-path: polygon(0 6%, 100% 19%, 100% 100%, 0% 100%);
            clip-path: polygon(0 6%, 100% 19%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
    
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    background-color: rgb(15, 15, 15);
   
  
}
.cover{
    width: 1250px;
    height: 72%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
   
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 320px;
    height: 79% !important;
    /* background-color: blueviolet;        */
    display: flex;
    position: absolute;
    top: 60px;
    right: 95px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 260px !important;
    height: 240px;
    position: relative;
    top: 0px;
    left: 33px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 695px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 55px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 42px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 15px;
    letter-spacing: 0.4px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 70px;
    color: white;
    line-height: 20px;
    padding-left: 7px;
}



.swiper-button-next{
    color: #ff7200 !important; 
    position: absolute;
    top: 400px !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 400px !important;
    z-index: 10;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 488px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 520px;
    overflow: hidden;
    z-index: -1;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 1250px;
    height: 370px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
    /* background-color: seagreen; */
   
}


.inner_div_1_1{
    width: 320px;
    height: 79%;
   /* background-color: blueviolet;      */
    display: flex;
    position: absolute;
    top: 62px;
    left:40px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    /* background-color: aqua; */
   
}

.inner_div_1_1 img{
    width: 260px;
    height: 240px;
    position: relative;
    top: 0;
    left: 23px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 675px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 110px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 42px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 15px;
    font-weight: 300;
    text-align: justify;
    padding-top: 50px;
    color: white;
    padding-top: 57px;
    line-height: 20px;
    letter-spacing: 0.4px;
}



.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
.footer_advisorry{
    position: absolute;
    top: 1020px;
    width: 100%;
    z-index: 10;
}
.footer_advisorry_new{
    position: absolute;
    top: 740px;
    width: 100%;
    z-index: 10;
}

}
@media only screen and (max-width: 2001px){
    
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,body{
    width: 100%;
   font-family: 'Montserrat', sans-serif;

}

.Advisory_main{
    background-color:black;
/* border: 1px solid red; */
    width: 100%;
    height: 260px;
    position: absolute; 
    top: 69px;
    z-index: 4;
    
}
.advisoryFooter{
    width: 100%;
    position: absolute;
    top: 1022px;
    z-index: 11;
}
.Advisory_main .Advisory_heading{
    
  
    width: 100%;
    height: 70px;
    position: relative;
    top: 36px;
    display: flex;
    justify-content: center;

}
.Advisory_main h1{
    
    text-align: center;
    color: #ff7200;
    font-family: 'Fjalla One', sans-serif;
    font:smaller;
   
    font-size: 59px;
    font-weight: 900;
 
   letter-spacing: 0px ! important;
    
    height: 180px;
}

.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    -webkit-clip-path: polygon(0 6%, 100% 19%, 100% 100%, 0% 100%);
            clip-path: polygon(0 6%, 100% 19%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
    
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    background-color: rgb(15, 15, 15);
   
  
}
.cover{
    width: 1250px;
    height: 72%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 320px;
    height: 79% !important;
    /* background-color: blueviolet;        */
    display: flex;
    position: absolute;
    top: 60px;
    right: 105px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 260px !important;
    height: 240px;
    position: relative;
    top: 0px;
    left: 33px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 675px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 55px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 70px;
    color: white;
    line-height: 20px;
    padding-left: 7px;
}



.swiper-button-next{
   
    color: #ff7200 !important; 
    position: absolute;
    top: 400px !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 400px !important;
    z-index: 10;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 520px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 520px;
    overflow: hidden;
    z-index: -1;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 1250px;
    height: 370px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;   
}
.hide{
    display: none;
}

.inner_div_1_1{
    width: 320px;
    height: 79%;
   /* background-color: blueviolet;      */
    display: flex;
    position: absolute;
    top: 74px;
    left:40px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    /* background-color: aqua; */
   
}

.inner_div_1_1 img{
    width: 260px;
    height: 240px;
    position: relative;
    top: 0;
    left: 23px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 675px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 110px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    padding-top: 70px;
    color: white;
    padding-left: 7px;
    line-height: 20px;
    letter-spacing: 0.4px;
}



.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */  
    z-index: 10;
    display: none !important;

}
.footer_advisorry{
    position: absolute;
    top: 1020px;
    width: 100%;
    z-index: 11110;
}

}
@media only screen and (max-width: 1501px){
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,body{
    width: 100%;
   font-family: 'Montserrat', sans-serif;

}

.Advisory_main{
    background-color:black;
/* border: 1px solid red; */
    width: 100%;
    height: 1020px;
    position: absolute; 
    top: 69px;
}
.advisoryFooter{
    width: 100%;
    position: absolute;
    top: 1025px;
    z-index: 11;
}
.Advisory_main .Advisory_heading{
    
  
    width: 100%;
    height: 70px;
    position: relative;
    top: 29px;
    display: flex;
    justify-content: center;

}
.Advisory_main h1{
    
    text-align: center;
    color: #ff7200;
    font-family: 'Fjalla One', sans-serif;
  
    letter-spacing: 0px ! important;
    font-size: 53px;
    font-weight: 900;
   
  
    
    height: 180px;
}

.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    -webkit-clip-path: polygon(0 5%, 100% 18%, 100% 100%, 0% 100%);
            clip-path: polygon(0 5%, 100% 18%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
    
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    background-color: rgb(15, 15, 15);
   
  
}
.cover{
    width: 1050px;
    height: 72%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
    /* background-color: rosybrown; */
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 270px;
    height: 69% !important;
     /* background-color: blueviolet;         */
    display: flex;
    position: absolute;
    top: 60px;
    right: 25px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 220px !important;
    height: 200px;
    position: relative;
    top: 0px;
    left: 33px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 675px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 55px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 13px;
    letter-spacing: 0.4px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 70px;
    color: white;
    line-height: 20px;
    padding-left: 7px;
}



.swiper-button-next{
   
    color: #ff7200 !important; 
    position: absolute;
    top: 400px !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 400px !important;
    z-index: 10;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 488px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 520px;
    overflow: hidden;
    z-index: -1;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 1050px;
    height: 370px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
    /* background-color: royalblue; */
   
}


.inner_div_1_1{
    width: 270px;
    height: 69%;
    /* background-color: blueviolet;       */
    display: flex;
    position: absolute;
    top: 72px;
    left:0px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    /* background-color: aqua; */
   
}

.inner_div_1_1 img{
    width: 220px;
    height: 210px;
    position: relative;
    top: 0;
    left: 23px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 675px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 50px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 13px;
    font-weight: 300;
    text-align: justify;
    padding-top: 60px;
    color: white;
    padding-left: 7px;
    line-height: 20px;
    letter-spacing: 0.4px;
}



.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
.advisory_foooter{
    position: relative;
    top: 320px;
}
}
@media only screen and (max-width: 1201px){
  
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,body{
    width: 100%;
   font-family: 'Montserrat', sans-serif;

}
.advisoryFooter{
    position: absolute;
    width: 100%;
    top: 965px;
}
.Advisory_main{
    background-color:black;
/* border: 1px solid red; */
    width: 100%;
    height: 260px;
    position: absolute; 
    top: 69px;
   
       
    
    
}

.Advisory_main .Advisory_heading{
    
  
    width: 100%;
    height: 40px;
    position: relative;
    top: 29px;
    display: flex;
    justify-content: center;

}
.Advisory_main h1{
    
    text-align: center;
    color: #ff7200;
    font-family: 'Fjalla One', sans-serif;
   
    position: relative;
    top: -10px;
   
    font-size: 48px;
    font-weight: 900;    
    height: 180px;
}

.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    -webkit-clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
    
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    background-color: rgb(15, 15, 15);
   
  
}
.cover{
    width: 850px;
    height: 72%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
     /* background-color: rosybrown;  */
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 220px;
    height: 62% !important;
      /* background-color: blueviolet;          */
    display: flex;
    position: absolute;
    top: 60px;
    right: 5px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 200px !important;
    height: 200px;
    position: relative;
    top: -5px;
    left: 0px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 575px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 15px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 13px;
    letter-spacing: 0.4px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 54px;
    color: white;
    line-height: 20px;
    padding-left: 7px;
}



.swiper-button-next{
   
    color: #ff7200 !important; 
    position: absolute;
    top: 400px !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 400px !important;
    z-index: 10;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 438px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 520px;
    overflow: hidden;
    z-index: -1;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 850px;
    height: 370px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
     /* background-color: royalblue;  */
   
}


.inner_div_1_1{
    width: 220px;
    height: 62%;
     /* background-color: blueviolet;        */
    display: flex;
    position: absolute;
    top: 71px;
    left:0px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    /* background-color: aqua; */
   
}

.inner_div_1_1 img{
    width: 200px;
    height: 190px;
    position: relative;
    top: 0;
    left: 3px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 575px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 20px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 13px;
    font-weight: 300;
    text-align: justify;
    padding-top: 56px;
    color: white;
    padding-left: 7px;
    line-height: 20px;
    letter-spacing: 0.4px;
}



.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
.footer_advisorry{
    position: absolute;
    top: 950px;
    width: 100%;
    z-index: 10111;
}
}
@media only screen and (max-width: 952px){
  
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    
    html,body{
        width: 100%;
       font-family: 'Montserrat', sans-serif;
    
    }
    .advisoryFooter{
        width: 100%;
        position: absolute;
        top: 950px;
    }
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
       
           
        
        
    }
    
    .Advisory_main .Advisory_heading{
        
      
        width: 100%;
        height: 40px;
        position: relative;
        top: 29px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
       
        position: relative;
        top: -10px;
       
        font-size: 48px;
        font-weight: 900;    
        height: 180px;
    }
    
    .Advisory_submain{
        width: 100%;
        height: 515px !important;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 80px;
        -webkit-clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
        z-index: -1;
        overflow: hidden;
        
    }
    /*  */
    
    .Advisory_submain .swiper-container{
        width: 100% !important; 
        height: 550px !important;
       
        position: relative;
        top: 20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
         /* background-color: aqua;  */
    
    }
    
    .Advisory_submain .swiper-slide{
        width: 100% !important;
        height: 500px;
        position: relative;
        top: 0px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
        background-color: rgb(15, 15, 15);
       
      
    }
    .cover{
        width: 850px;
        height: 72%;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: -10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
         /* background-color: rosybrown;  */
        
    }
    
    .outer_div_advisory{
        width: 100%;
        height: 100%;
        /* background-color: blue; */
    }
    
    .inner_div_1{
        width: 220px;
        height: 62% !important;
          /* background-color: blueviolet;          */
        display: flex;
        position: absolute;
        top: 60px;
        right: 5px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
      /* border: 1px solid red; */
    }
    
    .inner_div_1 img{
        width: 200px !important;
        height: 200px;
        position: relative;
        top: -5px;
        left: 0px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2{
        width: 575px;
        height: 99% !important;
        /* background-color: green; */
        position: absolute;
        top: 10px;
        left: 15px;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2 h3{
        font-size: 32px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2 p{
        font-size: 13px;
        letter-spacing: 0.4px;
        font-weight: lighter;
        text-align: justify;
        padding-top: 54px;
        color: white;
        line-height: 20px;
        padding-left: 7px;
    }
    
    
    
    .swiper-button-next{
       
        color: #ff7200 !important; 
        position: absolute;
        top: 400px !important;
        z-index: 10;
    } 
    
    
    .swiper-button-prev { 
        color: #ff7200 !important;
        position: absolute;
        top: 400px !important;
        z-index: 10;
    
    
    
    } 
    
    
    .Advisory_submain .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    swiper-pagination-current{
        background:white;
    }
      .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;
       
      
       
      }
    
     .swiper-pagination-bullet {
        opacity: 1;
    
        background-color: white;
    }
    /*  */
    .Advisory_submain_2{
        width: 100%;
        height: 438px;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 520px;
        overflow: hidden;
        z-index: -1;
        
    }
    
    
    .Advisory_submain_2 .swiper-container{
        width: 100% !important; 
        height: 410px;
        background-color: rgb(15, 15, 15);
        position: relative;
        top: -20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    
    }
    
    .Advisory_submain_2 .swiper-slide{
        width: 100% !important;
        height: 410px;
        position: relative;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
      
      
    }
    .cover_1{
        width: 850px;
        height: 370px;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
         /* background-color: royalblue;  */
       
    }
    
    
    .inner_div_1_1{
        width: 220px;
        height: 62%;
         /* background-color: blueviolet;        */
        display: flex;
        position: absolute;
        top: 71px;
        left:0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
        /* background-color: aqua; */
       
    }
    
    .inner_div_1_1 img{
        width: 200px;
        height: 190px;
        position: relative;
        top: 0;
        left: 3px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2_1{
        width: 575px;
        height: 92%;
        /* background-color: green; */
        position: absolute;
        top: 30px;
        right: 20px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2_1 h3{
        font-size: 32px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2_1 p{
        font-size: 13px;
        font-weight: 300;
        text-align: justify;
        padding-top: 56px;
        color: white;
        padding-left: 7px;
        line-height: 20px;
        letter-spacing: 0.4px;
    }
    
    
    
    .Advisory_submain_2 .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    .footer_advisorry{
        position: absolute;
        top: 950px;
        width: 100%;
        z-index: 10111;
    }
    }
@media only screen and (max-width: 901px){
   
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,body{
    width: 100%;
   font-family: 'Montserrat', sans-serif;

}

.Advisory_main{
    background-color:black;
/* border: 1px solid red; */
    width: 100%;
    height: 260px;
    position: absolute; 
    top: 69px;
    /* background-color: pink; */
  
       
    
    
}

.Advisory_main .Advisory_heading{
    
  
    width: 100%;
    height: 70px;
    position: relative;
    top: 37px;
    display: flex;
    justify-content: center;

}
.Advisory_main h1{
    
    text-align: center;
    color: #ff7200;
    font-family: 'Fjalla One', sans-serif;
    font-size: 44px;
    font-weight: 900;
    height: 180px;
}


.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    -webkit-clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
    
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    background-color: rgb(15, 15, 15);
   
  
}
.cover{
    width: 660px;
    height: 72%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
   
      
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 190px;
    height: 53% !important;
      
    display: flex;
    position: absolute;
    top: 60px;
    right: 0px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 170px !important;
    height: 166px;
    position: absolute;
    top: 2px;
    left: 2px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 455px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 0px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 28px;
    position: absolute;
    top: 0p;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 12px;
    letter-spacing: 0px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 54px;
    color: white;
    line-height: 18px;
    padding-left: 7px;
}



.swiper-button-next{
   
    color: #ff7200 !important; 
    position: absolute;
    top: 417px !important;
    display: block !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 417px !important;
    z-index: 10;
    display: block !important;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 385px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 560px;
    overflow: visible;
    z-index: -11;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 660px;
    height: 370px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
      
   
}


.inner_div_1_1{
    width: 190px;
    height: 53%;
     /* background-color: blueviolet;        */
    display: flex;
    position: absolute;
    top: 72px;
    left:0px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    
   
}

.inner_div_1_1 img{
    width: 170px;
    height: 165px;
    position: relative;
    top: 0;
    left: 3px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 455px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 28px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 12px;
    font-weight: 300;
    text-align: justify;
    padding-top: 58px;
    color: white;
    padding-left: 7px;
    line-height: 17px;
    letter-spacing: 0px;
}



.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
}
@media only screen and (max-width: 801px){
   
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    
    html,body{
        width: 100%;
       font-family: 'Montserrat', sans-serif;
    
    }
    
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
      
      
           
        
        
    }
    
    .Advisory_main .Advisory_heading{
        
      
        width: 100%;
        height: 70px;
        position: relative;
        top: 37px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
        font-size: 44px;
        font-weight: 900;
        height: 180px;
    }
    
    
    .Advisory_submain{
        width: 100%;
        height: 505px !important;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 80px;
        -webkit-clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
        z-index: -1;
        overflow: hidden;
        /* background-color: pink; */
        
    }
    /*  */
    
    .Advisory_submain .swiper-container{
        width: 100% !important; 
        height: 550px !important;
       
        position: relative;
        top: 20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
         /* background-color: aqua;  */
         z-index: -2;
    
    }
    
    .Advisory_submain .swiper-slide{
        width: 100% !important;
        height: 500px !important;
        position: relative;
        top: 0px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
        /* background-color: green; */
       
      
    }
    .cover{
        width: 660px;
        height: 72%;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: -10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
       
          
        
    }
    
    .outer_div_advisory{
        width: 100%;
        height: 100%;
        /* background-color: blue; */
    }
    
    .inner_div_1{
        width: 190px;
        height: 53% !important;
          
        display: flex;
        position: absolute;
        top: 60px;
        right: 0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
      /* border: 1px solid red; */
    }
    
    .inner_div_1 img{
        width: 170px !important;
        height: 166px;
        position: absolute;
        top: 2px;
        left: 2px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2{
        width: 455px;
        height: 99% !important;
        /* background-color: green; */
        position: absolute;
        top: 10px;
        left: 0px;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2 h3{
        font-size: 28px;
        position: absolute;
        top: 0p;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2 p{
        font-size: 12px;
        letter-spacing: 0px;
        font-weight: lighter;
        text-align: justify;
        padding-top: 54px;
        color: white;
        line-height: 18px;
        padding-left: 7px;
    }
    
    
    
    .swiper-button-next{
       
        color: #ff7200 !important; 
        position: absolute;
        top: 417px !important;
        display: block !important;
        z-index: 10;
    } 
    
    
    .swiper-button-prev { 
        color: #ff7200 !important;
        position: absolute;
        top: 417px !important;
        z-index: 10;
        display: block !important;
    
    
    
    } 
    
    
    .Advisory_submain .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    swiper-pagination-current{
        background:white;
    }
      .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;
       
      
       
      }
    
     .swiper-pagination-bullet {
        opacity: 1;
    
        background-color: white;
    }
    /*  */
    .Advisory_submain_2{
        width: 100%;
        height: 390px;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 560px;
        overflow: visible;
        z-index: -11;
        
    }
    
    
    .Advisory_submain_2 .swiper-container{
        width: 100% !important; 
        height: 410px;
        background-color: rgb(15, 15, 15);
        position: relative;
        top: -20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    
    }
    
    .Advisory_submain_2 .swiper-slide{
        width: 100% !important;
        height: 410px !important;
        position: relative;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
      
      
    }
    .cover_1{
        width: 660px;
        height: 370px;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
          
       
    }
    
    
    .inner_div_1_1{
        width: 190px;
        height: 53%;
         /* background-color: blueviolet;        */
        display: flex;
        position: absolute;
        top: 72px;
        left:0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
        
       
    }
    
    .inner_div_1_1 img{
        width: 170px;
        height: 165px;
        position: relative;
        top: 0;
        left: 3px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2_1{
        width: 455px;
        height: 92%;
        /* background-color: green; */
        position: absolute;
        top: 30px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2_1 h3{
        font-size: 28px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2_1 p{
        font-size: 12px;
        font-weight: 300;
        text-align: justify;
        padding-top: 58px;
        color: white;
        padding-left: 7px;
        line-height: 17px;
        letter-spacing: 0px;
    }
    
    
    
    .Advisory_submain_2 .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
}
@media only screen and (max-width: 701px){
    
    html,body{
        width: 100%;
       font-family: 'Montserrat', sans-serif;
    
    }
    .advisoryFooter{
        width: 100%;
        position: absolute;
        top: 920px;
    }
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
           
        
        
    }
    
    .Advisory_main .Advisory_heading{
        
      
        width: 100%;
        height: 70px;
        position: relative;
        top: 30px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
        font-size: 44px;
        font-weight: 900;
        
        height: 180px;
    }
    


    .Advisory_submain{
        width: 100%;
        height: 525px !important;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 80px;
        -webkit-clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
        z-index: -1;
        overflow: hidden;
        
    }
    /*  */
    
    .Advisory_submain .swiper-container{
        width: 100% !important; 
        height: 550px !important;
       
        position: relative;
        top: 20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
         /* background-color: aqua;  */
    
    }
    
    .Advisory_submain .swiper-slide{
        width: 100% !important;
        height: 500px ;
        position: relative;
        top: 0px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
      
       
      
    }
    .cover{
        width: 500px;
        height: 55%;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: -55px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
    }
    
    .outer_div_advisory{
        width: 100%;
        height: 100%;
        /* background-color: blue; */
    }
    
    .inner_div_1{
        width: 150px;
        height: 55% !important;
        display: flex;
        position: absolute;
        top: 45px;
        right: 0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
    }
    
    .inner_div_1 img{
        width: 130px !important;
        height: 126px;
        position: relative;
        top: -2px;
        left: 2px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2{
        width: 325px;
        height: 90% !important;
        position: absolute;
        top: 10px;
        left: 0px;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2 h3{
        font-size: 23px;
        position: absolute;
        top: 0p;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
       
    
     }
    .inner_div_2 p{
        font-size: 11px;
        letter-spacing: 0px;
        font-weight: lighter;
        text-align: justify;
        padding-top: 44px;
        color: white;
        line-height: 16px;
        padding-left: 7px;
    }
    
    
    
    .swiper-button-next{
       
        color: #ff7200 !important; 
        position: absolute;
        top: 457px !important;
        z-index: 10;
    } 
    
    
    .swiper-button-prev { 
        color: #ff7200 !important;
        position: absolute;
        top: 457px !important;
        z-index: 10;
    
    
    
    } 
    
    
    .Advisory_submain .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    swiper-pagination-current{
        background:white;
    }
      .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;
       
      
       
      }
    
     .swiper-pagination-bullet {
        opacity: 1;
    
        background-color: white;
    }
    /*  */
    .Advisory_submain_2{
        width: 100%;
        height: 390px;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 520px;
        overflow: visible;
        z-index: -11;
        
    }
    
    
    .Advisory_submain_2 .swiper-container{
        width: 100% !important; 
        height: 410px;
        background-color: rgb(15, 15, 15);
        position: relative;
        top: -20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    
    }
    
    .Advisory_submain_2 .swiper-slide{
        width: 100% !important;
        height: 410px;
        position: relative;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
      
      
    }
    .cover_1{
        width: 500px;
        height: 250px;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 57px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
        /* background-color: salmon; */
          
       
    }
    
    
    .inner_div_1_1{
        width: 150px;
        height: 63%;
         /* background-color: blueviolet;        */
        display: flex;
        position: absolute;
        top: 68px;
        left:10px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
        /* background-color:red; */
       
    }
    
    .inner_div_1_1 img{
        width: 130px;
        height: 122px;
        position: relative;
        top: 0;
        left: 3px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2_1{
        width: 325px;
        height: 92%;
        /* background-color: green; */
        position: absolute;
        top: 30px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2_1 h3{
        font-size: 23px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2_1 p{
        font-size: 11px;
        font-weight: 300;
        text-align: justify;
        padding-top: 54px;
        color: white;
        padding-left: 7px;
        line-height: 16px;
        letter-spacing: 0px;
    }
    
    
    
    .Advisory_submain_2 .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
}  
@media only screen and (max-width: 521px){
    
    html,body{
        width: 100%;
       font-family: 'Montserrat', sans-serif;
    }
    .advisoryFooter{
        width: 100%;
        position: absolute;
        top: 900px;
    }
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
        
    }
    
    .Advisory_main .Advisory_heading{

        width: 100%;
        height: 70px;
        position: relative;
        top: 40px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
        font-size: 38px;
        font-weight: 900;
  
      
        
        height: 180px;
    }
    

    .Advisory_submain{
        width: 100%;
        height: 515px !important;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 80px;
        -webkit-clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
        z-index: -1;
        overflow: hidden;
        
    }
    /*  */
    
    .Advisory_submain .swiper-container{
        width: 100% !important; 
        height: 550px !important;
       
        position: relative;
        top: 20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
         /* background-color: aqua;  */
    
    }
    
    .Advisory_submain .swiper-slide{
        width: 100% !important;
        height: 500px !important;
        position: relative;
        top: -40px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;  
         
       
      
    }
    .cover{
        width: 400px;
        height: 75%;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
         /* background-color: royalblue;  */
       
          
        
    }
    
    .outer_div_advisory{
        width: 100%;
        height: 100%;
        /* background-color: blue; */
    }
    
    .inner_div_1{
        width: 120px;
        height: 35% !important;
        /* background-color: saddlebrown;  */
        display: flex;
        position: absolute;
        top: 45px;
        right: 0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
      /* border: 1px solid red; */
    }
    
    .inner_div_1 img{
        width: 100px !important;
        height: 106px;
        position: relative;
        top: 0px;
        left: 7px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2{
        width: 265px;
        height: 99% !important;
        /* background-color: green; */
        position: absolute;
        top: 10px;
        left: 0px;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2 h3{
        font-size: 19px;
        position: absolute;
        top: 0p;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2 p{
        font-size: 10px;
        letter-spacing: 0px;
        font-weight: lighter;
        text-align: justify;
        padding-top: 44px;
        color: white;
        line-height: 14px;
        padding-left: 7px;
    }
    
    
    
    .swiper-button-next{
       
        color: #ff7200 !important; 
        position: absolute;
        top: 488px !important;
        z-index: 10;
    } 
    
    
    .swiper-button-prev { 
        color: #ff7200 !important;
        position: absolute;
        top: 488px !important;
        z-index: 10;
    
    
    
    } 
    
    
    .Advisory_submain .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    swiper-pagination-current{
        background:white;
    }
      .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;
      }
    
     .swiper-pagination-bullet {
        opacity: 1;
    
        background-color: white;
    }
    /*  */
    .Advisory_submain_2{
        width: 100%;
        height: 369px;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 520px;
        overflow: visible;
        z-index: -11;
        
    }
    
    
    .Advisory_submain_2 .swiper-container{
        width: 100% !important; 
        height: 410px;
        background-color: rgb(15, 15, 15);
        position: relative;
        top: -20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    
    }
    
    .Advisory_submain_2 .swiper-slide{
        width: 100% !important;
        height: 410px !important;
        position: relative;
        top: -30px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
        
      
      
    }
    .cover_1{
        width: 400px;
        height: 250px;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 45px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
         /* background-color: salmon;  */
          
       
    }
    
    
    .inner_div_1_1{
        width: 120px;
        height: 51% !important;
         /* background-color: blueviolet;        */
        display: flex;
        position: absolute;
        top: 64px;
        left:10px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
        
       
    }
    
    .inner_div_1_1 img{
        width: 100px;
        height: 95px;
        position: relative;
        top: 0;
        left: 3px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2_1{
        width: 265px;
        height: 92%;
        /* background-color: green; */
        position: absolute;
        top: 30px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2_1 h3{
        font-size: 19px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2_1 p{
        font-size: 10px;
        font-weight: 300;
        text-align: justify;
        padding-top: 47px;
        color: white;
        padding-left: 7px;
        line-height: 14px;
        letter-spacing: 0px;
    }
    
    
    
    .Advisory_submain_2 .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
}
@media only screen and (max-width: 426px){
    
    html,body{
        width: 100%;
       font-family: 'Montserrat', sans-serif;
    
    }
    
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
       
           
        
        
    }
    
    .Advisory_main .Advisory_heading{
        
      
        width: 100%;
        height: 70px;
        position: relative;
        top: 43px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        letter-spacing: 0px ! important;
        position: relative;
        top: -10px;
        font-size: 34px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        
        height: 180px;
    }
    
    .Advisory_submain{
        width: 100%;
        height: 515px !important;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 80px;
        -webkit-clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
        z-index: -1;
        overflow: hidden;
    }
    /*  */
    
    .Advisory_submain .swiper-container{
        width: 100% !important; 
        height: 550px !important;
       
        position: relative;
        top: 20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
         /* background-color: aqua;  */
    
    }
    
    .Advisory_submain .swiper-slide{
        width: 100% !important;
        height: 500px !important;
        position: relative;
        top: -40px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
       
    }
    .cover{
        width: 350px;
        height: 79%;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
          /* background-color: royalblue;  
        */
          
        
    }
    
    .outer_div_advisory{
        width: 100%;
        height: 100%;
        /* background-color: blue; */
    }
    
    .inner_div_1{
        width: 100px;
        height: 29% !important;
        /* background-color: saddlebrown;    */
        display: flex;
        position: absolute;
        top: 32px;
        right: 0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
      /* border: 1px solid red; */
      border-radius: none !important;
    }
    
    .inner_div_1 img{
        width: 90px !important;
        height: 86px;
        position: relative;
        top: 0px;
        left: 0px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2{
        width: 235px;
        height: 99% !important;
        /* background-color: green; */
        position: absolute;
        top: 10px;
        left: 0px;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2 h3{
        font-size: 16px;
        position: absolute;
        top: 0p;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2 p{
        font-size: 10px;
        letter-spacing: 0px;
        font-weight: lighter;
        text-align: justify;
        padding-top: 34px;
        color: white;
        line-height: 14px;
        padding-left: 7px;
    }
    
    
    
    .swiper-button-next{
       
        color: #ff7200 !important; 
        position: absolute;
        top: 492px !important;
        z-index: 10;
    } 
    
    
    .swiper-button-prev { 
        color: #ff7200 !important;
        position: absolute;
        top: 492px !important;
        z-index: 10;
    
    
    
    } 
    
    
    .Advisory_submain .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    swiper-pagination-current{
        background:white;
    }
      .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;
       
      
       
      }
    
     .swiper-pagination-bullet {
        opacity: 1;
    
        background-color: white;
    }
    /*  */
    .Advisory_submain_2{
        width: 100%;
        height: 358px;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 530px;
        overflow: visible;
        z-index: -11;
        
    }
    
    
    .Advisory_submain_2 .swiper-container{
        width: 100% !important; 
        height: 410px;
        background-color: rgb(15, 15, 15);
        position: relative;
        top: -20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    
    }
    
    .Advisory_submain_2 .swiper-slide{
        width: 100% !important;
        height: 410px;
        position: relative;
        top: -30px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
      
      
    }
    .cover_1{
        width: 350px;
        height: 250px;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 35px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
         /* background-color: salmon;   */
          
       
    }
    
    
    .inner_div_1_1{
        width: 108px;
        height: 45% !important;
         /* background-color: blueviolet;         */
        display: flex;
        position: absolute;
        top: 57px;
        left:0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
        
       
    }
    
    .inner_div_1_1 img{
        width: 90px !important;
        height: 87px;
        position: relative;
        top: 0;
        left: 3px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2_1{
        width: 240px;
        height: 92%;
        /* background-color: green; */
        position: absolute;
        top: 30px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2_1 h3{
        font-size: 16px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2_1 p{
        font-size: 10px;
        font-weight: 300;
        text-align: justify;
        padding-top: 35px;
        color: white;
        padding-left: 7px;
        line-height: 14px;
        letter-spacing: 0px;
    }
    
    
    
    .Advisory_submain_2 .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
}  
@media only screen and (max-width: 375px){
    
html,body{
    width: 100%;
   font-family: 'Montserrat', sans-serif;

}

.Advisory_main{
    background-color:black;
/* border: 1px solid red; */
    width: 100%;
    height: 260px;
    position: absolute; 
    top: 69px;
    z-index: 10;
      
    
    
}

.Advisory_main .Advisory_heading{
    
  
    width: 100%;
    height: 70px;
    position: relative;
    top: 42px;
    display: flex;
    justify-content: center;

}
.Advisory_main h1{
    
    text-align: center;
    color: #ff7200;
   position: relative;
   top: -10px;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0px ! important;
    font-family: 'Fjalla One', sans-serif;
    height: 180px;
}
 
.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    -webkit-clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: -40px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    
   
  
}
.cover{
    width: 100%;
    height: 79%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    
    
      
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 100px;
    height: 29% !important;
     /* background-color: saddlebrown;   */
    display: flex;
    position: absolute;
    top: 32px;
    right: 10px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 90px !important;
    height: 86px;
    position: relative;
    top: 0px;
    left: 0px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 235px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 10px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 15px;
    position: absolute;
    top: 0p;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 10px;
    letter-spacing: 0px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 34px;
    color: white;
    line-height: 14px;
    padding-left: 7px;
}



.swiper-button-next{
   
    color: #ff7200 !important; 
    position: absolute;
    top: 499px !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 499px !important;
    z-index: 10;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 350px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 530px;
    overflow: visible;
    z-index: -11;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    top: -30px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 100%;
    height: 250px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 35px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
     /* background-color: salmon;   */
      
   
}


.inner_div_1_1{
    width: 100px;
    height: 45% !important;
     /* background-color: blueviolet;         */
    display: flex;
    position: absolute;
    top: 52px;
    left:10px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    
   
}

.inner_div_1_1 img{
    width: 85px;
    height: 84px;
    position: relative;
    top: 0;
    left: 3px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 240px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 10px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 15px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 10px;
    font-weight: 300;
    text-align: justify;
    padding-top: 33px;
    color: white;
    padding-left: 7px;
    line-height: 14px;
    letter-spacing: 0px;
}
.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}

}
.formElem{
    width: 100%;
    height:100vh;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    background-color: #181818;
    overflow: scroll !important;
}
.innerFormElem{
    width: 50%;
    height: 80%;
    position: relative;
    top: 35px;
    background-color: #080808;
    box-shadow: 0px 0px 6px #ff7200;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 20px;
}
.formRow{
    width: 90%;
    height: 45px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    position: relative;
   
}
.myInp{
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0px;
    margin-left: auto;
    margin-right: auto;
    background-color: #080808;
    border-bottom: 1px solid #ff7200;
}
.noBrdr{
    border: none;
}
.myInp::-webkit-input-placeholder{
    color: #ff7200 !important;
    letter-spacing: 2px;
    font-size: 0.7rem;
    font-weight: 400;
}
.myInp:-ms-input-placeholder{
    color: #ff7200 !important;
    letter-spacing: 2px;
    font-size: 0.7rem;
    font-weight: 400;
}
.myInp::placeholder{
    color: #ff7200 !important;
    letter-spacing: 2px;
    font-size: 0.7rem;
    font-weight: 400;
}
.myInp{
    color: #C0C0C0;
    letter-spacing: 2px;
    font-size: 0.7rem;
    font-weight: 400;
    position: relative;
    left: 0px;
}
.flexCol{
    display: flex;
    flex-direction: column;
   height: 60px;
   justify-content: space-between;
    /* background-color: pink; */
}
.flexCol>span{
    color: #ff7200;
    letter-spacing: 2px;
    font-size: 0.7rem;
    font-weight: 400;
    padding-left: 5%;
}
.textArea{
    width: 90%;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    background-color: #080808;
    color: #C0C0C0;
    padding-left: 6px;
    padding-top: 2px;
    border: 1px solid #ff7200;
    border-radius: 5px;
    resize: none;
}
.textArea:focus{
    outline: none;
}
.requiredText{
    color: #fff !important;
    font-size: 0.65rem !important;
}
.subBtn{
    width: 20%;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ff7200;
    background-color: #ff7200;
    border-radius: 3px;
    cursor: pointer;
}
.plane{
    color: #fff;
}
@media only screen and (min-width:1550px){
    .formElem{
        width: 100%;
        height:100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #181818;
        overflow: scroll !important;
        position: relative;
    }
    .innerFormElem{
        width: 55%;
        height: 80%;
        position: relative;
        top: 35px;
        background-color: #080808;
        box-shadow: 0px 0px 6px #ff7200;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
    }
    .formRow{
        width: 90%;
        height: 45px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
       
    }
    .myInp{
        width: 90%;
        height: 100%;
        position: absolute;
        top: 0px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        border-bottom: 1px solid #ff7200;
    }
    .noBrdr{
        border: none;
    }
    .myInp::-webkit-input-placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.8rem;
        font-weight: 400;
    }
    .myInp:-ms-input-placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.8rem;
        font-weight: 400;
    }
    .myInp::placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.8rem;
        font-weight: 400;
    }
 
    .myInp{
        color: #C0C0C0 !important;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
        position: relative;
        left: 0px;
    }
    .flexCol{
        display: flex;
        flex-direction: column;
       height: 90px;
       justify-content: space-between;
        /* background-color: pink; */
    }
    .flexCol>span{
        color: #ff7200;
        letter-spacing: 2px;
        font-size: 0.8rem;
        font-weight: 400;
        padding-left: 5%;
    }
    .textArea{
        width: 90%;
        height: 60px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        color: #C0C0C0;
        padding-left: 6px;
        padding-top: 2px;
        border: 1px solid #ff7200;
        border-radius: 5px;
        resize: none;
    }
    .textArea:focus{
        outline: none;
    }
    .requiredText{
        color: #fff !important;
        font-size: 0.7rem !important;
    }
    .subBtn{
        width: 20%;
        height: 35px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #ff7200;
        background-color: #ff7200;
        border-radius: 3px;
        cursor: pointer;
    }
    .plane{
        color: #fff;
    }
}
@media only screen and (max-width:1100px){
    
    .innerFormElem{
        width: 70%;
        height: 80%;
        position: relative;
        top: 35px;
        background-color: #080808;
        box-shadow: 0px 0px 6px #ff7200;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
    }
}
@media only screen and (max-width:800px){
    
    .innerFormElem{
        width: 90%;
        height: 80%;
        position: relative;
        top: 35px;
        background-color: #080808;
        box-shadow: 0px 0px 6px #ff7200;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
    }
}
@media only screen and (max-width:600px){
    .formElem{
        width: 100%;
        height:100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #181818;
        overflow: scroll !important;
        position: relative;
    }
    .innerFormElem{
        width: 90%;
        height: 80%;
        position: relative;
        top: 35px;
        background-color: #080808;
        box-shadow: 0px 0px 6px #ff7200;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
    }
    .formRow{
        width: 98%;
        height: 45px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
       
    }
    .myInp{
        width: 90%;
        height: 100%;
        position: absolute;
        top: 0px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        border-bottom: 1px solid #ff7200;
    }
    .noBrdr{
        border: none;
    }
    .myInp::-webkit-input-placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
    }
    .myInp:-ms-input-placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
    }
    .myInp::placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
    }
    .myInp{
        color: #C0C0C0 !important;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
        position: relative;
        left: 0px;
    }
    .flexCol{
        display: flex;
        flex-direction: column;
       height: 60px;
       justify-content: space-between;
        /* background-color: pink; */
    }
    .flexCol>span{
        color: #ff7200;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
        padding-left: 5%;
    }
    .textArea{
        width: 90%;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        color: #C0C0C0;
        padding-left: 6px;
        padding-top: 2px;
        border: 1px solid #ff7200;
        border-radius: 5px;
        resize: none;
    }
    .textArea:focus{
        outline: none;
    }
    .requiredText{
        color: #fff !important;
        font-size: 0.6rem !important;
    }
    .subBtn{
        width: 20%;
        height: 35px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #ff7200;
        background-color: #ff7200;
        border-radius: 3px;
        cursor: pointer;
    }
}
@media only screen and (max-width:450px){
    .formElem{
        width: 100%;
        height:100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #181818;
        overflow: scroll !important;
        position: relative;
    }
    .innerFormElem{
        width: 92%;
        height: 80%;
        position: relative;
        top: 35px;
        background-color: #080808;
        box-shadow: 0px 0px 6px #ff7200;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
    }
    .formRow{
        width: 100%;
        height: 45px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
       
    }
    .myInp{
        width: 95%;
        height: 100%;
        position: absolute;
        top: 0px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        border-bottom: 1px solid #ff7200;
    }
    .noBrdr{
        border: none;
    }
    .myInp::-webkit-input-placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.6rem;
        font-weight: 400;
    }
    .myInp:-ms-input-placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.6rem;
        font-weight: 400;
    }
    .myInp::placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.6rem;
        font-weight: 400;
    }
    .myInp{
        color: #C0C0C0 ;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
        position: relative;
        left: 0px;
    }
    .flexCol{
        display: flex;
        flex-direction: column;
       height: 60px;
       justify-content: space-between;
        /* background-color: pink; */
    }
    .flexCol>span{
        color: #ff7200;
        letter-spacing: 2px;
        font-size: 0.6rem;
        font-weight: 400;
        padding-left: 2.5%;
    }
    .textArea{
        width: 95%;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        color: #C0C0C0;
        padding-left: 6px;
        padding-top: 2px;
        border: 1px solid #ff7200;
        border-radius: 5px;
        resize: none;
    }
    .textArea:focus{
        outline: none;
    }
    .requiredText{
        color: #fff !important;
        font-size: 0.55rem !important;
    }
    .subBtn{
        width: 24%;
        height: 33px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #ff7200;
        background-color: #ff7200;
        border-radius: 3px;
        cursor: pointer;
    }
}
