@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,300;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,300;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
*{
    padding:0;
    margin: 0;
    box-sizing: border-box;
   
}
html {
    padding: 0px;
    margin: 0px;
    width: 100vw;
}

body {
    width: 100%;
}

.footer_main{
    position: relative;
    width: 100%;
    height: 400px;
   
   
}

.footer_part1{
    position: relative;
    bottom: 10px;
    width: 100%;
    height: 250px;
    background: #ff7200;
    clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
}


.footer_heading1 {
   
    position: relative;
    top: 70px;
    width: 28%;
    height: 120px;
    left: 12%;
  
}  

.footer_heading1 h1{ 
    color: #fff;
    font-size: 28px;
    float: left;
     font-family: 'Montserrat', sans-serif;
   
   font-weight: 700;
    
}

.footer_heading1 p{
    float: left;
    margin-top: 12px;
    color: #fff;
    text-shadow: 0px 0px 1px black;
    font-size: 14px;
   font-weight: 300;
     font-family: 'Montserrat', sans-serif;
}

.email_1{
    position: relative;
    display: flex;
   
    left: 59%;
    bottom: 20px;
    width: 400px;
    color: transparent;
    height: 45px;
    border: 1.5px solid #ff7200;
    border-radius: 9px;

}
.email_1 #text_field1{
    width: 303px;
    background: transparent;
    border: 0px solid;
    border-style: none;
    outline: none;
    

}
::placeholder{
  
    font-size: 10px;
    padding-left: 20px;
     font-family: 'Montserrat', sans-serif;
}



.email_1 button{
    position: relative;
    left: 14%;
    margin-top: 1.5px;
    width: 35px;
    height: 37px;
    border-radius: 7px;
    background: #ff7200;
    border: none;
    cursor: pointer;
}

#arrow2{
    position: relative;
    left: 0%;
}

.footer_new_part{
    width: 100%;
    height: 324px;
  /*   background-color:#1c1c1c;  */
  background-color: black;
 position: relative;
 bottom: 10px;
  display: flex;
  
  justify-content: space-evenly;
  
  
    
    
}

.footer_new_part .footer_logo{
    width: 200px;  
    height: 40px;
    
    position: absolute;
    left: 5%;
    
    
}

    #logo_footer{
    width: 133px;
    
    height: 52px;
    position:absolute;
    left: 52%;
    top: 13px;
    
    
}

.data_footer1{
    /*  border: 1px solid yellow;  */
    width: 5%;
    height: 190px;
    position: absolute;
    top: 110px; 
    right: 83%;
   
  
}
               



.data_footer_1 p{
    font-size: 13px;
   
   font-weight: 300;
    color:white;
     font-family: 'Montserrat', sans-serif;
}

.data_footer_1 #address{
   
    font-size: 12px;
     font-family: 'Montserrat', sans-serif;
    
}

.location_all{
    width: 32px;
    height: 143px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    position: relative;
    
    
   
      
}

#location{
    width: 27px;
   margin-top: 2px;

  
}
.addressdiv{
    
   
    width: 49%;
    height: 180px;
    position: absolute;
    top: 115px;
    right: 35%;
    display: flex;
    flex-direction: column;
   
   
   
}


#addresss{
    color: #fff;
   
    text-decoration: none;
    font-size: 14px;
   font-weight: 300;
     font-family: 'Montserrat', sans-serif;

}

#addresss:hover{
    color: #ff7200;
    text-decoration: underline;
}
#addresss_1{
    color: #fff;
    position: absolute;
    top: 69px;
   font-weight: 300;
    font-size: 14px;
    text-decoration: none;
     font-family: 'Montserrat', sans-serif;
}

#addresss_1:hover{
    color: #ff7200;
    text-decoration: underline;
}

#addresss_2{
    color: #fff;
    position: absolute;
    top: 120px;
   font-weight: 300; 
    text-decoration: none;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;
}
#addresss_2:hover{
    color: #ff7200;
    text-decoration: underline;
}

   

.data_footer2{
   /*  border: 1px solid orange;  */
    width:29%;
    height: 190px;
    position: absolute;
    top: 59px;
    right: 32%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
   
   
   
}

.data_footer2 p{
   
    color:white;
   
    font-size: 13px;
    
   
    font-weight: bold;
     font-family: 'Montserrat', sans-serif;
}
.data_footer2 .data_footer_1{

    color: white;
    text-decoration: none;
    
  
    font-size: 14px;
   font-weight: 300;
     font-family: 'Montserrat', sans-serif;
}

.data_footer2 .data_footer_1:hover{
    color: #ff7200;
    text-decoration: underline;

}
.data_footer2 .data_footer_2{

    color: white;
    text-decoration: none;
   
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;

    
}

.data_footer2 .data_footer_2:hover{
    color: #ff7200;
    text-decoration: underline;

}
.data_footer2 .data_footer_3{

    color: white;
    text-decoration: none;
   
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;
}

.data_footer2 .data_footer_3:hover{
    color: #ff7200;
    text-decoration: underline;

}




.data_footer3{
    width: 20%;
    height: 230px;
   /*  border: 1px solid greenyellow; */
    position: absolute;
    top: 59px;
    right: 28%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
   
}

.data_footer3 p{ 
   
    color:white;
    font-size: 13px;
    font-weight: bold;
     font-family: 'Montserrat', sans-serif;
}

.data_footer3 .data_footer3_1{
    color: white;
    text-decoration: none;
  
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;
}

.data_footer3 .data_footer3_1:hover{
    color: #ff7200;
    text-decoration: underline;
}

.data_footer3 .data_footer3_2{
    color: white;
    text-decoration: none;
   
   font-weight: 300;
    left: -57px;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;
}
.data_footer3 .data_footer3_2:hover{
    color: #ff7200;
    text-decoration: underline;
}

.data_footer3 .data_footer3_3{
    color: white;
    text-decoration: none;
   
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;
}
.data_footer3 .data_footer3_3:hover{
    color: #ff7200;
    text-decoration: underline;
}

.data_footer3 .data_footer3_4{
    color: white;
    
   font-weight: 300;
    right: 56px;
    font-size: 14px;
    text-decoration: none;
     font-family: 'Montserrat', sans-serif;
}

.data_footer3 .data_footer3_4:hover{
    color: #ff7200;
    text-decoration: underline;
}

.data_footer4{
    width: 22%;
    height: 230px;
   /*  border: 1px solid cyan;  */

    position: absolute;
    top: 59px;
    right: 13%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  
   
}

.data_footer4 p{
  
    color:white;
    font-size: 13px;
    font-weight: bold;
     font-family: 'Montserrat', sans-serif;
    


}

.data_footer4 .data_footer4_one{
    color: white;
    text-decoration: none;
    
    font-size: 14px;
   font-weight: 300;
     font-family: 'Montserrat', sans-serif;
}


.data_footer4 .data_footer4_one:hover{
    color: #ff7200;
    text-decoration: underline;
}
.data_footer4 .data_footer4_two{
    color: white;
    text-decoration: none;
    
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;

}

.data_footer4 .data_footer4_two:hover{
    color: #ff7200;  
    text-decoration: underline;
}

.data_footer4 .data_footer4_three{
    color: white;
    text-decoration: none;
    
   font-weight: 300;
    font-size: 14px;
     font-family: 'Montserrat', sans-serif;

}

.data_footer4 .data_footer4_three:hover{
    color: #ff7200;
    text-decoration: underline;
}



.data_footer5{
    width: 12%;
    height: 220px;
    /*  border: 1px solid cyan;  */
     position: absolute;
     top: 50px;
     right: 10%;
     display: flex;
     flex-direction: column;
     justify-content: space-evenly;
   
}
.social{
    width: 120px;
  
    
    display: flex;
    justify-content: space-around;
    position: relative;
  
    
}
.data_footer5 p{
    
    color:white;
    font-size: 13px;
    font-weight: bold;
     font-family: 'Montserrat', sans-serif;
}



.last_foot{
    background-color:#1a1a1a;
   /*  border: 1px solid black; */
    position: relative;
    top: -10px;
    
    width: 100%;
    height: 43px;    
    text-align: center;
}
.last_foot p{
    color: white;
 
    font-size: 12px;
    letter-spacing: 2px;
    position: relative;
    top: 13px;
     font-family: 'Montserrat', sans-serif;   
    
}

#root{
    height: 0px;
}
input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
margin: 0;
    }





@media screen and  (min-width: 1550px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
        
    }

    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
        
    }
    
    body {
        width: 100%;
    }
    .homeFooter{
        position: relative;
        top: 876px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 400px;            
    }

    #text_field1{
        padding-left: 10px;
    }

    
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 250px;
        background: #ff7200;
        clip-path: polygon(46% 0%, 100% 25%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 26%;
        height: 120px;
        left: 6%;  
    } 
    
    .footer_heading1 a{
        cursor: pointer;
    }
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 32px;
        overflow: hidden;
            font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 14px;
        font-weight: 300;
            font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 410px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 393px; 
        height: 38px;
        background-color: transparent;
        position: absolute;
        top: 0px;
        border: 0px solid;
        border-style: none;
        outline: none;
        left: 0px;
    
    }
    #text_field1::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: 0px;
        padding-left: 0px !important;
        
        
    }
    

    
    
    
    .email_1 button{
        position: absolute;
        left: 367px !important;
        top: 0px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
        color: black;
    }
    
    .footer_new_part{
        width: 100%;
        height: 320px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 12px;
        display: flex;       
        justify-content: space-evenly;
    }
    
    .footer_new_part .footer_logo{
        width: 20%;  
        height: 40px;
        position: absolute;
        left: 10%;
        
        
        
    }
    
        #logo_footer{
        width: 133px;
        
        height: 52px;
        position:absolute;
        left: 6%;
        top: 16px;
        
        
    }
    
    
    .data_footer1{
            /* border: 1px solid yellow;   */
        width: 5%;
        height: 190px;
        position: absolute;
        top: 95px; 
        right: 84%;
        
        
        
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 14px;
        
        font-weight: 300;
        color:white;
            font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
            font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 21%;
        height: 180px;
        /* border: 1px solid red; */
        position: absolute;
        top: 96px;
        right: 64.5%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 14px;
        font-weight: 300;
            font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
        font-weight: 300;
        font-size: 14px;
        text-decoration: none;
            font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
        font-weight: 300; 
        text-decoration: none;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        

    
    .data_footer3{
        width: 12%;
        height: 277px;
        /* border: 1px solid red;  */
        position: absolute;
        top: 27px;
        right: 48%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 15px;
        font-weight: bold;
            font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
        font-weight: 300;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
        font-weight: 300;
        left: -57px;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
        font-weight: 300;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
        font-weight: 300;
        right: 56px;
        font-size: 14px;
        text-decoration: none;
            font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 11%;
        height: 230px;
            /* border: 1px solid cyan;  */
        
        position: absolute;
        top: 31px;
        right: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
            font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 14px;
        font-weight: 300;
            font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
        font-weight: 300;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
        font-weight: 300;
        font-size: 14px;
            font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 12%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 20px;
            right: 14%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
            font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -20px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        font-weight: 300;
        font-size: 11px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
            font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
/*  */

        .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 40%;
        height: 550px;
        z-index: 10 !important;
        
        
        
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 42px;
        font-weight: 600;
        padding-top: 2px;

    }
    

    /*  */

    .form_div form{
        width: 53%;
        /* border: 1px solid red; */
        position: absolute;
        top: 55%;
        left: 51%;
        transform: translate(-50%, -50%);
        
    }
    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
    }
    
    .form_div input{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        line-height: 27px;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 40%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        
        font-weight: bold;
        
    }
    .close_pop_up{
        width: 40%;
        margin-top: 13px;
        position: absolute;
        left: 188px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
        
        
        
        

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }

    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        height: 31px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;

    }

    
    
}
@media screen and  (max-width: 1550px){
    .homeFooter{
        position: relative;
        top: 724px;
    }
    
    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
        
    }

    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
        
    }
    
    body {
        width: 100%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 400px;
        /* top: 777px; */
          
    }

    #text_field1{
        padding-left: 10px;
    }

    
    
    .footer_part1{
        position: relative;
        bottom: -11px;
        width: 100%;
        height: 250px;
        background: #ff7200;
        clip-path: polygon(46% 0%, 100% 25%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 26%;
        height: 120px;
        left: 6%;  
    } 
    
    .footer_heading1 a{
        cursor: pointer;
    }
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 32px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 410px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 393px; 
        height: 38px;
        background-color: transparent;
        position: absolute;
        top: 0px;
        left: 0px;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    #text_field1::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;       
    }
    

    
    
    
    .email_1 button{
        position: absolute;
        left: 367px !important;
        top: 0px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
        color: black;
    }
    
    .footer_new_part{
        width: 100%;
        height: 320px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 12px;
        display: flex;       
        justify-content: space-evenly;
    }
    
    .footer_new_part .footer_logo{
        width: 20%;  
        height: 40px;
        position: absolute;
        left: 10%;
       
        
        
    }
    
        #logo_footer{
        width: 133px;
        
        height: 52px;
        position:absolute;
        left: 6%;
        top: 16px;
        
        
    }
    
    
    .data_footer1{
          /* border: 1px solid yellow;   */
        width: 5%;
        height: 190px;
        position: absolute;
        top: 95px; 
        right: 84%;
        
       
       
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 14px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 21%;
        height: 180px;
        /* border: 1px solid red; */
        position: absolute;
        top: 96px;
        right: 64.5%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 14px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        

    
    .data_footer3{
        width: 12%;
        height: 277px;
        /* border: 1px solid red;  */
        position: absolute;
        top: 27px;
        right: 48%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 14px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 11%;
        height: 230px;
         /* border: 1px solid cyan;  */
      
        position: absolute;
        top: 31px;
        right: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 12%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 20px;
            right: 14%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -20px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
       font-weight: 300;
        font-size: 11px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
/*  */

     .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 550px;
       z-index: 10 !important;
     
       
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 42px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 53%;
        /* border: 1px solid red; */
        position: absolute;
        top: 55%;
        left: 51%;
        transform: translate(-50%, -50%);
       
    }
    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
    }
    
    .form_div input{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        line-height: 27px;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 40%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 40%;
        margin-top: 13px;
        position: absolute;
        left: 188px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }

    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        height: 31px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;

    }

   
    
}
@media screen and  (max-width: 1415px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
      
    }
    
    body {
        width: 100%;
      
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    #text_field1{
        padding-left: 10px;
    }

    ::placeholder{
        padding-left: 0px;

    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 410px;
    }
    
    .footer_part1{
        position: relative;
        bottom: -10px;
        width: 100%;
        height: 250px;
        background: #ff7200;
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 32%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 32px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: white !important;
        
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 350px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
        border: none !important;
    
    }
    .email_1 #text_field1{
        width: 343px;
        height: 37px;
       
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 0px !important;
        position: relative;
        right: 0px;
        top: 1px;
        font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        
        top: 1.5px;
        left: 310px !important;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
        color: black !important;
    }
    
    
    .footer_new_part{
        width: 100%;
        height: 310px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 11%;  
        height: 40px;
        position: absolute;
        left: 7.4%;
        top: 19px;
        /* border: 1px solid red; */
       
        
        
    }
    
        #logo_footer{
        width: 133px;
        
        height: 52px;
        position:absolute;
        left: 6%;
        top: 0px;
        
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 190px;
        position: absolute;
        top: 95px; 
        right: 87%;
     
       
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 22%;
        height: 180px;
        position: absolute;
        /* border: 2px solid red; */
        top: 96px;
        right: 66%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 14px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
     
    
    
    .data_footer3{
        width: 12%;
        height: 272px;
       /* border: 1px solid red; */
        position: absolute;
        top: 29px;
        right: 47%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 14px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 11%;
        height: 230px;
        /*  border: 1px solid cyan;  */
       
        position: absolute;
        top: 31px;
        right: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 14px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 14px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 12%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 20px;
            right: 13%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 15px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -12px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 11px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 550px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 42px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 62%;
        height: 470px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
       
        
        
        /* border: 1px solid red;  */
      
       
    }
    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
    }
    
    .form_div input{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        line-height: 27px;
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 40%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 40%;
        margin-top: 13px;
        position: absolute;
        left: 188px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 190%;
        height: 31px;
        line-height: 22px;
        padding-left: 6px;
        color: grey;

    }
    
    
}
@media screen and  (max-width: 1201px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 410px;
    }
    .homeFooter{
        position: relative;
        top: 655px;
    }
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 250px;
        background: #ff7200;
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 38%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 30px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 13px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 280px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 273px;
        height: 39px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 239px !important;
        top: 1.5px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
    }
    
    
    .footer_new_part{
        width: 100%;
        height: 310px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 40px;
        
        position: absolute;
        left: 10%;
        
        
    }
    
        #logo_footer{
        width: 133px;
        
        height: 52px;
        position:absolute;
        left: 6%;
        top: 1px;
        
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 190px;
        position: absolute;
        top: 95px; 
        right: 84%;
        
       
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: -1px;
    
        
    }
    .addressdiv{
        
        
        width: 22%;
        height: 180px;
        position: absolute;
        /* border: 3px solid red; */
        top: 96px;
        right: 62%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 123px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    .data_footer3{
        width: 26%;
        height: 272px;
        
        position: absolute;
        top: 27px;
        right: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 13%;
        height: 230px;
        /*  border: 1px solid cyan;  */
        
        position: absolute;
        top: 31px;
        right: 26%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 13%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 19px;
            right: 11%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 11px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 50%;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;   */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 130%;
        line-height: 22px;
       
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 148px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 130%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;

    }
    
    
    
    
}
@media screen and  (max-width: 1051px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 400px;
    }
    .homeFooter{
        position: relative;
        top: 622px;
    }
    .footer_part1{
        position: relative;
        bottom: -8px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 38%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 25px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 13px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 250px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 243px;
        height: 37px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none; 
        font-size: 10px;
        
    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        position: relative;
        top: 4px !important;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 212px !important;
        top: 2px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 313px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 40px;
        
        position: absolute;
        left: 8%;
        
        
    }
    
        #logo_footer{
        width: 128px;
        
        height: 48px;
        position:absolute;
        left: 4%;
        top: 0px;
        
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 180px;
        position: absolute;
        top: 89px; 
        right: 86%;
      
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 24%;
        height: 180px;
        position: absolute;
        /* border: 2px solid red; */
        top: 94px;
        right: 62%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    .data_footer3{
        width: 26%;
        height: 2669x;
        
        position: absolute;
        top: 27px;
        right: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 13%;
        height: 230px;
        /*  border: 1px solid cyan;  */
        position: absolute;
        top: 31px;
        right: 27%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 16%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 19px;
            right: 8%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 10px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 143%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;
        font-size: 10px;

    }
    
    
    
}
@media screen and  (max-width: 1001px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 400px;
    }
    .homeFooter{
        position: relative;
        top: 570px;
    }
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 38%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 25px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 13px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 250px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 243px;
        height: 37px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none; 
        font-size: 10px;
        
    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 212px !important;
        top: 2px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 313px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 40px;
        
        position: absolute;
        left: 8%;
        
        
    }
    
        #logo_footer{
        width: 128px;
        
        height: 48px;
        position:absolute;
        left: 4%;
        top: 0px;
        
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 180px;
        position: absolute;
        top: 89px; 
        right: 86%;
      
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 24%;
        height: 180px;
        position: absolute;
        /* border: 2px solid red; */
        top: 94px;
        right: 62%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    .data_footer3{
        width: 26%;
        height: 2669x;
        
        position: absolute;
        top: 27px;
        right: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 13%;
        height: 230px;
        /*  border: 1px solid cyan;  */
        position: absolute;
        top: 31px;
        right: 27%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 16%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 19px;
            right: 8%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 10px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 143%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;
        font-size: 10px;

    }
    
    
    
}
@media screen and  (max-width: 951px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 400px;
    }
    .homeFooter{
        position: relative;
        top: 190px;
    }
      
    
    
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 38%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 25px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 13px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 62%;
        bottom: 0px;
        width: 250px;
        background-color: #fff;
        height: 41px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 243px;
        height: 37px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none; 
        font-size: 10px;
        
    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 212px !important;
        top: 2px;
        
        width: 35px;
        height: 34px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 0px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 313px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 40px;
        
        position: absolute;
        left: 8%;
        
        
    }
    
        #logo_footer{
        width: 128px;
        
        height: 48px;
        position:absolute;
        left: 4%;
        top: 0px;
        
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 180px;
        position: absolute;
        top: 89px; 
        right: 86%;
      
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 24%;
        height: 180px;
        position: absolute;
        /* border: 2px solid red; */
        top: 94px;
        right: 62%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    .data_footer3{
        width: 26%;
        height: 2669x;
        
        position: absolute;
        top: 27px;
        right: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 13%;
        height: 230px;
        /*  border: 1px solid cyan;  */
        position: absolute;
        top: 31px;
        right: 27%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 16%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 19px;
            right: 8%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 10px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 40%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 143%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;
        font-size: 10px;

    }
    
    
    
}
@media screen and  (max-width: 801px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 440px;
       
    }
    
    
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 42%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 20px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 55%;
        bottom: 0px;
        width: 220px;
        background-color: #fff;
        height: 38px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 213px;
        height: 36px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 182px !important;
        top: 1px;
        
        width: 35px;
        height: 32px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: -0.4px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 520px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
        
        position: absolute;
        left: 9%;
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 7%;
        top: 0px;
       
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 170px;
        position: absolute;
        top: 89px; 
        right: 84%;
      
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 34%;
        height: 180px;
        position: absolute;
        
        top: 95px;
        right: 49%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    
    
    .data_footer3{
        width: 26%;
        height: 265px;
        /*  border: 1px solid greenyellow; */
        position: absolute;
        top: 20px;
        right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 55%;
        height: 230px;
        /*  border: 1px solid cyan;  */
      
        position: absolute;
        top: 299px;
        right: 33%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 25%;
        height: 220px;
         
            position: absolute;
            top: 288px;
            right: 6%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 9px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 55%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }

    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 143%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;
        font-size: 10px;

    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    
    
    
}
@media screen and  (max-width: 701px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 440px;
    }
    .homeFooter{
        position: relative;
        top: -200px;
    }
    
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 42%;
        height: 120px;
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 20px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 55%;
        bottom: 0px;
        width: 220px;
        background-color: #fff;
        height: 38px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 213px;
        height: 36px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        position: absolute;
        left: 2px;    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        position: relative;
        left: 5px !important;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 182px !important;
        top: 1px;
        
        width: 35px;
        height: 32px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: -0.4px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 520px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
        
        position: absolute;
        left: 9%;
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 7%;
        top: 0px;
       
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 170px;
        position: absolute;
        top: 89px; 
        right: 84%;
      
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 34%;
        height: 180px;
        position: absolute;
        
        top: 95px;
        right: 49%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    
    
    .data_footer3{
        width: 26%;
        height: 265px;
        /*  border: 1px solid greenyellow; */
        position: absolute;
        top: 20px;
        right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 12px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 55%;
        height: 230px;
        /*  border: 1px solid cyan;  */
      
        position: absolute;
        top: 299px;
        right: 33%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 12px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 25%;
        height: 220px;
         
            position: absolute;
            top: 288px;
            right: 6%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 13px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 9px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 55%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }

    #dropdown_products{
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 143%;
        height: 24px;
        line-height: 25px;
        padding-left: 6px;
        color: grey;
        font-size: 10px;

    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    
    
    
}
@media screen and  (max-width: 622px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 410px;
    }
    .homeFooter{
        position: relative;
        top: 510px;
    }
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 220px;
        background: #ff7200;
        
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 90px;
        width: 41%;
        height: 120px;
        left: 6%;
        z-index: 10;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 17px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 11px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 52%;
        bottom: 0px;
        width: 200px;
        background-color: #fff;
        height: 36px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
        border: none;
    
    }
    .email_1 #text_field1{
        width: 193px;
        height: 33px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 162px !important;
        top: 2px;
        
        width: 35px;
        height: 30px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 1px;
    }
  
    .footer_new_part{
        width: 100%;
        height: 540px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
        position: absolute;
        left: 5%;
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 7%;
        top: 0px;
       
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 170px;
        position: absolute;
        top: 89px; 
        right: 88%;
        
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 27px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 42%;
        height: 180px;
        position: absolute;
        
        top: 95px;
        right: 43%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 11px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 11px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    .data_footer3{
        width: 26%;
        height: 260px;
        /*  border: 1px solid greenyellow; */
        position: absolute;
        top: 30px;
        right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 11px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 55%;
        height: 230px;
        /*  border: 1px solid cyan;  */
    
        position: absolute;
        top: 310px;
        right: 37%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 11px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 11px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 23%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 297px;
            right: 7.9%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 9px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }
    /*  */

    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 55%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    
    
    
    
}
@media screen and  (max-width: 521px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
        
       
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 610px;
    }
    .homeFooter{
        position: relative;
        top: 446px;
    }
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 196px;
        background: #ff7200;
        overflow: hidden;
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 70px;
        width: 43%;
        height: 110px;
        left: 6%;
        
       
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 19px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 11px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 54%;
        bottom: 0px;
        width: 170px;
        background-color: #fff;
        height: 31px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 163px;
        height: 26px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 138px !important;
        top: 1px;
        
        width: 29px;
        height: 25px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: -0.2px;
        
    }
    
    .footer_new_part{
        width: 100%;
        height: 540px;
        /*   background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
        
        justify-content: space-evenly;
        
        
        
        
    }
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
      
        position: absolute;
        left: 4%;
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 6%;
        top: 0px;
       
        
    }
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 260px;
        position: absolute;
        top: 89px; 
        right: 88%;
        
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 22px;
        margin-top: 2px;
    
        
    }
    .addressdiv{
        
        
        width: 42%;
        height: 180px;
        position: absolute;
        
        top: 95px;
        right: 42%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
           
    
    
    
    .data_footer3{
        width: 29%;
        height: 254px;
         /* border: 1px solid greenyellow;  */
        position: absolute;
        top: 32px;
        right: 4%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
       
       font-weight: 300;
        right: 56px;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 25%;
        height: 250px;
          /* border: 1px solid cyan;   */
    
        position: absolute;
        top: 304px;
        right: 67%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 29%;
        height: 220px;
        /*  border: 1px solid cyan;  */
            position: absolute;
            top: 292px;
            right: 4%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 8px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 65%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
       
       
       
      

    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    ::placeholder{
        padding-left: 0px;
        font-size: 9px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    
    
    
}
@media screen and  (max-width: 425px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 410px;
    }
    .homeFooter{
        position: relative;
        top: 359px;
    }
    
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 186px;
        background: #ff7200;
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 70px;
        width: 43%;
        height: 106px;
        left: 6%;
       
       
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 17px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 54%;
        bottom: 0px;
        width: 150px;
        background-color: #fff;
        height: 30px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
      
    
    }
    .email_1 #text_field1{
        width: 143px;
        height: 26px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 119px !important;
        top: 1.5px;
        
        width: 27px;
        height: 24px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 1px;
    }
    
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
        
        position: absolute;
        
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 4%;
        top:  0px;
       
        
    }
   
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 170px;
        position: absolute;
        top: 80px; 
        right: 88%;
        
        
        
    }
                    
    
    
    
    .data_footer_1 p{
        font-size: 13px;
        
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
        
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 139px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        position: relative;
        
        
        
            
    }
    
    #location{
        width: 19px;
        margin-top: 1px;
    
        
    }
    .addressdiv{
        
        
        width: 47%;
        height: 180px;
        position: absolute;
        
        top: 85px;
        right: 38%;
        display: flex;
        flex-direction: column;
        
        
        
    }
    
    
    #addresss{
        color: #fff;
        
        text-decoration: none;
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 66px;
       font-weight: 300;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 120px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
        
    
    
    
    .data_footer3{
        width: 28%;
        height: 254px;
         /* border: 1px solid greenyellow;  */
        position: absolute;
        top: 26px;
        right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer3 p{ 
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        left: -57px;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 25%;
        height: 240px;
        /*  border: 1px solid cyan;  */
       
        position: absolute;
        top: 309px;
        right:67%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
    }
    
    .data_footer4 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 29%;
        height: 220px;
      /* border: 1px solid cyan;   */
        position: absolute;
        top: 298px;
        right: 6%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
       
        
        
    }
    .social{
        width: 120px;
        
        
        display: flex;
        justify-content: space-around;
        position: relative;
        
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
        /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
        
        font-size: 9px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
        
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 70%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
    }

    ::placeholder{
        font-size: 9px;
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    
    
}
@media screen and  (max-width: 375px){

    *{
        padding:0;
        margin: 0;
        box-sizing: border-box;
    }
    html {
        padding: 0px;
        margin: 0px;
        width: 100vw;
    }
    
    body {
        width: 100%;
    }
    
    .footer_main{
        position: relative;
        width: 100%;
        height: 410px;
    }
    .homeFooter{
        position: relative;
        top: 348px;
    }
    .footer_part1{
        position: relative;
        bottom: 10px;
        width: 100%;
        height: 186px;
        background: #ff7200;
        clip-path: polygon(48% 0%, 100% 28%, 100% 100%, 0 100%, 0 20%);
    }
    
    .footer_heading1 {
        
        position: relative;
        top: 70px;
        width: 43%;
        height: 100px;
       
        left: 6%;
       
       
        
    }  
    
    .footer_heading1 h1{ 
        color: #fff;
        font-size: 17px;
        overflow: hidden;
         font-family: 'Montserrat', sans-serif;
       font-weight: 700;
        
    }
    
    .footer_heading1 p{
        float: left;
        margin-top: 5px;
        color: #fff;
        
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    .email_1{
        position: relative;
        left: 54%;
        bottom: 0px;
        width: 150px;
        background-color: #fff;
        height: 30px;
        box-shadow: 2px 2px 5px black;
        border-radius: 9px;
    
    }
    .email_1 #text_field1{
        width: 143px;
        height: 25px;
        background: transparent;
        border: 0px solid;
        border-style: none;
        outline: none;
        
    
    }
    ::placeholder{
        color: black !important;
        font-weight: 300;
        font-size: 12px;
        padding-left: 20px;
         font-family: 'Montserrat', sans-serif;
    }

    
    
    
    .email_1 button{
        position: absolute;
        left: 120px !important;
        top: 1.2px;
        
        width: 27px;
        height: 24px;
        border-radius: 7px;
        background: #ff7200;
        border: none;
        cursor: pointer;
        box-shadow: 2px 2px 5px grey;
    }
    
    #arrow2{
        position: relative;
        left: 0%;
        bottom: 1px;
    }

    .footer_new_part{
        width: 100%;
        height: 546px;
      /*background-color:#1c1c1c;  */
        background-color: black;
        position: relative;
        bottom: 10px;
        display: flex;
      
      justify-content: space-evenly;
      
      
        
        
    }
    .footer_new_part .footer_logo{
        width: 200px;  
        height: 30px;
      
        position: absolute;
        left: 8%;
       
        
    }
    
        #logo_footer{
        width: 118px;
        
        height: 48px;
        position:absolute;
        left: 4%;
        top: -3px;
       
        
    }
    
    
    .data_footer1{
        /*  border: 1px solid yellow;  */
        width: 5%;
        height: 170px;
        position: absolute;
        top: 80px; 
        right: 85%;
       
      
    }
                   
    
    
    
    .data_footer_1 p{
        font-size: 13px;
       
       font-weight: 300;
        color:white;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer_1 #address{
       
        font-size: 12px;
         font-family: 'Montserrat', sans-serif;
        
    }
    
    .location_all{
        width: 32px;
        height: 143px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      
        position: relative;
        
        
       
          
    }
    
    #location{
        width: 22px;
       margin-top: 2px;
    
      
    }
    .addressdiv{
        
       
        width: 42%;
        height: 180px;
        position: absolute;
        /* border: 1px solid red; */
        top: 84px;
        right: 38%;
        display: flex;
        flex-direction: column;
       
       
       
    }
    
    
    #addresss{
        color: #fff;
       
        text-decoration: none;
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    #addresss:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    #addresss_1{
        color: #fff;
        position: absolute;
        top: 69px;
       font-weight: 300;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    #addresss_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    #addresss_2{
        color: #fff;
        position: absolute;
        top: 124px;
       font-weight: 300; 
        text-decoration: none;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    #addresss_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
       
    
    
    
    
    .data_footer3{
        width: 26%;
        height: 254px;
       /*  border: 1px solid greenyellow; */
       position: absolute;
       top: 26px;
       right: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
       
    }
    
    .data_footer3 p{ 
       
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1{
        color: white;
        text-decoration: none;
      
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_1:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_2{
        color: white;
        text-decoration: none;
       
       font-weight: 300;
        left: -57px;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_2:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_3{
        color: white;
        text-decoration: none;
       
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    }
    .data_footer3 .data_footer3_3:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer3 .data_footer3_4{
        color: white;
        
       font-weight: 300;
        right: 56px;
        font-size: 10px;
        text-decoration: none;
         font-family: 'Montserrat', sans-serif;
    }
    
    .data_footer3 .data_footer3_4:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    .data_footer4{
        width: 55%;
        height: 247px;
       /*  border: 1px solid cyan;  */
    
        position: absolute;
        top: 309px;
        right: 34%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      
       
    }
    
    .data_footer4 p{
      
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
        
    
    
    }
    
    .data_footer4 .data_footer4_one{
        color: white;
        text-decoration: none;
        
        font-size: 10px;
       font-weight: 300;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    .data_footer4 .data_footer4_one:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    .data_footer4 .data_footer4_two{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_two:hover{
        color: #ff7200;  
        text-decoration: underline;
    }
    
    .data_footer4 .data_footer4_three{
        color: white;
        text-decoration: none;
        
       font-weight: 300;
        font-size: 10px;
         font-family: 'Montserrat', sans-serif;
    
    }
    
    .data_footer4 .data_footer4_three:hover{
        color: #ff7200;
        text-decoration: underline;
    }
    
    
    
    .data_footer5{
        width: 29%;
        height: 220px;
        /*  border: 1px solid cyan;  */
         position: absolute;
         top: 298px;
         right:8%;
         display: flex;
         flex-direction: column;
         justify-content: space-evenly;
       
    }
    .social{
        width: 120px;
      
        
        display: flex;
        justify-content: space-around;
        position: relative;
      
        
    }
    .data_footer5 p{
        
        color:white;
        font-size: 12px;
        font-weight: bold;
         font-family: 'Montserrat', sans-serif;
    }
    
    
    
    .last_foot{
        background-color:#1a1a1a;
       /*  border: 1px solid black; */
        position: relative;
        top: -10px;
        
        width: 100%;
        height: 43px;    
        text-align: center;
    }
    .last_foot p{
        color: white;
     
        font-size: 9px;
        letter-spacing: 2px;
        position: relative;
        top: 13px;
      
         font-family: 'Montserrat', sans-serif;   
        
    }
    
    #root{
        height: 0px;
    }

    /*  */
    
    
    .active_pop_up{
        overflow-y: hidden;
    }
    .pop_up_outer, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 6 !important;
    }
    .overlay{
        background: rgba(49,49,49,0.8);
    }
    .form_div{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        width: 80%;
       height: 500px;
       z-index: 10 !important;
       
    }

    .form_div h1{
        color: #ff7200;
        text-align: center;
        font-size: 33px;
        font-weight: 600;
        padding-top: 2px;

    }
   

    /*  */

    .form_div form{
        width: 210px;
        height: 420px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;  
        /* border: 1px solid red;     */
    }

    .form_div label{
        color: #ff7200;
        font-size: 12px;
        font-weight: 500;
        
       
    }
    
    .form_div input{
        margin-top: 10px;
        border: 1px solid silver;
        border-radius: 6px;
        width: 120%;
        line-height: 22px;
       
    }
    ::placeholder{
        color: silver;
    }
    
    
    
    #footer_contact_btn{
        width: 35%;
        margin-top: 13px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
       
        font-weight: bold;
     
    }
    .close_pop_up{
        width: 35%;
        margin-top: 13px;
        position: absolute;
        left: 128px;
        border: 1px solid silver;
        border-radius: 6px;
        line-height: 29px;
        background-color: #ff7200;
        color: white;
        font-size: 12px;
    }

    ::placeholder{
        font-size: 9px;
        padding-left: 0px;
    }
    .footer_contact{
        padding-left: 10px;
    }
    

    #plane{
        background-color: #ff7200;
        color: #fff;
    }

    
    
}
    
    
    
    
    
    

    
    
    
    
        
  
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    







    


    
     


