


@media only screen and (min-width: 2001px){

    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    
    html,body{
        width: 100%;
        font-family: 'Montserrat', sans-serif;
    
    }
    .hide{display: none;}
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
        z-index: 4;
    }
    .advisoryFooter{
        width: 100%;
        position: absolute;
        top: 1022px;
        z-index: 11;
    }
    .Advisory_main .Advisory_heading{
        
        
        width: 100%;
        height: 70px;
        position: relative;
        top: 19px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
        
        font-size: 69px;
        font-weight: 900;
        letter-spacing: 0px ! important;
      
        
        
        height: 180px;
    }
  
.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    clip-path: polygon(0 6%, 100% 19%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
    
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    background-color: rgb(15, 15, 15);
   
  
}
.cover{
    width: 1250px;
    height: 72%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
   
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 320px;
    height: 79% !important;
    /* background-color: blueviolet;        */
    display: flex;
    position: absolute;
    top: 60px;
    right: 95px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 260px !important;
    height: 240px;
    position: relative;
    top: 0px;
    left: 33px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 695px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 55px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 42px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 15px;
    letter-spacing: 0.4px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 70px;
    color: white;
    line-height: 20px;
    padding-left: 7px;
}



.swiper-button-next{
    color: #ff7200 !important; 
    position: absolute;
    top: 400px !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 400px !important;
    z-index: 10;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 488px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 520px;
    overflow: hidden;
    z-index: -1;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 1250px;
    height: 370px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
    /* background-color: seagreen; */
   
}


.inner_div_1_1{
    width: 320px;
    height: 79%;
   /* background-color: blueviolet;      */
    display: flex;
    position: absolute;
    top: 62px;
    left:40px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    /* background-color: aqua; */
   
}

.inner_div_1_1 img{
    width: 260px;
    height: 240px;
    position: relative;
    top: 0;
    left: 23px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 675px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 110px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 42px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 15px;
    font-weight: 300;
    text-align: justify;
    padding-top: 50px;
    color: white;
    padding-top: 57px;
    line-height: 20px;
    letter-spacing: 0.4px;
}



.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
.footer_advisorry{
    position: absolute;
    top: 1020px;
    width: 100%;
    z-index: 10;
}
.footer_advisorry_new{
    position: absolute;
    top: 740px;
    width: 100%;
    z-index: 10;
}

}
@media only screen and (max-width: 2001px){
    
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,body{
    width: 100%;
   font-family: 'Montserrat', sans-serif;

}

.Advisory_main{
    background-color:black;
/* border: 1px solid red; */
    width: 100%;
    height: 260px;
    position: absolute; 
    top: 69px;
    z-index: 4;
    
}
.advisoryFooter{
    width: 100%;
    position: absolute;
    top: 1022px;
    z-index: 11;
}
.Advisory_main .Advisory_heading{
    
  
    width: 100%;
    height: 70px;
    position: relative;
    top: 36px;
    display: flex;
    justify-content: center;

}
.Advisory_main h1{
    
    text-align: center;
    color: #ff7200;
    font-family: 'Fjalla One', sans-serif;
    font:smaller;
   
    font-size: 59px;
    font-weight: 900;
 
   letter-spacing: 0px ! important;
    
    height: 180px;
}

.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    clip-path: polygon(0 6%, 100% 19%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
    
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    background-color: rgb(15, 15, 15);
   
  
}
.cover{
    width: 1250px;
    height: 72%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 320px;
    height: 79% !important;
    /* background-color: blueviolet;        */
    display: flex;
    position: absolute;
    top: 60px;
    right: 105px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 260px !important;
    height: 240px;
    position: relative;
    top: 0px;
    left: 33px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 675px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 55px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 70px;
    color: white;
    line-height: 20px;
    padding-left: 7px;
}



.swiper-button-next{
   
    color: #ff7200 !important; 
    position: absolute;
    top: 400px !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 400px !important;
    z-index: 10;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 520px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 520px;
    overflow: hidden;
    z-index: -1;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 1250px;
    height: 370px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;   
}
.hide{
    display: none;
}

.inner_div_1_1{
    width: 320px;
    height: 79%;
   /* background-color: blueviolet;      */
    display: flex;
    position: absolute;
    top: 74px;
    left:40px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    /* background-color: aqua; */
   
}

.inner_div_1_1 img{
    width: 260px;
    height: 240px;
    position: relative;
    top: 0;
    left: 23px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 675px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 110px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    padding-top: 70px;
    color: white;
    padding-left: 7px;
    line-height: 20px;
    letter-spacing: 0.4px;
}



.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */  
    z-index: 10;
    display: none !important;

}
.footer_advisorry{
    position: absolute;
    top: 1020px;
    width: 100%;
    z-index: 11110;
}

}
@media only screen and (max-width: 1501px){
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,body{
    width: 100%;
   font-family: 'Montserrat', sans-serif;

}

.Advisory_main{
    background-color:black;
/* border: 1px solid red; */
    width: 100%;
    height: 1020px;
    position: absolute; 
    top: 69px;
}
.advisoryFooter{
    width: 100%;
    position: absolute;
    top: 1025px;
    z-index: 11;
}
.Advisory_main .Advisory_heading{
    
  
    width: 100%;
    height: 70px;
    position: relative;
    top: 29px;
    display: flex;
    justify-content: center;

}
.Advisory_main h1{
    
    text-align: center;
    color: #ff7200;
    font-family: 'Fjalla One', sans-serif;
  
    letter-spacing: 0px ! important;
    font-size: 53px;
    font-weight: 900;
   
  
    
    height: 180px;
}

.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    clip-path: polygon(0 5%, 100% 18%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
    
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    background-color: rgb(15, 15, 15);
   
  
}
.cover{
    width: 1050px;
    height: 72%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
    /* background-color: rosybrown; */
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 270px;
    height: 69% !important;
     /* background-color: blueviolet;         */
    display: flex;
    position: absolute;
    top: 60px;
    right: 25px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 220px !important;
    height: 200px;
    position: relative;
    top: 0px;
    left: 33px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 675px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 55px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 13px;
    letter-spacing: 0.4px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 70px;
    color: white;
    line-height: 20px;
    padding-left: 7px;
}



.swiper-button-next{
   
    color: #ff7200 !important; 
    position: absolute;
    top: 400px !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 400px !important;
    z-index: 10;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 488px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 520px;
    overflow: hidden;
    z-index: -1;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 1050px;
    height: 370px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
    /* background-color: royalblue; */
   
}


.inner_div_1_1{
    width: 270px;
    height: 69%;
    /* background-color: blueviolet;       */
    display: flex;
    position: absolute;
    top: 72px;
    left:0px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    /* background-color: aqua; */
   
}

.inner_div_1_1 img{
    width: 220px;
    height: 210px;
    position: relative;
    top: 0;
    left: 23px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 675px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 50px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 13px;
    font-weight: 300;
    text-align: justify;
    padding-top: 60px;
    color: white;
    padding-left: 7px;
    line-height: 20px;
    letter-spacing: 0.4px;
}



.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
.advisory_foooter{
    position: relative;
    top: 320px;
}
}
@media only screen and (max-width: 1201px){
  
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,body{
    width: 100%;
   font-family: 'Montserrat', sans-serif;

}
.advisoryFooter{
    position: absolute;
    width: 100%;
    top: 965px;
}
.Advisory_main{
    background-color:black;
/* border: 1px solid red; */
    width: 100%;
    height: 260px;
    position: absolute; 
    top: 69px;
   
       
    
    
}

.Advisory_main .Advisory_heading{
    
  
    width: 100%;
    height: 40px;
    position: relative;
    top: 29px;
    display: flex;
    justify-content: center;

}
.Advisory_main h1{
    
    text-align: center;
    color: #ff7200;
    font-family: 'Fjalla One', sans-serif;
   
    position: relative;
    top: -10px;
   
    font-size: 48px;
    font-weight: 900;    
    height: 180px;
}

.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
    
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    background-color: rgb(15, 15, 15);
   
  
}
.cover{
    width: 850px;
    height: 72%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
     /* background-color: rosybrown;  */
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 220px;
    height: 62% !important;
      /* background-color: blueviolet;          */
    display: flex;
    position: absolute;
    top: 60px;
    right: 5px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 200px !important;
    height: 200px;
    position: relative;
    top: -5px;
    left: 0px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 575px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 15px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 13px;
    letter-spacing: 0.4px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 54px;
    color: white;
    line-height: 20px;
    padding-left: 7px;
}



.swiper-button-next{
   
    color: #ff7200 !important; 
    position: absolute;
    top: 400px !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 400px !important;
    z-index: 10;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 438px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 520px;
    overflow: hidden;
    z-index: -1;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 850px;
    height: 370px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
     /* background-color: royalblue;  */
   
}


.inner_div_1_1{
    width: 220px;
    height: 62%;
     /* background-color: blueviolet;        */
    display: flex;
    position: absolute;
    top: 71px;
    left:0px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    /* background-color: aqua; */
   
}

.inner_div_1_1 img{
    width: 200px;
    height: 190px;
    position: relative;
    top: 0;
    left: 3px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 575px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 20px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 32px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 13px;
    font-weight: 300;
    text-align: justify;
    padding-top: 56px;
    color: white;
    padding-left: 7px;
    line-height: 20px;
    letter-spacing: 0.4px;
}



.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
.footer_advisorry{
    position: absolute;
    top: 950px;
    width: 100%;
    z-index: 10111;
}
}
@media only screen and (max-width: 952px){
  
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    
    html,body{
        width: 100%;
       font-family: 'Montserrat', sans-serif;
    
    }
    .advisoryFooter{
        width: 100%;
        position: absolute;
        top: 950px;
    }
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
       
           
        
        
    }
    
    .Advisory_main .Advisory_heading{
        
      
        width: 100%;
        height: 40px;
        position: relative;
        top: 29px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
       
        position: relative;
        top: -10px;
       
        font-size: 48px;
        font-weight: 900;    
        height: 180px;
    }
    
    .Advisory_submain{
        width: 100%;
        height: 515px !important;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 80px;
        clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
        z-index: -1;
        overflow: hidden;
        
    }
    /*  */
    
    .Advisory_submain .swiper-container{
        width: 100% !important; 
        height: 550px !important;
       
        position: relative;
        top: 20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
         /* background-color: aqua;  */
    
    }
    
    .Advisory_submain .swiper-slide{
        width: 100% !important;
        height: 500px;
        position: relative;
        top: 0px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
        background-color: rgb(15, 15, 15);
       
      
    }
    .cover{
        width: 850px;
        height: 72%;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: -10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
         /* background-color: rosybrown;  */
        
    }
    
    .outer_div_advisory{
        width: 100%;
        height: 100%;
        /* background-color: blue; */
    }
    
    .inner_div_1{
        width: 220px;
        height: 62% !important;
          /* background-color: blueviolet;          */
        display: flex;
        position: absolute;
        top: 60px;
        right: 5px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
      /* border: 1px solid red; */
    }
    
    .inner_div_1 img{
        width: 200px !important;
        height: 200px;
        position: relative;
        top: -5px;
        left: 0px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2{
        width: 575px;
        height: 99% !important;
        /* background-color: green; */
        position: absolute;
        top: 10px;
        left: 15px;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2 h3{
        font-size: 32px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2 p{
        font-size: 13px;
        letter-spacing: 0.4px;
        font-weight: lighter;
        text-align: justify;
        padding-top: 54px;
        color: white;
        line-height: 20px;
        padding-left: 7px;
    }
    
    
    
    .swiper-button-next{
       
        color: #ff7200 !important; 
        position: absolute;
        top: 400px !important;
        z-index: 10;
    } 
    
    
    .swiper-button-prev { 
        color: #ff7200 !important;
        position: absolute;
        top: 400px !important;
        z-index: 10;
    
    
    
    } 
    
    
    .Advisory_submain .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    swiper-pagination-current{
        background:white;
    }
      .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;
       
      
       
      }
    
     .swiper-pagination-bullet {
        opacity: 1;
    
        background-color: white;
    }
    /*  */
    .Advisory_submain_2{
        width: 100%;
        height: 438px;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 520px;
        overflow: hidden;
        z-index: -1;
        
    }
    
    
    .Advisory_submain_2 .swiper-container{
        width: 100% !important; 
        height: 410px;
        background-color: rgb(15, 15, 15);
        position: relative;
        top: -20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    
    }
    
    .Advisory_submain_2 .swiper-slide{
        width: 100% !important;
        height: 410px;
        position: relative;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
      
      
    }
    .cover_1{
        width: 850px;
        height: 370px;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
         /* background-color: royalblue;  */
       
    }
    
    
    .inner_div_1_1{
        width: 220px;
        height: 62%;
         /* background-color: blueviolet;        */
        display: flex;
        position: absolute;
        top: 71px;
        left:0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
        /* background-color: aqua; */
       
    }
    
    .inner_div_1_1 img{
        width: 200px;
        height: 190px;
        position: relative;
        top: 0;
        left: 3px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2_1{
        width: 575px;
        height: 92%;
        /* background-color: green; */
        position: absolute;
        top: 30px;
        right: 20px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2_1 h3{
        font-size: 32px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2_1 p{
        font-size: 13px;
        font-weight: 300;
        text-align: justify;
        padding-top: 56px;
        color: white;
        padding-left: 7px;
        line-height: 20px;
        letter-spacing: 0.4px;
    }
    
    
    
    .Advisory_submain_2 .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    .footer_advisorry{
        position: absolute;
        top: 950px;
        width: 100%;
        z-index: 10111;
    }
    }
@media only screen and (max-width: 901px){
   
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,body{
    width: 100%;
   font-family: 'Montserrat', sans-serif;

}

.Advisory_main{
    background-color:black;
/* border: 1px solid red; */
    width: 100%;
    height: 260px;
    position: absolute; 
    top: 69px;
    /* background-color: pink; */
  
       
    
    
}

.Advisory_main .Advisory_heading{
    
  
    width: 100%;
    height: 70px;
    position: relative;
    top: 37px;
    display: flex;
    justify-content: center;

}
.Advisory_main h1{
    
    text-align: center;
    color: #ff7200;
    font-family: 'Fjalla One', sans-serif;
    font-size: 44px;
    font-weight: 900;
    height: 180px;
}


.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
    
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    background-color: rgb(15, 15, 15);
   
  
}
.cover{
    width: 660px;
    height: 72%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
   
      
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 190px;
    height: 53% !important;
      
    display: flex;
    position: absolute;
    top: 60px;
    right: 0px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 170px !important;
    height: 166px;
    position: absolute;
    top: 2px;
    left: 2px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 455px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 0px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 28px;
    position: absolute;
    top: 0p;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 12px;
    letter-spacing: 0px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 54px;
    color: white;
    line-height: 18px;
    padding-left: 7px;
}



.swiper-button-next{
   
    color: #ff7200 !important; 
    position: absolute;
    top: 417px !important;
    display: block !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 417px !important;
    z-index: 10;
    display: block !important;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 385px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 560px;
    overflow: visible;
    z-index: -11;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 660px;
    height: 370px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
      
   
}


.inner_div_1_1{
    width: 190px;
    height: 53%;
     /* background-color: blueviolet;        */
    display: flex;
    position: absolute;
    top: 72px;
    left:0px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    
   
}

.inner_div_1_1 img{
    width: 170px;
    height: 165px;
    position: relative;
    top: 0;
    left: 3px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 455px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 28px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 12px;
    font-weight: 300;
    text-align: justify;
    padding-top: 58px;
    color: white;
    padding-left: 7px;
    line-height: 17px;
    letter-spacing: 0px;
}



.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
}
@media only screen and (max-width: 801px){
   
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    
    html,body{
        width: 100%;
       font-family: 'Montserrat', sans-serif;
    
    }
    
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
      
      
           
        
        
    }
    
    .Advisory_main .Advisory_heading{
        
      
        width: 100%;
        height: 70px;
        position: relative;
        top: 37px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
        font-size: 44px;
        font-weight: 900;
        height: 180px;
    }
    
    
    .Advisory_submain{
        width: 100%;
        height: 505px !important;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 80px;
        clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
        z-index: -1;
        overflow: hidden;
        /* background-color: pink; */
        
    }
    /*  */
    
    .Advisory_submain .swiper-container{
        width: 100% !important; 
        height: 550px !important;
       
        position: relative;
        top: 20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
         /* background-color: aqua;  */
         z-index: -2;
    
    }
    
    .Advisory_submain .swiper-slide{
        width: 100% !important;
        height: 500px !important;
        position: relative;
        top: 0px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
        /* background-color: green; */
       
      
    }
    .cover{
        width: 660px;
        height: 72%;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: -10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
       
          
        
    }
    
    .outer_div_advisory{
        width: 100%;
        height: 100%;
        /* background-color: blue; */
    }
    
    .inner_div_1{
        width: 190px;
        height: 53% !important;
          
        display: flex;
        position: absolute;
        top: 60px;
        right: 0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
      /* border: 1px solid red; */
    }
    
    .inner_div_1 img{
        width: 170px !important;
        height: 166px;
        position: absolute;
        top: 2px;
        left: 2px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2{
        width: 455px;
        height: 99% !important;
        /* background-color: green; */
        position: absolute;
        top: 10px;
        left: 0px;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2 h3{
        font-size: 28px;
        position: absolute;
        top: 0p;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2 p{
        font-size: 12px;
        letter-spacing: 0px;
        font-weight: lighter;
        text-align: justify;
        padding-top: 54px;
        color: white;
        line-height: 18px;
        padding-left: 7px;
    }
    
    
    
    .swiper-button-next{
       
        color: #ff7200 !important; 
        position: absolute;
        top: 417px !important;
        display: block !important;
        z-index: 10;
    } 
    
    
    .swiper-button-prev { 
        color: #ff7200 !important;
        position: absolute;
        top: 417px !important;
        z-index: 10;
        display: block !important;
    
    
    
    } 
    
    
    .Advisory_submain .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    swiper-pagination-current{
        background:white;
    }
      .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;
       
      
       
      }
    
     .swiper-pagination-bullet {
        opacity: 1;
    
        background-color: white;
    }
    /*  */
    .Advisory_submain_2{
        width: 100%;
        height: 390px;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 560px;
        overflow: visible;
        z-index: -11;
        
    }
    
    
    .Advisory_submain_2 .swiper-container{
        width: 100% !important; 
        height: 410px;
        background-color: rgb(15, 15, 15);
        position: relative;
        top: -20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    
    }
    
    .Advisory_submain_2 .swiper-slide{
        width: 100% !important;
        height: 410px !important;
        position: relative;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
      
      
    }
    .cover_1{
        width: 660px;
        height: 370px;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
          
       
    }
    
    
    .inner_div_1_1{
        width: 190px;
        height: 53%;
         /* background-color: blueviolet;        */
        display: flex;
        position: absolute;
        top: 72px;
        left:0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
        
       
    }
    
    .inner_div_1_1 img{
        width: 170px;
        height: 165px;
        position: relative;
        top: 0;
        left: 3px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2_1{
        width: 455px;
        height: 92%;
        /* background-color: green; */
        position: absolute;
        top: 30px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2_1 h3{
        font-size: 28px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2_1 p{
        font-size: 12px;
        font-weight: 300;
        text-align: justify;
        padding-top: 58px;
        color: white;
        padding-left: 7px;
        line-height: 17px;
        letter-spacing: 0px;
    }
    
    
    
    .Advisory_submain_2 .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
}
@media only screen and (max-width: 701px){
    
    html,body{
        width: 100%;
       font-family: 'Montserrat', sans-serif;
    
    }
    .advisoryFooter{
        width: 100%;
        position: absolute;
        top: 920px;
    }
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
           
        
        
    }
    
    .Advisory_main .Advisory_heading{
        
      
        width: 100%;
        height: 70px;
        position: relative;
        top: 30px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
        font-size: 44px;
        font-weight: 900;
        
        height: 180px;
    }
    


    .Advisory_submain{
        width: 100%;
        height: 525px !important;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 80px;
        clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
        z-index: -1;
        overflow: hidden;
        
    }
    /*  */
    
    .Advisory_submain .swiper-container{
        width: 100% !important; 
        height: 550px !important;
       
        position: relative;
        top: 20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
         /* background-color: aqua;  */
    
    }
    
    .Advisory_submain .swiper-slide{
        width: 100% !important;
        height: 500px ;
        position: relative;
        top: 0px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
      
       
      
    }
    .cover{
        width: 500px;
        height: 55%;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: -55px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
    }
    
    .outer_div_advisory{
        width: 100%;
        height: 100%;
        /* background-color: blue; */
    }
    
    .inner_div_1{
        width: 150px;
        height: 55% !important;
        display: flex;
        position: absolute;
        top: 45px;
        right: 0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
    }
    
    .inner_div_1 img{
        width: 130px !important;
        height: 126px;
        position: relative;
        top: -2px;
        left: 2px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2{
        width: 325px;
        height: 90% !important;
        position: absolute;
        top: 10px;
        left: 0px;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2 h3{
        font-size: 23px;
        position: absolute;
        top: 0p;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
       
    
     }
    .inner_div_2 p{
        font-size: 11px;
        letter-spacing: 0px;
        font-weight: lighter;
        text-align: justify;
        padding-top: 44px;
        color: white;
        line-height: 16px;
        padding-left: 7px;
    }
    
    
    
    .swiper-button-next{
       
        color: #ff7200 !important; 
        position: absolute;
        top: 457px !important;
        z-index: 10;
    } 
    
    
    .swiper-button-prev { 
        color: #ff7200 !important;
        position: absolute;
        top: 457px !important;
        z-index: 10;
    
    
    
    } 
    
    
    .Advisory_submain .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    swiper-pagination-current{
        background:white;
    }
      .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;
       
      
       
      }
    
     .swiper-pagination-bullet {
        opacity: 1;
    
        background-color: white;
    }
    /*  */
    .Advisory_submain_2{
        width: 100%;
        height: 390px;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 520px;
        overflow: visible;
        z-index: -11;
        
    }
    
    
    .Advisory_submain_2 .swiper-container{
        width: 100% !important; 
        height: 410px;
        background-color: rgb(15, 15, 15);
        position: relative;
        top: -20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    
    }
    
    .Advisory_submain_2 .swiper-slide{
        width: 100% !important;
        height: 410px;
        position: relative;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
      
      
    }
    .cover_1{
        width: 500px;
        height: 250px;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 57px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
        /* background-color: salmon; */
          
       
    }
    
    
    .inner_div_1_1{
        width: 150px;
        height: 63%;
         /* background-color: blueviolet;        */
        display: flex;
        position: absolute;
        top: 68px;
        left:10px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
        /* background-color:red; */
       
    }
    
    .inner_div_1_1 img{
        width: 130px;
        height: 122px;
        position: relative;
        top: 0;
        left: 3px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2_1{
        width: 325px;
        height: 92%;
        /* background-color: green; */
        position: absolute;
        top: 30px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2_1 h3{
        font-size: 23px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2_1 p{
        font-size: 11px;
        font-weight: 300;
        text-align: justify;
        padding-top: 54px;
        color: white;
        padding-left: 7px;
        line-height: 16px;
        letter-spacing: 0px;
    }
    
    
    
    .Advisory_submain_2 .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
}  
@media only screen and (max-width: 521px){
    
    html,body{
        width: 100%;
       font-family: 'Montserrat', sans-serif;
    }
    .advisoryFooter{
        width: 100%;
        position: absolute;
        top: 900px;
    }
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
        
    }
    
    .Advisory_main .Advisory_heading{

        width: 100%;
        height: 70px;
        position: relative;
        top: 40px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        font-family: 'Fjalla One', sans-serif;
        font-size: 38px;
        font-weight: 900;
  
      
        
        height: 180px;
    }
    

    .Advisory_submain{
        width: 100%;
        height: 515px !important;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 80px;
        clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
        z-index: -1;
        overflow: hidden;
        
    }
    /*  */
    
    .Advisory_submain .swiper-container{
        width: 100% !important; 
        height: 550px !important;
       
        position: relative;
        top: 20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
         /* background-color: aqua;  */
    
    }
    
    .Advisory_submain .swiper-slide{
        width: 100% !important;
        height: 500px !important;
        position: relative;
        top: -40px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;  
         
       
      
    }
    .cover{
        width: 400px;
        height: 75%;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
         /* background-color: royalblue;  */
       
          
        
    }
    
    .outer_div_advisory{
        width: 100%;
        height: 100%;
        /* background-color: blue; */
    }
    
    .inner_div_1{
        width: 120px;
        height: 35% !important;
        /* background-color: saddlebrown;  */
        display: flex;
        position: absolute;
        top: 45px;
        right: 0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
      /* border: 1px solid red; */
    }
    
    .inner_div_1 img{
        width: 100px !important;
        height: 106px;
        position: relative;
        top: 0px;
        left: 7px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2{
        width: 265px;
        height: 99% !important;
        /* background-color: green; */
        position: absolute;
        top: 10px;
        left: 0px;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2 h3{
        font-size: 19px;
        position: absolute;
        top: 0p;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2 p{
        font-size: 10px;
        letter-spacing: 0px;
        font-weight: lighter;
        text-align: justify;
        padding-top: 44px;
        color: white;
        line-height: 14px;
        padding-left: 7px;
    }
    
    
    
    .swiper-button-next{
       
        color: #ff7200 !important; 
        position: absolute;
        top: 488px !important;
        z-index: 10;
    } 
    
    
    .swiper-button-prev { 
        color: #ff7200 !important;
        position: absolute;
        top: 488px !important;
        z-index: 10;
    
    
    
    } 
    
    
    .Advisory_submain .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    swiper-pagination-current{
        background:white;
    }
      .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;
      }
    
     .swiper-pagination-bullet {
        opacity: 1;
    
        background-color: white;
    }
    /*  */
    .Advisory_submain_2{
        width: 100%;
        height: 369px;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 520px;
        overflow: visible;
        z-index: -11;
        
    }
    
    
    .Advisory_submain_2 .swiper-container{
        width: 100% !important; 
        height: 410px;
        background-color: rgb(15, 15, 15);
        position: relative;
        top: -20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    
    }
    
    .Advisory_submain_2 .swiper-slide{
        width: 100% !important;
        height: 410px !important;
        position: relative;
        top: -30px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
        
      
      
    }
    .cover_1{
        width: 400px;
        height: 250px;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 45px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
         /* background-color: salmon;  */
          
       
    }
    
    
    .inner_div_1_1{
        width: 120px;
        height: 51% !important;
         /* background-color: blueviolet;        */
        display: flex;
        position: absolute;
        top: 64px;
        left:10px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
        
       
    }
    
    .inner_div_1_1 img{
        width: 100px;
        height: 95px;
        position: relative;
        top: 0;
        left: 3px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2_1{
        width: 265px;
        height: 92%;
        /* background-color: green; */
        position: absolute;
        top: 30px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2_1 h3{
        font-size: 19px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2_1 p{
        font-size: 10px;
        font-weight: 300;
        text-align: justify;
        padding-top: 47px;
        color: white;
        padding-left: 7px;
        line-height: 14px;
        letter-spacing: 0px;
    }
    
    
    
    .Advisory_submain_2 .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
}
@media only screen and (max-width: 426px){
    
    html,body{
        width: 100%;
       font-family: 'Montserrat', sans-serif;
    
    }
    
    .Advisory_main{
        background-color:black;
    /* border: 1px solid red; */
        width: 100%;
        height: 260px;
        position: absolute; 
        top: 69px;
       
           
        
        
    }
    
    .Advisory_main .Advisory_heading{
        
      
        width: 100%;
        height: 70px;
        position: relative;
        top: 43px;
        display: flex;
        justify-content: center;
    
    }
    .Advisory_main h1{
        
        text-align: center;
        color: #ff7200;
        letter-spacing: 0px ! important;
        position: relative;
        top: -10px;
        font-size: 34px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        
        height: 180px;
    }
    
    .Advisory_submain{
        width: 100%;
        height: 515px !important;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 80px;
        clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
        z-index: -1;
        overflow: hidden;
    }
    /*  */
    
    .Advisory_submain .swiper-container{
        width: 100% !important; 
        height: 550px !important;
       
        position: relative;
        top: 20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
         /* background-color: aqua;  */
    
    }
    
    .Advisory_submain .swiper-slide{
        width: 100% !important;
        height: 500px !important;
        position: relative;
        top: -40px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
       
    }
    .cover{
        width: 350px;
        height: 79%;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 10px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
          /* background-color: royalblue;  
        */
          
        
    }
    
    .outer_div_advisory{
        width: 100%;
        height: 100%;
        /* background-color: blue; */
    }
    
    .inner_div_1{
        width: 100px;
        height: 29% !important;
        /* background-color: saddlebrown;    */
        display: flex;
        position: absolute;
        top: 32px;
        right: 0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
      /* border: 1px solid red; */
      border-radius: none !important;
    }
    
    .inner_div_1 img{
        width: 90px !important;
        height: 86px;
        position: relative;
        top: 0px;
        left: 0px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2{
        width: 235px;
        height: 99% !important;
        /* background-color: green; */
        position: absolute;
        top: 10px;
        left: 0px;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2 h3{
        font-size: 16px;
        position: absolute;
        top: 0p;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2 p{
        font-size: 10px;
        letter-spacing: 0px;
        font-weight: lighter;
        text-align: justify;
        padding-top: 34px;
        color: white;
        line-height: 14px;
        padding-left: 7px;
    }
    
    
    
    .swiper-button-next{
       
        color: #ff7200 !important; 
        position: absolute;
        top: 492px !important;
        z-index: 10;
    } 
    
    
    .swiper-button-prev { 
        color: #ff7200 !important;
        position: absolute;
        top: 492px !important;
        z-index: 10;
    
    
    
    } 
    
    
    .Advisory_submain .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
    swiper-pagination-current{
        background:white;
    }
      .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #ff7200 !important;
       
      
       
      }
    
     .swiper-pagination-bullet {
        opacity: 1;
    
        background-color: white;
    }
    /*  */
    .Advisory_submain_2{
        width: 100%;
        height: 358px;
        background-color: rgb(15, 15, 15);
        position: absolute;
        top: 530px;
        overflow: visible;
        z-index: -11;
        
    }
    
    
    .Advisory_submain_2 .swiper-container{
        width: 100% !important; 
        height: 410px;
        background-color: rgb(15, 15, 15);
        position: relative;
        top: -20px;
        left: 0px;
        overflow: hidden;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    
    }
    
    .Advisory_submain_2 .swiper-slide{
        width: 100% !important;
        height: 410px;
        position: relative;
        top: -30px;
        right: 0px;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        /* background-color: beige; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-color:rgb(15, 15, 15);
        box-shadow: none;
        z-index: -1;
      
      
    }
    .cover_1{
        width: 350px;
        height: 250px;
        background-color:rgb(15, 15, 15);
        position: relative;
        top: 35px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        /* border: 2px solid red;  */
        cursor: grab;
        border-radius: 12px;
         /* background-color: salmon;   */
          
       
    }
    
    
    .inner_div_1_1{
        width: 108px;
        height: 45% !important;
         /* background-color: blueviolet;         */
        display: flex;
        position: absolute;
        top: 57px;
        left:0px !important;
        align-items: center;
        background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
        background-repeat: no-repeat;
        background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
        background-position: right bottom, right bottom, 4px 4px;
        
       
    }
    
    .inner_div_1_1 img{
        width: 90px !important;
        height: 87px;
        position: relative;
        top: 0;
        left: 3px;
        text-align: center;
       align-items: center;
       justify-content: center;
       background-clip: content-box;
       border-image-slice: 1;
       z-index: 2;
       
       
    }
    
    
    .inner_div_2_1{
        width: 240px;
        height: 92%;
        /* background-color: green; */
        position: absolute;
        top: 30px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        background-color: transparent ;
    }
    
    .inner_div_2_1 h3{
        font-size: 16px;
        position: absolute;
        top: 0px;
        left: 6px;
        color: #ff7200;
        text-align: center;
        align-items: center;
        justify-content: center;
    
     }
    .inner_div_2_1 p{
        font-size: 10px;
        font-weight: 300;
        text-align: justify;
        padding-top: 35px;
        color: white;
        padding-left: 7px;
        line-height: 14px;
        letter-spacing: 0px;
    }
    
    
    
    .Advisory_submain_2 .swiper-pagination {
        position: absolute;
        top: 420px;
        width: 90px;
        left: 50%;
        height: 30px;
        /* border: 2px solid red; */
        z-index: 10;
        display: none !important;
    
    }
}  
@media only screen and (max-width: 375px){
    
html,body{
    width: 100%;
   font-family: 'Montserrat', sans-serif;

}

.Advisory_main{
    background-color:black;
/* border: 1px solid red; */
    width: 100%;
    height: 260px;
    position: absolute; 
    top: 69px;
    z-index: 10;
      
    
    
}

.Advisory_main .Advisory_heading{
    
  
    width: 100%;
    height: 70px;
    position: relative;
    top: 42px;
    display: flex;
    justify-content: center;

}
.Advisory_main h1{
    
    text-align: center;
    color: #ff7200;
   position: relative;
   top: -10px;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0px ! important;
    font-family: 'Fjalla One', sans-serif;
    height: 180px;
}
 
.Advisory_submain{
    width: 100%;
    height: 515px !important;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 80px;
    clip-path: polygon(0 0, 100% 11%, 100% 100%, 0% 100%);
    z-index: -1;
    overflow: hidden;
}
/*  */

.Advisory_submain .swiper-container{
    width: 100% !important; 
    height: 550px !important;
   
    position: relative;
    top: 20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
     /* background-color: aqua;  */

}

.Advisory_submain .swiper-slide{
    width: 100% !important;
    height: 500px;
    position: relative;
    top: -40px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
    
   
  
}
.cover{
    width: 100%;
    height: 79%;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    
    
      
    
}

.outer_div_advisory{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.inner_div_1{
    width: 100px;
    height: 29% !important;
     /* background-color: saddlebrown;   */
    display: flex;
    position: absolute;
    top: 32px;
    right: 10px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
  /* border: 1px solid red; */
}

.inner_div_1 img{
    width: 90px !important;
    height: 86px;
    position: relative;
    top: 0px;
    left: 0px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2{
    width: 235px;
    height: 99% !important;
    /* background-color: green; */
    position: absolute;
    top: 10px;
    left: 10px;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2 h3{
    font-size: 15px;
    position: absolute;
    top: 0p;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2 p{
    font-size: 10px;
    letter-spacing: 0px;
    font-weight: lighter;
    text-align: justify;
    padding-top: 34px;
    color: white;
    line-height: 14px;
    padding-left: 7px;
}



.swiper-button-next{
   
    color: #ff7200 !important; 
    position: absolute;
    top: 499px !important;
    z-index: 10;
} 


.swiper-button-prev { 
    color: #ff7200 !important;
    position: absolute;
    top: 499px !important;
    z-index: 10;



} 


.Advisory_submain .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}
swiper-pagination-current{
    background:white;
}
  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #ff7200 !important;
   
  
   
  }

 .swiper-pagination-bullet {
    opacity: 1;

    background-color: white;
}
/*  */
.Advisory_submain_2{
    width: 100%;
    height: 350px;
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 530px;
    overflow: visible;
    z-index: -11;
    
}


.Advisory_submain_2 .swiper-container{
    width: 100% !important; 
    height: 410px;
    background-color: rgb(15, 15, 15);
    position: relative;
    top: -20px;
    left: 0px;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   

}

.Advisory_submain_2 .swiper-slide{
    width: 100% !important;
    height: 410px;
    position: relative;
    top: -30px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    /* background-color: beige; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(15, 15, 15);
    box-shadow: none;
    z-index: -1;
  
  
}
.cover_1{
    width: 100%;
    height: 250px;
    background-color:rgb(15, 15, 15);
    position: relative;
    top: 35px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    /* border: 2px solid red;  */
    cursor: grab;
    border-radius: 12px;
     /* background-color: salmon;   */
      
   
}


.inner_div_1_1{
    width: 100px;
    height: 45% !important;
     /* background-color: blueviolet;         */
    display: flex;
    position: absolute;
    top: 52px;
    left:10px !important;
    align-items: center;
    background-image:linear-gradient(#ff7200, #ff7200), linear-gradient(#ff7200, #ff7200);            
    background-repeat: no-repeat;
    background-size:  3px 35%, 35% 3px, calc(100% - 8px) calc(100% - 8px);
    background-position: right bottom, right bottom, 4px 4px;
    
   
}

.inner_div_1_1 img{
    width: 85px;
    height: 84px;
    position: relative;
    top: 0;
    left: 3px;
    text-align: center;
   align-items: center;
   justify-content: center;
   background-clip: content-box;
   border-image-slice: 1;
   z-index: 2;
   
   
}


.inner_div_2_1{
    width: 240px;
    height: 92%;
    /* background-color: green; */
    position: absolute;
    top: 30px;
    right: 10px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: transparent ;
}

.inner_div_2_1 h3{
    font-size: 15px;
    position: absolute;
    top: 0px;
    left: 6px;
    color: #ff7200;
    text-align: center;
    align-items: center;
    justify-content: center;

 }
.inner_div_2_1 p{
    font-size: 10px;
    font-weight: 300;
    text-align: justify;
    padding-top: 33px;
    color: white;
    padding-left: 7px;
    line-height: 14px;
    letter-spacing: 0px;
}
.Advisory_submain_2 .swiper-pagination {
    position: absolute;
    top: 420px;
    width: 90px;
    left: 50%;
    height: 30px;
    /* border: 2px solid red; */
    z-index: 10;
    display: none !important;

}

}