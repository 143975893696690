@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800;900&display=swap');

@media only screen and (min-width:1551px){
    .navbar_div_outer{
        width: 100%;
        height: 70px;
        display: flex; 
        position: fixed;
        z-index: 100;
       
       
    }
    .crosss{display: none;}
    .hide_crosss{display: none;}
    #burger{display: none;}
    #no_burger{display: none;}
    .slide_navbar a{display: none;}
    .hide_slide_nav{display: none;}
    
    .navbar_inner_1{
        width: 52%;
        height: 70px;
        position: absolute; 
        background-color: #000;
        opacity: 1;
    }
    
    .navbar_inner_1 img{
        opacity: 0.16;
    }
    
    #logo{
        position: absolute;
        top: 8px;
        left: 11%;
        width: 150px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    
    .navbar_inner_1 h2{
       position: absolute;
       top: 15px;
       left: 100px;
       font-size: 32px;
       color: #ff7200;
       cursor: pointer;
       text-shadow: 1px 1px 0px black;
    
    }
    
    #black_sign{
        color: black;
        cursor: pointer;
    }
    
    .navbar_inner_2{
        width: 50%;
        height: 100%;
       position: relative;
       background-color: #000;
       left: 50%;
       display: flex;
       flex-direction: row;      
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 40%;
        height: auto;
        position: absolute;
        left: 120px;
        justify-content: space-around;
        display: flex;
    
       
    
    }
    
    .inner_2_1 a{
        position: relative;
        top: 24px;
        font-weight: 700;
        text-decoration: none;
        font-size: 16px;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    .inner_2_1 a:hover{
        color: #ff7200;
    }
}
@media only screen and (max-width:1551px){
.navbar_div_outer{
    width: 100%;
    height: 70px;
    display: flex; 
    position: fixed;
    z-index: 100;
   
}
.crosss{display: none;}
.hide_crosss{display: none;}
#burger{display: none;}
#no_burger{display: none;}
.slide_navbar a{display: none;}
.hide_slide_nav{display: none;}

.navbar_inner_1{
    width: 52%;
    height: 70px;
    position: absolute; 
    background-color: #000;
    opacity: 1;
}

.navbar_inner_1 img{
    opacity: 0.16;
}

#logo{
    position: absolute;
    top: 8px;
    left: 11%;
    width: 150px;
    opacity: 1;
    z-index: 2;
    cursor: pointer;
}

.navbar_inner_1 h2{
   position: absolute;
   top: 15px;
   left: 100px;
   font-size: 32px;
   color: #ff7200;
   cursor: pointer;
   text-shadow: 1px 1px 0px black;

}

#black_sign{
    color: black;
    cursor: pointer;
}

.navbar_inner_2{
    width: 50%;
    height: 100%;
   position: relative;
   background-color: #000;
   left: 50%;
   display: flex;
   flex-direction: row; 
}

.navbar_inner_2 img{
    width: 100%;
}


.inner_2_1{
    width: 50%;
    height: auto;
    position: absolute;
    left: 120px;
    justify-content: space-around;
    display: flex;
}

.inner_2_1 a{
    position: relative;
    top: 24px;
    font-weight: 700;
    text-decoration: none;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.inner_2_1 a:hover{
    color: #ff7200;
}
}
@media only screen and (max-width:1101px){
    .navbar_div_outer{
        width: 100%;
        height: 70px;
        display: flex; 
        position: fixed;
        z-index: 100;  
    }
    .crosss{display: none;}
    .hide_crosss{display: none;}
    #burger{display: none;}
    #no_burger{display: none;}
    .slide_navbar a{display: none;}
    .hide_slide_nav{display: none;}
    
    .navbar_inner_1{
        width: 51%;
        height: 70px;
        position: absolute; 
        background-color:#000;
        /* background-color: red; */
    }
    
    .navbar_inner_1 img{
        opacity: 0.16;
    }
    
    #logo{
        position: absolute;
        top: 8px;
        left: 10%;
        width: 150px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    
    .navbar_inner_1 h2{
       position: absolute;
       top: 15px;
       left: 100px;
       font-size: 32px;
       color: #ff7200;
       cursor: pointer;
       text-shadow: 1px 1px 0px black;
    
    }
    
    #black_sign{
        color: black;
        cursor: pointer;
    }
    
    .navbar_inner_2{
        width: 50%;
        height: 100%;
       position: relative;
       background-color:#000;
       left: 50%;
       display: flex;
       flex-direction: row;
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 54%;
        height: auto;
        position: absolute;
        left: 120px;
        justify-content: space-around;
        display: flex;
       /* background-color: pink; */
       
    
    }
    
    .inner_2_1 a{
        position: relative;
        top: 24px;
        font-weight: 700;
        font-size: 13px;
        text-decoration: none;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    .inner_2_1 a:hover{
        color: #ff7200;
    }
}
@media only screen and (max-width:801px){
    .navbar_div_outer{
        width: 100%;
        height: 70px;
        display: flex; 
        position: fixed;
        z-index: 100;  
    }
    .crosss{display: none;}
    .hide_crosss{display: none;}
    #burger{display: none;}
    #no_burger{display: none;}
    .slide_navbar a{display: none;}
    .hide_slide_nav{display: none;}
    
    .navbar_inner_1{
        width: 52%;
        height: 70px;
        position: absolute; 
        background-color: #000;
        opacity: 1;
    }
    
    .navbar_inner_1 img{
        opacity: 0.16;
    }
    
    #logo{
        position: absolute;
        top: 8px;
        left: 10%;
        width: 150px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    
    .navbar_inner_1 h2{
        position: absolute;
        top: 15px;
        left: 100px;
        font-size: 32px;
        color: #ff7200;
        cursor: pointer;
        text-shadow: 1px 1px 0px black;
    
    }
    
    #black_sign{
        color: black;
        cursor: pointer;
    }
    
    .navbar_inner_2{
        width: 50%;
        height: 100%;
        position: relative;
        background-color: #000;
        left: 50%;
        display: flex;
        flex-direction: row;        
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 54%;
        height: auto;
        position: absolute;
        left: 120px;
        justify-content: space-around;
        display: flex;
        /* background-color: pink; */
        
    
    }
    
    .inner_2_1 a{
        position: relative;
        top: 24px;
        font-weight: 700;
        font-size: 12px;
        text-decoration: none;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    .inner_2_1 a:hover{
        color: #ff7200;
    }
}
@media only screen and (max-width:701px){
    #logo{
        position: absolute;
        top: 8px;
        left: 12%;
        width: 150px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    .navbar_inner_2{
        width: 50%;
        height: 100%;
       position: relative;
       background-color:#000;
       left: 50%;
       display: flex;
       flex-direction: row;
       clip-path: polygon(2% 0, 100% 0, 100% 100%, 0% 100%);
       /* background-color: antiquewhite; */
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 20%;
        height: auto;
        position: absolute;
        left: 72%;
        justify-content: space-around;
        display: flex;
       
        /* background-color: aqua; */
    
       
    
    }
    .inner_2_1 a{
        display: none;
    }

    #burger{
        color: #ff7200;
        display: block;
        font-size: 23px;
        position: absolute;
        top: 20px;
        right: 0px;
    }

    #no_burger{display: none;}

    .crosss{
        color: #ff7200;
        display: block;
        font-size: 19px;
        position: absolute;
        top: 25px;
        right: 0px;
    }
    .hide_crosss{display: none;}
    .hide_slide_nav{display: none;}

    .slide_navbar{
        width: 20%;
        height: 87px;
        position: absolute;
        top:47px;
        left: 76.6%;
        display: flex;
        flex-direction: column;
        background-color: #000;
        text-align: center;
        justify-content: space-evenly;
        z-index: 10;
  
        
    }
    .slide_navbar a{
        color: white;
        letter-spacing: 0.3px;
        text-decoration: none;
        font-size: 11px;
        display: block;
    }
    .slide_navbar a:hover{
        color: #ff7200;
    }
}
@media only screen and (max-width:501px){
    #logo{
        position: absolute;
        top: 9px;
        left: 12%;
        width: 140px;
        height: 57px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    .navbar_inner_2{
        width: 50%;
        height: 100%;
       position: relative;
       background-color: #000;
       left: 50%;
       display: flex;
       flex-direction: row;
       clip-path: polygon(2% 0, 100% 0, 100% 100%, 0% 100%);
       /* background-color: antiquewhite; */
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 20%;
        height: auto;
        position: absolute;
        left: 66%;
        justify-content: space-around;
        display: flex;      
    
    }
    .inner_2_1 a{
        display: none;
    }

    #burger{
        color: #ff7200;
        display: block;
        font-size: 23px;
        position: absolute;
        top: 20px;
        right: 0px;
    }

    #no_burger{display: none;}

    .crosss{
        color: #ff7200;
        display: block;
        font-size: 19px;
        position: absolute;
        top: 25px;
        right: 0px;
    }
    .hide_crosss{display: none;}
    .hide_slide_nav{display: none;}

    .slide_navbar{
        width: 23%;
        height: 87px;
        position: absolute;
        top:47px;
        left: 71%;
        display: flex;
        flex-direction: column;
        background-color: #000;
        text-align: center;
        justify-content: space-evenly;
        z-index: 10;
  
        
    }
  

    .slide_navbar a{
        color: white;
        letter-spacing: 0px;
        text-decoration: none;
        font-size: 11px;
        display: block;

    }
    .slide_navbar a:hover{
        color: #ff7200;
    }
}
@media only screen and (max-width:401px){
    #logo{
        position: absolute;
        top: 9px;
        left: 12%;
        width: 140px;
        height: 57px;
        opacity: 1;
        z-index: 2;
        cursor: pointer;
    }
    .navbar_inner_2{
        width: 50%;
        height: 100%;
       position: relative;
       background-color: #000;
       left: 50%;
       display: flex;
       flex-direction: row;
       clip-path: polygon(2% 0, 100% 0, 100% 100%, 0% 100%);
       /* background-color: antiquewhite; */
    }
    
    .navbar_inner_2 img{
        width: 100%;
    }
    
    
    .inner_2_1{
        width: 20%;
        height: auto;
        position: absolute;
        left: 66%;
        justify-content: space-around;
        display: flex;
       
        /* background-color: aqua; */
    
       
    
    }
    .inner_2_1 a{
        display: none;
    }

    #burger{
        color: #ff7200;
        display: block;
        font-size: 23px;
        position: absolute;
        top: 20px;
        right: 0px;
    }

    #no_burger{display: none;}

    .crosss{
        color: #ff7200;
        display: block;
        font-size: 19px;
        position: absolute;
        top: 25px;
        right: 0px;
    }
    .hide_crosss{display: none;}
    .hide_slide_nav{display: none;}

    .slide_navbar{
        width: 27%;
        height: 87px;
        position: absolute;
        top:47px;
        left: 71%;
        display: flex;
        flex-direction: column;
        background-color: #000;
        text-align: center;
        justify-content: space-evenly;
        z-index: 10;
  
        
    }
  

    .slide_navbar a{
        color: white;
        letter-spacing: 0px;
        text-decoration: none;
        font-size: 11px;
        display: block;

    }
    .slide_navbar a:hover{
        color: #ff7200;
    }
}


