@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800;900&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
}

@media only screen and (min-width:1550px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }

    .hide_secure_main_mobile {
        display: none;
    }

    .verifying_yes_mob {
        display: none;
    }

    .yes_details_more {
        display: none;
    }

    #special_text_white2_new {
        display: none;
    }

    #special_text_white3_new {
        display: none;
    }

    .lynksign_data_mobile {
        display: none;
    }

    .working_steps_mobile_1 {
        display: none;
    }

    .working_steps_mobile_2 {
        display: none;
    }

    .secure_main_mobile {
        display: none;
    }

    .securing_file_div {
        display: none;
    }

    .securing_file_daata {
        display: none;
    }

    .file_securing {
        display: none;
    }

    .verify_mobile {
        display: none;
    }

    .hide_verify_mobile {
        display: none;
    }

    .verfiy_mob_1 {
        display: none;
    }

    .hide_verfiy_mob_1 {
        display: none;
    }

    .hide_verifying_yes_mob {
        display: none;
    }

    #special_text_1_new {
        display: none;
    }

    #special_text_2_new {
        display: none;
    }

    #special_text_white_new {
        display: none;
    }

    #special_text_white1_new {
        display: none;
    }

    .verifying_no_mob {
        display: none;
    }

    .hide_verifying_no_mob {
        display: none;
    }



    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;

    }



    #scrolling_to_top:hover {
        background-color: #ff7200;

    }

    .home_div_outer {
        width: 100%;
        height: 650px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
        /* display: none; */
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 87%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 710px;
        height: 100%;
    }

    .newLynkRow {
        width: 76%;
        height: 40%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;

    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 70%;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .bigElem1 {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 14rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 13px;
        text-align: left;
        justify-content: space-around;
    }

    .headSpan1 {
        font-size: 3.5rem;
        color: #989898;
        position: relative;
        top: 7px;
    }

    .headSpan2 {
        font-size: 4.5rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 13px;
    }

    .newLynkHeadBtn {
        width: 420px;
        height: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .lockw {
        width: 100%;
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .myLock {
        width: 60px;
        border-radius: 50%;
        border: 1px solid silver;
        padding: 12px;
    }

    .nodebtn {
        width: 300px;
        height: 79px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .nodebtn:active {
        transform: translateY(-2px);
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 9%;
        margin-right: 18px;
    }

    .btnSpan {
        font-size: 0.9rem;
        color: #fff;
        font-weight: 600;
    }

    .btnNewSpan {
        font-size: 1rem;
        color: #fff;
    }



    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 740px;
        position: relative;
        top: 0px;
        /* background-color: grey; */

    }

    .lynksign_work_inner {
        width: 100%;
        height: 80px;
        position: relative;
        text-align: center;
        position: relative;
        top: 30px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 55px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
    }

    .one {
        width: 20%;
        height: 100%;

    }

    .one_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .one_text p {
        font-size: 16px;
        position: relative;
        top: 10px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted {
        width: 78%;
        height: 32%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .one_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder {
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one {
        font-size: 53px;
        position: relative;
        top: -10px;
        left: 85%;
        transform: rotate(40deg);
        color: #ff7200;
    }

    #arrow_two {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 95%;
        transform: rotate(-40deg);
        color: #ff7200;
    }

    #arrow_three {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 89%;
        transform: rotate(40deg);
        color: #ff7200;
    }


    #arrow_four {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: -6%;
        transform: rotate(-40deg);
        color: #ff7200;
    }


    /*  */
    .two {
        width: 20%;
        height: 100%;
    }

    .two_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 288px;
        /* background-color: #ff7200; */
    }

    .two_text p {
        font-size: 16px;
        position: relative;
        top: 10px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .two_dotted {
        width: 78%;
        height: 32%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .two_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock {
        width: 90px;
        height: 90px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    /*  */

    .three {
        width: 20%;
        height: 100%;
    }

    .three_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .three_text p {
        font-size: 16px;
        position: relative;
        top: 10px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted {
        width: 78%;
        height: 32%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .three_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen {
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    /*  */


    .four {
        width: 20%;
        height: 100%;
    }

    .four_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 278px;
        /* background-color: #ff7200; */
    }

    .four_text p {
        font-size: 16px;
        position: relative;
        top: 10px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted {
        width: 78%;
        height: 32%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .four_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .three_folder {
        width: 90px;
        height: 90px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }


    .five {
        width: 20%;
        height: 100%;
    }

    .five_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .five_text p {
        font-size: 16px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted {
        width: 78%;
        height: 32%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .five_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal {
        width: 44px;
        height: 35px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 30px;
    }

    .not_equal {
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 30px;
    }



    /*  */
    .lynksign_for {
        width: 100%;
        height: 620px;
        background-color: #3d3d3d;
        position: relative;
        top: 119px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 55px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 77%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 120px;
        justify-content: space-between;
        /* background-color: pink; */

    }



    /*  */
    #circle1 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 13px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line1 {
        border-left: 6px solid white;
        height: 303px;

        transform: rotate(34.5deg);
        /* position:absolute; */
    }

    /*  */

    #circle3 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 10px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    .curved_div_1 {
        width: 27%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;  */
        align-items: center;
        text-align: center;
        position: relative;


        /* background-color: antiquewhite; */

    }

    #circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;

        top: 12px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line {
        border-left: 6px solid white;
        height: 342px;
        /* transform: rotate(42.2deg); */
        position: relative;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;

    }

    .verify {
        position: relative;
        top: 45px;
    }

    .curved_div_1 p {
        color: white;
        position: relative;
        top: 90px;
        font-size: 16px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_2 {
        width: 27%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        /* border-left-color: transparent;   */
        align-items: center;
        position: relative;
        text-align: center;
    }

    .projection {
        position: relative;
        top: 45px;
    }

    .curved_div_2 p {
        color: white;
        position: relative;
        top: 95px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_3 {
        width: 25%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        position: relative;
        align-items: center;
        text-align: center;
    }

    .concept {
        position: relative;
        top: 45px;
    }

    .curved_div_3 p {
        color: white;
        position: relative;
        top: 90px;
        font-size: 16px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }




    /*  */
    .secure_verify_div {
        width: 100%;
        height: 550px;
        position: relative;
        top: 52px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 50px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;

    }

    .secure_inner h4 {
        font-size: 20px;
        font-weight: 500;
        padding-top: 49px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 22px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 230px;
        height: 60px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        transform: translateY(-6px);
        border-radius: 32px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        transform: translateY(-2px);

    }


    #upload-photo[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 18px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }







    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* background-color: pink; */
    }

    .verify_inner h2 {
        font-size: 50px;
        padding-left: 20px;
        padding-top: 10px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_inner h4 {
        font-size: 20px;
        font-weight: 500;
        padding-top: 52px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 22px;
        padding-left: 20px;
        padding-top: 44px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;


    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 240px;
        height: 60px;
        border: none;
        background-color: #ff7200;
        font-size: 21px;
        color: black;
        cursor: pointer;
        transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 32px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        transform: translateY(-2px);
    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 550px;
        position: relative;
        top: 52px;

    }

    .securing_main_div {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: black;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 50px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 20px;
        font-weight: 500;
        padding-top: 70px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 20px;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 30%;
        height: 60px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 15px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);

    }

    .another_upload:active {
        transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;

    }

    .securing_form {
        width: 75%;
        height: 90%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .securing_form form {
        width: 100%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 60px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 22px;
        font-weight: 600;
        position: relative;
        top: 82px;
        left: -5px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        left: 30px;
        top: 82px;
    }

    .sign {
        width: 150px;
        height: 42px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 100px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);
        border-radius: 19px;
        font-family: 'Montserrat', sans-serif;
    }

    #label_timee {
        position: relative;
        left: 29px;
        top: 70px;
    }




    .sign:active {
        transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
      
       
    }
    /* .overlay{
        background-color: rgba(49,49,49,0.8);
    } */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 550px;
        position: relative;
        top: 50px;
    }

    .visibleEye {
        position: absolute;
        top: 40px;
        right: 55px;
        cursor: pointer;
        /* color: #ff7200; */
    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 45px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 166px;
        position: relative;
        top: 155px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify h2 {
        font-size: 50px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 20px;
        padding-top: 50px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        text-align: center;
    }

    #to_left {
        /* position: absolute; */
        left: 0%;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
    }

    #to_left1 {
        position: relative;
        font-size: 15px;
        bottom: 10px;
        left: 6%;
        font-weight: 600;
        width: 88%;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 14px;
        position: relative;
        top: 0px;
        left: 1%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 14px;
        position: relative;
        top: 0px;
        left: 10px;
        display: inline-block;


    }

    .submit_id_1 {
        width: 20%;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        bottom: 50px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        transform: translateY(-2px);
    }

    #get_id {
        position: relative;
        top: 0px;
    }

    .form_verifying form {
        width: 100%;
        height: 80%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 22px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 170px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 60px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 13px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 30px;
        width: 230px;
        background-color: #212121;
        color: #C0C0C0;
    }

    .submit_id {
        width: 170px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 55px;
        left: 20px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        transform: translateY(-2px);
    }






    .show_verifying_div_1 {
        width: 100%;
        height: 550px;
        position: relative;
        top: 50px;
        background-color: white;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }

    #locatione {
        color: #ff7200;
        width: auto;
        font-size: 8px;
        position: absolute;
        top: 70%;
        left: 62%;
        font-weight: 700;
        text-decoration: underline !important;
        cursor: pointer;
        z-index: 1;
        /* border: 1px solid black; */
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }




    .verified_yes {
        width: 100%;
        height: 550px;
        position: relative;
        top: 52px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 80%;
        height: 20%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: green; */

    }

    .yes_1 h2 {
        font-size: 32px;
        position: relative;
        top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }

    .yes_2 {
        width: 70%;
        height: 75%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .boxes {
        width: 100%;
        height: 40px;
        display: flex;
        /* background-color: red; */
    }

    .box_0 {
        width: 20%;
        height: 100%;
    }

    .box_1 {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .box_1 p {
        font-size: 1.3rem;
        font-weight: 600;
        color: #fff;
    }

    .box_2 {
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 20px;
    }

    #special_green {
        font-size: 25px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        text-align: center;
        bottom: -25px;
        font-weight: 700;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 18px !important;
        padding-left: 6px !important;
        font-weight: 600;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 18px !important;
        font-weight: 600;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 18px !important;
        font-weight: 600;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 18px !important;
        font-weight: 600;
    }



    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 100%;
        height: 40%;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 50px;
        padding-top: 40px;
        position: relative;
        color: #C0C0C0;
        top: -5px;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 20px;
        padding-top: 50px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */

    }



    #trxnid {
        position: relative;

        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;
    }

    #choose_file {
        position: relative;

        top: 41px;
        font-weight: 600;
        color: white;
        font-size: 18px;
    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
    }

    .submit_id_green {
        width: 140px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 90px;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 550px;
        position: relative;
        top: 50px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    #fetched_location {
        width: 260px;
        height: auto;
        display: inline-block;
        text-align: start;
        font-size: 14px;
        position: absolute;
        top: -7px;
        left: 102px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #212121;
        word-wrap: break-word;
    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 360px;
        cursor: pointer;
        color: #fff;
    }

    #hide_remove {
        display: none;
    }

    .no_1 {
        width: 79%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 35px;
        position: relative;
        top: 2px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 138px;
        position: relative;
        top: 120px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 50px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 20px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    #trxnid_no {
        position: relative;
        /* left: 6%; */
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
    }

    #choose_file_no {
        position: relative;
        /* left: 6%; */
        top: 40px;
        font-weight: 600;
        color: white;
        font-size: 18px;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 20px;
        text-decoration: underline;
        top: 0px;

        font-size: 14px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 12px;
        text-decoration: underline;
        top: 0px;
        font-size: 15px;
    }

    .submit_id_red {
        width: 140px;
        height: 40px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 86px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        transform: translateY(-2px);
    }

    #server_time::placeholder {
        padding-left: 0px;
        color: #C0C0C0;
        font-size: 17px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 5;

    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 22%;
        height: 400px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        transform: translateY(40%);
        transition: transform 0.1s;
        transform-style: preserve-3d;
        animation: slide 0.1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        border: 3.5px solid #ff7200;
        z-index: 100;

    }

    @keyframes slide {
        0% {
            transform: translateY(10%);
        }

        50% {
            transform: translateY(20%);
        }

        100% {
            transform: translateY(35%);
        }
    }

    .otp_image {
        width: 86%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 99px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 68px;
    }

    .closeMe {
        width: 40px;
    }

    .otp_image h3 {
        font-size: 17px;
        position: relative;
        top: 80px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {

        padding: 5px;
        border: 2px solid #ccc;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 31px;
        width: 72%;
        height: 35px;
        letter-spacing: 1px;
        font-size: 1rem;
        background-color: #FFF;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 45px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }


    .Load,
    .loader_overlay {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;


    }

    .loader_overlay {
        background-color: rgba(49, 49, 49, 0.8);
    }

    .loader_content {
        position: absolute;
        width: 3.2%;
        height: 50px;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* background-color: #fff; */
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;

    }

    .loadingg {

        margin-left: auto;
        margin-right: auto;

    }

    .ClienstElem {
        width: 100%;
        height: auto;
    }





























}

@media only screen and (max-width:1550px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    #hide {
        display: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    .uploadLoader {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .displayFlexMe {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .displayFlexMe>span {
        font-size: 1rem !important;
        color: #ff7200;
    }

    .Lo {
        width: 100%;
        position: relative;
        top: 2700px !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }

    .hide_secure_main_mobile {
        display: none;
    }

    #special_text_white2_new {
        display: none;
    }

    #special_text_white3_new {
        display: none;
    }

    .lynksign_data_mobile {
        display: none;
    }

    .working_steps_mobile_1 {
        display: none;
    }

    .working_steps_mobile_2 {
        display: none;
    }

    .secure_main_mobile {
        display: none;
    }

    .securing_file_div {
        display: none;
    }

    .securing_file_daata {
        display: none;
    }

    .file_securing {
        display: none;
    }

    .verify_mobile {
        display: none;
    }

    .hide_verify_mobile {
        display: none;
    }

    .verfiy_mob_1 {
        display: none;
    }

    .hide_verfiy_mob_1 {
        display: none;
    }

    .hide_verifying_yes_mob {
        display: none;
    }

    #special_text_1_new {
        display: none;
    }

    #special_text_2_new {
        display: none;
    }

    #special_text_white_new {
        display: none;
    }

    #special_text_white1_new {
        display: none;
    }

    .verifying_no_mob {
        display: none;
    }

    .hide_verifying_no_mob {
        display: none;
    }

    .verifying_yes_mob {
        display: none;
    }

    .yes_details_more {
        display: none;
    }

    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;

    }

    #scrolling_to_top:hover {
        background-color: #ff7200;

    }

    .home_div_outer {
        width: 100%;
        height: 650px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
        /* display: none; */
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 87%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 95%;
        height: 100%;
    }

    .newLynkRow {
        width: 76%;
        height: 40%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;

    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 70%;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .bigElem1 {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 13rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 13px;
        text-align: left;
        justify-content: space-around;
    }

    .headSpan1 {
        font-size: 3rem;
        color: #989898;
        position: relative;
        top: 7px;
    }

    .headSpan2 {
        font-size: 4rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 13px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 50%;
        height: 77px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
        transform: translateY(-6px);
        box-shadow: 0px 3px #404040;
    }

    .nodebtn:active {
        transform: translateY(-2px);
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .nodebtn:focus:not(:focus-visible) {
        outline: none;
    }

    .btnImg {
        width: 9%;
        margin-right: 18px;
    }

    .btnSpan {
        font-size: 0.9rem;
        color: #fff;
        font-weight: 600;
    }

    .btnNewSpan {
        font-size: 1rem;
        color: #fff;
    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .lockw {
        width: 100%;
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .myLock {
        width: 60px;
        border-radius: 50%;
        border: 1px solid silver;
        padding: 12px;
    }


    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 740px;
        position: relative;
        top: 0px;
        /* background-color: grey; */

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 30px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 35px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
    }

    .one {
        width: 20%;
        height: 100%;

    }

    .one_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .one_text p {
        font-size: 15px;
        position: relative;
        top: 20px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted {
        width: 72%;
        height: 30%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 40px;
    }

    .one_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder {
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one {
        font-size: 53px;
        position: relative;
        top: -10px;
        left: 85%;
        transform: rotate(40deg);
        color: #ff7200;
    }

    #arrow_two {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 95%;
        transform: rotate(-40deg);
        color: #ff7200;
    }

    #arrow_three {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 89%;
        transform: rotate(40deg);
        color: #ff7200;
    }


    #arrow_four {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: -6%;
        transform: rotate(-40deg);
        color: #ff7200;
    }


    /*  */
    .two {
        width: 20%;
        height: 100%;
    }

    .two_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 288px;
        /* background-color: #ff7200; */
    }

    .two_text p {
        font-size: 15px;
        position: relative;
        top: 20px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .two_dotted {
        width: 72%;
        height: 30%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 290px;
    }

    .two_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock {
        width: 90px;
        height: 90px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    /*  */

    .three {
        width: 20%;
        height: 100%;
    }

    .three_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .three_text p {
        font-size: 15px;
        position: relative;
        top: 20px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted {
        width: 72%;
        height: 30%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 40px;
    }

    .three_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen {
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    /*  */


    .four {
        width: 20%;
        height: 100%;
    }

    .four_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 278px;
        /* background-color: #ff7200; */
    }

    .four_text p {
        font-size: 15px;
        position: relative;
        top: 30px;
        letter-spacing: 0.5px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted {
        width: 72%;
        height: 30%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 290px;
    }

    .four_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .three_folder {
        width: 90px;
        height: 90px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }


    .five {
        width: 20%;
        height: 100%;
    }

    .five_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;

    }

    .five_text p {
        font-size: 15px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted {
        width: 72%;
        height: 30%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 40px;
    }

    .five_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal {
        width: 44px;
        height: 35px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 30px;
    }

    .not_equal {
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 30px;
    }

    /*  */
    .lynksign_for {
        width: 100%;
        height: 570px;
        background-color: #3d3d3d;
        position: relative;
        top: 53px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 35px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 77%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        /* background-color: pink; */

    }



    /*  */
    #circle1 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 13px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line1 {
        border-left: 6px solid white;
        height: 303px;

        transform: rotate(34.5deg);
        /* position:absolute; */
    }

    /*  */

    #circle3 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 10px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    .curved_div_1 {
        width: 27%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;  */
        align-items: center;
        text-align: center;
        position: relative;


        /* background-color: antiquewhite; */

    }

    #circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;

        top: 12px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line {
        border-left: 6px solid white;
        height: 342px;
        /* transform: rotate(42.2deg); */
        position: relative;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;

    }

    .verify {
        position: relative;
        top: 45px;
    }

    .curved_div_1 p {
        color: white;
        position: relative;
        top: 70px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_2 {
        width: 27%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        /* border-left-color: transparent;   */
        align-items: center;
        position: relative;
        text-align: center;
    }

    .projection {
        position: relative;
        top: 45px;
    }

    .curved_div_2 p {
        color: white;
        position: relative;
        top: 70px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_3 {
        width: 25%;
        height: 300px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        position: relative;
        align-items: center;
        text-align: center;
    }

    .concept {
        position: relative;
        top: 45px;
    }

    .curved_div_3 p {
        color: white;
        position: relative;
        top: 70px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }




    /*  */
    .secure_verify_div {
        width: 100%;
        height: 440px;
        position: relative;
        top: 52px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 440px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;

    }

    .secure_inner h4 {
        font-size: 18px;
        font-weight: 500;
        padding-top: 54px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 34%;
        height: 60px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        transform: translateY(-6px);
        border-radius: 32px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;

    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 18px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .verify_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .verify_inner h4 {
        font-size: 18px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 44px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;


    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 69%;
        height: 60px;
        border: none;
        background-color: #ff7200;
        font-size: 21px;
        color: black;
        cursor: pointer;
        transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 32px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        transform: translateY(-2px);
    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 400px;
        position: relative;
        top: 52px;

    }

    .securing_main_div {
        width: 100%;
        height: 440px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 18px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 30%;
        height: 60px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 15px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);

    }

    .another_upload:active {
        transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;

    }

    .securing_form {
        width: 75%;
        height: 90%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 45px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 19px;
        font-weight: 600;
        position: relative;
        top: 32px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_form form ::placeholder {
        font-size: 12px;
        font-weight: 600;

        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        left: 30px;
        top: 30px;
    }

    #fetched_location {
        width: 250px;
        height: auto;
        /* border: 2px solid red;  */
        display: inline-block;
        text-align: start;
        font-size: 13px;
        position: absolute;
        top: -12px;
        left: 93px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #212121;
        word-wrap: break-word;

    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 360px;
        cursor: pointer;
        color: #fff;
    }

    #hide_remove {
        display: none;
    }


    .sign {
        width: 150px;
        height: 42px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 50px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);
        border-radius: 19px;
        font-family: 'Montserrat', sans-serif;
    }

    #label_timee {
        position: relative;
        top: 23px;
        left: 30px;
    }




    .sign:active {
        transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  
   
}
/* .overlay{
    background-color: rgba(49,49,49,0.8);
} */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 445px;
        position: relative;
        top: 50px;
    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 34px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 156px;
        position: relative;
        top: 125px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 20px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        text-align: center;
    }

    #to_left {
        /* position: absolute; */
        left: 0%;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
    }

    #to_left1 {
        position: relative;
        font-size: 15px;
        bottom: 10px;
        left: 6%;
        font-weight: 600;
        width: 88%;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 14px;
        position: relative;
        top: 0px;
        left: 1%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 14px;
        position: relative;
        top: 0px;
        left: 10px;
        display: inline-block;


    }

    .submit_id_1 {
        width: 20%;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        bottom: 50px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        transform: translateY(-2px);
    }

    #get_id {
        position: relative;
        top: 0px;
    }

    .form_verifying form {
        width: 100%;
        height: 80%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 20px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 30%;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 60px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 13px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        width: 248px;
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 30px;
        background-color: #212121;
        color: #C0C0C0 ! important;
    }

    .submit_id {
        width: 30%;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 55px;
        left: 20px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        transform: translateY(-2px);
    }






    .show_verifying_div_1 {
        width: 100%;
        height: 443px;
        position: relative;
        top: 50px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }

    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }

    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }

    #locatione {
        color: #ff7200;
        width: auto;
        font-size: 8px;
        position: absolute;
        top: 68%;
        left: 63%;
        font-weight: 700;
        text-decoration: underline !important;
        cursor: pointer;
        z-index: 1;
        /* border: 2px solid black; */
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }

    .verified_yes {
        width: 100%;
        height: 440px;
        position: relative;
        top: 52px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 80%;
        height: 20%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: green; */

    }

    .yes_1 h2 {
        font-size: 29px;
        position: relative;
        top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }

    .yes_2 {
        width: 70%;
        height: 75%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;

    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .boxes {
        width: 100%;
        height: 40px;
        display: flex;
        /* background-color: red; */
    }

    .box_0 {
        width: 10%;
    }

    .box_1 {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        /* background-color: cyan; */
    }

    .box_1 p {
        font-size: 1.1rem;
        font-weight: 600;
        color: #fff;
    }

    .box_2 {
        width: 70%;
        height: 100%;
        display: flex;
        text-align: left;
        align-items: center;
        /* padding-left: 20px; */
    }

    #special_green {
        font-size: 22px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        text-align: center;
        bottom: -10px;
        font-weight: 700;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 15px !important;
        padding-left: 6px !important;
        font-weight: 600;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 15px !important;
        font-weight: 600;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 15px !important;
        font-weight: 600;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 15px !important;
        font-weight: 600;
    }


    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 100%;
        height: 40%;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: -5px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */

    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;
    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 105px;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 450px;
        position: relative;
        top: 50px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;

    }

    .no_1 {
        width: 79%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 28px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 118px;
        position: relative;
        top: 100px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
    }

    #trxnid_no {
        position: absolute;
        left: 6%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
    }

    #choose_file_no {
        position: absolute;
        left: 6%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 18px;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 20px;
        text-decoration: underline;
        top: 0px;

        font-size: 14px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 12px;
        text-decoration: underline;
        top: 0px;
        font-size: 15px;
    }

    .submit_id_red {
        width: 120px;
        height: 33px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 106px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        transform: translateY(-2px);
    }

    #server_time::placeholder {
        color: #C0C0C0 ! important;
        font-size: 14px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 5;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 22%;
        height: 400px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        transform: translateY(40%);
        transition: transform 0.1s;
        transform-style: preserve-3d;
        animation: slide 0.1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        border: 3.5px solid #ff7200;
        z-index: 100;
    }

    @keyframes slide {
        0% {
            transform: translateY(10%);
        }

        50% {
            transform: translateY(20%);
        }

        100% {
            transform: translateY(35%);
        }



    }

    .otp_image {
        width: 81%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 99px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 68px;
    }

    .closeMe {
        width: 40px;
    }

    .otp_image h3 {
        font-size: 17px;
        position: relative;
        top: 80px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {

        padding: 5px;
        border: 2px solid #ccc;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 31px;
        width: 72%;
        height: 35px;
        letter-spacing: 1px;
        background-color: #fff;
        color: #000 !important;
        position: relative;
        font-size: 1rem;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 45px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .ClienstElem {
        width: 100%;
        height: auto;
    }

    .visibleEye {
        position: absolute;
        top: 40px;
        right: 55px;
        cursor: pointer;
        /* color: #ff7200; */
    }

}

@media only screen and (max-width:1301px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }

    .lynksign_data_mobile {
        display: none;
    }

    .working_steps_mobile_1 {
        display: none;
    }

    .working_steps_mobile_2 {
        display: none;
    }

    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;

    }

    #scrolling_to_top:hover {
        background-color: #ff7200;

    }

    .home_div_outer {
        width: 100%;
        height: 650px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 87%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 90%;
        height: 100%;
    }

    .newLynkRow {
        width: 76%;
        height: 30%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .bigElem1 {
        width: auto;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 10rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-around;
        position: relative;
        top: -15px;
    }

    .headSpan1 {
        font-size: 2.7rem;
        color: #989898;
        position: relative;
        top: 10px;
    }

    .headSpan2 {
        font-size: 3.3rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 8px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 65%;
        height: 70px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 9%;
        margin-right: 18px;
    }

    .btnSpan {
        font-size: 0.85rem;
        color: #fff;
        font-weight: 500;
    }

    .btnNewSpan {
        font-size: 0.85rem;
        color: #fff;
    }


    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 740px;
        position: relative;
        top: 0px;

        /* background-color: grey; */

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 30px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 35px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
    }

    .one {
        width: 20%;
        height: 100%;

    }

    .one_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .one_text p {
        font-size: 13px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted {
        width: 72%;
        height: 25%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 16px;
    }

    .one_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder {
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one {
        font-size: 53px;
        position: relative;
        top: -10px;
        left: 85%;
        transform: rotate(40deg);
        color: #ff7200;
    }

    #arrow_two {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 95%;
        transform: rotate(-40deg);
        color: #ff7200;
    }

    #arrow_three {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: 89%;
        transform: rotate(40deg);
        color: #ff7200;
    }


    #arrow_four {
        font-size: 53px;
        position: relative;
        top: -20px;
        left: -6%;
        transform: rotate(-40deg);
        color: #ff7200;
    }


    /*  */
    .two {
        width: 20%;
        height: 100%;
    }

    .two_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 288px;
        /* background-color: #ff7200; */
    }

    .two_text p {
        font-size: 13px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .two_dotted {
        width: 72%;
        height: 25%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .two_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock {
        width: 70px;
        height: 70px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    /*  */

    .three {
        width: 20%;
        height: 100%;
    }

    .three_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .three_text p {
        font-size: 13px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted {
        width: 72%;
        height: 25%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .three_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen {
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    /*  */


    .four {
        width: 20%;
        height: 100%;
    }

    .four_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 278px;
        /* background-color: #ff7200; */
    }

    .four_text p {
        font-size: 13px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted {
        width: 72%;
        height: 25%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .four_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .three_folder {
        width: 65px;
        height: 65px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }


    .five {
        width: 20%;
        height: 100%;
    }

    .five_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .five_text p {
        font-size: 13px;
        position: relative;
        top: 10px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted {
        width: 72%;
        height: 25%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .five_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal {
        width: 34px;
        height: 25px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 30px;
    }

    .not_equal {
        width: 35px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 20px;
    }

    /*  */
    .lynksign_for {
        width: 100%;
        height: 520px;
        background-color: #3d3d3d;
        position: relative;
        top: 53px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 35px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 82%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        /* background-color: pink; */

    }



    /*  */
    #circle1 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 13px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line1 {
        border-left: 6px solid white;
        height: 303px;

        transform: rotate(34.5deg);
        /* position:absolute; */
    }

    /*  */

    #circle3 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 10px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    .curved_div_1 {
        width: 32%;
        height: 270px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;  */
        align-items: center;
        text-align: center;
        position: relative;


        /* background-color: antiquewhite; */

    }

    #circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;

        top: 12px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line {
        border-left: 6px solid white;
        height: 342px;
        /* transform: rotate(42.2deg); */
        position: relative;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;

    }

    .verify {
        position: relative;
        top: 45px;
    }

    .curved_div_1 p {
        color: white;
        position: relative;
        top: 55px;
        font-size: 14px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_2 {
        width: 32%;
        height: 270px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        /* border-left-color: transparent;   */
        align-items: center;
        position: relative;
        text-align: center;
    }

    .projection {
        position: relative;
        top: 35px;
    }

    .curved_div_2 p {
        color: white;
        position: relative;
        top: 60px;
        font-size: 14px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_3 {
        width: 32%;
        height: 270px;
        border: 6px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        position: relative;
        align-items: center;
        text-align: center;
    }

    .concept {
        position: relative;
        top: 27px;
    }

    .curved_div_3 p {
        color: white;
        position: relative;
        top: 50px;
        font-size: 14px;
        padding-left: 35px;
        padding-right: 35px;
        font-family: 'Montserrat', sans-serif;
    }




    /*  */
    .secure_verify_div {
        width: 100%;
        height: 440px;
        position: relative;
        top: 52px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 440px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;


    }

    .secure_inner h4 {
        font-size: 16px;
        font-weight: 500;
        padding-top: 34px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 19px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 34%;
        height: 60px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        transform: translateY(-6px);
        border-radius: 32px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;


    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 14px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        color: #C0C0C0;
    }

    .verify_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .verify_inner h4 {
        font-size: 16px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 19px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;



    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 69%;
        height: 60px;
        border: none;
        background-color: #ff7200;
        font-size: 16px;
        color: black;
        cursor: pointer;
        transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 32px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        transform: translateY(-2px);
    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 400px;
        position: relative;
        top: 52px;

    }

    .securing_main_div {
        width: 100%;
        height: 440px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 40px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 18px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 180px;
        height: 50px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 14px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);

    }

    .another_upload:active {
        transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .securing_form {
        width: 85%;
        height: 90%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 50px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 16px;
        font-weight: 600;
        position: relative;
        top: 32px;
        text-align: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        left: 30px;
        top: 30px;
        background-color: #212121;
        color: #C0C0C0;
    }


    .sign {
        width: 130px;
        height: 42px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 45px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);
        border-radius: 19px;
        font-family: 'Montserrat', sans-serif;
    }




    .sign:active {
        transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
      
       
    }
    /* .overlay{
        background-color: rgba(49,49,49,0.8);
    } */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 465px;
        position: relative;
        top: 50px;
    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 34px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 156px;
        position: relative;
        top: 125px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 17px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        /* background-color: antiquewhite; */
    }

    #to_left {
        position: absolute;
        left: 3%;
        font-size: 16px;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #to_left1 {
        position: absolute;
        font-size: 16px;
        bottom: 100px;
        left: 3%;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 13px;
        position: relative;
        top: 0px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        left: 0px;
        display: inline-block;

    }

    #special_text_1_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 12px;
        position: relative;
        top: -50px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 12px;
        position: relative;
        top: -52px;
        left: 0px;
        display: inline-block;

    }

    #get_id {
        position: relative;
        top: 0px;
    }

    .form_verifying form {
        width: 100%;
        height: 80%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 18px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 30%;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 70px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 13px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        width: 248px;
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 30px;
        background-color: #212121;
        color: #C0C0C0;
    }

    .submit_id {
        width: 30%;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 63px;
        left: 20px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        transform: translateY(-2px);
    }

    .submit_id_1 {
        width: 30%;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: -6px;
        left: 20px;

        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        transform: translateY(-2px);
    }





    .show_verifying_div_1 {
        width: 100%;
        height: 443px;
        position: relative;
        top: 50px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }


    #locatione {

        color: #ff7200;
        width: 190px;
        font-size: 8px;
        position: absolute;
        top: 67%;
        left: 63%;
        font-weight: 700;
        text-decoration: underline !important;
        cursor: pointer;
        z-index: 1;
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }


    #fetched_location {
        width: 250px;
        height: auto;
        display: inline-block;
        text-align: start;
        font-size: 13px;
        position: absolute;
        top: -15px;
        left: 83px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #212121;
        word-wrap: break-word;
    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 330px;

        cursor: pointer;
        color: #fff;
    }

    #hide_remove {
        display: none;
    }





    .verified_yes {
        width: 100%;
        height: 440px;
        position: relative;
        top: 52px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 80%;
        height: 20%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: green; */

    }

    .yes_1 h2 {
        font-size: 22px;
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }



    .yes_2 {
        width: 70%;
        height: 70%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 30px;
        text-align: center;
        /* background-color: green; */

    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;


    }

    .label_name {
        display: inline-block;


    }

    .label_mail {
        display: inline-block;
        position: relative;
        left: 13px;
    }

    .label_place {
        display: inline-block;

    }

    .label_time {
        position: absolute;
        left: 33px;
        display: inline-block;
    }


    .yes_2 label {
        font-size: 19px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        right: 20px;
        top: 40px;
    }


    #special_green {
        font-size: 18px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        bottom: -4px;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 8px;
        font-size: 14px !important;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 16px;
        font-size: 14px !important;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 12px;
        font-size: 14px !important;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 15px;
        font-size: 14px !important;
    }


    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 100%;
        height: 40%;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: 5px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 17px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 90%;
        height: 47%;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        /* background-color: red; */
        align-items: center;
    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
        display: none;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;

    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
        display: none;

    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
        display: none;
    }


    #special_text_white_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        /* left: 1%; */
        text-decoration: underline;
        top: 0px;
        font-size: 12px;
        display: block;

    }

    #special_text_white1_new {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        display: block;
        font-weight: 400;
        /* left: 3%; */
        top: 20px;
        font-size: 12px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 85px;

        align-items: center;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 450px;
        position: relative;
        top: 50px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .no_1 {
        width: 79%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 24px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 118px;
        position: relative;
        top: 100px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 40px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;

    }

    #trxnid_no {
        position: absolute;
        left: 3%;
        top: 14px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        display: none;
    }

    #choose_file_no {
        position: absolute;
        left: 3%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 15px;
        display: none;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 0px;
        text-decoration: underline;
        top: 0px;
        display: none;
        font-size: 11px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 7px;
        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: none;
    }

    #special_text_white2_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;

        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: block;
    }


    #special_text_white3_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        top: 23px;
        font-size: 11px;
    }

    .submit_id_red {
        width: 120px;
        height: 33px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 66px;

        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        transform: translateY(-2px);
    }

    #label_timee {
        position: relative;
        left: 12px;
    }

    #server_time {
        position: relative;
        /* left: 0px; */
        color: #C0C0C0;
        font-size: 14px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 25%;
        height: 350px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        transform: translateY(40%);
        transition: transform 0.1s;
        transform-style: preserve-3d;
        animation: slide 0.1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        z-index: 100;
        border: 3.5px solid #ff7200;

    }

    @keyframes slide {
        0% {
            transform: translateY(10%);
        }

        50% {
            transform: translateY(20%);
        }

        100% {
            transform: translateY(35%);
        }



    }









    .otp_image {
        width: 80%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 99px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 68px;
    }

    .closeMe {
        width: 40px;
    }

    .otp_image h3 {
        font-size: 15px;
        position: relative;
        top: 70px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {

        padding: 5px;
        border: 2px solid #ccc;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 31px;
        width: 72%;
        height: 35px;
        letter-spacing: 1px;
        font-size: 1rem;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 49px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(70, 68, 68);
    }

    .otp_btn:active {
        transform: translateY(-2px);
    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .lockw {
        width: 100%;
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .myLock {
        width: 60px;
        border-radius: 50%;
        border: 1px solid silver;
        padding: 8px;
    }

}

@media only screen and (max-width:1001px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    .lynksign_data_mobile {
        display: none;
    }

    .working_steps_mobile_1 {
        display: none;
    }

    .working_steps_mobile_2 {
        display: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }

    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;

    }

    #scrolling_to_top:hover {
        background-color: #ff7200;
    }

    .home_div_outer {
        width: 100%;
        height: 590px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 87%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 96%;
        height: 76%;
    }

    .newLynkRow {
        width: 76%;
        height: 30%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .bigElem1 {
        width: auto;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 7.7rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-around;
        position: relative;
        top: -15px;
    }

    .headSpan1 {
        font-size: 2.3rem;
        color: #989898;
        position: relative;
        top: 10px;
    }

    .headSpan2 {
        font-size: 3rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 8px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 75%;
        height: 60px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 8%;
        margin-right: 13px;
    }

    .btnSpan {
        font-size: 0.72rem;
        color: #fff;
        font-weight: 500;
    }

    .btnNewSpan {
        font-size: 0.7rem;
        color: #fff;
    }


    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 720px;
        position: relative;
        top: -7px;
        /* background-color: antiquewhite; */

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 20px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 32px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
        /* background-color: antiquewhite; */
    }

    .one {
        width: 20%;
        height: 100%;

    }

    .one_text {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .one_text p {
        font-size: 11.5px;
        position: relative;
        top: 0px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted {
        width: 72%;
        height: 20%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 16px;
    }

    .one_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one {
        font-size: 47px;
        position: relative;
        top: -10px;
        left: 85%;
        transform: rotate(40deg);
        color: #ff7200;
    }

    #arrow_two {
        font-size: 47px;
        position: relative;
        top: -20px;
        left: 85%;
        transform: rotate(-40deg);
        color: #ff7200;
    }

    #arrow_three {
        font-size: 47px;
        position: relative;
        top: -20px;
        left: 91%;
        transform: rotate(40deg);
        color: #ff7200;
    }


    #arrow_four {
        font-size: 47px;
        position: relative;
        top: 10px;
        left: -11%;
        transform: rotate(-40deg);
        color: #ff7200;
    }


    /*  */
    .two {
        width: 20%;
        height: 100%;
    }

    .two_text {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 288px;
        /* background-color: #ff7200; */
    }

    .two_text p {
        font-size: 11.5px;
        position: relative;
        top: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .two_dotted {
        width: 72%;
        height: 20%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .two_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    /*  */

    .three {
        width: 20%;
        height: 100%;
    }

    .three_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .three_text p {
        font-size: 11.5px;
        position: relative;
        top: 0px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted {
        width: 72%;
        height: 20%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .three_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen {
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    /*  */


    .four {
        width: 20%;
        height: 100%;
    }

    .four_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 23%;
        text-align: center;
        position: relative;
        top: 278px;
        /* background-color: #ff7200; */
    }

    .four_text p {
        font-size: 11.5px;
        position: relative;
        top: 0px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted {
        width: 72%;
        height: 20%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 270px;
    }

    .four_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .three_folder {
        width: 51px;
        height: 51px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }


    .five {
        width: 20%;
        height: 100%;
    }

    .five_text {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 20%;
        text-align: center;
        /* background-color: #ff7200; */
    }

    .five_text p {
        font-size: 11.5px;
        position: relative;
        top: 0px;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted {
        width: 72%;
        height: 20%;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 20px;
    }

    .five_inner {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal {
        width: 27px;
        height: 25px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 20px;
    }

    .not_equal {
        width: 27px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10px;
    }



















































    /*  */
    .lynksign_for {
        width: 100%;
        height: 480px;
        background-color: #3d3d3d;
        position: relative;
        top: -7px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 32px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 90%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        /* background-color: pink; */

    }



    /*  */
    #circle1 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 13px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line1 {
        border-left: 6px solid white;
        height: 303px;

        transform: rotate(34.5deg);
        /* position:absolute; */
    }

    /*  */

    #circle3 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 10px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    .curved_div_1 {
        width: 32%;
        height: 250px;
        border: 4px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;  */
        align-items: center;
        text-align: center;
        position: relative;


        /* background-color: antiquewhite; */

    }

    #circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 0;

        top: 12px;
        /* background-color: white; */
        border: 1 px solid white;
    }

    #curved_line {
        border-left: 6px solid white;
        height: 342px;
        /* transform: rotate(42.2deg); */
        position: relative;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;

    }

    .verify {
        position: relative;
        top: 45px;
    }

    .curved_div_1 p {
        color: white;
        position: relative;
        top: 55px;
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_2 {
        width: 32%;
        height: 250px;
        border: 4px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        /* border-left-color: transparent;   */
        align-items: center;
        position: relative;
        text-align: center;
    }

    .projection {
        position: relative;
        top: 35px;
    }

    .curved_div_2 p {
        color: white;
        position: relative;
        top: 60px;
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;
        font-family: 'Montserrat', sans-serif;
    }

    .curved_div_3 {
        width: 32%;
        height: 250px;
        border: 4px solid #ff7200;
        border-radius: 25%;
        /* border-right-color: transparent;   */
        position: relative;
        align-items: center;
        text-align: center;
    }

    .concept {
        position: relative;
        top: 27px;
    }

    .curved_div_3 p {
        color: white;
        position: relative;
        top: 50px;
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;
        font-family: 'Montserrat', sans-serif;
    }




    /*  */
    .secure_verify_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;


    }

    .secure_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 34px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 40%;
        height: 49px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        transform: translateY(-6px);
        border-radius: 25px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;


    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        /* background-color: pink; */
    }

    .verify_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;



    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 81%;
        height: 49px;
        border: none;
        background-color: #ff7200;
        font-size: 12px;
        color: black;
        cursor: pointer;
        transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 22px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {

        transform: translateY(-2px);
    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -7px;


    }

    .securing_main_div {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 141px;
        height: 45px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 11px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);

    }

    .another_upload:active {
        transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .securing_form {
        width: 100%;
        height: 100%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 85%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 40px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 12px;
        text-align: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #fetched_location {
        width: 181px;
        height: auto;
        display: inline-block;
        text-align: start;
        font-size: 9.5px;
        position: absolute;
        top: -6px;
        left: 60px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #212121;
        word-wrap: break-word;

    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 250px;
        top: 0px;
        cursor: pointer;
        color: #fff;
    }

    #hide_remove {
        display: none;
    }

    #label_timee {
        position: relative;
        left: 5px;
        top: 6px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        background-color: #212121;
        top: 10px;
        color: #C0C0C0;
    }

    .sign {
        width: 111px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 26px;
        left: 0px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);
        border-radius: 22px;
        font-family: 'Montserrat', sans-serif;
    }

    .sign:active {
        transform: translateY(-2px);
    }

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -10px;
        /* background-color: red; */

    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 30px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 126px;
        position: relative;
        top: 100px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 90%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .heading_verify h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 14px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    #to_left {
        position: absolute;
        left: 3%;
        font-size: 16px;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #to_left1 {
        position: absolute;
        font-size: 16px;
        bottom: 100px;
        left: 3%;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 13px;
        position: relative;
        top: 0px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        left: 0px;
        display: inline-block;

    }

    #special_text_1_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 8px;
        position: relative;
        top: -50px;
        left: 0%;
        display: inline-block;


    }

    #special_text_2_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 8px;
        position: relative;
        top: -72px;
        left: 0px;
        display: inline-block;

    }

    #get_id {
        position: relative;
        top: 0px;
        width: 110px;
    }

    .form_verifying form {
        width: 100%;
        height: 70%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 16px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 120px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 44px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        width: 180px;
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 10px;
        background-color: #212121;
        color: #C0C0C0;
    }

    .submit_id {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 37px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        transform: translateY(-2px);
    }

    .submit_id_1 {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: -44px;
        left: -6px !important;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        transform: translateY(-2px);
    }





    .show_verifying_div_1 {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }

    #locatione {
        color: #ff7200;
        width: auto;
        font-size: 8px;
        position: absolute;
        top: 65%;
        left: 53%;
        font-weight: 700;
        text-decoration: underline !important;
        cursor: pointer;
        z-index: 1;
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }




    .verified_yes {
        width: 100%;
        height: 400px;
        position: relative;
        top: -9px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 96%;
        height: 25%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;


    }

    .yes_1 h2 {
        font-size: 18px;
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }



    .yes_2 {
        width: 100%;
        height: 70%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
        text-align: center;
        /* background-color: green; */

    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .boxes {
        width: 100%;
        height: 40px;
        display: flex;
    }

    .box_0 {
        width: 10%;
        height: 100%;
    }

    .box_1 {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .box_1 p {
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
    }

    .box_2 {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 0px;
    }

    #special_green {
        font-size: 16px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        text-align: center;
        bottom: -20px;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 13px !important;
        padding-left: 6px !important;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 13px !important;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 13px !important;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        font-size: 13px !important;
    }



    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 80%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 5px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 14px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 47%;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        /* background-color: antiquewhite; */
        align-items: center;
    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
        display: none;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;

    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
        display: none;

    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
        display: none;
    }


    #special_text_white_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        /* left: 1%; */
        text-decoration: underline;
        top: 0px;
        font-size: 9px;

    }

    #special_text_white1_new {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        display: block;
        font-weight: 400;
        /* left: 3%; */
        top: 20px;
        font-size: 9px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 65px;
        font-size: 11px;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .no_1 {
        width: 92%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 18px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 98px;
        position: relative;
        top: 80px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;

    }

    #trxnid_no {
        position: absolute;
        left: 3%;
        top: 14px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        display: none;
    }

    #choose_file_no {
        position: absolute;
        left: 3%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 15px;
        display: none;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 0px;
        text-decoration: underline;
        top: 0px;
        display: none;
        font-size: 11px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 7px;
        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: none;
    }

    #special_text_white2_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;

        text-decoration: underline;
        top: 10px;
        font-size: 8px;
        display: block;
    }


    #special_text_white3_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        top: 17px;
        font-size: 8px;
    }

    .submit_id_red {
        width: 120px;
        height: 35px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 51px;
        font-size: 11px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        transform: translateY(-2px);
    }

    #server_time::placeholder {
        color: #C0C0C0;
        font-size: 11px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 290px;
        height: 350px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        transform: translateY(40%);
        transition: transform 0.1s;
        transform-style: preserve-3d;
        animation: slide 0.1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        border: 3.5px solid #ff7200;
        z-index: 100;
    }

    @keyframes slide {
        0% {
            transform: translateY(10%);
        }

        50% {
            transform: translateY(20%);
        }

        100% {
            transform: translateY(35%);
        }



    }









    .otp_image {
        width: 79%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 90px;
        position: relative;
        top: 35px;
        color: #ff7200;
    }

    .otp_image h3 {
        font-size: 15px;
        position: relative;
        top: 80px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {
        font-size: 0.9rem;
        padding: 5px;
        border: 2px solid #ccc;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 31px;
        width: 72%;
        height: 35px;
        font-size: 12px;
        letter-spacing: 1px;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 45px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .lockw {
        width: 100%;
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .myLock {
        width: 42px;
        border-radius: 50%;
        border: 1px solid silver;
        padding: 8px;
        position: relative;
        top: 5px;
    }

    .myLock img {
        width: 20px;
    }



}

@media only screen and (max-width:701px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;

    }

    .show_verifying_div {
        display: none;
    }

    .show_secure_div {
        display: none;
    }

    .show_verifying_div_1 {
        display: none;
    }

    .verified_yes {
        display: none;
    }

    .not_verified {
        display: none;
    }

    .hide_not_verified {
        display: none;
    }

    #root {
        height: 0px;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }


    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;

    }

    #scrolling_to_top:hover {
        background-color: #ff7200;
    }

    .home_div_outer {
        width: 100%;
        height: 450px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 76%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 100%;
        height: 79%;
    }

    .newLynkRow {
        width: 76%;
        height: 40%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .bigElem1 {
        width: auto;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 6.7rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-around;
        position: relative;
        top: -15px;
    }

    .headSpan1 {
        font-size: 1.8rem;
        color: #989898;
        position: relative;
        top: 9px;
    }

    .headSpan2 {
        font-size: 2.2rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 3px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 85%;
        height: 50px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 7%;
        margin-right: 7px;
    }

    .btnSpan {
        font-size: 0.6rem;
        color: #fff;
        font-weight: 500;
    }

    .btnNewSpan {
        font-size: 0.6rem;
        color: #fff;
    }

    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 899px;
        position: relative;
        top: -7px;
        background-color: rgb(187, 184, 184);

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 20px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 28px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
        display: none;
        /* background-color: antiquewhite; */
    }

    /*  */
    .working_steps_mobile_1 {
        width: 95%;
        height: 320px;
        position: relative;
        top: 50px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .one_mobile {
        width: 100%;
        height: 40%;
        /* background-color: aqua; */
        display: flex;
    }

    .one_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 10px;
        left: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .one_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .one_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one_mobile {
        font-size: 50px;
        position: relative;
        left: 93%;
        top: 77px;
        transform: rotate(30deg);
        color: #ff7200;
    }

    .two_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;
        /* background-color: blue; */
    }

    .two_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .two_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .two_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .two_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    #arrow_two_mobile {
        font-size: 50px;
        position: relative;
        left: 37%;
        top: 30px;
        transform: rotate(140deg);
        color: #ff7200;
    }

    /*  */

    .working_steps_mobile_2 {
        width: 95%;
        height: 350px;
        position: relative;
        top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        /* background-color: blue; */
    }

    .three_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 14px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .three_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_three_mobile {
        font-size: 50px;
        position: relative;
        left: 100%;
        top: 80px;
        transform: rotate(30deg);
        color: #ff7200;
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;
        /* background-color: blue; */
    }

    .three_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .four_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 10px;
        display: flex;
        /* background-color: blue; */
    }

    .four_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blueviolet; */

    }

    .four_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .four_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    #arrow_four_mobile {
        font-size: 50px;
        position: relative;
        left: -9%;
        top: 110px;
        transform: rotate(140deg);
        color: #ff7200;
    }


    .five_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        position: relative;
        top: 22px;
        /* background-color: blue; */
    }

    .five_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 14px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .five_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted_mobile {
        width: 143px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .five_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal_mobile {
        width: 39px;
        height: 30px;
        position: relative;
        top: 0px;
    }

    .not_equal_mobile {
        width: 39px;
        height: 44px;
        position: relative;
        bottom: 0px;
    }

    /*  */





    /*  */
    .lynksign_for {
        width: 100%;
        height: 880px;
        background-color: #3d3d3d;
        position: relative;
        top: -7px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 26px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 90%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        display: none;
        /* background-color: pink; */

    }

    .lynksign_data_mobile {
        width: 75%;
        height: 81%;
        /* background-color: blueviolet; */
        position: relative;
        top: 58px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .curved_1_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_1_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_1_mobile p {
        position: relative;
        top: 40px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 13.5px;
        color: white;
    }

    .curved_2_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_2_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_2_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        color: white;
    }

    .curved_3_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_3_mobile img {
        width: 50px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_3_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        color: white;
    }



    /*  */
    .secure_verify_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
        display: none;
    }

    .secure_mobile {
        width: 100%;
        height: 420px;
    }

    .secure_main_mobile {
        width: 100%;
        height: 420px;
        position: relative;
        top: -380px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_secure_main_mobile {
        display: none
    }

    .secure_mobile_inner {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 40px;
        background-color: #000;
    }

    .secure_mobile_head {
        width: 100%;
        height: 60%;
        text-align: center;
        /* background-color: burlywood; */
    }

    .secure_mobile_head h2 {
        font-size: 36px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #secure__mobile_color {
        color: #ff7200;
    }

    .secure_mobile_head h4 {
        font-size: 25px;
        position: relative;
        top: 70px;
        font-weight: 400;
        font-size: 15px;
        color: #fff;
    }

    #upload_pdf {
        font-size: 15px;
        position: relative;
        top: 109px;
    }

    .choosse_div_mobile {
        width: 100%;
        height: 35%;
        /* background-color: #ff7200; */
        text-align: center;
    }

    .choose_div_btn_mobile {
        width: 40%;
        height: 44px;
        position: relative;
        top: 56px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        box-shadow: 0px 3px rgb(78, 40, 40);
        transform: translateY(-6px);
    }

    .choose_div_btn_mobile:active {
        transform: translateY(-2px);
    }

    #overlap_text_mobile {
        font-size: 14px;
        font-weight: 400;
        color: black;
    }

    .securing_file_div {
        width: 100%;
        height: 555px;
        position: relative;
        top: -369px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_securing_file_div {
        display: none;
    }

    .securing_file_inner {
        width: 80%;
        height: 459px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 53px;
        background-color: #000;
    }

    .file_securing {
        width: 100%;
        height: 15%;
        /* background-color: pink; */
        border: 2px solid silver;
        border-right-color: transparent;
        border-left-color: transparent;
        border-top-color: transparent;
        position: relative;
        display: flex;
        justify-content: space-around;
        /* display: block; */
        /* background-color: red; */
    }

    .file_securing p {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 25px;
        font-weight: 400;
        color: #fff;
    }

    .another_upload_mob {
        width: 90px;
        height: 31px;
        position: relative;
        top: 22px;
        background-color: #ff7200;
        border: none;
        color: white;
        font-size: 9px;
        border-radius: 11px;
        box-shadow: 0px 3px rgb(78, 40, 40);
        transform: translateY(-6px);
    }

    .another_upload_mob:active {
        transform: translateY(-2px);
    }

    #file_name_mob {
        position: relative;
        left: 6px;
        color: #C0C0C0;
        font-size: 12px;
        font-weight: 700;
        border-bottom: 3px solid #C0C0C0;
        ;
    }


    .securing_file_daata {
        width: 100%;
        height: 82%;
        display: block;
    }

    .securing_file_daata form {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .securing_file_daata form label {
        font-size: 18px;
        font-weight: 600;
        position: relative;
        top: 70px;
        color: #fff;
    }

    #label_timee_mob {
        position: relative;
        left: -25px;
        top: 20px;

    }

    .securing_file_daata form input {
        position: relative;
        top: 70px;
    }

    .sign_mobile {
        width: 40%;
        height: 44px;
        position: relative;
        top: 48px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(70, 68, 68);
    }

    .sign_mobile:active {
        transform: translateY(-2px);
    }

    .verify_mobile {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -382px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verify_mobile {
        display: none;
    }

    .verify_mob_inner {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;

    }

    .verify_mob_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_head h2 {
        font-size: 36px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #verify_mob_clr {
        color: #ff7200;
    }

    .verify_mob_head h4 {
        font-size: 15px;
        font-weight: 400;
        position: relative;
        top: 50px;
        color: #fff;
    }

    .verify_mob_upload {
        width: 90%;
        height: 150px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_upload h2 {
        font-size: 15px;
        position: relative;
        top: 40px;
        color: #C0C0C0;
    }

    .verify_mob_upload button {
        width: 40%;
        height: 40px;
        background-color: #ff7200;
        color: #000;
        border: none;
        border-radius: 10px;
        position: relative;
        top: 90px;
        box-shadow: 0px 3px rgb(78, 40, 40);
        transform: translateY(-6px);
    }

    .verify_mob_upload:active {
        transform: translateY(-2px);

    }

    .verfiy_mob_1 {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -380px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verfiy_mob_1 {
        display: none;
    }

    .verify_mob_inner_1 {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;
    }

    .verify_1_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_1_head h2 {
        font-size: 36px;
        position: relative;
        top: 27px;
        color: #C0C0C0;
    }

    .verify_1_head h4 {
        font-size: 15px;
        font-weight: 400;
        position: relative;
        top: 54px;
        color: #fff;
    }

    .verifying_1_form {
        width: 100%;
        height: 180px;
    }

    .verifying_1_form form {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .verifying_1_form form label {
        position: relative;
        top: 50px;
        font-weight: 500;
        color: #fff;
    }

    #get_id_mob {
        position: relative;
        top: 0px;
        width: 210px;
        background-color: #383838;
    }

    .choose_div_btn_1_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 60px;
        left: -30px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
    }

    .choose_div_btn_1_mob:active {

        transform: translateY(-2px);
    }


    #overlap_text_1_mob {
        color: #000;
        font-size: 14px;
        font-weight: 400;
    }

    .submit_id_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 20px;
        color: #000;
        top: 60px;
        left: 30px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
    }

    .submit_id_mob:active {

        transform: translateY(-2px);
    }



    /* YESSS MOBILE */

    .verifying_yes_mob {
        width: 100%;
        height: 480px;
        background-color: #E8E8E8;
        position: relative;
        top: -380px;
        /* background-color: rgb(247,245,245); */
        display: block;
    }

    .hide_verifying_yes_mob {
        display: none;
    }

    .verifying_yes_mob_in {
        width: 80%;
        height: 84%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #212121;
        /* box-shadow: 1px 1px 10px black; */
    }

    .yes_details {
        width: 100%;
        height: 70px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .yes_details h2 {
        font-size: 22px;
        position: relative;
        top: 17px;
        color: #909090;
    }

    .yes_details_more {
        width: 100%;
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        position: relative;
        top: 30px;
    }

    .newYes {
        width: 100%;
        height: 40px;
        display: flex;
    }

    .dataSpan {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .dataSpan span {
        font-size: 0.9rem;
        font-weight: 500;
        padding-right: 15px;
        color: #fff;
    }

    .dataField {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        /* background-color: red; */
    }

    .dataField span {
        font-size: 0.8rem;
        color: #C0C0C0;
    }

    #mob_name {
        position: absolute;
        left: 110px;
        top: 40px;
    }

    #mob_email {
        position: absolute;
        left: 110px;
        top: 80px;
    }

    #mob_place {
        position: absolute;
        left: 110px;
        top: 120px;
    }

    #mob_time {
        position: absolute;
        left: 110px;
        top: 160px;
    }

    .alignCentre {
        position: relative;
        top: 15px;
        text-align: center;
        justify-content: center;
    }

    #special_green_mob {
        font-size: 18px;
        color: green;
        position: relative;
        top: 0px;
        font-weight: 600;
    }

    #mob_yes_clrs {
        position: relative;
        left: 12px;
        /* padding-left: 12px; */
        color: red;
        font-size: 11px;
        font-weight: 400;
    }

    #mob_yes_time {
        padding-left: 15px;
        color: red;
        font-size: 11px;
        font-weight: 400;
    }

    .mob_another_btn {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        border-radius: 10px;
        position: relative;
        top: 20px;
        font-size: 0.8rem;
        transform: translateY(-6px);
        box-shadow: 0px 1px rgb(78, 40, 40);
    }

    .mob_another_btn:active {

        transform: translateY(-2px);
    }



    /*  */

    .verifying_no_mob {
        width: 100%;
        height: 420px;
        background-color: #E8E8E8;
        display: block;
        position: relative;
        top: -380px;

    }

    .hide_verifying_no_mob {
        display: none;
    }

    .verifying_no_inn_mob {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 40px;
        text-align: center;
        border-radius: 10px;
        background-color: #212121;
        box-shadow: 1px 1px 10px black;
    }

    .question_mob {
        width: 90%;
        height: 90%;
        margin-left: auto;
        margin-right: auto;

    }

    .question_mob h2 {
        font-size: 21px;
        position: relative;
        top: 20px;
        color: #909090;
    }

    #cross_mob {
        font-size: 79px;
        color: red;
        position: relative;
        top: 66px;
    }

    .wrong_mob_btn {
        width: 120px;
        height: 39px;
        background-color: red;
        border: none;
        color: white;
        position: relative;
        top: -15px;
        border-radius: 10px;
        transform: translateY(-6px);
        box-shadow: 0px 1px rgb(78, 40, 40);
    }

    .wrong_mob_btn:active {

        transform: translateY(-2px);
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;


    }

    .secure_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 34px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 40%;
        height: 49px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        transform: translateY(-6px);
        border-radius: 25px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;


    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        /* background-color: pink; */
    }

    .verify_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;



    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 81%;
        height: 49px;
        border: none;
        background-color: #ff7200;
        font-size: 12px;
        color: black;
        cursor: pointer;
        transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 22px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        transform: translateY(-2px);

    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -7px;


    }

    .securing_main_div {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 141px;
        height: 45px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 11px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);

    }

    .another_upload:active {
        transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .securing_form {
        width: 100%;
        height: 100%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 85%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 40px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 32px;
        text-align: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #label_timee {
        position: relative;
        left: 16px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        background-color: #212121;
        top: 30px;
        color: #C0C0C0;
    }

    .sign {
        width: 137px;
        height: 43px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 53px;
        left: 0px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);
        border-radius: 22px;
        font-family: 'Montserrat', sans-serif;
    }




    .sign:active {
        transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        
        
    }
    /* .overlay{
        background-color: rgba(49,49,49,0.8);
    } */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -10px;
        /* background-color: red; */

    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 30px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 126px;
        position: relative;
        top: 100px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 90%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .heading_verify h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 14px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        /* background-color: antiquewhite; */
    }

    #to_left {
        position: absolute;
        left: 3%;
        font-size: 16px;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #to_left1 {
        position: absolute;
        font-size: 16px;
        bottom: 100px;
        left: 3%;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 13px;
        position: relative;
        top: 0px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        left: 0px;
        display: inline-block;

    }

    #special_text_1_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -50px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -72px;
        left: 0px;
        display: inline-block;

    }

    #get_id {
        position: relative;
        top: 0px;
        width: 110px;
    }

    .form_verifying form {
        width: 100%;
        height: 70%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 16px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 120px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 44px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 30px;
        background-color: #000;
        color: #C0C0C0;

    }

    .submit_id {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 37px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        transform: translateY(-2px);
    }

    .submit_id_1 {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: -36px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        transform: translateY(-2px);
    }





    .show_verifying_div_1 {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }

    #locatione {
        color: #ff7200;
        font-size: 7px;
        position: absolute;
        top: 63%;
        left: 58%;
        cursor: pointer;
        width: auto;
        z-index: 1;
        /* border: 1px solid black; */
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }

    #fetched_location_mob {

        font-size: 11px;
        position: relative;
        left: 22px;
        top: -35px;
        font-weight: 600;
        color: green;
        padding-left: 100px;
    }

    #fetched_location {
        width: 183px;
        height: auto;
        display: inline-block;
        text-align: start;
        font-size: 11px;
        position: absolute;
        top: -4px;
        left: 89px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #000;
        word-wrap: break-word;
    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 275px;
        cursor: pointer;
        color: #fff;
    }




    .verified_yes {
        width: 100%;
        height: 400px;
        position: relative;
        top: -9px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 96%;
        height: 25%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;


    }

    .yes_1 h2 {
        font-size: 18px;
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }



    .yes_2 {
        width: 100%;
        height: 70%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
        text-align: center;
        /* background-color: green; */

    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;


    }

    .label_name {
        position: absolute !important;
        left: -47px !important;



    }

    .label_mail {
        position: absolute;
        right: 0px !important;
    }

    .label_place {
        position: relative;
        right: 65px !important;

    }

    .label_time {

        position: relative !important;
        right: 1px !important;
    }


    .yes_2 label {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        right: 20px;
        top: 40px;
    }

    #special_green {
        font-size: 16px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        bottom: -14px;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 8px;
        font-size: 15px !important;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 14px;
        font-size: 15px !important;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 12px;
        font-size: 15px !important;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 50px;
        font-size: 15px !important;
    }


    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 80%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 5px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 14px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 47%;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        /* background-color: antiquewhite; */
        align-items: center;
    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
        display: none;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;

    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
        display: none;

    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
        display: none;
    }


    #special_text_white_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        /* left: 1%; */
        text-decoration: underline;
        top: 0px;
        font-size: 9px;

    }

    #special_text_white1_new {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        display: block;
        font-weight: 400;
        /* left: 3%; */
        top: 20px;
        font-size: 9px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 65px;
        font-size: 11px;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;

    }

    .no_1 {
        width: 92%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 18px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 98px;
        position: relative;
        top: 80px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;

    }

    #trxnid_no {
        position: absolute;
        left: 3%;
        top: 14px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        display: none;
    }

    #choose_file_no {
        position: absolute;
        left: 3%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 15px;
        display: none;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 0px;
        text-decoration: underline;
        top: 0px;
        display: none;
        font-size: 11px;

    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 7px;
        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: none;
    }

    #special_text_white2_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;

        text-decoration: underline;
        top: 10px;
        font-size: 8px;
        display: block;
    }


    #special_text_white3_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        top: 17px;
        font-size: 8px;
    }

    .submit_id_red {
        width: 120px;
        height: 35px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 51px;
        font-size: 11px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        transform: translateY(-2px);
    }

    #server_time {
        position: relative;
        left: 50px;
        color: #C0C0C0;
        font-size: 12px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 250px;
        height: 300px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        transform: translateY(40%);
        transition: transform 0.1s;
        transform-style: preserve-3d;
        animation: slide 0.1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        border: 3.5px solid #ff7200;
        z-index: 100;

    }

    @keyframes slide {
        0% {
            transform: translateY(10%);
        }

        50% {
            transform: translateY(20%);
        }

        100% {
            transform: translateY(35%);
        }
    }

    .otp_image {
        width: 76%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 70px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 60px;
    }

    .closeMe {
        width: 33px;
    }

    .otp_image h3 {
        font-size: 14px;
        position: relative;
        top: 55px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {
        font-size: 0.9rem;
        padding: 5px;
        border: 2px solid #ccc;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 15px;
        width: 72%;
        height: 35px;
        font-size: 12px;
        letter-spacing: 1px;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 25px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .ClienstElem {
        width: 100%;
        height: auto;
        position: relative;
        top: -385px;
    }

    .visibleEye {
        position: absolute;
        top: 25px;
        right: 45px;
        cursor: pointer;
        /* color: #ff7200; */
    }






}

@media only screen and (max-width:501px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;

    }

    .show_verifying_div {
        display: none;
    }

    .show_secure_div {
        display: none;
    }

    .show_verifying_div_1 {
        display: none;
    }

    .verified_yes {
        display: none;
    }

    .not_verified {
        display: none;
    }

    #root {
        height: 0px;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }


    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        right: 13px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;
    }

    #scrolling_to_top:hover {
        background-color: #ff7200;
    }

    .home_div_outer {
        width: 100%;
        height: 350px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 70%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 95%;
        height: 70%;
    }

    .newLynkRow {
        width: 76%;
        height: 40%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .newLynkRow1 {
        width: 76%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .bigElem1 {
        width: auto;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 4rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-around;
        position: relative;
        top: -15px;
    }

    .headSpan1 {
        font-size: 1.2rem;
        color: #989898;
        position: relative;
        top: 9px;
    }

    .headSpan2 {
        font-size: 1.8rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 2px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 93%;
        height: 40px;
        border-radius: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 7%;
        margin-right: 7px;
    }

    .btnSpan {
        font-size: 8.3px;
        color: #fff;
        font-weight: 500;
    }

    .btnNewSpan {
        font-size: 8.5px;
        color: #fff;
    }

    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 899px;
        position: relative;
        top: -7px;
        background-color: rgb(187, 184, 184);

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 20px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
        display: none;
        /* background-color: antiquewhite; */
    }

    /*  */
    .working_steps_mobile_1 {
        width: 95%;
        height: 320px;
        position: relative;
        top: 50px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .one_mobile {
        width: 100%;
        height: 40%;
        /* background-color: aqua; */
        display: flex;
    }

    .one_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 10px;
        left: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .one_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .one_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one_mobile {
        font-size: 50px;
        position: relative;
        left: 87%;
        top: 87px;
        transform: rotate(35deg);
        color: #ff7200;
    }

    .two_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;
        /* background-color: blue; */
    }

    .two_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .two_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .two_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .two_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    #arrow_two_mobile {
        font-size: 50px;
        position: relative;
        left: 47%;
        top: 30px;
        transform: rotate(136deg);
        color: #ff7200;
    }

    /*  */

    .working_steps_mobile_2 {
        width: 95%;
        height: 350px;
        position: relative;
        top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        /* background-color: blue; */
    }

    .three_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 14px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .three_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_three_mobile {
        font-size: 50px;
        position: relative;
        left: 90%;
        top: 90px;
        transform: rotate(40deg);
        color: #ff7200;
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;
        /* background-color: blue; */
    }

    .three_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .four_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 10px;
        display: flex;
        /* background-color: blue; */
    }

    .four_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blueviolet; */

    }

    .four_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .four_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    #arrow_four_mobile {
        font-size: 50px;
        position: relative;
        left: -2%;
        top: 126px;
        transform: rotate(140deg);
        color: #ff7200;
    }


    .five_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        position: relative;
        top: 22px;
        /* background-color: blue; */
    }

    .five_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 14px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .five_text_mobile p {
        font-size: 12px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted_mobile {
        width: 133px;
        height: 130px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .five_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal_mobile {
        width: 39px;
        height: 30px;
        position: relative;
        top: 0px;
    }

    .not_equal_mobile {
        width: 39px;
        height: 44px;
        position: relative;
        bottom: 0px;
    }

    /*  */





    /*  */
    .lynksign_for {
        width: 100%;
        height: 880px;
        background-color: #3d3d3d;
        position: relative;
        top: -7px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 90%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        display: none;
        /* background-color: pink; */

    }

    .lynksign_data_mobile {
        width: 75%;
        height: 81%;
        /* background-color: blueviolet; */
        position: relative;
        top: 58px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .curved_1_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_1_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_1_mobile p {
        position: relative;
        top: 48px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }

    .curved_2_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_2_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_2_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }

    .curved_3_mobile {
        width: 56%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_3_mobile img {
        width: 50px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_3_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }



    /*  */
    .secure_verify_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
        display: none;
    }

    .secure_mobile {
        width: 100%;
        height: 420px;
    }

    .secure_main_mobile {
        width: 100%;
        height: 420px;
        position: relative;
        top: -380px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_secure_main_mobile {
        display: none
    }

    .secure_mobile_inner {
        width: 88%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 40px;
        background-color: #000;
    }

    .secure_mobile_head {
        width: 100%;
        height: 60%;
        text-align: center;
        /* background-color: burlywood; */
    }

    .secure_mobile_head h2 {
        font-size: 30px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #secure__mobile_color {
        color: #ff7200;
    }

    .secure_mobile_head h4 {
        position: relative;
        top: 70px;
        font-weight: 400;
        font-size: 13px;
        color: #fff;
    }

    #upload_pdf {
        font-size: 15px;
        position: relative;
        top: 109px;
    }

    .choosse_div_mobile {
        width: 100%;
        height: 35%;
        /* background-color: #ff7200; */
        text-align: center;
    }

    .choose_div_btn_mobile {
        width: 40%;
        height: 44px;
        position: relative;
        top: 56px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        transform: translateY(-6px);
    }

    .choose_div_btn_mobile:active {
        transform: translateY(-2px);
    }

    #overlap_text_mobile {
        font-size: 12px;
        font-weight: 400;
    }

    .securing_file_div {
        width: 100%;
        height: 555px;
        position: relative;
        top: -369px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_securing_file_div {
        display: none;
    }

    .securing_file_inner {
        width: 88%;
        height: 459px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 53px;
        background-color: #000;
    }

    .file_securing {
        width: 100%;
        height: 15%;
        /* background-color: pink; */
        border: 2px solid silver;
        border-right-color: transparent;
        border-left-color: transparent;
        border-top-color: transparent;
        position: relative;
        display: flex;
        justify-content: space-around;
        /* display: block; */
        /* background-color: red; */
    }

    .file_securing p {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 25px;
        font-weight: 400;
        color: #fff;
    }

    .another_upload_mob {
        width: 90px;
        height: 31px;
        position: relative;
        top: 22px;
        background-color: #ff7200;
        border: none;
        color: white;
        font-size: 9px;
        border-radius: 11px;

    }

    #file_name_mob {
        position: relative;
        left: 6px;
        color: #C0C0C0;
        ;
        font-size: 12px;
        font-weight: 700;
        border-bottom: 3px solid #C0C0C0;
        ;
    }


    .securing_file_daata {
        width: 100%;
        height: 82%;
        display: block;
    }

    .securing_file_daata form {
        width: 90%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .securing_file_daata form label {
        font-size: 13px;
        font-weight: 600;
        position: relative;
        top: 70px;
        right: 12px;
        color: #fff;
    }

    #fetched_location {
        width: 194px;
        height: auto;
        display: inline-block;
        text-align: start;
        font-size: 10px;
        position: absolute;
        top: -7px;
        left: 53px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #000;
        word-wrap: break-word;
    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 250px;
        cursor: pointer;
        color: #fff;
    }

    #label_timee_mob {
        position: relative;
        left: -40px;
    }

    .securing_file_daata form input {
        position: relative;
        top: 70px;
        width: 190px;
        background-color: #000;
    }

    .sign_mobile {
        width: 40%;
        height: 44px;
        position: relative;
        top: 49px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        transform: translateY(-6px);
    }

    .sign_mobile:active {
        transform: translateY(-2px);
    }

    .verify_mobile {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -382px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verify_mobile {
        display: none;
    }

    .verify_mob_inner {
        width: 88%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;

    }

    .verify_mob_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_head h2 {
        font-size: 30px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #verify_mob_clr {
        color: #ff7200;
    }

    .verify_mob_head h4 {
        font-size: 13px;
        font-weight: 400;
        position: relative;
        top: 65px;
        color: #fff;
    }

    .verify_mob_upload {
        width: 90%;
        height: 150px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_upload h2 {
        font-size: 15px;
        position: relative;
        top: 40px;
        color: #C0C0C0;
    }

    .verify_mob_upload button {
        width: 42%;
        height: 40px;
        background-color: #ff7200;
        color: #000;
        border: none;
        border-radius: 10px;
        position: relative;
        top: 90px;
        font-size: 12px;
    }

    .verfiy_mob_1 {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -380px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verfiy_mob_1 {
        display: none;
    }

    .verify_mob_inner_1 {
        width: 88%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;
    }

    .verify_1_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_1_head h2 {
        font-size: 30px;
        position: relative;
        top: 27px;
        color: #C0C0C0;
    }

    .verify_1_head h4 {
        font-size: 13px;
        font-weight: 400;
        position: relative;
        top: 74px;
        color: #fff;
    }

    .verifying_1_form {
        width: 100%;
        height: 180px;
    }

    .verifying_1_form form {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .verifying_1_form form label {
        position: relative;
        top: 50px;
        font-weight: 500;
        color: #fff;
    }

    #get_id_mob {
        position: relative;
        top: 0px;
        left: 10px;
        width: 170px;
        background-color: #383838;
    }

    .choose_div_btn_1_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 60px;
        left: -30px;
    }

    #overlap_text_1_mob {
        color: #000;
        font-size: 12px;
        font-weight: 400;
    }

    .submit_id_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 20px;
        color: #000;
        top: 60px;
        left: 30px;
        font-size: 12px;
    }


    /* YESSS MOBILE */

    .verifying_yes_mob {
        width: 100%;
        height: 480px;
        background-color: #E8E8E8;
        position: relative;
        top: -380px;
        /* background-color: rgb(247,245,245); */
        display: block;
    }

    .hide_verifying_yes_mob {
        display: none;
    }

    .verifying_yes_mob_in {
        width: 90%;
        height: 84%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #212121;
        /* box-shadow: 1px 1px 10px black; */
    }

    .yes_details {
        width: 95%;
        height: 70px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .yes_details h2 {
        font-size: 20px;
        position: relative;
        top: 17px;
        color: #909090;
    }

    .yes_details_more {
        width: 95%;
        height: 270px;
        /* background-color: bisque; */
        position: relative;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    #mob_name {
        position: absolute;
        left: 80px;
        top: 40px;
        font-size: 14px;
    }

    #mob_email {
        position: absolute;
        left: 80px;
        top: 80px;
        font-size: 14px;
    }

    #mob_place {
        position: absolute;
        left: 80px;
        top: 120px;
        font-size: 14px;
    }

    #mob_time {
        position: absolute;
        left: 80px;
        top: 160px;
        font-size: 14px;
    }

    #special_green_mob {
        font-size: 16px;
        color: green;
        position: relative;
        top: 0px;
        font-weight: 600;
    }

    #mob_yes_clrs {
        padding-left: 12px;
        color: red;
        font-size: 14px;
        font-weight: 400;
    }

    .mob_another_btn {
        width: 120px;
        height: 30px;
        background-color: #ff7200;
        border: none;
        color: #000;
        border-radius: 10px;
        position: relative;
        top: 22px;
    }

    /*  */

    .verifying_no_mob {
        width: 100%;
        height: 420px;
        background-color: #E8E8E8;
        display: block;
        position: relative;
        top: -380px;

    }

    .hide_verifying_no_mob {
        display: none;
    }

    .verifying_no_inn_mob {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 40px;
        text-align: center;
        border-radius: 10px;
        background-color: #212121;
        box-shadow: 1px 1px 10px black;
    }

    .question_mob {
        width: 90%;
        height: 90%;
        margin-left: auto;
        margin-right: auto;

    }

    .question_mob h2 {
        font-size: 19px;
        position: relative;
        top: 20px;
        color: #909090;
    }

    #cross_mob {
        font-size: 79px;
        color: red;
        position: relative;
        top: 66px;
    }

    .wrong_mob_btn {
        width: 120px;
        height: 39px;
        background-color: red;
        border: none;
        color: white;
        position: relative;
        top: -15px;
        border-radius: 10px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;


    }

    .secure_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 34px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 40%;
        height: 49px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        transform: translateY(-6px);
        border-radius: 25px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;


    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        /* background-color: pink; */
    }

    .verify_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;



    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 81%;
        height: 49px;
        border: none;
        background-color: #ff7200;
        font-size: 12px;
        color: black;
        cursor: pointer;
        transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 22px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        transform: translateY(-2px);

    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -7px;


    }

    .securing_main_div {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 141px;
        height: 45px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 11px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);

    }

    .another_upload:active {
        transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .securing_form {
        width: 100%;
        height: 100%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 85%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 40px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 32px;
        text-align: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #label_timee {
        position: relative;
        left: 16px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        background-color: #212121;
        top: 30px;
        color: #C0C0C0;
    }

    .sign {
        width: 137px;
        height: 43px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 53px;
        left: 0px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);
        border-radius: 22px;
        font-family: 'Montserrat', sans-serif;
    }




    .sign:active {
        transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        
        
    }
    /* .overlay{
        background-color: rgba(49,49,49,0.8);
    } */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -10px;
        /* background-color: red; */

    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 30px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 126px;
        position: relative;
        top: 100px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 90%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .heading_verify h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 14px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        /* background-color: antiquewhite; */
    }

    #to_left {
        position: absolute;
        left: 3%;
        font-size: 16px;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #to_left1 {
        position: absolute;
        font-size: 16px;
        bottom: 100px;
        left: 3%;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 13px;
        position: relative;
        top: 0px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        left: 0px;
        display: inline-block;

    }

    #special_text_1_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -50px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -72px;
        left: 0px;
        display: inline-block;

    }

    #get_id {
        position: relative;
        top: 0px;
        width: 110px;
    }

    .form_verifying form {
        width: 100%;
        height: 70%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 16px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 120px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 44px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 0px;
        background-color: #212121;
        color: #C0C0C0;
    }

    .submit_id {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 37px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        transform: translateY(-2px);
    }

    .submit_id_1 {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: -36px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        transform: translateY(-2px);
    }





    .show_verifying_div_1 {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }


    #locatione {
        color: #ff7200;
        font-size: 7px;
        position: absolute;
        top: 63%;
        left: 53%;
        cursor: pointer;
        width: auto;
        z-index: 1;
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }

    #fetched_location_mob {

        font-size: 10px;
        position: relative;
        left: 22px;
        top: -35px;
        font-weight: 600;
        color: green;
        padding-left: 80px;
    }

    .verified_yes {
        width: 100%;
        height: 400px;
        position: relative;
        top: -9px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 96%;
        height: 25%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .yes_details_more {
        width: 100%;
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        position: relative;
        top: 30px;
    }

    .newYes {
        width: 100%;
        height: 40px;
        display: flex;
    }

    .dataSpan {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .dataSpan span {
        font-size: 0.9rem;
        font-weight: 500;
        padding-right: 15px;
        color: #fff;
    }

    .dataField {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        text-align: left;
        /* background-color: red; */
    }

    .dataField span {
        font-size: 0.8rem;
        color: #C0C0C0;
    }

    .yes_1 h2 {
        font-size: 18px;
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }

    .yes_2 {
        width: 100%;
        height: 70%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
        text-align: center;
        /* background-color: green; */
    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .label_name {
        position: absolute !important;
        left: -47px !important;
    }

    .label_mail {
        position: absolute;
        right: 0px !important;
    }

    .label_place {
        position: relative;
        right: 65px !important;

    }

    .label_time {
        position: relative !important;
        right: 1px !important;
    }

    .yes_2 label {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        right: 20px;
        top: 40px;
    }

    #special_green {
        font-size: 16px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        bottom: -14px;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 8px;
        font-size: 15px !important;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 14px;
        font-size: 15px !important;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 12px;
        font-size: 15px !important;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 50px;
        font-size: 15px !important;
    }


    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 80%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        color: #C0C0C0;
        top: 5px;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 14px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 47%;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        /* background-color: antiquewhite; */
        align-items: center;
    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
        display: none;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;

    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
        display: none;

    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
        display: none;
    }


    #special_text_white_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        /* left: 1%; */
        text-decoration: underline;
        top: 0px;
        font-size: 9px;

    }

    #special_text_white1_new {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        display: block;
        font-weight: 400;
        /* left: 3%; */
        top: 20px;
        font-size: 9px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 65px;
        font-size: 11px;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .no_1 {
        width: 92%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 18px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 98px;
        position: relative;
        top: 80px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;

    }

    #trxnid_no {
        position: absolute;
        left: 3%;
        top: 14px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        display: none;
    }

    #choose_file_no {
        position: absolute;
        left: 3%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 15px;
        display: none;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 0px;
        text-decoration: underline;
        top: 0px;
        display: none;
        font-size: 11px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 7px;
        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: none;
    }

    #special_text_white2_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;

        text-decoration: underline;
        top: 10px;
        font-size: 8px;
        display: block;
    }


    #special_text_white3_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        top: 17px;
        font-size: 8px;
    }

    .submit_id_red {
        width: 120px;
        height: 35px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 51px;
        font-size: 11px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        transform: translateY(-2px);
    }

    #server_time {
        position: relative;
        left: 18px !important;
        color: #C0C0C0;
        font-size: 12px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 250px;
        height: 300px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        transform: translateY(40%);
        transition: transform 0.1s;
        transform-style: preserve-3d;
        animation: slide 0.1s;
        z-index: 100;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        border: 2.7px solid #ff7200;


    }

    @keyframes slide {
        0% {
            transform: translateY(10%);
        }

        50% {
            transform: translateY(20%);
        }

        100% {
            transform: translateY(35%);
        }



    }









    .otp_image {
        width: 75%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 70px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 68px;
    }

    .closeMe {
        width: 33px;
    }

    .otp_image h3 {
        font-size: 15px;
        position: relative;
        top: 55px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;

        /* background-color: aqua; */

    }

    #otp_input {
        font-size: 0.9rem;
        padding: 5px;
        border: 2px solid #ccc;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 15px;
        width: 72%;
        height: 35px;
        font-size: 12px;
        letter-spacing: 1px;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 25px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }
}

@media only screen and (max-width:401px) {
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;

    }

    .show_verifying_div {
        display: none;
    }

    .show_secure_div {
        display: none;
    }

    .show_verifying_div_1 {
        display: none;
    }

    .verified_yes {
        display: none;
    }

    .not_verified {
        display: none;
    }

    #root {
        height: 0px;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ff7200 !important;
        border-radius: 7px;

    }

    #scrolling_to_top {
        background-color: transparent;
        z-index: 100;
        width: 35px;
        box-shadow: 1px 1px 4px black;
        height: 35px;
        background: rgb(194, 189, 189) no-repeat;
    }

    #scrolling_to_top:hover {
        background-color: #ff7200;
    }

    .home_div_outer {
        width: 100%;
        height: 350px;
        position: relative;
        top: -6px;
        display: flex;
        background-color: #131313;
        overflow: hidden;
    }

    .bgimg1 {
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -100px;
    }

    .bgimg2 {
        position: absolute;
        width: 100%;
        height: 400px;
        bottom: 0px;
    }

    .newLynkElem {
        width: 50%;
        height: 70%;
        position: relative;
        top: 80px;
    }

    .newLynkElem1 {
        width: 48%;
        height: 70%;
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blockchain {
        width: 90%;
        height: 64%;
    }

    .newLynkRow {
        width: 85%;
        height: 37%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
    }

    .newLynkRow1 {
        width: 85%;
        height: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .newLynkHead {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .bigElem1 {
        width: auto;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .bigElem1>span {
        font-size: 3.5rem;
        color: #ff7200;
        font-weight: 800;
        /* text-shadow: 0px 0px 5px #E0E0E0; */
    }

    .bigElem2 {
        width: 70%;
        height: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-around;
        position: relative;
        top: -15px;
    }

    .headSpan1 {
        font-size: 1rem;
        color: #989898;
        position: relative;
        top: 8px;
    }

    .headSpan2 {
        font-size: 1.4rem;
        color: #ff7200;
        font-weight: 700;
        position: relative;
        bottom: 1px;
    }

    .newLynkHeadBtn {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .nodebtn {
        width: 90%;
        height: 40px;
        border-radius: 35px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff7200;
        cursor: pointer;
        box-shadow: inset 0px 1px 10px #606060;
    }

    .nodebtn:hover {
        box-shadow: 1px 1px 10px #606060;
    }

    .btnImg {
        width: 6%;
        margin-right: 7px;
    }

    .btnSpan {
        font-size: 8px;
        color: #fff;
        font-weight: 500;
    }

    .btnNewSpan {
        font-size: 8px;
        color: #fff;
    }

    /*  */

    .lynksign_work_div {
        width: 100%;
        height: 859px;
        position: relative;
        top: -7px;
        background-color: rgb(187, 184, 184);

    }

    .lynksign_work_inner {
        width: 100%;
        height: 50px;
        position: relative;
        text-align: center;
        position: relative;
        top: 20px;
        /* background-color: #ff7200; */
    }

    .lynksign_work_inner h2 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;

    }

    #lynk_color {
        color: #ff7200;
    }

    /*  */
    .working_steps {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 84%;
        position: relative;
        top: 55px;
        display: flex;
        display: none;
        /* background-color: antiquewhite; */
    }

    /*  */
    .working_steps_mobile_1 {
        width: 95%;
        height: 320px;
        position: relative;
        top: 50px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .one_mobile {
        width: 100%;
        height: 40%;
        /* background-color: aqua; */
        display: flex;
    }

    .one_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 10px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .one_text_mobile p {
        font-size: 11px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .one_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 0px;
    }

    .one_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_one_mobile {
        font-size: 50px;
        position: relative;
        left: 87%;
        top: 87px;
        transform: rotate(35deg);
        color: #ff7200;
    }

    .two_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;

    }

    .two_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 7px;

    }

    .two_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .lock_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .two_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }

    .two_text_mobile p {
        font-size: 11px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    #arrow_two_mobile {
        font-size: 50px;
        position: relative;
        left: 47%;
        top: 30px;
        transform: rotate(136deg);
        color: #ff7200;
    }

    /*  */

    .working_steps_mobile_2 {
        width: 95%;
        height: 350px;
        position: relative;
        top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /* background-color: red; */
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        /* background-color: blue; */
    }

    .three_text_mobile {
        width: 50%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: pink; */
    }

    .three_text_mobile p {
        font-size: 11px;
        position: relative;
        top: 24px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .three_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 20px;
    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .folder_mobile {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    #arrow_three_mobile {
        font-size: 50px;
        position: relative;
        left: 90%;
        top: 90px;
        transform: rotate(40deg);
        color: #ff7200;
    }

    .three_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 0px;
        display: flex;
        /* background-color: blue; */
    }

    .three_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        left: 3px;
        top: 7px;

    }

    .three_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .pen_mobile {
        width: 55px;
        height: 55px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto
    }

    .four_mobile {
        width: 100%;
        height: 40%;
        position: relative;
        top: 10px;
        display: flex;
    }

    .four_text_mobile {
        width: 50%;
        height: 70%;
        position: relative;
        top: 26px;
        left: 10px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blueviolet; */

    }

    .four_text_mobile p {
        font-size: 11px;
        position: relative;
        top: 10px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .four_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        right: 12px;
        top: 7px;

    }

    .four_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    #arrow_four_mobile {
        font-size: 50px;
        position: relative;
        left: -2%;
        top: 126px;
        transform: rotate(140deg);
        color: #ff7200;
    }


    .five_mobile {
        width: 100%;
        height: 40%;
        display: flex;
        position: relative;
        top: 22px;
        /* background-color: blue; */
    }

    .five_text_mobile {
        width: 45%;
        height: 80px;
        position: relative;
        top: 20px;
        left: 5px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: blue; */
    }

    .five_text_mobile p {
        font-size: 11px;
        position: relative;
        top: 6px;
        color: black;
        font-family: 'Montserrat', sans-serif;
    }

    .five_dotted_mobile {
        width: 113px;
        height: 110px;
        margin-right: auto;
        margin-left: auto;
        border: 2px dashed black;
        border-radius: 50%;
        text-align: center;
        position: relative;
        top: 6px;
        left: 0px;
    }

    .five_inner_mobile {
        width: 86%;
        margin-left: auto;
        margin-right: auto;
        height: 86%;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .equal_mobile {
        width: 39px;
        height: 30px;
        position: relative;
        top: 0px;
    }

    .not_equal_mobile {
        width: 39px;
        height: 44px;
        position: relative;
        bottom: 0px;
    }

    /*  */





    /*  */
    .lynksign_for {
        width: 100%;
        height: 880px;
        background-color: #3d3d3d;
        position: relative;
        top: -7px;
    }

    #lynk_clr {
        color: #fff;
    }

    #color_orange {
        color: #ff7200;
    }

    .lynksign_for_inner {
        width: 100%;
        height: 50px;
        position: relative;
        top: 25px;
        text-align: center;
    }

    .lynksign_for_inner h2 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;

    }

    /*  */
    .lynksign_data {
        width: 90%;
        height: 55%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 110px;
        justify-content: space-between;
        display: none;
        /* background-color: pink; */

    }

    .lynksign_data_mobile {
        width: 75%;
        height: 81%;
        position: relative;
        top: 58px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .curved_1_mobile {
        width: 76%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_1_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_1_mobile p {
        position: relative;
        top: 48px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }

    .curved_2_mobile {
        width: 76%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_2_mobile img {
        width: 66px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_2_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }

    .curved_3_mobile {
        width: 76%;
        margin-right: auto;
        margin-left: auto;
        height: 207px;
        border-radius: 30px;
        border: 4px solid #ff7200;
        align-items: center;
        text-align: center;
    }

    .curved_3_mobile img {
        width: 50px;
        height: 66px;
        position: relative;
        top: 30px;
    }

    .curved_3_mobile p {
        position: relative;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
        color: white;
    }



    /*  */
    .secure_verify_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;





    }

    .hide_secure_div {
        display: none;
    }

    .secure_verify_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
        display: none;
    }

    .secure_mobile {
        width: 100%;
        height: 420px;
    }

    .secure_main_mobile {
        width: 100%;
        height: 420px;
        position: relative;
        top: -380px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_secure_main_mobile {
        display: none
    }

    .secure_mobile_inner {
        width: 88%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 40px;
        background-color: #000;
    }

    .secure_mobile_head {
        width: 100%;
        height: 60%;
        text-align: center;
        /* background-color: burlywood; */
    }

    .secure_mobile_head h2 {
        font-size: 30px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #secure__mobile_color {
        color: #ff7200;
    }

    .secure_mobile_head h4 {
        position: relative;
        top: 70px;
        font-weight: 400;
        font-size: 13px;
        color: #fff;
    }

    #upload_pdf {
        font-size: 15px;
        position: relative;
        top: 109px;
    }

    .choosse_div_mobile {
        width: 100%;
        height: 35%;
        /* background-color: #ff7200; */
        text-align: center;
    }

    .choose_div_btn_mobile {
        width: 40%;
        height: 44px;
        position: relative;
        top: 56px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        transform: translateY(-6px);
    }

    .choose_div_btn_mobile:active {
        transform: translateY(-2px);
    }

    #overlap_text_mobile {
        font-size: 12px;
        font-weight: 400;
    }

    .securing_file_div {
        width: 100%;
        height: 555px;
        position: relative;
        top: -369px;
        display: block;
        background-color: #E8E8E8;
    }

    .hide_securing_file_div {
        display: none;
    }

    .securing_file_inner {
        width: 88%;
        height: 459px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 1px 1px 10px black;
        position: relative;
        top: 53px;
        background-color: #000;

    }

    .file_securing {
        width: 100%;
        height: 19%;
        border: 2px solid silver;
        border-right-color: transparent;
        border-left-color: transparent;
        border-top-color: transparent;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* background-color: red; */
    }

    .file_securing p {
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 5px;
        left: 15px;
        font-weight: 400;
        color: #fff;
    }

    .another_upload_mob {
        width: 90px;
        height: 31px;
        position: relative;
        top: 4px;
        background-color: #ff7200;
        border: 0.2px solid #ff7200;
        color: #fff;
        font-size: 9px;
        font-weight: 700;
        border-radius: 11px;

    }

    #file_name_mob {
        position: relative;
        left: 6px;
        color: #C0C0C0;
        ;
        font-size: 12px;
        font-weight: 700;
        border-bottom: 3px solid #C0C0C0;
        ;
    }


    .securing_file_daata {
        width: 100%;
        height: 82%;
        display: block;
    }

    .securing_file_daata form {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .securing_file_daata form label {
        font-size: 11px;
        font-weight: 600;
        position: relative;
        top: 70px;
        color: #fff;
    }

    #fetched_location {
        width: 170px;
        height: auto;
        display: inline-block;
        /* border: 1px solid red; */
        text-align: start;
        font-size: 10px;
        position: absolute;
        top: -10px;
        left: 45px;
        font-weight: 600;
        color: #C0C0C0;
        z-index: 1;
        background-color: #000;
        word-wrap: break-word;
    }

    #hide_fetched_location {
        display: none;
    }

    #remove {
        position: absolute;
        left: 216px;

        cursor: pointer;
        color: #fff;
    }

    #label_timee_mob {
        position: relative;
        left: -40px;
    }

    .securing_file_daata form input {
        position: relative;
        top: 70px;
        position: relative;
        left: -2px;
        width: 165px;
    }

    .sign_mobile {
        width: 34%;
        height: 36px;
        position: relative;
        top: 45px;
        margin-right: auto;
        margin-left: auto;
        border: none;
        border-radius: 10px;
        background-color: #ff7200;
        transform: translateY(-6px);
    }

    .sign_mobile:active {
        transform: translateY(-2px);
    }

    .verify_mobile {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -382px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verify_mobile {
        display: none;
    }

    .verify_mob_inner {
        width: 88%;
        height: 76%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;

    }

    .verify_mob_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_head h2 {
        font-size: 30px;
        position: relative;
        top: 23px;
        color: #C0C0C0;
    }

    #verify_mob_clr {
        color: #ff7200;
    }

    .verify_mob_head h4 {
        font-size: 13px;
        font-weight: 400;
        position: relative;
        top: 65px;
        color: #fff;
    }

    .verify_mob_upload {
        width: 90%;
        height: 150px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_mob_upload h2 {
        font-size: 15px;
        position: relative;
        top: 40px;
        color: #C0C0C0;
    }

    .verify_mob_upload button {
        width: 35%;
        height: 38px;
        background-color: #ff7200;
        color: #000;
        border: none;
        border-radius: 10px;
        position: relative;
        top: 90px;
        font-size: 12px;
    }

    .verfiy_mob_1 {
        width: 100%;
        height: 430px;
        background-color: #fff;
        position: relative;
        top: -380px;
        background-color: #E8E8E8;
        display: block;
    }

    .hide_verfiy_mob_1 {
        display: none;
    }

    .verify_mob_inner_1 {
        width: 88%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #383838;
        box-shadow: 1px 1px 10px black;
    }

    .verify_1_head {
        width: 90%;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .verify_1_head h2 {
        font-size: 30px;
        position: relative;
        top: 27px;
        color: #C0C0C0;
    }

    .verify_1_head h4 {
        font-size: 13px;
        font-weight: 400;
        position: relative;
        top: 74px;
        color: #fff;
    }

    .verifying_1_form {
        width: 100%;
        height: 180px;
    }

    .verifying_1_form form {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .verifying_1_form form label {
        position: relative;
        top: 50px;
        font-weight: 500;
        color: #fff;
    }

    #get_id_mob {
        position: relative;
        top: 0px;
        left: 10px;
        width: 110px;
        background-color: #383838;
    }

    .choose_div_btn_1_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 60px;
        left: -10px;
    }

    #overlap_text_1_mob {
        color: #000;
        font-size: 10px;
        font-weight: 400;
    }

    .submit_id_mob {
        width: 35%;
        height: 37px;
        background-color: #ff7200;
        border-radius: 10px;
        border: none;
        position: relative;
        top: 20px;
        color: #000;
        top: 60px;
        left: 10px;
        font-size: 10px;
    }


    /* YESSS MOBILE */

    .verifying_yes_mob {
        width: 100%;
        height: 480px;
        background-color: #E8E8E8;
        position: relative;
        top: -380px;
        /* background-color: rgb(247,245,245); */
        display: block;
    }

    .hide_verifying_yes_mob {
        display: none;
    }

    .verifying_yes_mob_in {
        width: 90%;
        height: 84%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50px;
        border-radius: 12px;
        background-color: #212121;
        /* box-shadow: 1px 1px 10px black; */
    }

    .yes_details {
        width: 95%;
        height: 70px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .yes_details h2 {
        font-size: 18px;
        position: relative;
        top: 17px;
        color: #909090;
    }

    .yes_details_more {
        width: 100%;
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        position: relative;
        top: 30px;
    }

    .newYes {
        width: 100%;
        height: 40px;
        display: flex;
    }

    .dataSpan {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .dataSpan span {
        font-size: 0.8rem;
        font-weight: 500;
        padding-right: 15px;
        color: #fff;
    }

    .dataField {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        text-align: left;
        /* background-color: red; */
    }

    .dataField span {
        font-size: 0.8rem;
        color: #C0C0C0;
    }

    #mob_name {
        position: absolute;
        left: 70px;
        top: 30px;
        font-size: 12px;
    }

    #mob_email {
        position: absolute;
        left: 70px;
        top: 70px;
        font-size: 12px;
    }

    #mob_place {
        position: absolute;
        left: 70px;
        top: 110px;
        font-size: 12px;
    }

    #mob_time {
        position: absolute;
        left: 70px;
        top: 150px;
        font-size: 12px;
    }

    #special_green_mob {
        font-size: 15px;
        color: green;
        position: relative;
        top: 0px;
        font-weight: 600;
    }

    #mob_yes_clrs {
        padding-left: 12px;
        color: red;
        font-size: 11px;
        font-weight: 400;
    }

    .mob_another_btn {
        width: 99px;
        height: 28px;
        background-color: #ff7200;
        border: none;
        color: #000;
        font-size: 10px;
        border-radius: 10px;
        position: relative;
        top: 12px;
    }

    /*  */

    .verifying_no_mob {
        width: 100%;
        height: 420px;
        background-color: #E8E8E8;
        display: block;
        position: relative;
        top: -380px;

    }

    .hide_verifying_no_mob {
        display: none;
    }

    .verifying_no_inn_mob {
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 40px;
        text-align: center;
        border-radius: 10px;
        background-color: #212121;
        box-shadow: 1px 1px 10px black;
    }

    .question_mob {
        width: 90%;
        height: 90%;
        margin-left: auto;
        margin-right: auto;

    }

    .question_mob h2 {
        font-size: 16px;
        position: relative;
        top: 20px;
        color: #909090;
    }

    #cross_mob {
        font-size: 79px;
        color: red;
        position: relative;
        top: 66px;
    }

    .wrong_mob_btn {
        width: 100px;
        height: 37px;
        font-size: 10px;
        background-color: red;
        border: none;
        color: white;
        position: relative;
        top: -15px;
        border-radius: 10px;
    }

    .secure_left {
        width: 50%;
        height: 100%;
        background-color: #000;
        border-radius: 0px 45px 45px 0px;
        box-shadow: 0px 1px 8px black inset;
        /* background-color: antiquewhite; */

    }

    .secure_inner {
        width: 100%;
        height: 40%;
        position: relative;
        top: 25px;
        text-align: center;

    }

    .secure_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Montserrat', sans-serif;


    }

    .secure_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 34px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #secure_color {
        color: #ff7200;

    }

    .upload_secure {
        width: 100%;
        height: 20%;
        text-align: center;


    }

    .upload_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }


    .choosse_div {
        width: 100%;
        height: 100px;
        position: relative;
        top: 20px;
        text-align: center;

    }

    .choose_div_btn {
        width: 40%;
        height: 49px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        top: 34px;

        transform: translateY(-6px);
        border-radius: 25px;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn:active {
        transform: translateY(-2px);

    }


    #upload-photo[type=file] {

        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;


    }

    #upload-photo1[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo2[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #upload-photo3[type=file] {
        position: relative;
        top: 50px;
        width: 220px;
        font-size: 17px;
        position: absolute;
        z-index: 1000;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 24px;
        width: 100%;
        cursor: pointer;
    }

    #overlap_text {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_right {
        width: 50%;
        height: 80%;
        background-color: #212121;

    }

    .verify_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        /* background-color: pink; */
    }

    .verify_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 10px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 35px;
        color: #fff;

        font-family: 'Montserrat', sans-serif;
    }

    #verify_color {
        color: #ff7200;
    }

    .verify_secure {
        width: 100%;
        height: 40%;
        text-align: center;

    }

    .verify_secure h2 {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 50px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .verify_secure button {
        width: 300px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
        top: 27px;
        background-color: rgb(122, 122, 122);
        border: none;



    }


    .choose_div_1 {
        width: 50%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: #ff7200; */
        position: relative;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

    .choose_div_1 button {
        width: 81%;
        height: 49px;
        border: none;
        background-color: #ff7200;
        font-size: 12px;
        color: black;
        cursor: pointer;
        transform: translateY(-6px);
        font-family: 'Encode Sans Semi Condensed', sans-serif;
        border-radius: 22px;
        transform: translateY(-6px);
        box-shadow: 0px 3px rgb(78, 40, 40);
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;

    }

    .choose_div_1 button:active {
        transform: translateY(-2px);

    }






    /*  */
    /*  */
    .toggle_secure_div {

        display: none;
    }

    .show_secure_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -7px;


    }

    .securing_main_div {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
        border-radius: 8px;
    }



    .securing_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 1px 8px black;
        background-color: #000;

    }

    #securing {
        color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }

    .securing_inner {
        width: 100%;
        height: 50%;
        position: relative;
        top: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .securing_inner h2 {
        font-size: 30px;
        padding-left: 20px;
        padding-top: 15px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    .securing_inner h4 {
        font-size: 14px;
        font-weight: 500;
        padding-top: 50px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    .upload_securing {
        width: 100%;
        height: 33%;
        position: relative;
        top: 50px;
        text-align: center;


    }

    .upload_btn {
        position: absolute;
        top: 100px;
        left: 130px;
        width: 150px;
        height: 35px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(-6px);
        border-radius: 6px;
        border: none;
        box-shadow: 0px 2px rgb(78, 40, 40);

    }

    .upload_btn:active {
        transform: translateY(-2px);


    }

    #main_file {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        text-decoration: underline;
    }

    .another_upload {
        position: relative;
        top: 50px;
        right: 0px;
        width: 141px;
        height: 45px;
        border: none;
        background-color: #ff7200;
        cursor: pointer;
        color: black;
        font-family: 'Montserrat', sans-serif;
        border-radius: 32px;
        border: none;
        font-size: 11px;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);

    }

    .another_upload:active {
        transform: translateY(-2px);
    }


    .upload_securing h2 {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .securing_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .securing_form {
        width: 100%;
        height: 100%;
        /* background: pink; */
        margin-left: auto;
        margin-right: auto;
    }

    .securing_form form {
        width: 100%;
        height: 85%;
        margin-left: auto;
        margin-right: auto;
        /* background-color: rgb(177, 177, 44); */
        position: relative;
        top: 40px;
        text-align: center;
    }

    .securing_form form label {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 32px;
        text-align: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
    }

    #label_timee {
        position: relative;
        left: 16px;
    }

    input {
        outline: 0;
        border-width: 0 0 2px;
        border-color: #505050;
        position: relative;
        background-color: #212121;
        top: 30px;
        color: #C0C0C0;
    }

    .sign {
        width: 137px;
        height: 43px;
        background-color: #ff7200;
        border: none;
        position: relative;
        top: 53px;
        left: 0px;
        cursor: pointer;
        box-shadow: 0px 2px rgb(78, 40, 40);
        transform: translateY(-6px);
        border-radius: 22px;
        font-family: 'Montserrat', sans-serif;
    }




    .sign:active {
        transform: translateY(-2px);
    }


    /*  */

    /* .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        
        
    }
    /* .overlay{
        background-color: rgba(49,49,49,0.8);
    } */

    .modal_content {
        position: absolute;
        width: 50%;
        height: 40%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        margin-left: auto;
        margin-right: auto;

    }

    /*  */
    .show_verifying_div {
        width: 100%;
        height: 360px;
        position: relative;
        top: -10px;
        /* background-color: red; */

    }

    .hide_verifying_div {
        display: none;
    }

    .inner_verifying {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .inner_verifying_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        background-color: #000;
        box-shadow: 0px 1px 8px black;
    }


    .text_img {
        width: 100%;
        height: 100%;
        text-align: center;

    }

    .text_img h3 {
        font-size: 30px;
        position: relative;
        top: 60px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    #question {
        font-size: 126px;
        position: relative;
        top: 100px;
        color: #ff7200;
    }

    .inner_verifying_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify {
        width: 90%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .heading_verify h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #C0C0C0;
    }

    #verifying_text {
        color: #ff7200;
    }

    .heading_verify h4 {
        font-size: 14px;
        padding-top: 30px;
        font-weight: 400;
        position: relative;
        top: 25px;
        color: #C0C0C0;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form_verifying_1 {
        width: 100%;
        height: 55%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* background-color: antiquewhite; */
    }

    .form_verifying_1 form {
        width: 100%;
        height: 74%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 50px;
        /* background-color: antiquewhite; */
    }

    #to_left {
        position: absolute;
        left: 3%;
        font-size: 16px;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #to_left1 {
        position: absolute;
        font-size: 16px;
        bottom: 100px;
        left: 3%;
        font-weight: 600;
        display: none;
        color: #fff;
    }

    #special_text_1 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 13px;
        position: relative;
        top: 0px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2 {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        left: 0px;
        display: inline-block;

    }

    #special_text_1_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -50px;
        left: 0%;
        display: inline-block;

    }

    #special_text_2_new {
        color: #C0C0C0;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 11px;
        position: relative;
        top: -72px;
        left: 0px;
        display: inline-block;

    }

    #get_id {
        position: relative;
        top: 0px;
        width: 110px;
    }

    .form_verifying form {
        width: 100%;
        height: 70%;
        /* background-color: green; */
        position: relative;
        top: 50px;
        /* border: 2px dashed silver; */
    }

    .form_verifying form label {
        font-size: 16px;
        color: #C0C0C0;
        position: relative;
        top: 15px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .form_verifying form p {
        font-size: 20px;
    }

    .choose_div_btn_1 {
        width: 120px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #ff7200;
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;
        transform: translateY(-6px);
        border-radius: 12px;
        border: none;
        top: 44px;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .choose_div_btn_1:active {
        transform: translateY(-2px);

    }

    #overlap_text_1 {
        font-size: 11px;
        position: relative;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        display: inline-block;
        height: 0px;
        font-family: 'Montserrat', sans-serif;
    }


    input {
        outline: 0;
        border-width: 0 0 2px;
        position: relative;
        left: 30px;
        background-color: #212121;
        color: #C0C0C0;
    }

    .submit_id {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 37px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id:active {
        transform: translateY(-2px);
    }

    .submit_id_1 {
        width: 120px;
        height: 40px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: -36px;
        left: 20px;
        font-size: 11px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 12px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_1:active {
        transform: translateY(-2px);
    }





    .show_verifying_div_1 {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;
        /* background-color: pink; */
    }

    .hide_verifying_div_1 {
        display: none;
    }

    .inner_verifying_1 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    #special_text {
        color: black;
        font-weight: 1000;
        text-decoration: underline;
        font-size: 17px;
        position: relative;
        top: 0px;
        display: inline-block;

    }


    .pdf_viewer {
        width: 100%;
        height: 600px;
    }

    .signature {
        width: 100%;
        height: 230px;
        /* border: 2px solid black; */


    }


    .hide_signature {
        display: none;
    }

    #imageCanvas {
        width: 15%;
        height: 230px;


        /* border: 2px solid black; */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #aa {

        position: absolute;
        display: flex;
        margin-left: auto;

    }















    .signature1 {
        width: 100%;
        height: 200px;
        /* border: 2px solid black; */
    }


    #canvas_id {
        width: 50%;
        display: flex;
        height: 160px;
        border: 2px solid black;
        position: relative;
        top: 55px;
        margin-left: auto;
        margin-right: auto;
        z-index: 22;
    }

    .close_btn {
        width: 110px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        background-color: #ff7200;
        position: absolute;
        top: 20px;
        right: 20px;

    }

    #locatione {
        color: #ff7200;
        font-size: 7px;
        position: absolute;
        top: 67%;
        left: 50%;
        cursor: pointer;
        width: auto;
        z-index: 1;
    }

    #location_icon {
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
    }

    #fetched_location_mob {

        font-size: 10px;
        position: relative;
        left: 22px;
        top: -35px;
        font-weight: 600;
        color: green;
        padding-left: 80px;
    }



    .verified_yes {
        width: 100%;
        height: 400px;
        position: relative;
        top: -9px;

    }

    .hide_verified_yes {
        display: none;
    }


    .verified_yes_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .verified_yes_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .yes_1 {
        width: 96%;
        height: 25%;
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;


    }

    .yes_1 h2 {
        font-size: 18px;
        position: relative;
        top: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #909090;
    }



    .yes_2 {
        width: 100%;
        height: 70%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 20px;
        text-align: center;
        /* background-color: green; */

    }

    .yes_2_inner {
        width: 100%;
        height: 70%;
        /* background-color: yellow; */
        margin-left: auto;
        margin-right: auto;
        position: relative;


    }

    .label_name {
        position: absolute !important;
        left: -47px !important;



    }

    .label_mail {
        position: absolute;
        right: 0px !important;
    }

    .label_place {
        position: relative;
        right: 65px !important;

    }

    .label_time {

        position: relative !important;
        right: 1px !important;
    }


    .yes_2 label {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        right: 20px;
        top: 40px;
    }

    #special_green {
        font-size: 16px;
        font-family: 'Caveat', cursive;
        color: green;
        position: relative;
        bottom: -14px;
    }

    #special_color1 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 8px;
        font-size: 15px !important;
    }

    #special_color2 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 14px;
        font-size: 15px !important;
    }

    #special_color3 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 12px;
        font-size: 15px !important;
    }

    #special_color4 {
        color: #C0C0C0;
        font-family: 'Caveat', cursive;
        position: relative;
        left: 50px;
        font-size: 15px !important;
    }


    .verified_yes_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }

    .heading_verify_new {
        width: 80%;
        height: 40%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        /* background-color: antiquewhite; */
    }

    .heading_verify_new h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        color: #C0C0C0;
        top: 5px;
        font-family: 'Montserrat', sans-serif;
    }

    #white_text {
        color: #ff7200;
    }


    .heading_verify_new h4 {
        font-size: 14px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new {
        width: 100%;
        height: 47%;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        /* background-color: antiquewhite; */
        align-items: center;
    }



    #trxnid {
        position: absolute;
        left: 8%;
        top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: white;
        display: none;
    }



    #special_text_white {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 1%;
        text-decoration: underline;
        top: 0px;
        font-size: 14px;

    }

    #choose_file {
        position: absolute;
        left: 8%;
        top: 61px;
        font-weight: 600;
        color: white;
        font-size: 18px;
        display: none;

    }

    #special_text_white1 {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        font-weight: 400;
        left: 3%;
        top: 0px;
        font-size: 15px;
        display: none;
    }


    #special_text_white_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        /* left: 1%; */
        text-decoration: underline;
        top: 0px;
        font-size: 9px;

    }

    #special_text_white1_new {
        color: #C0C0C0;
        text-decoration: underline;
        position: relative;
        display: block;
        font-weight: 400;
        /* left: 3%; */
        top: 20px;
        font-size: 9px;
    }

    .submit_id_green {
        width: 120px;
        height: 33px;
        background-color: #ff7200;
        border: none;
        color: #000;
        position: relative;
        top: 65px;
        font-size: 11px;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    .submit_id_green:active {
        transform: translateY(-2px);
    }

    /*  */

    .not_verified {
        width: 100%;
        height: 360px;
        position: relative;
        top: -8px;


    }

    .hide_not_verified {
        display: none;
    }


    .not_verified_inner {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        display: flex;
        background-color: #212121;
    }

    .not_verified_left {
        width: 50%;
        height: 100%;
        border-radius: 0px 45px 45px 0px;
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
    }

    .no_1 {
        width: 92%;
        height: 90%;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        top: 40px;
        text-align: center;


    }

    .no_1 h2 {
        font-size: 18px;
        position: relative;
        top: 15px;
        color: #909090;
        font-family: 'Montserrat', sans-serif;
    }

    #cross {
        font-size: 98px;
        position: relative;
        top: 80px;
        color: red;
    }

    .not_verified_right {
        width: 50%;
        height: 100%;
        background-color: #212121;
    }


    .heading_verify_new_1 {
        width: 100%;
        height: 40%;
        text-align: center;
    }

    .heading_verify_new_1 h2 {
        font-size: 30px;
        padding-top: 40px;
        position: relative;
        top: 12px;
        font-family: 'Montserrat', sans-serif;
    }

    .heading_verify_new_1 h4 {
        font-size: 18px;
        padding-top: 40px;
        position: relative;
        top: 14px;
        color: white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;

    }

    .form_verifying_new form {
        width: 100%;
        height: 80%;
        margin-right: auto;
        margin-left: auto;
        /* background-color: pink; */
        position: relative;
        top: 30px;
        text-align: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;

    }

    #trxnid_no {
        position: absolute;
        left: 3%;
        top: 14px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        display: none;
    }

    #choose_file_no {
        position: absolute;
        left: 3%;
        top: 70px;
        font-weight: 600;
        color: white;
        font-size: 15px;
        display: none;
    }

    #special_text_white2 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 0px;
        text-decoration: underline;
        top: 0px;
        display: none;
        font-size: 11px;
    }


    #special_text_white3 {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        left: 7px;
        text-decoration: underline;
        top: 0px;
        font-size: 11px;
        display: none;
    }

    #special_text_white2_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;

        text-decoration: underline;
        top: 10px;
        font-size: 8px;
        display: block;
    }


    #special_text_white3_new {
        color: #C0C0C0;
        position: relative;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        top: 17px;
        font-size: 8px;
    }

    .submit_id_red {
        width: 120px;
        height: 35px;
        background-color: red;
        border: none;
        color: #fff;
        position: relative;
        top: 51px;
        font-size: 11px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(0px);
        border-radius: 6px;
        box-shadow: 0px 3px rgb(78, 40, 40);

    }

    #verifying_text_white {
        color: #ff7200;
    }

    .submit_id_red:active {
        transform: translateY(-2px);
    }

    #server_time {
        position: relative;
        left: -15px;
        color: #C0C0C0;
        font-size: 10px;
        font-family: 'Caveat', cursive;
        font-family: 'Poppins', sans-serif;
    }

    /*  */
    /*  */
    .active_modal {
        overflow-y: hidden;
    }

    .email_pop_up {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    .hide_email_pop {
        display: none;
    }

    .email_inner {
        width: 250px;
        height: 300px;
        /* background-color: yellow; */
        border: 1px solid black;
        margin-left: auto;
        z-index: 100;
        margin-right: auto;
        text-align: center;
        border-radius: 11px;
        position: relative;
        top: 0px;
        background-color: white;
        transform: translateY(40%);
        transition: transform 0.1s;
        transform-style: preserve-3d;
        animation: slide 0.1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        border: 2.7px solid #ff7200;


    }

    @keyframes slide {
        0% {
            transform: translateY(10%);
        }

        50% {
            transform: translateY(20%);
        }

        100% {
            transform: translateY(35%);
        }



    }









    .otp_image {
        width: 75%;
        height: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    #mail {
        font-size: 68px;
        position: relative;
        top: 35px;
        color: #ff7200;
        width: 68px;
    }

    .closeMe {
        width: 33px;
    }

    .otp_image h3 {
        font-size: 15px;
        position: relative;
        top: 55px;
        color: black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .otp_bottom {
        width: 100%;
        height: 50%;
        position: relative;
        /* background-color: aqua; */

    }

    #otp_input {
        font-size: 0.85rem;
        padding: 5px;
        border: 2px solid #ccc;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        position: relative;
        left: 0px;
        top: 15px;
        width: 72%;
        height: 35px;
        font-size: 11px;
        letter-spacing: 1px;
        color: #000 !important;
    }

    .otp_btn {
        width: 72%;
        height: 35px;
        border-radius: 5px;
        position: relative;
        top: 25px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #ff7200;
        font-family: 'Montserrat', sans-serif;
    }




























}