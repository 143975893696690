.formElem{
    width: 100%;
    height:100vh;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    background-color: #181818;
    overflow: scroll !important;
}
.innerFormElem{
    width: 50%;
    height: 80%;
    position: relative;
    top: 35px;
    background-color: #080808;
    box-shadow: 0px 0px 6px #ff7200;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 20px;
}
.formRow{
    width: 90%;
    height: 45px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    position: relative;
   
}
.myInp{
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0px;
    margin-left: auto;
    margin-right: auto;
    background-color: #080808;
    border-bottom: 1px solid #ff7200;
}
.noBrdr{
    border: none;
}
.myInp::placeholder{
    color: #ff7200 !important;
    letter-spacing: 2px;
    font-size: 0.7rem;
    font-weight: 400;
}
.myInp{
    color: #C0C0C0;
    letter-spacing: 2px;
    font-size: 0.7rem;
    font-weight: 400;
    position: relative;
    left: 0px;
}
.flexCol{
    display: flex;
    flex-direction: column;
   height: 60px;
   justify-content: space-between;
    /* background-color: pink; */
}
.flexCol>span{
    color: #ff7200;
    letter-spacing: 2px;
    font-size: 0.7rem;
    font-weight: 400;
    padding-left: 5%;
}
.textArea{
    width: 90%;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    background-color: #080808;
    color: #C0C0C0;
    padding-left: 6px;
    padding-top: 2px;
    border: 1px solid #ff7200;
    border-radius: 5px;
    resize: none;
}
.textArea:focus{
    outline: none;
}
.requiredText{
    color: #fff !important;
    font-size: 0.65rem !important;
}
.subBtn{
    width: 20%;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ff7200;
    background-color: #ff7200;
    border-radius: 3px;
    cursor: pointer;
}
.plane{
    color: #fff;
}
@media only screen and (min-width:1550px){
    .formElem{
        width: 100%;
        height:100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #181818;
        overflow: scroll !important;
        position: relative;
    }
    .innerFormElem{
        width: 55%;
        height: 80%;
        position: relative;
        top: 35px;
        background-color: #080808;
        box-shadow: 0px 0px 6px #ff7200;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
    }
    .formRow{
        width: 90%;
        height: 45px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
       
    }
    .myInp{
        width: 90%;
        height: 100%;
        position: absolute;
        top: 0px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        border-bottom: 1px solid #ff7200;
    }
    .noBrdr{
        border: none;
    }
    .myInp::placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.8rem;
        font-weight: 400;
    }
 
    .myInp{
        color: #C0C0C0 !important;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
        position: relative;
        left: 0px;
    }
    .flexCol{
        display: flex;
        flex-direction: column;
       height: 90px;
       justify-content: space-between;
        /* background-color: pink; */
    }
    .flexCol>span{
        color: #ff7200;
        letter-spacing: 2px;
        font-size: 0.8rem;
        font-weight: 400;
        padding-left: 5%;
    }
    .textArea{
        width: 90%;
        height: 60px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        color: #C0C0C0;
        padding-left: 6px;
        padding-top: 2px;
        border: 1px solid #ff7200;
        border-radius: 5px;
        resize: none;
    }
    .textArea:focus{
        outline: none;
    }
    .requiredText{
        color: #fff !important;
        font-size: 0.7rem !important;
    }
    .subBtn{
        width: 20%;
        height: 35px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #ff7200;
        background-color: #ff7200;
        border-radius: 3px;
        cursor: pointer;
    }
    .plane{
        color: #fff;
    }
}
@media only screen and (max-width:1100px){
    
    .innerFormElem{
        width: 70%;
        height: 80%;
        position: relative;
        top: 35px;
        background-color: #080808;
        box-shadow: 0px 0px 6px #ff7200;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
    }
}
@media only screen and (max-width:800px){
    
    .innerFormElem{
        width: 90%;
        height: 80%;
        position: relative;
        top: 35px;
        background-color: #080808;
        box-shadow: 0px 0px 6px #ff7200;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
    }
}
@media only screen and (max-width:600px){
    .formElem{
        width: 100%;
        height:100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #181818;
        overflow: scroll !important;
        position: relative;
    }
    .innerFormElem{
        width: 90%;
        height: 80%;
        position: relative;
        top: 35px;
        background-color: #080808;
        box-shadow: 0px 0px 6px #ff7200;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
    }
    .formRow{
        width: 98%;
        height: 45px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
       
    }
    .myInp{
        width: 90%;
        height: 100%;
        position: absolute;
        top: 0px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        border-bottom: 1px solid #ff7200;
    }
    .noBrdr{
        border: none;
    }
    .myInp::placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
    }
    .myInp{
        color: #C0C0C0 !important;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
        position: relative;
        left: 0px;
    }
    .flexCol{
        display: flex;
        flex-direction: column;
       height: 60px;
       justify-content: space-between;
        /* background-color: pink; */
    }
    .flexCol>span{
        color: #ff7200;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
        padding-left: 5%;
    }
    .textArea{
        width: 90%;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        color: #C0C0C0;
        padding-left: 6px;
        padding-top: 2px;
        border: 1px solid #ff7200;
        border-radius: 5px;
        resize: none;
    }
    .textArea:focus{
        outline: none;
    }
    .requiredText{
        color: #fff !important;
        font-size: 0.6rem !important;
    }
    .subBtn{
        width: 20%;
        height: 35px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #ff7200;
        background-color: #ff7200;
        border-radius: 3px;
        cursor: pointer;
    }
}
@media only screen and (max-width:450px){
    .formElem{
        width: 100%;
        height:100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #181818;
        overflow: scroll !important;
        position: relative;
    }
    .innerFormElem{
        width: 92%;
        height: 80%;
        position: relative;
        top: 35px;
        background-color: #080808;
        box-shadow: 0px 0px 6px #ff7200;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
    }
    .formRow{
        width: 100%;
        height: 45px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
       
    }
    .myInp{
        width: 95%;
        height: 100%;
        position: absolute;
        top: 0px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        border-bottom: 1px solid #ff7200;
    }
    .noBrdr{
        border: none;
    }
    .myInp::placeholder{
        color: #ff7200 !important;
        letter-spacing: 2px;
        font-size: 0.6rem;
        font-weight: 400;
    }
    .myInp{
        color: #C0C0C0 ;
        letter-spacing: 2px;
        font-size: 0.7rem;
        font-weight: 400;
        position: relative;
        left: 0px;
    }
    .flexCol{
        display: flex;
        flex-direction: column;
       height: 60px;
       justify-content: space-between;
        /* background-color: pink; */
    }
    .flexCol>span{
        color: #ff7200;
        letter-spacing: 2px;
        font-size: 0.6rem;
        font-weight: 400;
        padding-left: 2.5%;
    }
    .textArea{
        width: 95%;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: #080808;
        color: #C0C0C0;
        padding-left: 6px;
        padding-top: 2px;
        border: 1px solid #ff7200;
        border-radius: 5px;
        resize: none;
    }
    .textArea:focus{
        outline: none;
    }
    .requiredText{
        color: #fff !important;
        font-size: 0.55rem !important;
    }
    .subBtn{
        width: 24%;
        height: 33px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #ff7200;
        background-color: #ff7200;
        border-radius: 3px;
        cursor: pointer;
    }
}