@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,300;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,300;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');


*{ 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.main_company{
   
    height: 650px;  
    width: 100%;
    position: relative;   
    top: 70px;
   
  
 }          

.sub_main_company{
    background-color: rgb(25,29,29);
    width: 100%;
    height: 485px;
    position: relative;
    top: 0px;
}

.sub_main_company h1{
      font-family: 'Montserrat', sans-serif;
    font-weight:900;
    color: #ff7200;;
    font-size: 59px;
    
    position: relative;  
   
    top: 24px;
   text-align: center;
      font-family: 'Montserrat', sans-serif;
   
}

.sub_main_company p{
   color: #fff;
   padding-left: -10%;
   padding-top: 48px;
   font-weight:300;
   padding-left: 5%;
   padding-right: 6%;
   font-size: 14px;
   letter-spacing: 1.5px;
   
   text-align: justify;
   text-align: center;
     font-family: 'Montserrat', sans-serif;
}

#one_company{
    padding-left: 533px;
    color: #fff;
}
#two_company{
    padding-left: 366px;
    color: #fff;
}
#three_company{
    padding-left: 347px;
    color: #fff; 
}

.sub_main_2{
    position: relative;
    top: -70px;
    width: 100%;
    height: 546px;
    background-color:white;
   
   
} 

/* #arrow1{
    position: relative;
    left: -366%;

} */
 

#Family{
    width: 68%;
    height: 522px;
   
    z-index: 10;
}

::-webkit-scrollbar{
    width: 6px;
}

::-webkit-scrollbar-thumb{
    background-color: #ff7200;
    border-radius: 7px;
    
}
#scrolling_to_top{
    background-color:transparent;
    z-index: 100;
    width: 35px;
    box-shadow: 1px 1px 4px black;
    height: 35px;
    background: rgb(194, 189, 189) no-repeat;

}
#scrolling_to_top:hover{
    background-color: #ff7200;
    }
    
    
    svg{
        vertical-align: middle;
        width: 22px;
        cursor: pointer;
    }
    
    svg:hover{ 
        width: 27px;
    }

    

@media only screen and (min-width:1901px){
   
    *{
        margin: 0px;
        padding: 0px;
    }
    
    html,body{
      margin: 0px;
      padding: 0px;
  }   
    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 18px;
     }
     .about_foooter{
        position: relative;
        top: 1005px;
    }  
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:700;
        color: #ff7200;;
        font-size: 68px;
        position: relative;
        top: 40px;
        font-weight: 900 ;
        letter-spacing: 0px ! important;  
      
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 90%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 18px;
       letter-spacing: 0.5px;
       line-height: 27px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 62%;
        margin-left: auto;
        margin-right: auto;
        height: 866px;
        border: 5px solid #ff7200;
       
       
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }  
    .customer_div1{
        display: none;
    }
    .customer_div{
        width: 100%;
        height: 700px;
        position: absolute;
        top: 2653px;
        display: flex;
        justify-content: center;
    }
    
    .customer_div h1{
         
        font-size: 80px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 39px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 149px;
        background-size:  0% 6px;
        background-position:  bottom;
        letter-spacing: 0px !important;
       
    
    }
    
    .customers_div_1{
        width: 94%;
        height: 198px;
      
        position: absolute;
        top: 230px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 13%;
        height: 198px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 94%;
        height: 198px;
        position: absolute;
        top: 456px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 15%;
        height: 198px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    /* 3 */
    #sch{
        width: 120px !important; 
    }
    
    .customers_div_3 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_3 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customer_images{
        width: 150px;
    }
    
    #cma{
        width: 148px;
        height: 110px;
    }
    
    #bharat{
        width: 130px;
        height: 139px;
    }
    
    #aditya{
        width: 140px;
        height: 130px;
    }
    
    #lg{
        width: 130px;
        height: 70px;
    }
    
    #dadri{
        width: 140px;
        height: 130px;
    }
    
    #hundred{
        position: relative;
        top: 14px;
    
    }
    
    #client_100{
        position: relative;
        top: 14px;
    
    }
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 645px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 50px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
        filter: grayscale(100%);
        
    }
    #more_3500 h1:hover{
        color: black;
    }
    .emptyDiv{
        width: 100%;
        height: 10px;
        position: relative;
        top: -150px;
    }

    
}
@media only screen and (max-width:1901px){
   
      
      *{
          margin: 0px;
          padding: 0px;
      }
      
      html,body{
        margin: 0px;
        padding: 0px;
       
    }
    .about_foooter{
        position: relative;
        top: 925px;
    }   
    .main_company{
       
        height: 530px;
        width: 100%;
        position: relative;
        top: 18px;
       
        
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100% !important;
        height: 780px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px ! important;
        color: #ff7200;;
        font-size: 63px;
        position: relative;
        top: 40px;
        font-weight: 900 !important;
        
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 90%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 17px;
       letter-spacing: 0.5px;
       line-height: 25px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 65%;
        margin-left: auto;
        margin-right: auto;
        height: 796px;
        border: 5px solid #ff7200;
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    
.customer_div1{
    display: none;
}
.customer_div{
    width: 100%;
    height: 700px;
    position: absolute;
    top: 2653px;
    display: flex;
    justify-content: center;
}

.customer_div h1{
     
    font-size: 70px;
    color: black;
    text-shadow: none;
    position: absolute;
    top: 30px;
    font-weight: 900;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 0px;
    background-image:linear-gradient(#ff7200, #ff7200);
    background-repeat: no-repeat;
    line-height: 140px;
    background-size:  0% 6px;
    background-position:  bottom;
    letter-spacing: 0px !important;
   

}

.customers_div_1{
    width: 97%;
    height: 198px;
    /* border: 1px solid red; */
    position: absolute;
    top: 222px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_1 div{
    width: 11%;
    height: 198px;
  
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_1 img{
    filter: grayscale(100%);;
}

.customers_div_1 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}


.customers_div_2{
    width: 97%;
    height: 198px;
    
    position: absolute;
    top: 450px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_2 div{
    width: 11%;
    height: 198px;
 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_2 img{
    filter: grayscale(100%);;
}

.customers_div_2 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}
#sch{
    width: 120px;
}

.customer_images{
    width: 150px;
}

#cma{
    width: 130px;
    height: 100px;
}

#bharat{
    width: 120px;
    height: 134px;
}

#aditya{
    width: 130px;
    height: 120px;
}

#lg{
    width: 150px;
    height: 80px;
}

#dadri{
    width: 120px;
    height: 110px;
}
#teas{
    width: 68%;
}
#hundred{
    position: relative;
    top: 14px;

}

#client_100{
    position: relative;
    top: 14px;

}
#sch{
    width: 55%;
}
#more_3500{
    width: 100%;
    height: 66px;
    position: relative;
    top: 652px;
  
    display: flex;
    align-items: center;
    justify-content: center;
}

#more_3500 h1{
    position: relative;
    font-size: 46px;
    color: #424141;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background-image:linear-gradient(white,white);
    background-repeat: none;
    background-size:  0% 0px;
    filter: grayscale(100%);
    
}
#more_3500 h1:hover{
    color: black;
}
.emptyDiv{
    width: 100%;
    height: 20px;
    position: relative;
    top: -150px;
}

}
@media only screen and (max-width:1550px){
    body{
        margin: 0px;
        padding: 0px;
        box-sizing:border-box ;
    }
    
    .main_company{
        height: 530px;
        width: 100% !important;
        position: absolute;
        top: 70px;        
     }
    .about_foooter{
        position: relative;
        top: 1330px;
    }
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       
        /* border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900 !important;
        color: #ff7200;;
        font-size: 59px;
        position: relative;
        top: 40px;
        letter-spacing: 0px ! important;
      
       
    }
    
    .sub_main_company p{
        /* border: 1px solid green;   */
       color: #fff;
       width: 90%;
       height: 84%;
       padding-left: 0%;
       padding-right: 0%;
       margin-left: auto;
       margin-right: auto;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 15px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        height: 626px;
        border: 4px solid #ff7200;
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    


.customer_div1{
    display: none;
}

.customer_div{
    width: 100%;
    height: 700px;
    position: absolute;
    top: 2445px;
    display: flex;
    justify-content: center;
}
.customer_div h1{    
    font-size: 54px;
    color: black;
    text-shadow: none;
    position: absolute;
    top: 10px;
    font-weight: 900;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 0px;
    background-image:linear-gradient(#ff7200, #ff7200);
    background-repeat: no-repeat;
    line-height: 110px;
    background-size:  0% 4px;
    background-position:  bottom;
    letter-spacing: 0px !important;
}

.customers_div_1{
    width: 95%;
    position: absolute;
    top: 210px;
    display: flex;
    justify-content: space-around;
}

.customers_div_1 div{
    width: 12%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_1 img{
    filter: grayscale(100%);;
}

.customers_div_1 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}
.customers_div_2{
    width: 95%;
    height: 160px;
    /* border: 2px solid green;  */
    position: absolute;
    top: 408px;
    display: flex;
    justify-content: space-around; 
}
.customers_div_2 div{
    width: 12% !important;
    height: 160px;
 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}
.customers_div_3{
    width: 24%;
    height: 182px;
    position: absolute;
    top: 638px;
    display: flex;
    justify-content: space-between; 
}
.customers_div_3 div{
    width: 50% !important;
    height: 170px;
 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}
#sch{
    width: 60%;
}

.customers_div_2 img{
    filter: grayscale(100%);
}

.customers_div_2 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);
}


.customer_images{
    width: 120px;
}

#cma{
    width: 85%;
    height: 100px;
}

#bharat{
    width: 100px;
    height: 130px;
}

#aditya{
    width: 110px;
    height: 100px;
}

#lg{
    width: 100px;
    height: 60px;
}

#dadri{
    width: 110px;
    height: 95px;
}

#hundred{
    position: relative;
    top: 14px;

}

#client_100{
    position: relative;
    top: 14px;

}

#more_3500{
    width: 100%;
    height: 66px;
    position: relative;
    top: 589px;
  
    display: flex;
    align-items: center;
    justify-content: center;
}

#more_3500 h1{
    position: relative;
    font-size: 46px;
    color: #424141;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background-image:linear-gradient(white,white);
    background-repeat: none;
    background-size:  0% 0px;
    filter: grayscale(100%);
    
}

#more_3500 h1:hover{
    color: black;
}

}
@media only screen and (max-width:1301px){
    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;        
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    .about_foooter{
        position: relative;
        top: 777px;
    }
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif !important;
        font-weight:900;
        color: #ff7200;
        letter-spacing: 0px ! important;
        font-size: 49px;
        position: relative;
        top: 40px;
        font-weight: 900;
      
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        height: 600px; 
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }    
    .customer_div1{
        display: none;
    }
    .customer_div{
        width: 100%;
        height: 700px;
        position: absolute;
        top: 2405px;
        display: flex;
        justify-content: center;
       
    }
    
    .customer_div h1{
         
        font-size: 54px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size:  0% 4px;
        background-position:  bottom;
       
    
    }
    
    .customers_div_1{
        width: 98%;
        height: 160px;
        /* border: 2px solid black; */
        position: absolute;
        top: 197px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 14%;
        height: 160px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 98%;
        height: 160px;
        
        position: absolute;
        top: 396px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 14%;
        height: 160px;
     
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customer_images{
        width: 106px;
    }
    
    #cma{
        width: 110px;
        height: 120px;
    }
    
    #bharat{
        width: 105px;
        height: 115px;
    }
    
    #aditya{
        width: 110px;
        height: 100px;
    }
    
    #lg{
        width: 100px;
        height: 55px;
    }
    
    #dadri{
        width: 110px;
        height: 90px;
    }
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 563px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 42px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
        filter: grayscale(100%);
        
    }
    #more_3500 h1:hover{
        color: black;
    }    

}
@media only screen and (max-width:1201px){
    .main_company{
       
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    .about_foooter{
        position: relative;
        top: 757px;
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif !important;
        font-weight:900;
        color: #ff7200;;
        font-size: 49px;
        position: relative;
        top: 40px;
        font-weight: 900;
        letter-spacing: 0px ! important;
        
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
       margin-left: auto !important;
       margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
       line-height: 22px;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        height: 580px;  
    }     
    #Family{
        width: 100%;
        height: 100%;     
    }
    .customer_div{
        width: 100%;
        height: 640px;
        position: absolute;
        top: 2400px;
        display: flex;
        justify-content: center;
    }
    
    .customer_div h1{
         
        font-size: 48px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size:  0% 4px;
        background-position:  bottom;
        letter-spacing: 0px !important;
       
    
    }
    
    .customers_div_1{
        width: 98%;
        height: 150px;
        /* border: 2px solid red; */
        position: absolute;
        top: 170px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 16%;
        height: 150px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    .customers_div_2{
        width: 98%;
        height: 150px;
        position: absolute;
        top: 352px;
        display: flex;
        justify-content: space-around; 
    }
    
    .customers_div_2 div{
        width: 16%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    .customers_div_3{
        width: 24%;
        height: 178px;
        position: absolute;
        top: 577px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_3 div{
        width: 16%;
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    #sch{
        width: 60%;
    }
    
    .customers_div_2 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customer_images{
        width: 89px;
    }
    
    #cma{
        width: 90px;
        height: 100px;
    }
    
    #bharat{
        width: 85px;
        height: 100px;
    }
    
    #aditya{
        width: 90px;
        height: 90px;
    }
    
    #lg{
        width: 90px;
        height: 55px;
    }
    
    #dadri{
        width: 85px;
        height: 76px;
    }
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 35px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
        filter: grayscale(100%);
        
    }
    
    #more_3500 h1:hover{
        color: black;
    } 
 
 
}
@media only screen and (max-width:1051px){
    .main_company{
       
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;  
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    .about_foooter{
        position: relative;
        top: 715px;
    }
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 49px;
        position: relative;
        top: 40px;
        letter-spacing: 0px ! important;
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
       margin-left: auto !important;
       margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: 540px;   
    } 
    
    #Family{
        width: 100%;
        height: 100%;     
    }    
    .customer_div{
        width: 100%;
        height: 584px;
        position: absolute;
        top: 2400px;
        display: flex;
        justify-content: center;
    }
    
    .customer_div h1{
         
        font-size: 48px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size:  0% 4px;
        background-position:  bottom;
       
    
    }
    
    .customers_div_1{
        width: 98%;
        height: 150px;
      
        position: absolute;
        top: 162px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 16%;
        height: 150px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 98%;
        height: 150px;
        position: absolute;
        top: 340px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 16%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    .customers_div_3{
        width: 24%;
        height: 168px;
        position: absolute;
        top: 528px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_3 div{
        width: 49%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customer_images{
        width: 90px;
    }
    
    #cma{
        width: 80px;
        height: 85px;
    }
    
    #bharat{
        width: 80px;
        height: 95px;
    }
    
    #aditya{
        width: 80px;
        height: 80px;
    }
    
    #lg{
        width: 80px;
        height: 55px;
    }
    
    #dadri{
        width: 76px;
        height: 76px;
    }
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 485px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 35px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
        filter: grayscale(100%);
        
    }
    
    #more_3500 h1:hover{
        color: black;
    }    
}
@media only screen and (max-width:1001px){
    .main_company{
       
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;  
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 780px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 49px;
        position: relative;
        top: 40px;
        letter-spacing: 0px ! important;
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
       margin-left: auto !important;
       margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -188px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: 540px;   
    } 
    
    #Family{
        width: 100%;
        height: 100%;     
    }    
    .customer_div{
        width: 100%;
        height: 584px;
        position: absolute;
        top: 2143px;
        display: flex;
        justify-content: center;
    }
    
    .customer_div h1{
         
        font-size: 48px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 20px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px !important;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 110px;
        background-size:  0% 4px;
        background-position:  bottom;
       
    
    }
    
    .customers_div_1{
        width: 98%;
        height: 145px;
      
        position: absolute;
        top: 162px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 16%;
        height: 145px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 98%;
        height: 145px;
        
        position: absolute;
        top: 335px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 16%;
        height: 145px;
     
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customer_images{
        width: 82px;
    }
    
    #cma{
        width: 80px;
        height: 85px;
    }
    
    #bharat{
        width: 70px;
        height: 80px;
    }
    
    #aditya{
        width: 80px;
        height: 79px;
    }
    
    #lg{
        width: 70px;
        height: 50px;
    }
    
    #dadri{
        width: 80px;
        height: 70px;
    }
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 475px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 35px;
        color: #424141;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
        filter: grayscale(100%);
        
    }
    
    #more_3500 h1:hover{
        color: black;
    }    
  
}
@media only screen and (max-width:951px){
    
    html,body{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden !important;
    }

    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;
        z-index: 2;
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 820px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 42px;
        position: relative;
        top: 40px;
        font-weight: 700;
        letter-spacing: 0px ! important;
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
      
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 84%;
        margin-left: auto;
        margin-right: auto;
        height: 470px;  
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    .customer_div{
       display: none;
    }
.customer_div1{
    width: 100%;
    height: 900px;
    position: relative;
    top: -10px;
    display: flex;
    justify-content: center;  
}



.customer_div1 h1{
     
    font-size: 44px;
    color: black;
    text-shadow: none;
    position: absolute;
    top: 10px;
    font-weight: 900;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 0px !important;
    background-image:linear-gradient(#ff7200, #ff7200);
    background-repeat: no-repeat;
    line-height: 110px;
    background-size:  0% 4px;
    background-position:  bottom;
   

}

.customers_div_1{
    width: 90%;
    height: 145px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 155px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_1 div{
    width: 24%;
    height: 145px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_1 img{
    filter: grayscale(100%);;
}

.customers_div_1 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}


.customers_div_2{
    width: 68% !important;
    height: 145px;
    
    position: absolute;
    top: 325px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_2 div{
    width: 35% !important;
    height: 145px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_2 img{
    filter: grayscale(100%);;
}

.customers_div_2 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}

.customers_div_3{
    width: 90%;
    height: 145px;
    
    position: absolute;
    top: 497px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_3 div{
    width: 24%;
    height: 145px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_3 img{
    filter: grayscale(100%);;
}
.customers_div_3 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}

.customers_div_4{
    width: 68% !important;
    height: 145px;
     /* border: 1px solid green;  */
    position: absolute;
    top: 669px;
    display: flex;
    justify-content: space-evenly;
    
}

.customers_div_4 div{
    width: 35% !important;
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_4 img{
    filter: grayscale(100%);;
}
.customers_div_4 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}
/* 500 */
.customers_div_5{
    width: 90% !important;
    height: 158px;
    position: absolute;
    top: 841px;
    display: flex;
    justify-content: space-evenly;
    
}

.customers_div_5 div{
    width: 24% !important;
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_5 img{
    filter: grayscale(100%);;
}
.customers_div_5 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}
#sch{
    width: 46%;
}

.customer_images{
    width: 140px;
}

#cma{
    width: 150px;
    height: 120px;
}

#bharat{
    width: 100px;
    height: 118px;
}

#aditya{
    width: 130px;
    height: 105px;
}

#lg{
    width: 100px;
    height: 60px;
}

#dadri{
    width: 120px;
    height: 90px;
}
#teas{
    width: 60%;
}
#more_3500{
    width: 100%;
    height: 66px;
    position: relative;
    top: 985px;
  
    display: flex;
    align-items: center;
    justify-content: center;
}

#more_3500 h1{
    position: relative;
    font-size: 30px;
    color: black;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background-image:linear-gradient(white,white);
    background-repeat: none;
    background-size:  0% 0px;
    filter: grayscale(100%);
    
}

}
@media only screen and (max-width:751px){
    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;  
     }
     .about_foooter{
        position: relative;
        top: 737px;
    }
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 910px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:700;
        color: #ff7200;;
        font-size: 42px;
        position: relative;
        top: 40px;
        font-weight: 900;
        letter-spacing: 0px ! important;
      
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 80%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: 400px; 
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    
.customer_div1{
    width: 100%;
    height: 850px;
    position: relative;
    top: 0px;
    display: flex;
    justify-content: center;
     
}



.customer_div1 h1{
     
    font-size: 38px;
    color: black;
    text-shadow: none;
    position: absolute;
    top: 0px;
    font-weight: 900;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 0px;
    background-image:linear-gradient(#ff7200, #ff7200);
    background-repeat: no-repeat;
    line-height: 110px;
    background-size:  0% 4px;
    background-position:  bottom;
    letter-spacing: 0px !important;
   

}

.customers_div_1{
    width: 94%;
    height: 140px;
   
    position: absolute;
    top: 115px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_1 div{
    width: 24%;
    height: 140px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_1 img{
    filter: grayscale(100%);;
}

.customers_div_1 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}


.customers_div_2{
    width: 68% !important;
    height: 140px;
    
    position: absolute;
    top: 290px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_2 div{
    width: 35% !important;
    height: 140px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_2 img{
    filter: grayscale(100%);;
}

.customers_div_2 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}

.customers_div_3{
    width: 94%;
    height: 170px;
    
    position: absolute;
    top: 463px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_3 div{
    width: 24%;
    height: 140px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_3 img{
    filter: grayscale(100%);;
}
.customers_div_3 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}
.customers_div_4{
    width: 68% ! important;
    height: 168px;
    position: absolute;
    top: 634px;
    display: flex;
    justify-content: space-around;
    
}
.customers_div_4 div{
    width: 35% !important;
    height: 140px; 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}
.customers_div_5{
    width: 94% ! important;
    height: 168px;
    position: absolute;
    top: 805px;
    display: flex;
    justify-content: space-around;
    
}
.customers_div_5 div{
    width: 24% !important;
    height: 140px; 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customer_images{
    width: 110px;
}

#cma{
    width: 120px;
    height: 100px;
}

#bharat{
    width: 90px;
    height: 108px;
}

#aditya{
    width: 90px;
    height: 83px;
}

#lg{
    width: 90px;
    height: 50px;
}

#dadri{
    width: 94px;
    height: 80px;
}

#more_3500{
    width: 100%;
    height: 66px;
    position: relative;
    top: 955px;
  
    display: flex;
    align-items: center;
    justify-content: center;
}

#more_3500 h1{
    position: relative;
    font-size: 28px;
    color: black;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background-image:linear-gradient(white,white);
    background-repeat: none;
    background-size:  0% 0px;
    filter: grayscale(100%);
    
}


}
@media only screen and (max-width:701px){
  
}
@media only screen and (max-width:621px){
    .main_company{
       
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;  
     }
     .about_foooter{
        position: relative;
        top: 645px;
    }
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 870px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:700;
        color: #ff7200;;
        font-size: 42px;
        position: relative;
        top: 40px;
        font-weight: 900;
        letter-spacing: 0px ! important;
      
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 100%;
       height: 84%;
       padding-left: 2px;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 14px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: 350px;
    } 
    
    #Family{
        width: 100%;
        height: 100%;     
    }
    


.customer_div1{
    width: 100%;
    height: 750px;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
     
}



.customer_div1 h1{
     
    font-size: 35px;
    color: black;
    text-shadow: none;
    position: absolute;
    top: 0px;
    font-weight: 900;
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 0px;
    background-image:linear-gradient(#ff7200, #ff7200);
    background-repeat: no-repeat;
    line-height: 110px;
    background-size:  0% 4px;
    background-position:  bottom;
    letter-spacing: 0px !important;
   

}

.customers_div_1{
    width: 97%;
    height: 170px;
    position: absolute;
    top: 120px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_1 div{
    width: 26% !important;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}

.customers_div_1 img{
    filter: grayscale(100%);;
}

.customers_div_1 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}


.customers_div_2{
    width: 73% !important;
    height: 170px;
    position: absolute;
    top: 263px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_2 div{
    width: 37% !important;
    height: 120px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_2 img{
    filter: grayscale(100%);;
}

.customers_div_2 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}

.customers_div_3{
    width: 97%;
    height: 120px;
    
    position: absolute;
    top: 407px;
    display: flex;
    justify-content: space-around;
    
}

.customers_div_3 div{
    width: 26%;
    height: 120px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}


.customers_div_3 img{
    filter: grayscale(100%);;
}
.customers_div_3 img:hover{
    cursor: pointer;
    filter: none;
    filter: grayscale(0%);;
}

.customers_div_4{
    width: 73% !important;
    height: 168px;
    position: absolute;
    top: 551px;
    display: flex;
    justify-content: space-around;
    
}
.customers_div_4 div{
    width: 37%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}
.customers_div_5{
    width: 97% !important;
    height: 168px;
    position: absolute;
    top: 697px;
    display: flex;
    justify-content: space-around;
    
}
.customers_div_5 div{
    width: 26%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px grey;
}
#sch{
    width: 55% !important;
}
.customer_images{
    width: 95px;
}

#cma{
    width: 100px;
    height: 90px;
}

#bharat{
    width: 85px;
    height: 105px;
}

#aditya{
    width: 95px;
    height: 78px;
}

#lg{
    width: 80px;
    height: 40px;
}

#dadri{
    width: 90px;
    height: 80px;
}


#more_3500{
    width: 100%;
    height: 66px;
    position: relative;
    top: 820px;
  
    display: flex;
    align-items: center;
    justify-content: center;
}

#more_3500 h1{
    position: relative;
    font-size: 26px;
    color: black;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background-image:linear-gradient(white,white);
    background-repeat: none;
    background-size:  0% 0px;
    filter: grayscale(100%);
}

}
@media only screen and (max-width:521px){

    .main_company{  
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;  
     }
    
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 850px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    .about_foooter{
        position: relative;
        top: 587px;
    }
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 38px;
        position: relative;
        top: 40px;
        font-weight: 700;
        letter-spacing: 0px ! important;
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 100%;
       height: 84%;
       padding-left: 2px;
       padding-right: 3px;
       padding-top: 72px;
       position: relative;
       bottom: 0px;
       font-size: 13px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
      
    }
    
    
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 88%;
        margin-left: auto;
        margin-right: auto;
        height:310px;  
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    .customer_div1{
        width: 100%;
        height: 690px;
        position: absolute;
        top: 0px;
        display: flex;
        justify-content: center;  
    }
    
    .customer_div1 h1{   
        font-size: 30px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 80px;
        background-size:  0% 4px;
        background-position:  bottom;
        letter-spacing: 0px !important;
       
    
    }
    
    .customers_div_1{
        width: 98%;
        height: 150px;
       
        position: absolute;
        top: 100px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 23%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 74% !important;
        height: 140px;
        
        position: absolute;
        top: 235px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 38% !important;
        height: 110px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    .customers_div_3{
        width: 98%;
        height: 130px;
        
        position: absolute;
        top: 372px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_3 div{
        width: 23%;
        height: 110px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_3 img{
        filter: grayscale(100%);;
    }
    .customers_div_3 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    .customers_div_4{
        width: 74% !important;
        height: 168px;
        position: absolute;
        top: 507px;
        display: flex;
        justify-content: space-around;
        
    }
    
    
    .customers_div_4 div{
        width: 38%;
        height: 110px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    .customers_div_5{
        width: 98% !important;
        height: 168px;
        position: absolute;
        top: 644px;
        display: flex;
        justify-content: space-around;
        
    }
    
    
    .customers_div_5 div{
        width: 23%;
        height: 110px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customer_images{
        width: 85px;
    }
    
    #cma{
        width: 80px;
        height: 75px;
    }
    
    #bharat{
        width: 70px;
        height: 80px;
    }
    
    #aditya{
        width: 80px;
        height: 65px;
    }
    
    #lg{
        width: 70px;
        height: 40px;
    }
    
    #dadri{
        width: 72px;
        height: 66px;
    }
    
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 757px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 23px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
    }

}
@media only screen and (max-width:425px){
    
    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px; 
     }
     .about_foooter{
        position: relative;
        top: 394px;
    }
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 710px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 36px;
        position: relative;
        top: 40px;
        letter-spacing: 0px ! important;
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 95%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 59px;
       position: relative;
       bottom: 0px;
       font-size: 11px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
       letter-spacing: 0px;
       line-height: 17px;
      
    }
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 260px;  
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    .customer_div1{
        width: 100%;
        height: 630px;
        position: absolute;
        top: 0px;
        display: flex;
        justify-content: center;
         
    }
    
    
    
    .customer_div1 h1{
         
        font-size: 26px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 70px;
        background-size:  0% 4px;
        background-position:  bottom;
        letter-spacing: 0px !important;
       
    
    }
    
    .customers_div_1{
        width: 99%;
        height: 120px;
        left: 1%px;
        position: absolute;
        top: 90px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 23%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 74% !important;
        height: 120px;
        /* left: 1%; */
        position: absolute;
        top: 212px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 36% !important;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    .customers_div_3{
        width: 99%;
        height: 130px;
        /* left: 1%; */
        position: absolute;
        top: 336px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_3 div{
        width: 23%;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_3 img{
        filter: grayscale(100%);;
    }
    .customers_div_3 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    .customers_div_4{
        width: 74% !important;
        height: 128px;
        /* left: 1%; */
        position: absolute;
        top: 459px;
        display: flex;
        justify-content: space-around;
        
    }
    .customers_div_4 div{
        width: 36% !important;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    .customers_div_5{
        width: 99% !important;
        height: 128px;
        /* left: 1%; */
        position: absolute;
        top: 579px;
        display: flex;
        justify-content: space-around;
        
    }
    .customers_div_5 div{
        width: 23% !important;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    .customer_images{
        width: 72px;
    }
    
    #cma{
        width: 70px;
        height: 60px;
    }
    
    #bharat{
        width: 60px;
        height: 79px;
    }
    
    #aditya{
        width: 70px;
        height: 64px;
    }
    
    #lg{
        width: 68px;
        height: 40px;
    }
    
    #dadri{
        width: 60px;
        height: 60px;
    }
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 675px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 22px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;
       
    }
  

}
@media only screen and (max-width:375px){
    .main_company{
        height: 530px;
        width: 100%;
        position: relative;
        top: 70px;
     }
     .about_foooter{
        position: relative;
        top: 417px;
    }
    .sub_main_company{
        background-color: rgb(25,29,29);
        width: 100%;
        height: 730px;
        position: relative; 
        top: -20px;
        clip-path: polygon(0 0, 100% 0, 100% 86%, 75% 100%, 25% 100%, 0 86%);
        z-index: -1;
       /*  border: 0.2px solid red; */
    }
    
    .sub_main_company h1{
        font-family: 'Fjalla One', sans-serif;
        font-weight:900;
        color: #ff7200;;
        font-size: 32px;
        position: relative;
        top: 40px;
        letter-spacing: 0px ! important;
      
       
    }
    
    .sub_main_company p{
     /*    border: 1px solid green;  */
       color: #fff;
       width: 95%;
       height: 84%;
       margin-left: auto !important;
        margin-right: auto !important;
       padding-top: 57px;
       position: relative;
       bottom: 0px;
       font-size: 11px;
       font-weight: 300;
       font-family: 'Montserrat', sans-serif;
       text-shadow: none;
       letter-spacing: 0px;
      
    } 
    .sub_main_2{
        position: relative;
        top: -148px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 265px;  
    } 
    #Family{
        width: 100%;
        height: 100%;     
    }
    .customer_div1{
        width: 100%;
        height: 620px;
        
        position: absolute;
        top: 0px;
        display: flex;
        justify-content: center;
    }
    .customer_div1 h1{
         
        font-size: 26px;
        color: black;
        text-shadow: none;
        position: absolute;
        top: 0px;
        font-weight: 900;
        font-family: 'Fjalla One', sans-serif;
        letter-spacing: 0px;
        background-image:linear-gradient(#ff7200, #ff7200);
        background-repeat: no-repeat;
        line-height: 70px;
        background-size:  0% 4px;
        background-position:  bottom;
        letter-spacing: 0px !important;
       
    
    }
    
    .customers_div_1{
        width: 99%;
        height: 120px;
        left: 2px;
        position: absolute;
        top: 92px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_1 div{
        width: 23%;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customers_div_1 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_1 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    
    .customers_div_2{
        width: 74% !important;
        height: 120px;
        position: absolute;
        margin-left:auto ;
        margin-right: auto;
        top: 215px;
        /* left: 0px; */
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_2 div{
        width: 35% !important;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_2 img{
        filter: grayscale(100%);;
    }
    
    .customers_div_2 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    .customers_div_3{
        width: 99%;
        height: 120px;
        left: 2px !important;
        position: absolute;
        top: 338px;
        left: 0px;
        display: flex;
        justify-content: space-around;
        
    }
    
    .customers_div_3 div{
        width: 23%;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    
    .customers_div_3 img{
        filter: grayscale(100%);;
    }
    .customers_div_3 img:hover{
        cursor: pointer;
        filter: none;
        filter: grayscale(0%);;
    }
    
    .customers_div_4{
        width: 74% !important;
        height: 168px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 458px;
        display: flex;
        justify-content: space-around;
        
    }
    
    
    .customers_div_4 div{
        width: 35% !important;
        height: 100px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 10px grey;
    }
    
    .customer_images{
        width: 63px;
    }
    
    #cma{
        width: 70px;
        height: 60px;
    }
    
    #bharat{
        width: 60px;
        height: 80px;
    }
    
    #aditya{
        width: 67px;
        height: 57px;
    }
    
    #lg{
        width: 59px;
        height: 38px;
    }
    
    #dadri{
        width: 65px;
        height: 55px;
    }
    
    
    #more_3500{
        width: 100%;
        height: 66px;
        position: relative;
        top: 659px;
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #more_3500 h1{
        position: relative;
        font-size: 18px;
        color: black;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
        top: 13px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background-image:linear-gradient(white,white);
        background-repeat: none;
        background-size:  0% 0px;  
    }
  

}

