@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800;900&display=swap');

#nprogress .bar {
    background: #ff7200 !important;
    position: relative;
    top: 70px !important;
    height: 7px !important;
}
a{
    /* text-decoration: none; */
    color: #ff7200;
}
#nprogress .peg {
    box-shadow: 0 0 0px #000, 0 0 0px #000 !important;
}

#nprogress .spinner-icon {
    position: relative;
    top: 70px !important;
    border-top-color: #ff7200 !important;
    border-left-color: #ff7200 !important;
}
.xxxl{
    color: #ff7200 !important;
        font-weight: 700 ! important;
}
.progress-bar{
    width: 100% !important;
    background-color: #ff7200;    
}

@media only screen and (min-width:1551px){
    .div70{
        width: 100%;
        height: 90px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        background-color: #E8E8E8;
    
    }
    .hideAndSeek{
        position: absolute;
        width: 100%;
        height: 10px;
        top: 71px;
    }
    .progressBar{
        position: absolute;
        width: 100%;
        left: 0px;
        max-height: 10px !important;
    
    }
    progress {
        border: none;
      }
      progress::-webkit-progress-value {
        background: #ff7200;
      }
      
      progress::-moz-progress-bar {
        background: #ff7200;
      }
      progress::-webkit-progress-bar {
        background: #D0D0D0;
      }
    .progressValueSpan{
        position: absolute;
        font-size: 0.65rem;
        font-weight: 700;
        left: 50%;
        right: 50%;
        top: -2px;
        z-index: 11;
    }
    .hide_plz{
        display: none;
    }
    .show_plz{
        display: block;
    }
    .boxElem{
        position: absolute;
        bottom: 135px;
        left: 5px;
    }
    .rnd{
        height: auto ! important ;
        display: flex;
        flex-direction: column;
        border:  2px dotted #ddd;
        text-align: start;
        word-wrap: break-word;
        background-color: transparent;
        padding-left: 4px;
    }
    
    .draggableText{
        font-size: 0.8rem;
        font-weight: 600;
        position: relative;
    padding: 0px;
    }
    .adjust{
        display: flex;
        align-items: center;
        position: relative;
        top: 50% !important;
    }
    #specified_text{
        font-size: 9px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .draggableTextNew{
        color: #ff7200;
        font-size: 0.65rem;
        display: block;
    }
    .signElem{
        height: 16px;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        /* justify-content: space-between !important; */
    }
    .draggableTextSign{
        width: auto !important;
        display: block !important;
        font-size: 0.7rem;
        font-weight: 600;
        cursor: pointer;
    }
    .resizeableImg{
        height: 20px;
    }
    
      
    #fetched_ltn{
        color: black;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -4px;
        padding-left: 2px;
    }
    
    
    #hide_specified{display: none;}

    .sign_div{
        width: 100%;
        height: auto;
        background-color: #E8E8E8;
    }
    .conditional_text{
        padding-left: 15px;
        color: #ff7200;
        position: relative;
        bottom: 2px;
        font-size: 14px;
    }
      
    .pdf_sign{
        width: 45%;
        height: auto !important;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(240, 239, 239);
        overflow: hidden;
        padding-bottom: 0px;    
    }
    .choosen_image{
        position: relative;
        width: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transform: rotateX(180deg);
        border-bottom: 1px solid #888888;
    }
    
    .choosen_image_1{
        position: relative;
        width: 100%;
        border-bottom: 1px solid grey;
        display: flex;
        margin-left: auto;
        margin-right: auto;
      
        /* transform: rotateX(180deg); */
    }
    
    .draggable{
        width: 100%;
        height: 100px;
       
    }
   
.hide_plz{
    display: none;
}
.show_plz{
    display: block;
}

#hide_specified{display: none;}


#notspecified{display: none;}

#last_sign{
    width: auto;
    height: 45px;
    /* border: 1px dotted silver; */
    position: relative;
    left: 23px;
    bottom: 4px;
   
}
.text{
    color: #ff7200;
    padding-left: 15px;
    position: relative;
    bottom: 0px;
}

.form_data h4{
    font-size: 22px;
    padding-top: 11px;
    padding-left: 12px;
    font-family: 'Encode Sans Semi Condensed', sans-serif;
}

    /*  */
  
    .Download{
        width: 100%;
        height: 100px;
        position: relative;
        display: flex;
        top: 0px;
        text-align: center;
        align-items:center ;
        justify-content: center;
        background-color: #E8E8E8;
    }
    .download_btn{
        width: 9%;
        height: 30px;
        cursor: pointer;
        border-radius: 6px;
        border: none;
        position: relative;
        justify-content: center;
        text-align: center;
        align-items: center;
        display: flex;
        background-color: #ff7200;
        color: #fff;
        margin-right: 15px;
        border: none;
    }
    
    .emailButton{
        width: fit-content !important;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        display: flex;
        /* border: 1px solid #fff; */
        background-color: #ff7200;
        color: #fff;
    }
    .button_text{
        height:100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
    }
    .button_icon{
        width: auto  !important;
        padding-left: 7px;
        padding-right: 7px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .infoIcon{
        background-color: #ff7200;
        color: #fff;
    }
    .Download .titleBox{
        width:220px;
        height: auto;
        padding: 5px;
        background-color: #727376;
        position: absolute;
        top: 40px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: none;
    }
    .titleBox::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -7px;
        left: 8%;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #727376;
        overflow: hidden;
    }
    .Download>.emailButton .button_icon:hover>.titleBox{
        min-width:fit-content;
        height: auto;
        padding: 5px;
        background-color: #727376;
        color: #666666;
        position: absolute;
        top: 35px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: block !important;
    }
    
    .titleBox span{
        color: #fff;
        font-size: 0.75rem;
    }
    
    
    
    .group_btns{
        width: 45%;
        height: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 11%;
        position: relative;
        display: flex;
        justify-content: space-between;
      
        text-align: center;
        align-items: center;
    }
    
    .save{
        width: 80px;
        height: 24px;
        background-color: green;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
       
    }
      
    .save:active{
        transform: translateY(-2px);   
    
    }
    #save_icon{
      font-size: 17px;  
      padding-top: 2px;
      color: #f1f1f1;
    }
    
    
    
    
    .show{
        width: 80px;
        height: 24px;
        background-color: rgb(78, 78, 233);
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
       
    }
      
    .show:active{
        transform: translateY(-2px);   
    
    }
        
    
    #eye{
        font-size: 17px;
        padding-top: 2px;
    }
    .clear{
        width: 80px;
        height: 24px;
       background-color: red;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
       
    }
      
    .clear:active{
        transform: translateY(-2px);   
    
    }
    
    #delete{
        font-size: 16px;
        padding-top: 3px;
        color: white;
    }
    .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 2;
       
    }
    .overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .model_content{
        position: absolute;
        width: 60%;
        height: 300px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        border: 4.5px solid #ff7200;
        z-index: 100;
        overflow: hidden;
    }
    .model_content.open{
        height: 550px !important;
        animation: mymove;
        animation-duration: 0.5s !important;
        overflow: auto;
    }
    @keyframes mymove {
        0%{
            height: 300px;
        }
        100%{
            height: 550px !important;
        }
    }
    .model_content.close{
        height: 300px;
        animation: mymoveBack;
        animation-duration: 0.5s !important;
    }
    @keyframes mymoveBack {
        0%{
            height: 550px !important;
        }
        100%{
            height: 300px !important;
        }
    }
    .sigCloseBtn{
        width: 30px;
        height: 29px;
       position: absolute;
       top: 0px;
       border-radius: 5px;
       right: 0px;
       background-color: #ff7200;
       border: 1px solid #000;
    }
    .colorPallateElem{
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        top: 80px;
        align-items: center;
        justify-content: center;
    }
    .pallate{
        background-color: #ff7200;
        width: 80px;
        height: 24px;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
    }
    .pallateIcon{
        color: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet);
    }
    .sigCloseBtn:active{
        transform: translateY(-2px);   
    }
    
    #sigClose{
    font-size: 1.2rem;
    }
    
    #sign_area{
        width: 62%;
        height: 200px;
        position: relative;
        top: 10%;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    #sign_area h3{
        position: relative;
        bottom: 3px;
    }
    .singnatureCanvas{
        border: 2px dashed silver;
        width: 100%;
        min-height: 150px;
    }
   
    /*  */
    .hide_text{
        display: none;
    }
    
    .show_hide_text{
        color: #ff7200;
        padding-left: 15px;
        position: relative;
        bottom: 13px;
    }
    
    .hide_textt{
        display: none;
    }
    .Load, .loader_overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
      
       
    }
    .loader_overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .loader_content{
        position: absolute;
        width: auto;
        height: 50px;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* background-color: #fff; */
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;      
        text-align:center ;
    }
    .displayFlexMe {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .displayFlexMe>span{
        color: #ff7200 !important;
        font-weight: 700;
    }
    
    
    .loadingg{
        margin-left: auto;
        margin-right: auto;
    }
    .signFooter{
        position: relative;
        top: 0px;
        background-color: #E8E8E8;
    }
    
    
    } 
@media only screen and (max-width:1550px){
.div70{
    width: 100%;
    height: 90px;
    background-color: #E8E8E8;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.hideAndSeek{
    position: absolute;
    width: 100%;
    height: 10px;
    top: 71px;
    background-color: cyan;
}
.progressBar{
    position: absolute;
    width: 100%;
    left: 0px;
    max-height: 10px !important;

}
progress {
    border: none;
  }
  progress::-webkit-progress-value {
    background: #ff7200;
  }
  
  progress::-moz-progress-bar {
    background: #ff7200;
  }
  progress::-webkit-progress-bar {
    background: #D0D0D0;
  }
.progressValueSpan{
    position: absolute;
    font-size: 0.65rem;
    font-weight: 700;
    top: -2px;
    z-index: 11;
}

#tableToPrint{
    position: relative;
}
.myColor{
    color: #000;
}
.sign_div{
    width: 100%;
    height: auto;
    text-align: center;
    position: relative;
    top: 0px;
    background-color: #E8E8E8;
}
.blockMe{
    display: block !important;
}
#num{
    position: relative;
    top: 22px;
    color: black;
    font-weight: 600;
}

#slash{
    color: #ff7200;
    font-size: 23px;
    position: relative;
    top: 6px;
    
}

.pre{
    position: relative;
    top: 7px;
    cursor: pointer;   
    right: 7px;
    font-size: 23px;
}

.next{
    position: relative;
    top: 7px;
   left: 7px;
   font-size: 23px;
    cursor: pointer;
    color: red ;
   
}
.pdf_sign{
    width: 45%;
    height: auto !important;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: rgb(240, 239, 239);
    overflow: hidden;
    padding-bottom: 0px;
    /* background-color: red; */

}
.tableToPrint{
    /* overflow: hidden !important; */
}
.choosen_image{
    position: relative;
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: rotateX(180deg);
    border-bottom: 1px solid #888888;
}

.choosen_image_1{
    position: relative;
    width: 100%;
    border-bottom: 1px solid grey;
    /* height: 1002px; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
  
    /* transform: rotateX(180deg); */
}
.hide_plz{
    display: none;
}
.show_plz{
    display: block;
}
.resize{
    min-width: 50px !important;
    height: 50px;
    /* background-color: red; */
    display: flex;
}
.boxElem{
    position: absolute;
    bottom: 135px;
    left: 5px;
}
.rnd{
    height: auto !important ;
    display: flex;
    flex-direction: column;
    border:  2px dotted #ddd;
    text-align: start;
    /* word-wrap: break-word; */
    background-color: transparent;
    padding-left: 3px;
    display: inline-block;
}
.adjust{
    display: flex;
    position: relative;
    top: 50% !important;
    align-items: center !important;
}
.draggableText{
    font-size: 0.8rem;
    font-weight: 600;
    position: relative;
    padding: 0px;
    justify-content: space-between ;
}
.inlineblock{
    display: inline-block;
}
#specified_text{
    font-size: 9px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding-left: 5px; 
}
.draggableTextNew{
    color: #ff7200;
    font-size: 0.65rem;
    display: block;
}
.signElem{
    height: 16px;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    /* justify-content: space-between !important; */
}
.draggableTextSign{
    width: auto !important;
    display: block !important;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
}
.resizeableImg{
    height: 20px;
}
  
#fetched_ltn{
    color: black;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: -4px;
    padding-left: 2px;
}
.hide_plz{
    display: none;
}
.show_plz{
    display: block;
}

#hide_specified{display: none;}
#specified{
    color: black;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: 0px;
    padding-left: 1px;
}

#notspecified{display: none;}
.text{
    color: #ff7200;
    padding-left: 15px;
    position: relative;
    bottom: 0px;
}

.form_data h4{
    font-size: 22px;
    padding-top: 11px;
    padding-left: 12px;
    font-family: 'Encode Sans Semi Condensed', sans-serif;
}
/*  */
.Download{
    width: 100%;
    height: 100px;
    position: relative;
    display: flex;
    top: 0px;
    text-align: center;
    align-items:center ;
    justify-content: center;
    background-color: #E8E8E8;
}
.download_btn{
    width: 10%;
    height: 30px;
    cursor: pointer;
    border-radius: 6px;
    border: none;
    position: relative;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    background-color: #ff7200;
    color: #fff;
  margin-right: 15px;
   
}

.emailButton{
    width: fit-content !important;
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    display: flex;
   
    /* border: 1px solid #fff; */
    background-color: #ff7200;
    color: #fff;
}
.button_text{
   
    height:100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #fff;
}
.button_icon{
    width: auto  !important;
    padding-left: 5px;
    padding-right: 5px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.infoIcon{
    background-color: #ff7200;
    color: #fff;
}
.Download .titleBox{
    width:200px;
    height: auto;
    padding: 5px;
    background-color: #727376;
    position: absolute;
    top: 40px;
    left: 5px !important;
    border-radius: 4px;
    z-index: 9999999999;
    display: none;
}
.titleBox::after{
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: -7px;
    left: 8%;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #727376;
    overflow: hidden;
}
.Download>.emailButton .button_icon:hover>.titleBox{
    min-width:fit-content;
    height: auto;
    padding: 5px;
    background-color: #727376;
    color: #666666;
    position: absolute;
    top: 35px;
    left: 0px;
    border-radius: 4px;
    z-index: 9999999999;
    display: block !important;
}

.titleBox span{
    color: #fff;
    font-size: 0.7rem;
}



.group_btns{
    width: 51%;
    height: 40px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 14%;
    position: relative;
    display: flex;
    justify-content: space-between;
  
    text-align: center;
    align-items: center;
}

.save{
    width: 80px;
    height: 24px;
    background-color: green;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.save:active{
    transform: translateY(-2px);   

}
#save_icon{
  font-size: 17px;  
  padding-top: 2px;
  color: #f1f1f1;
}




.show{
    width: 80px;
    height: 24px;
    background-color: rgb(78, 78, 233);
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.show:active{
    transform: translateY(-2px);   

}
    

#eye{
    font-size: 17px;
    padding-top: 2px;
    color: black;
}
.clear{
    width: 80px;
    height: 24px;
   background-color: red;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.clear:active{
    transform: translateY(-2px);   

}

#delete{
    font-size: 16px;
    padding-top: 3px;
    color: white;
}

.modal, .overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed; 
    z-index: 2; 
}
.overlay{
    background-color: rgba(49,49,49,0.8); 
    
}

.model_content{
    position: absolute;
    width: 45%;
    height: 300px;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
    border: 3.5px solid #ff7200;
    z-index: 100;
    overflow: hidden;
}
.model_content.open{
    height: 550px;
    animation: mymove;
    animation-duration: 0.5s;
}
@keyframes mymove {
    0%{
        height: 300px;
    }
    100%{
        height: 550px;
    }
}
.model_content.close{
    height: 300px;
    animation: mymoveBack;
    animation-duration: 0.5s;
}
@keyframes mymoveBack {
    0%{
        height: 550px;
    }
    100%{
        height: 300px;
    }
}
.sigCloseBtn:active{
    transform: translateY(-2px);   
}

#sigClose{
font-size: 1.2rem;
}
.newbtn{
    position: absolute;
    cursor: pointer;
    top: -8px;
    width: 30px;
    border-radius: 8px !important;
    height: 28px;
    right: -16px;
   background-color: #ff7200 !important;
    color: white;
    border: none;
    transform: translateY(-6px);
    border: none;
}
  
.sigCloseBtn{
    width: 30px;
    height: 29px;
   position: absolute;
   top: 0px;
   border-radius: 5px;
   right: 0px;
   background-color: #ff7200;
   border: 1px solid #000 !important;
   border: none;
   overflow: visible;
}
  
.sigCloseBtn:active{
    transform: translateY(-2px);   
}

#sigClose{
font-size: 1.2rem;
}

#sign_area{
    width: 62%;
    height: 200px;
    position: relative;
    top: 10%;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
#sign_area h3{
    font-size: 20px;
    position: relative;
    bottom: 3px;
}

.singnatureCanvas{
    border: 2px dashed silver;
    width: 100%;
    min-height: 150px;
}
.hide_text{
    display: none;
}

.show_hide_text{
    color: #ff7200;
    padding-left: 15px;
    position: relative;
    bottom: 13px;
}

.hide_textt{
    display: none;
}



.Load, .loader_overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  
   
}
.loader_overlay{
    background-color: rgba(49,49,49,0.8);
}

.loader_content{
    position: absolute;
    width: auto;
    height: 50px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #fff; */
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
   text-align:center ;
}

.loadingg{
    margin-left: auto;
    margin-right: auto;
}
.pallate{
    background-color: #ff7200;
    width: 80px;
    height: 24px;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
}
.pallateIcon{
    color: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet);
}
.colorPallateElem{
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    top: 80px;
    align-items: center;
    justify-content: center;
}
.signFooter{
    position: relative;
    top: 0px;
    background-color: #E8E8E8;
}
}
@media only screen and (max-width:1450px){
    
.group_btns{
    width: 58%;
    height: 40px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 14%;
    position: relative;
    display: flex;
    justify-content: space-between;
  
    text-align: center;
    align-items: center;
}

.save{
    width: 75px;
    height: 24px;
    background-color: green;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.save:active{
    transform: translateY(-2px);   

}
#save_icon{
  font-size: 17px;  
  padding-top: 2px;
  color: #f1f1f1;
}




.show{
    width: 75px;
    height: 24px;
    background-color: rgb(78, 78, 233);
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.show:active{
    transform: translateY(-2px);   

}
    

#eye{
    font-size: 17px;
    padding-top: 2px;
    color: black;
}
.clear{
    width: 75px;
    height: 24px;
   background-color: red;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.clear:active{
    transform: translateY(-2px);   

}

#delete{
    font-size: 16px;
    padding-top: 3px;
    color: white;
}
.pallate{
    background-color: #ff7200;
    width: 75px;
    height: 24px;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
}

}
@media only screen and (max-width:1351px){
    
.rnd{
    /* min-height: 80px !important; */
    display: flex;
    flex-direction: column;
    border:  2px dotted #ddd;
    text-align: start;
    word-wrap: break-word;
    background-color: transparent;
    padding-left: 3px;
}

.draggableText{
    font-size: 0.75rem;
    font-weight: 600;
    position: relative;
    padding: 0px;
}
#specified_text{
    font-size: 9px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: -1px;
    padding-left: 5px; 
}
.draggableTextNew{
    color: #ff7200;
    font-size: 0.6rem;
    display: block;
}
.signElem{
    height: 16px;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    /* justify-content: space-between !important; */
}
.draggableTextSign{
    width: auto !important;
    display: block !important;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
}
.resizeableImg{
    height: 20px;
}
  
#fetched_ltn{
    color: black;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: -4px;
    padding-left: 2px;
}


.hide_plz{
    display: none;
}
.show_plz{
    display: block;
}

#hide_specified{display: none;}
#notspecified{display: none;}

.not_movable{
    width: 68%;
    height: 37px;
    cursor: pointer;
    display: flex;
    position: relative;
    top: -20px;
}

.group_btns{
    width: 52%;
    height: 40px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 14%;
    position: relative;
    display: flex;
    justify-content: space-between;
  
    text-align: center;
    align-items: center;
}

.save{
    width: 60px;
    height: 24px;
    background-color: green;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.save:active{
    transform: translateY(-2px);   

}
#save_icon{
  font-size: 17px;  
  padding-top: 2px;
  color: #f1f1f1;
}




.show{
    width: 60px;
    height: 24px;
    background-color: rgb(78, 78, 233);
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.show:active{
    transform: translateY(-2px);   

}
    

#eye{
    font-size: 17px;
    padding-top: 2px;
    color: black;
}
.clear{
    width: 60px;
    height: 24px;
   background-color: red;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
   
}
  
.clear:active{
    transform: translateY(-2px);   

}

#delete{
    font-size: 16px;
    padding-top: 3px;
    color: white;
}
.pallate{
    background-color: #ff7200;
    width: 60px;
    height: 24px;
    border-radius: 8px  ;
    cursor: pointer;
    border: none;
    transform: translateY(-6px);
    border-radius: 12px ;
    border: none;
    box-shadow: 0px 3px rgb(78, 40, 40);
}

.model_content{
    position: absolute;
    width: 55%;
    height: 300px;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
    border: 3.5px solid #ff7200;
    z-index: 100;
    overflow: hidden;
    animation-duration: 0.5s !important;
}
.model_content.open{
    height: 540px !important;
    animation: mymove;
    overflow: auto;
    animation-duration: 0.5s !important;
}
@keyframes mymove {
    0%{
        height: 300px;
    }
    100%{
        height: 540px !important;
    }
}
.model_content.close{
    height: 300px;
    animation: mymoveBack;
    animation-duration: 0.5s !important;
}
@keyframes mymoveBack {
    0%{
        height: 540px;
    }
    100%{
        height: 300px;
    }
}
#sign_area{
    width: 78%;
    height: 200px;
    position: relative;
    top: 10%;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.colorPallateElem{
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    top: 75px;
    align-items: center;
    justify-content: center;
}


}
@media only screen and (max-width:1100px){
    .boxElem{
        position: absolute;
        bottom: 135px;
        left: 5px;
    }
    .rnd{
        /* min-height: 80px !important; */
        display: flex;
        flex-direction: column;
        border:  2px dotted #ddd;
        text-align: start;
        word-wrap: break-word;
        background-color: transparent;
        padding-left: 3px;
    }
    
    .draggableText{
        font-size: 0.65rem;
        font-weight: 600;
        position: relative;
        padding: 0px;
    }
    #specified_text{
        font-size: 9px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .draggableTextNew{
        color: #ff7200;
        font-size: 0.6rem;
        display: block;
    }
    .signElem{
        height: 15px;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        /* justify-content: space-between !important; */
    }
    .draggableTextSign{
        width: auto !important;
        display: block !important;
        padding: 0px;
        font-size: 0.62rem;
        font-weight: 600;
        cursor: pointer;
    }
    .resizeableImg{
        height: 20px;
    }
    #fetched_ltn{
        color: black;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -4px;
        padding-left: 2px;
    }
    

    .sign_div{
        width: 100%;
        height: auto;
        background-color: #E8E8E8;
         
    }
      
    .pdf_sign{
        width: 70%;
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(240, 239, 239);
        overflow: hidden;
        padding-bottom: 0px;
    
    }
    
    .choosen_image{
        position: relative;
        border-bottom: 1px solid #888888;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transform: rotateX(180deg);
    }
    
    .choosen_image_1{
        position: relative;
      border-bottom: 1px solid grey;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* transform: rotateX(180deg); */
    }
    
        
.hide_plz{
    display: none;
}
.show_plz{
    display: block;
}

#hide_specified{display: none;}

#notspecified{display: none;}

    /*  */
    .Download{
        width: 100%;
        height: 100px;
        position: relative;
        display: flex;
        top: 0px;
        text-align: center;
        align-items:center ;
        justify-content: center;
        background-color: #E8E8E8;
    }
    .download_btn{
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        height: 25px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        position: relative;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 0.7rem;
        display: flex;
        background-color: #ff7200;
        color: #fff;
      margin-right: 15px;
       
    }
    
    .emailButton{
        width: fit-content !important;
        height: 25px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        display: flex;
       
        /* border: 1px solid #fff; */
        background-color: #ff7200;
        color: #fff;
    }
    .button_text{
        height:100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
        font-size: 0.7rem;
    }
    .button_icon{
        width: auto  !important;
        padding-left: 4px;
        padding-right: 4px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .infoIcon{
        background-color: #ff7200;
        color: #fff;
    }
    .Download .titleBox{
        width:200px;
        height: auto;
        padding: 5px;
        background-color: #727376;
        position: absolute;
        top: 40px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: none;
    }
    .titleBox::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -7px;
        left: 8%;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #727376;
        overflow: hidden;
    }
    .Download>.emailButton .button_icon:hover>.titleBox{
        min-width:fit-content;
        height: auto;
        padding: 5px;
        background-color: #727376;
        color: #666666;
        position: absolute;
        top: 30px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: block !important;
    }
    
    .titleBox span{
        color: #fff;
        font-size: 0.65rem;
    }
    
    .group_btns{
        width: 55%;
        height: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 13%;
        position: relative;
        display: flex;
        justify-content: space-between;
       /* background-color: #ff7200; */
        text-align: center;
        align-items: center;
    }
    
    .save{
        width: 62px;
        height: 24px;
        background-color: green;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
       
    }
      
    .save:active{
        transform: translateY(-2px);   
    
    }
    #save_icon{
      font-size: 17px;  
      padding-top: 2px;
      color: #f1f1f1;
    }
    .show{
        width: 62px;
        height: 24px;
        background-color: rgb(78, 78, 233);
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40); 
    }
      
    .show:active{
        transform: translateY(-2px);   
    }
        
    #eye{
        font-size: 17px;
        padding-top: 2px;
    }
    .clear{
        width: 62px;
        height: 24px;
       background-color: red;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
       
    }
      
    .clear:active{
        transform: translateY(-2px);   
    
    }
    
    #delete{
        font-size: 16px;
        padding-top: 3px;
        color: white;
    }
    .pallate{
        background-color: #ff7200;
        width: 62px;
        height: 24px;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
    }
    .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 2;
    }
    .overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .model_content{
        position: absolute;
        width: 68%;
        height: 300px;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        border: 3.5px solid #ff7200;
        z-index: 100;
    }
    .sigCloseBtn{
        width: 30px;
        height: 29px;
       position: absolute;
       top: 0px;
       border-radius: 5px;
       right: 0px;
       background-color: #ff7200;
       border: none;
    }
    .model_content.open{
        height: 490px;
        animation: mymove;
        animation-duration: 0.5s !important;
        overflow: auto;
    }
    @keyframes mymove {
        0%{
            height: 300px;
        }
        100%{
            height: 490px;
        }
    }
    .model_content.close{
        height: 300px;
        animation: mymoveBack;
        animation-duration: 0.5s !important;
    }
    @keyframes mymoveBack {
        0%{
            height: 490px;
        }
        100%{
            height: 300px;
        }
    }
      
    .sigCloseBtn:active{
        transform: translateY(-2px);   
    }
    
    #sigClose{
    font-size: 1.2rem;
    }
    
    #sign_area{
        width: 78% !important;
        height: 210px;
        position: relative;
        top: 8%;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
    }
    .singnatureCanvas{
        border: 2px dashed silver;
        width: 100%;
        min-height: 150px;
    }
    /*  */
    .hide_text{
        display: none;
    }
    
    .show_hide_text{
        color: #ff7200;
        padding-left: 15px;
        position: relative;
        bottom: 13px;
    }
    
    .hide_textt{
        display: none;
    }
    .colorPallateElem{
        width: 100%;
        height: 150px;
        display: flex;
        position: relative;
        top: 98px;
        align-items: center;
        justify-content: center;
    }
    .tray{
        height: 180px !important;
    }
    }
@media only screen and (max-width:800px){
    .boxElem{
        position: absolute;
        bottom: 135px;
        left: 5px;
    }
    .rnd{
        /* min-height: 80px !important; */
        display: flex;
        flex-direction: column;
        border:  2px dotted #ddd;
        text-align: start;
        word-wrap: break-word;
        background-color: transparent;
        padding-left: 1px;
    }
    
    .draggableText{
        font-size: 0.62rem;
        font-weight: 600;
        position: relative;
        padding: 0px;
    }
    #specified_text{
        font-size: 9px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .draggableTextNew{
        color: #ff7200;
        font-size: 0.6rem;
        display: block;
    }
    .signElem{
        height: 15px;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        /* justify-content: space-between !important; */
    }
    .draggableTextSign{
        width: auto !important;
        display: block !important;
        font-size: 0.64rem;
        font-weight: 600;
        cursor: pointer;
    }
    .resizeableImg{
        height: 20px;
    }
    #fetched_ltn{
        color: black;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -4px;
        padding-left: 2px;
    }

    .sign_div{
        width: 100%;
        height: auto;
        background-color: #E8E8E8;
    }
        
    .pdf_sign{
        width: 90%;
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(240, 239, 239);
        overflow: hidden;
    
    }
    
    .choosen_image{
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transform: rotateX(180deg);
        border-bottom: 1px solid #888888;
    }
    
    .choosen_image_1{
        position: relative;
        border-bottom: 1px solid grey;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* transform: rotateX(180deg); */
    }
    
    .form_data{
        width:auto;
        height: 120px;
        border: 2px dashed silver;
        cursor: grab;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        bottom: 7px;
        left: 23px;
        text-align: left;
    }
    
    #namee{
        color: black;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top: 14px;
        padding-left: 8px;
        font-family: 'Montserrat', sans-serif;
    }
    
    
    #emaill{
        color: black;
        font-weight: 600;
        font-size: 12px;
        padding-left: 8px;
        position: relative;
        top: 8px;
        font-family: 'Montserrat', sans-serif;
    }
    
    #placee{
        color: black;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        padding-left: 0px;
        top: -6px;
        left: -3px;
    }
    
    
    #fetched_ltn{
        color: black;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -6px;
        left: -6px;
    }
    
    #specified_text{
        font-size: 9px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .hide_plz{
        display: none;
    }
    .show_plz{
        display: block;
    }
    
    #hide_specified{display: none;}
    
    #placee_colored{
        color: black;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: 6px;
        padding-left: 1px;
    }
    #specified{
        color: black;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: 0px;
        padding-left: 1px;
    }
    
    #notspecified{display: none;}
    
    #timee{
        color: black;
        font-size: 11px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -22px;
        left: -3px;
        padding-left: 0px;
    }
    
    .not_movable{
        width: 60%;
        height: 27px;
        cursor: pointer;
        display: flex;
        position: relative;
        top: -27px;
        align-items: center;
        text-align: center;
    }
    #sign_{
        color: #000;
        font-size: 11px;
        padding-left: 10px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
      }
    
      #sign_done{
        color: red;
        font-size: 15px;
        text-decoration: underline;
        padding-left: 15px;
        font-weight: 600;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        animation: blinker 3s linear infinite;
      }
      .conditional_text{
        padding-left: 10px;
        color: #ff7200;
        position: relative;
        bottom: 23px;
        font-size: 8.5px;
    }
    #last_sign {
        width: 40%;
        height: 27px;
        position: relative;
        left: 23px;
        bottom: -5px;
    }
    
    /*  */
    .Download{
        width: 100%;
        height: 100px;
        position: relative;
        display: flex;
        top: 0px;
        text-align: center;
        align-items:center ;
        justify-content: center;
        background-color: #E8E8E8;
    }
    .download_btn{
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        height: 25px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        position: relative;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 0.7rem;
        display: flex;
        background-color: #ff7200;
        color: #fff;
      margin-right: 15px;
       
    }
    
    .emailButton{
        width: auto !important;
        height: 25px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        display: flex;
        background-color: #ff7200;
        color: #fff;
    }
    .button_text{
        height:100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
        font-size: 0.7rem;
    }
    .button_icon{
        width: auto  !important;
        padding-left: 2px;
        padding-right: 2px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .infoIcon{
        background-color: #ff7200;
        color: #fff;
    }
    .Download .titleBox{
        width:140px;
        height: auto;
        padding: 5px;
        background-color: #727376;
        position: absolute;
        top: 30px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: none;
    }
    .titleBox::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -7px;
        left: 12%;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #727376;
        overflow: hidden;
    }
    .Download>.emailButton .button_icon:hover>.titleBox{
        min-width:fit-content;
        height: auto;
        padding: 5px;
        background-color: #727376;
        color: #666666;
        position: absolute;
        top: 30px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: block !important;
    }
    
    .titleBox span{
        color: #fff;
        font-size: 0.65rem;
    }
    .group_btns{
        width: 68%;
        height: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 13%;
        position: relative;
        display: flex;
        justify-content: space-between;
        /* background-color: #ff7200; */
        text-align: center;
        align-items: center;
    }
    
    .save{
        width: 70px;
        height: 24px;
        background-color: green;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .save:active{
        transform: translateY(-2px);   
    
    }
    #save_icon{
        font-size: 17px;  
        padding-top: 2px;
        color: #f1f1f1;
    }
    
    
    
    
    .show{
        width: 70px;
        height: 24px;
        background-color: rgb(78, 78, 233);
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .show:active{
        transform: translateY(-2px);   
    
    }
        
    
    #eye{
        font-size: 17px;
        padding-top: 2px;
    }
    .clear{
        width: 70px;
        height: 24px;
        background-color: red;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .clear:active{
        transform: translateY(-2px);   
    
    }
    
    #delete{
        font-size: 16px;
        padding-top: 3px;
        color: white;
    }
    .pallate{
        background-color: #ff7200;
        width: 70px;
        height: 24px;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
    }
    .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 2;
       
    }
    .overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .model_content{
        position: absolute;
        width: 80%;
        height: 300px;
        top: 52%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        border: 3.5px solid #ff7200;
        z-index: 100;
    }
    .sigCloseBtn{
        width: 30px;
        height: 29px;
       position: absolute;
       top: 0px;
       border-radius: 5px;
       right: 0px;
       background-color: #ff7200;
       border: none;
    }
    .model_content.open{
        height: 495px !important;
        animation: mymove;
        animation-duration: 0.5s  !important;
    }
    @keyframes mymove {
        0%{
            height: 300px;
        }
        100%{
            height: 475px !important;
        }
    }
    .model_content.close{
        height: 300px;
        animation: mymoveBack ease-in;
        animation-duration: 0.5s !important;
    }
    @keyframes mymoveBack {
        0%{
            height: 475px;
        }
        100%{
            height: 300px;
        }
    }
      
    .sigCloseBtn:active{
        transform: translateY(-2px);   
    }
    
    #sigClose{
    font-size: 1.2rem;
    }
    
    #sign_area{
        width: 78% !important;
        height: 210px;
        position: relative;
        top: 10%;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
    }
    .singnatureCanvas{
        border: 2px dashed silver;
        width: 100%;
        min-height: 150px;
    }
    /*  */
    .hide_text{
        display: none;
    }
    
    .show_hide_text{
        color: #ff7200;
        padding-left: 15px;
        position: relative;
        bottom: 13px;
    }
    
    .hide_textt{
        display: none;
    }
    .colorPallateElem{
        width: 100%;
        height: 150px;
        display: flex;
        position: relative;
        top: 75px;
        align-items: center;
        justify-content: center;
    }
    .tray{
        height: 150px !important;
    }
 
    }
@media only screen and (max-width:600px){
    .boxElem{
        position: absolute;
        bottom: 135px;
        left: 5px;
    }
    .rnd{
        /* min-height: 80px !important; */
        display: flex;
        flex-direction: column;
        border:  2px dotted #ddd;
        text-align: start;
        word-wrap: break-word;
        background-color: transparent;
        padding-left: 1px;
    }
    
    .draggableText{
        font-size: 0.62rem;
        font-weight: 600;
        position: relative;
        padding: 0px;
    }
    #specified_text{
        font-size: 8px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .draggableTextNew{
        color: #ff7200;
        font-size: 0.6rem;
        display: block;
    }
    .signElem{
        height: 14px;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        /* justify-content: space-between !important; */
    }
    .draggableTextSign{
        width: auto !important;
        display: block !important;
        font-size: 0.6rem;
        font-weight: 600;
        cursor: pointer;
    }
    .resizeableImg{
        height: 20px;
    }
      
    #fetched_ltn{
        color: black;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -4px;
        padding-left: 2px;
    }


    .sign_div{
        width: 100%;
        height: auto;
        background-color: #E8E8E8; 
    }
        
    .pdf_sign{
        width: 90%;
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(240, 239, 239);
        overflow: hidden;
    }
    
    .choosen_image{
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transform: rotateX(180deg);
        border-bottom: 1px solid #888888;
    }
    
    .choosen_image_1{
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-bottom: 1px solid grey;
    }
    
    
       .hide_plz{
        display: none;
    }
    .show_plz{
        display: block;
    }
    
    #hide_specified{display: none;}
    
    #notspecified{display: none;}
    #last_sign {
        width: 40%;
        height: 27px;
        position: relative;
        left: 23px;
        bottom: -5px;
    }
    /*  */
    .Download{
        width: 100%;
        height: 100px;
        position: relative;
        display: flex;
        top: 0px;
        text-align: center;
        align-items:center ;
        justify-content: center;
        background-color: #E8E8E8;
    }
    .download_btn{
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        position: relative;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 0.65rem;
        display: flex;
        background-color: #ff7200;
        color: #fff;
      margin-right: 10px;
       
    }
    
    .emailButton{
        width: fit-content !important;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        display: flex;
        /* border: 1px solid #fff; */
        background-color: #ff7200;
        color: #fff;
    }
    .button_text{
        height:100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
        font-size: 0.65rem;
    }
    .button_icon{
        padding-left: 2px;
        padding-right: 2px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .infoIcon{
        background-color: #ff7200;
        color: #fff;
    }
    .Download .titleBox{
        width:140px;
        height: auto;
        padding: 5px;
        background-color: #727376;
        position: absolute;
        top: 30px;
        left: -50px !important;
        border-radius: 4px;
        z-index: 9999999999;
        display: none;
    }
    .titleBox::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -7px;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #727376;
        overflow: hidden;
    }
    .Download>.emailButton .button_icon:hover>.titleBox{
        min-width:fit-content;
        height: auto;
        padding: 5px;
        background-color: #727376;
        color: #666666;
        position: absolute;
        top: 30px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: block !important;
    }
    
    .titleBox span{
        color: #fff;
        font-size: 0.65rem;
    }
    .group_btns{
        width: 75%;
        height: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 15%;
        position: relative;
        display: flex;
        justify-content: space-between;
        /* background-color: #ff7200; */
        text-align: center;
        align-items: center;
    }
    
    .save{
        width: 52px;
        height: 20px;
        background-color: green;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .save:active{
        transform: translateY(-2px);   
    
    }
    #save_icon{
        font-size: 15px;  
        padding-top: 0px;
        color: #f1f1f1;
    }
    
    
    
    
    .show{
        width: 52px;
        height: 20px;
        background-color: rgb(78, 78, 233);
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
 
    .show:active{
        transform: translateY(-2px);   
    
    }
        
    
    #eye{
        font-size: 15px;
        padding-top: 0px;
    }
    .clear{
        width: 52px;
        height: 20px;
        background-color: red;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .clear:active{
        transform: translateY(-2px);   
    
    }
    .pallate{
        background-color: #ff7200;
        width: 52px;
        height: 20px;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
    }
    
    #delete{
        font-size: 13px;
        position: relative;
        bottom: 2px;
        padding-top: px !important;
        color: white;
    }
    .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 2;
        
    }
    .overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .model_content{
        position: absolute;
        width: 87%;
        height: 275px !important ;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        border: 3px solid #ff7200;
        z-index: 100;
    }
    .model_content.open{
        height: 475px !important;
        animation: mymove;
        animation-duration: 0.5s !important;
        
    }
    @keyframes mymove {
        0%{
            height: 275px !important;
        }
        100%{
            height: 475px !important;
        }
    }
    .model_content.close{
        height: 300px;
        animation: mymoveBack ease-in;
        animation-duration: 0.5s !important;
    }
    @keyframes mymoveBack {
        0%{
            height: 475px !important;
        }
        100%{
            height: 275px !important;
        }
    }
    .sigCloseBtn{
        width: 30px;
        height: 29px;
       position: absolute;
       top: 0px;
       border-radius: 5px;
       right: 0px;
       background-color: #ff7200;
       border: none;
    }
      
    .sigCloseBtn:active{
        transform: translateY(-2px);   
    }
    
    #sigClose{
    font-size: 1.2rem;
    }
    
    #sign_area{
        width: 78%;
        height: 189px;
        position: relative;
        top: 10%;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
    }
    .singnatureCanvas{
        border: 2px dashed silver;
        width: 100%;
        min-height: 120px;
    }
    /*  */
    .hide_text{
        display: none;
    }
    
    .show_hide_text{
        color: #ff7200;
        padding-left: 15px;
        position: relative;
        bottom: 13px;
    }
    
    .hide_textt{
        display: none;
    }
    .colorPallateElem{
        width: 100%;
        height: 150px;
        display: flex;
        position: relative;
        top: 79px;
        align-items: center;
        justify-content: center;
    }
    .tray{
        height: 150px !important;
    }
   
    }  
@media only screen and (max-width:400px){
    .boxElem{
        position: absolute;
        bottom: 135px;
        left: 5px;
    }
    .rnd{
        /* min-height: 89px !important; */
        display: flex;
        flex-direction: column;
        border:  2px dotted #ddd;
        text-align: start;
        word-wrap: break-word;
        background-color: transparent;
        padding-left: 1px;
    }
    
    .draggableText{
        font-size: 0.6rem;
        font-weight: 600;
        position: relative;
        padding: 0px;
    }
    #specified_text{
        font-size: 7px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }
    .draggableTextNew{
        color: #ff7200;
        font-size: 0.55rem;
        display: block;
    }
    .signElem{
        height: 14px;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: center;
        /* justify-content: space-between !important; */
    }
    .draggableTextSign{
        width: auto !important;
        display: block !important;
        font-size: 0.58rem;
        font-weight: 600;
        cursor: pointer;
    }
    .resizeableImg{
        height: 20px;
    }
      
    #fetched_ltn{
        color: black;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        top: -4px;
        padding-left: 2px;
    }

    .sign_div{
        width: 100%;
        height: auto;
        background-color: #E8E8E8; 
    }
        
    .pdf_sign{
        width: 94%;
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(240, 239, 239);
        overflow: hidden;
        padding-bottom: 0px;
    
    }
    
    .choosen_image{
        position: relative;
        border-bottom: 1px solid #888888;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transform: rotateX(180deg);
    }
    
    .choosen_image_1{
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-bottom: 1px solid grey;
    }
    

    .hide_plz{
        display: none;
    }
    .show_plz{
        display: block;
    }
    
    #hide_specified{display: none;}
    
    #notspecified{display: none;}
    #last_sign {
        width: 40%;
        height: 27px;
        position: relative;
        left: 23px;
        bottom: -5px;
    }
    /*  */
    .Download{
        width: 100%;
        height: 100px;
        position: relative;
        display: flex;
        top: 0px;
        text-align: center;
        align-items:center ;
        justify-content: center;
        background-color: #E8E8E8;
    }
    .download_btn{
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        position: relative;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 0.60rem;
        display: flex;
        background-color: #ff7200;
        color: #fff;
      margin-right: 10px;
       
    }
    
    .emailButton{
        width: fit-content !important;
        height: 30px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        display: flex;
        /* border: 1px solid #fff; */
        background-color: #ff7200;
        color: #fff;
    }
    .button_text{
        height:100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
        font-size: 0.60rem;
    }
    .button_icon{
        padding-left: 2px;
        padding-right: 2px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .infoIcon{
        background-color: #ff7200;
        color: #fff;
    }
    .Download .titleBox{
        width:120px;
        height: auto;
        padding: 5px;
        background-color: #727376;
        position: absolute;
        top: 30px;
        left: -85px !important;
        border-radius: 4px;
        z-index: 9999999999;
        display: none;
    }
    .titleBox::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -7px;
        right:-90px !important;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #727376;
        overflow: hidden;
    }
    .Download>.emailButton .button_icon:hover>.titleBox{
        min-width:fit-content;
        height: auto;
        padding: 5px;
        background-color: #727376;
        color: #666666;
        position: absolute;
        top: 30px;
        left: 0px;
        border-radius: 4px;
        z-index: 9999999999;
        display: block !important;
    }
    
    .titleBox span{
        color: #fff;
        font-size: 0.6rem;
    }
    .group_btns{
        width: 68%;
        height: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 16%;
        position: relative;
        display: flex;
        justify-content: space-between;
        /* background-color: #ff7200; */
        text-align: center;
        align-items: center;
    }
    
    .save{
        width: 52px;
        height: 20px;
        background-color: green;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .save:active{
        transform: translateY(-2px);   
    
    }
    #save_icon{
        font-size: 14px;  
        padding-top: 0px;
        color: #f1f1f1;
    }
    
    
    
    
    .show{
        width: 52px;
        height: 20px;
        background-color: rgb(78, 78, 233);
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .show:active{
        transform: translateY(-2px);   
    
    }
        
    
    #eye{
        font-size: 14px;
        padding-top: 0px;
    }
    .clear{
        width: 52px;
        height: 20px;
        background-color: red;
        border-radius: 8px  ;
        cursor: pointer;
        border: none;
        transform: translateY(-6px);
        border-radius: 12px ;
        border: none;
        box-shadow: 0px 3px rgb(78, 40, 40);
        
    }
        
    .clear:active{
        transform: translateY(-2px);   
    
    }
    
    #delete{
        font-size: 13px;
        padding-top: 3px;
        color: white;
    }
    .modal, .overlay{
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 2;
        
    }
    .overlay{
        background-color: rgba(49,49,49,0.8);
    }
    
    .model_content{
        position: absolute;
        width: 90%;
        height: 250px !important;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        border: 3px solid #ff7200;
        z-index: 100;
    }
    .model_content.open{
        height: 450px !important;
        animation: mymove;
        animation-duration: 0.5s  !important;
    }
    @keyframes mymove {
        0%{
            height: 250px !important;
        }
        100%{
            height: 450px !important;
        }
    }
    .model_content.close{
        height: 250px !important;
        animation: mymoveBack ease-in;
        animation-duration: 0.5s !important;
    }
    @keyframes mymoveBack {
        0%{
            height: 450px !important;
        }
        100%{
            height: 250px !important;
        }
    }
    
    .sigCloseBtn{
        width: 30px;
        height: 29px;
       position: absolute;
       top: 0px;
       border-radius: 5px;
       right: 0px;
       background-color: #ff7200;
       border: none;
    }
      
    .sigCloseBtn:active{
        transform: translateY(-2px);   
    }
    
    #sigClose{
    font-size: 1.2rem;
    }
    
    #sign_area{
        width: 78%;
        height: 165px;
        position: relative;
        top: 13%;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
    }
    .singnatureCanvas{
        border: 2px dashed silver;
        width: 100%;
        min-height: 120px;
    }
    /*  */
    .hide_text{
        display: none;
    }
    
    .show_hide_text{
        color: #ff7200;
        padding-left: 15px;
        position: relative;
        bottom: 13px;
    }
    
    .hide_textt{
        display: none;
    }
    }          







